import { Injectable } from '@angular/core';
import { APIResponse } from '@mommy/models/Comm.model';
import { MemberBabyInfo } from '@mommy/models/MemberBabyInfo.model';
import { Api } from '@mommy/services/api';

@Injectable({
  providedIn: 'root',
})
export class MemberBabyService {
  constructor(private api: Api) {}

  get_list(data?: any): Promise<any> {
    const endpoint = 'v1/member/member-baby';

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.get(endpoint).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-baby list get error', error);
        reject(error);
      }
    });
  }

  delete(member_baby_id): Promise<any> {
    const endpoint = `v1/member/member-baby/${member_baby_id}`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.delete(endpoint).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-baby delete error', error);
        reject(error);
      }
    });
  }

  add(memberBaby: MemberBabyInfo): Promise<any> {
    const endpoint = `v1/member/member-baby`;

    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};
        params = memberBaby;

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-baby add error', error);
        reject(error);
      }
    });
  }

  update(memberBaby: MemberBabyInfo): Promise<any> {
    const endpoint = `v1/member/member-baby/${memberBaby.member_baby_id}`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.put(endpoint, memberBaby).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-baby update error', error);
        reject(error);
      }
    });
  }
}
