import { gql } from 'apollo-angular';
import { expertFragment } from '../expert/expert.graphql';
import { hospitalFragment } from '../hospital/hospital.graphql';
import { postFragment } from '../post/post.graphql';
import { voteFragment } from '../vote/vote.graphql';

export const QUERY_MULTIPLE_LIST = gql`
query ($expert_updated_at: Float!, $hospital_updated_at: Float!, $post_updated_at: Float!, $vote_updated_at: Float!) {
  getExpertList(updated_at: $expert_updated_at) {
    ...expertBase
  }
  getHospitalList(updated_at: $hospital_updated_at) {
    ...hospitalBase
  }
  getPostList(updated_at: $post_updated_at) {
    ...postBase
  }
  getVoteList(updated_at: $vote_updated_at) {
    ...voteBase
  }
}
${expertFragment},
${hospitalFragment}
${postFragment}
${voteFragment}
`;
