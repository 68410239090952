import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SwiperComponent } from 'swiper/angular';
import { SwiperOptions } from 'swiper/types/swiper-options';

import SwiperCore, { Zoom } from 'swiper';
SwiperCore.use([Zoom]);

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.page.html',
  styleUrls: ['./image-modal.page.scss'],
})
export class ImageModalPage implements OnInit, AfterViewInit {
  @ViewChild('image_modal_swiper') swiper: SwiperComponent;

  @Input() img: string;
  config: SwiperOptions = {
    zoom: {
      maxRatio: 10,
      minRatio: 0.5,
    },
  };

  swipeState = {
    phase: 'init',
    direction: 'none',
    swipeType: 'none',
    startX: 0,
    startY: 0,
    distance: 0,
    distanceX: 0,
    distanceY: 0,
    threshold: 150, // required min distance traveled to be considered swipe
    restraint: 100, // maximum distance allowed at the same time in perpendicular direction
    allowedTime: 500, // maximum time allowed to travel that distance
    elapsedTime: 0,
    startTime: 0,
  };

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.initSwipeToClose(true);
  }

  onSwiper(params: any) {
    // console.log(params);
    // const [swiper] = params;
    // console.log(swiper);
  }

  zoom(zoomIn) {
    const zoom = this.swiper.swiperRef.zoom;
    if (zoomIn) {
      zoom.in();
    } else {
      zoom.out();
    }
  }

  close() {
    this.modalController.dismiss();
  }

  initSwipeToClose(isActive: boolean = true) {
    if (!isActive) {
      return;
    }

    const el = document.querySelector('ion-modal');
    el.addEventListener('mousedown', (event) => this.swipeStart(event), true);
    el.addEventListener('mousemove', (event) => this.swipeMove(event), true);
    el.addEventListener('mouseup', (event) => this.swipeEnd(event), true);
    el.addEventListener('touchstart', (event) => this.swipeStart(event), true);
    el.addEventListener('touchmove', (event) => this.swipeMove(event), true);
    el.addEventListener('touchend', (event) => this.swipeEnd(event), true);

    // 下面會出現 undefined error, 先忽略它, 放在 dismiss 時處理
    // this.modalController.getTop().then((modal) => {
    //   console.log('modal', modal);
    //   modal.onWillDismiss().then(() => {
    //     document.removeEventListener('mousedown', this.swipeStart, true);
    //     document.removeEventListener('mousemove', this.swipeMove, true);
    //     document.removeEventListener('mouseup', this.swipeMove, true);
    //     document.removeEventListener('touchstart', this.swipeStart, true);
    //     document.removeEventListener('touchmove', this.swipeMove, true);
    //     document.removeEventListener('touchend', this.swipeMove, true);
    //   });
    // });
  }

  swipeStart(event) {
    const { pageX, pageY } =
      event.type === 'touchstart' ? event.changedTouches[0] : event;

    this.swipeState = {
      ...this.swipeState,
      phase: 'start',
      direction: 'none',
      distance: 0,
      startX: pageX,
      startY: pageY,
      startTime: new Date().getTime(),
    };
  }

  swipeMove(event) {
    if (this.swipeState.phase === 'none') {
      return;
    }
    const { pageX, pageY } =
      event.type === 'touchmove' ? event.changedTouches[0] : event;
    // get horizontal dist traveled by finger while in contact with surface
    const distanceX = pageX - this.swipeState.startX;
    // get vertical dist traveled by finger while in contact with surface
    const distanceY = pageY - this.swipeState.startY;
    let direction;
    let distance;

    if (Math.abs(distanceX) > Math.abs(distanceY)) {
      // if distance traveled horizontally is greater than vertically, consider this a horizontal swipe
      direction = distanceX < 0 ? 'left' : 'right';
      distance = distanceX;
    } else {
      // else consider this a vertical swipe
      direction = distanceY < 0 ? 'up' : 'down';
      distance = distanceY;
    }
    this.swipeState = {
      ...this.swipeState,
      phase: 'move',
      direction,
      distance,
      distanceX,
      distanceY,
    };
    event.preventDefault();
  }

  swipeEnd(event) {
    if (this.swipeState.phase === 'none') {
      return;
    }
    const {
      allowedTime,
      direction,
      restraint,
      startTime,
      threshold,
      distanceX,
      distanceY,
    } = this.swipeState;
    let swipeType;

    const elapsedTime = new Date().getTime() - startTime; // get time elapsed
    if (elapsedTime <= allowedTime) {
      // first condition for a swipe met
      if (
        Math.abs(distanceX) >= threshold &&
        Math.abs(distanceY) <= restraint
      ) {
        // 2nd condition for horizontal swipe met
        swipeType = direction; // set swipeType to either "left" or "right"
      } else if (
        Math.abs(distanceY) >= threshold &&
        Math.abs(distanceX) <= restraint
      ) {
        // 2nd condition for vertical swipe met
        swipeType = direction; // set swipeType to either "top" or "down"
      }
    }

    this.swipeState = {
      ...this.swipeState,
      phase: 'end',
      swipeType,
    };

    if (swipeType === 'down') {
      return this.closeModal();
    }
  }

  closeModal() {
    try {
      document.removeEventListener('mousedown', this.swipeStart, true);
      document.removeEventListener('mousemove', this.swipeMove, true);
      document.removeEventListener('mouseup', this.swipeMove, true);
      document.removeEventListener('touchstart', this.swipeStart, true);
      document.removeEventListener('touchmove', this.swipeMove, true);
      document.removeEventListener('touchend', this.swipeMove, true);
    } catch (error) {
      console.error(error);
    }

    this.modalController.dismiss();
  }
}
