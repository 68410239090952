import { Injectable } from '@angular/core';
import { environment } from '@mommy/environments/environment';
import { StorageService } from '@mommy/services/storage.service';
import { AppSettings } from 'app/app.settings';
import { format, parseISO } from 'date-fns';
import addDays from 'date-fns/addDays';
import * as _ from 'lodash';

//type CacheDataType = 'Post' | 'Expert' | 'Hospital';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  constructor(private storage: StorageService) {}

  // 取得 cache 的最後一筆的 updateAt (Cursor)
  getDataCursor2(dataType: string) {
    console.log('getDataCursor2', dataType);

    return new Promise(async (resolve, reject) => {
      const data_key = `${dataType}`;

      // 若查無cache,則依照設定給予default cursor
      const default_cursor = () => {
        const _default_cursor_days = environment.DEFAULT_CURSOR[dataType];
        console.warn('_default_cursor_days', _default_cursor_days);
        if (_default_cursor_days) {
          const result_cursor = addDays(
            new Date(),
            _default_cursor_days
          ).getTime();
          console.warn('result_cursor', result_cursor);
          return result_cursor;
        } else {
          return 0;
        }
      };

      try {
        const myData: any = await this.storage.get(data_key);
        console.log('myData', myData);

        if (myData) {
          const first_raw = _.chain(myData)
            .orderBy(['updated_at'], ['desc'])
            .head()
            .value();

          console.log('first_raw', first_raw);

          if (first_raw) {
            const result_cursor = parseISO(first_raw.updated_at);
            console.log(
              'result_cursor',
              dataType,
              format(result_cursor, 'yyyy-MM-dd HH:mm:ss')
            );
            resolve(result_cursor.getTime()); // 1653618401429
          } else {
            resolve(default_cursor());
          }
        } else {
          console.log('no data, return 0');
          resolve(default_cursor());
        }
      } catch (error) {
        console.error(error);
        console.log('查無cache, 回傳0');
        resolve(default_cursor());
      }
    });
  }

  // 合併cache資料 及 剛呼叫 api 取得的資料
  // key, ex: post_id , expert_id
  mergeData2(online_data: any, dataType: string, key: string) {
    console.warn('mergeData2', dataType);

    return new Promise(async (resolve, reject) => {
      // 1. load cache data
      // 2. union cache and api data
      // 3. save to cache
      const data_key = `${dataType}`;

      try {
        let cache_data: any;

        try {
          cache_data = await this.storage.get(data_key);
        } catch (error) {
          console.log(error.message);
          cache_data = null;
        }

        console.warn('cache_data2', cache_data);
        console.warn('online_data2', online_data);

        let new_data;
        if (cache_data) {
          // merge local data & online messages
          // 若id相同,以firebase的資料為準(like delete->insert)
          // const diff_temp = _.differenceBy(cache_data, online_data, 'id');
          // const new_cache_temp = _.unionBy(diff_temp, online_data, 'id');

          // unionBy, 若id相同,優先取前面陣列的item
          // const new_cache_temp = _.unionBy(online_data, cache_data, 'id');
          // new_data = _.sortBy(new_cache_temp, 'createdAt');
          // this.storage.set(data_key, new_data);

          // 因為要保留 local 的資料(ex: read_flag)
          // 故方法為: (id相同者)update + unionBy覆蓋
          const overlap_data = _.intersectionBy(cache_data, online_data, key);
          const overlap_data2 = _.map(overlap_data, (elem: any) => {
            const _new_data = _.find(online_data, { [key]: elem[key] });
            return _.assign(elem, _new_data); // 更新資料 with online data,並保留local的資料
          });

          const new_online_data = _.unionBy(overlap_data2, online_data);
          const new_cache_temp = _.unionBy(new_online_data, cache_data, key);
          new_data = _.sortBy(new_cache_temp, 'createdAt');
        } else {
          new_data = _.sortBy(online_data, 'createdAt');
        }

        this.storage.set(data_key, new_data);

        // 儲存當下資料的版本編號, 以利下次讀取資料時,判斷是否要full refresh
        const app_version = AppSettings.APP_VERSION_CODE;
        this.storage.set(`${dataType}_version`, app_version);

        console.warn('new_data2', new_data);
        console.warn('mergeData2 done.');
        resolve(new_data);
      } catch (error) {
        console.error('mergeData2', error);
        reject(error);
      }
    });
  }

  // 更新cache資料
  setData2(online_data: any, dataType: string) {
    console.log('setData2', dataType);

    return new Promise(async (resolve, reject) => {
      // 3. save to cache
      const data_key = `${dataType}`;

      try {
        console.log('online_data', online_data);
        await this.storage.set(data_key, online_data);

        console.log('setData2 done.');
        resolve(online_data);
      } catch (error) {
        console.error('setData', error);
        reject(error);
      }
    });
  }

  // 將資料設定至指定的屬性
  patchData2(online_data: any, dataType: string, property: string) {
    console.log('patchData2', dataType);

    return new Promise(async (resolve, reject) => {
      // 3. save to cache
      const data_key = `${dataType}`;

      try {
        console.log('online_data', online_data);
        const exist_data = await this.storage.get(data_key);
        exist_data[property] = online_data[property];
        await this.storage.set(data_key, exist_data);

        console.log('patchData2 done.');
        resolve(exist_data);
      } catch (error) {
        console.error('patchData2', error);
        reject(error);
      }
    });
  }
}
