import { Component, Input, OnInit } from '@angular/core';
import { OrderReturnInfo } from '@mommy/models/ec/OrderReturnInfo.model';
import { format, parseISO } from 'date-fns';

@Component({
  selector: 'app-ec-order-return-info',
  templateUrl: './ec-order-return-info.component.html',
  styleUrls: ['./ec-order-return-info.component.scss'],
})
export class EcOrderReturnInfoComponent implements OnInit {
  _orderReturn: OrderReturnInfo;
  _applyAt;
  _returnAmount = 0;

  _display: 'all' | '' = '';

  constructor() {}

  ngOnInit() {}

  get orderReturn(): any {
    return this._orderReturn;
  }

  @Input() set orderReturn(value: OrderReturnInfo) {
    console.log('order', value);
    this._orderReturn = value;
    if (value) {
      this._applyAt = format(parseISO(value?.applyAt), 'yyyy/MM/dd HH:mm');
    }
  }

  get display(): any {
    return this._display;
  }

  @Input() set display(value: 'all' | '') {
    console.log('display', value);
    this._display = value || '';
  }
}
