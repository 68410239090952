import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { environment } from '@mommy/environments/environment';
import * as _ from 'lodash';
import { SwiperComponent } from 'swiper/angular';
import { SwiperOptions } from 'swiper/types/swiper-options';

@Component({
  selector: 'app-related-product',
  templateUrl: './related-product.component.html',
  styleUrls: ['./related-product.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RelatedProductComponent implements OnInit, AfterContentChecked {
  @ViewChild('swiper_related_products') swiperProduct: SwiperComponent;
  @Output() productClick: EventEmitter<any> = new EventEmitter<any>();

  config: SwiperOptions = {
    spaceBetween: 10,
    // autoplay: true,
    pagination: false,
    slidesPerView: 'auto',
  };

  _related_products: any;

  constructor() {
    console.log('RelatedProductComponent');
  }

  ngOnInit() {}

  get related_products(): any {
    return this._related_products;
  }

  @Input() set related_products(value: any) {
    console.log('related_products', value);
    if (value) {
      const new_value = _.cloneDeep(value);
      _.forEach(new_value, (commodity) => {
        commodity.backgroundImagePath =
          `url(${environment.EC_IMAGE_CDN_URL + commodity.imagePath})` +
          ', url(assets/img/default/ec_commodity_default.png)';
      });
      this._related_products = new_value;
      console.log('this._related_products', this._related_products);
    } else {
      this._related_products = [];
    }
  }

  ngAfterContentChecked(): void {
    if (this.swiperProduct) {
      console.log('swiperProduct', this.swiperProduct);
      this.swiperProduct.updateSwiper({});
    }
  }

  onProductClick(product) {
    console.log('onProductClick', product);
    this.productClick.emit(product);
  }
}
