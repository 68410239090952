import { MemberBabyGrowRecordInfo } from '@mommy/models/MemberBabyGrowRecordInfo.model';
import { MemberBabyInfo } from '@mommy/models/MemberBabyInfo.model';

export class GetMemberBabyGrowRecords {
  static readonly type = '[MemberBabyGrowRecord] GetMemberBabyGrowRecords';
}

export class RefreshMemberBabyGrowRecords {
  static readonly type = '[MemberBabyGrowRecord] RefreshMemberBabyGrowRecords';
}

export class AddMemberBabyGrowRecord {
  static readonly type = '[MemberBabyGrowRecord] AddMemberBabyGrowRecord';
  constructor(public payload: MemberBabyGrowRecordInfo) {}
}

export class ModifyMemberBabyGrowRecord {
  static readonly type = '[MemberBabyGrowRecord] ModifyMemberBabyGrowRecord';
  constructor(public payload: MemberBabyGrowRecordInfo) {}
}

export class RemoveMemberBabyGrowRecord {
  static readonly type = '[MemberBabyGrowRecord] RemoveMemberBabyGrowRecord';
  constructor(public grow_id: number) {}
}

export class ChangeMemberBaby {
  static readonly type = '[MemberBabyGrowRecord] ChangeMemberBaby';
  constructor(public member_baby: MemberBabyInfo) {}
}
