export class InitLocalCacheZipcode {
  static readonly type = '[Zipcode] InitLocalCache';
}

export class LoadCacheZipcode {
  static readonly type = '[Zipcode] LoadCache';
}

export class RefreshZipcode {
  static readonly type = '[Zipcode] Refresh';
}
