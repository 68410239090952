import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PostInfo } from '@mommy/models/PostInfo.model';

@Component({
  selector: 'app-video-post-list2',
  templateUrl: './video-post-list2.component.html',
  styleUrls: ['./video-post-list2.component.scss'],
})
export class VideoPostList2Component implements OnInit {
  @Output() postOnClick = new EventEmitter<PostInfo>();

  _post: PostInfo;
  _feature_image;

  constructor() {}

  ngOnInit() {}

  get post(): any {
    return this._post;
  }

  @Input() set post(value: any) {
    if (value) {
      this._post = value;
      this._feature_image =
        `url(${value.post_feature_image_url})` +
        ', url(assets/img/default/video_default.png)';

      console.log('video _post', value);
    } else {
      this._post = {};
    }
  }

  postClick() {
    this.postOnClick.emit(this._post);
  }
}
