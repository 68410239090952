import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mommy/environments/environment';

/**
 * Api is a generic REST Api handler. Set your API url first.
 */
@Injectable({
  providedIn: 'root',
})
export class ECApi {
  url = '';
  debug_url = '';

  myheader: HttpHeaders = new HttpHeaders();

  constructor(public http: HttpClient) {
    this.url = environment.EC_API_URL;
  }

  get(endpoint: string, params?: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
      };
    }

    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      // eslint-disable-next-line guard-for-in
      for (const k in params) {
        reqOpts.params.set(k, params[k]);
      }
    }

    return this.http.get(this.url + '/' + endpoint, reqOpts);
  }

  post(endpoint: string, body: any, options?) {
    console.log('api post endpoint', this.url + '/' + endpoint);
    console.log('api post body', body);
    console.log('api post options', options);
    return this.http.post(this.url + '/' + endpoint, body, options);
  }

  put(endpoint: string, body: any, options?) {
    return this.http.put(this.url + '/' + endpoint, body, options);
  }

  // delete(endpoint: string, body: any, options?: RequestOptions) {
  //   return this.http.post(this.url + '/' + endpoint, body, options);
  // }

  // patch(endpoint: string, body: any, options?: RequestOptions) {
  //   return this.http.put(this.url + '/' + endpoint, body, options);
  // }
}
