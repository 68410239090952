import { Injectable } from '@angular/core';
import { APIResponse } from '@mommy/models/Comm.model';
import { Api } from '@mommy/services/api';

@Injectable({
  providedIn: 'root',
})
export class MemberTaskService {
  constructor(private api: Api) {}

  retrive_award(member_task_id: number): Promise<any> {
    const endpoint = 'v1/member/member-task2/retrive_award';

    return new Promise(async (resolve, reject) => {
      try {
        const param: any = {};
        param.member_task_id = member_task_id;

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, param).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-task retrive_award error', error);
        reject(error);
      }
    });
  }
}
