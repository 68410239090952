import { Observable, throwError, timer } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';

export const genericRetryStrategy =
  ({
    maxRetryAttempts = 2,
    scalingDuration = 1000,
    excludedStatusCodes = [304, 400, 401, 403, 404],
    excludedUrl = ['auth/logout', '/hospital'],
  }: {
    maxRetryAttempts?: number;
    scalingDuration?: number;
    excludedStatusCodes?: number[];
    excludedUrl?: string[];
  } = {}) =>
  (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error, i) => {
        console.log('error', error);
        const excludeUrlFlag = excludedUrl.some((url) =>
          error.url.includes(url)
        );
        console.log('excludeUrlFlag', error.url, excludeUrlFlag);

        const retryAttempt = i + 1;
        // if maximum number of retries have been met
        // or response is a status code we don't wish to retry, throw error
        if (
          retryAttempt > maxRetryAttempts ||
          excludedStatusCodes.find((e) => e === error.status) ||
          excludeUrlFlag
        ) {
          // if (error.status === 401) {
          //   console.warn('got 401 error', error);
          // } else if (error.status === 403) {
          //   console.warn('got 403 error', error);
          // }
          return throwError(error);
        }
        console.log(
          `Attempt ${retryAttempt}: retrying in ${
            retryAttempt * scalingDuration
          }ms`
        );
        // retry after 1s, 2s, etc...
        // return timer(retryAttempt * scalingDuration);
        return timer(1 * scalingDuration);
      }),
      finalize(() => console.log('We are done!'))
    );
  };
