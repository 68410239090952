import { Injectable } from '@angular/core';
import { CodeTaxonomyInfo } from '@mommy/models/CodeTaxonomyInfo.model';
import { ElineUser } from '@mommy/models/Eline.model';
import { MyInfo } from '@mommy/models/MyInfo.model';
import { AuthService } from '@mommy/services/auth/auth.service';
import { ElineUserService } from '@mommy/services/eline/eline-user-service';
import { StorageService } from '@mommy/services/storage.service';
import { UserService } from '@mommy/services/user/user.service';
import {
  Action,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { addDays, differenceInDays, parseISO } from 'date-fns';
import * as _ from 'lodash';
import { AuthState } from '../auth/auth.state';
import { TaxonomyState } from '../taxonomy/taxonomy.state';
import {
  ClearUser,
  InitLocalCacheUser,
  LoadCacheUser,
  RefreshECUser,
  RefreshElineUser,
  RefreshMommyUser,
  UpdateMommyUser,
  UpdateMommyUserProfile,
} from './user.actions';

export interface UserStateModel {
  loading: boolean;
  mommy_user: MyInfo;
  eline_user: ElineUser;
  ec_user: MyInfo;
  hasCache: boolean;
}

const defaultUserState = (): UserStateModel => {
  return {
    loading: false,
    mommy_user: null,
    eline_user: null,
    ec_user: null,
    hasCache: false,
  };
};

@State<UserStateModel>({
  name: 'UserState',
  defaults: defaultUserState(),
})
@Injectable()
export class UserState implements NgxsAfterBootstrap {
  constructor(
    private storage: StorageService,
    private userService: UserService,
    private elineUserService: ElineUserService,
    private store: Store,
    private authService: AuthService
  ) {}

  async ngxsAfterBootstrap(ctx: StateContext<UserStateModel | null>) {
    console.log('[UserState] ngxsAfterBootstrap');

    // try {
    //   await ctx.dispatch(new LoadCacheMaternityKit()).toPromise();
    //   console.log('load local cache maternitykit success');
    //   // 再呼叫 getAllMaternityKit 來更新 local cache
    //   this.getMaternityKitFromServer(ctx);
    // } catch (error) {
    //   console.warn('LoadCacheMaternityKit error', error);
    //   // 如果沒有 cache, 就去 server 取
    //   this.getMaternityKitFromServer(ctx);
    // }
  }

  @Selector()
  static mommy_user(state: UserStateModel) {
    return state.mommy_user;
  }

  @Selector()
  static eline_user(state: UserStateModel) {
    return state.eline_user;
  }

  @Selector()
  static ec_user(state: UserStateModel) {
    return state.ec_user;
  }

  @Action(InitLocalCacheUser)
  async InitLocalCacheUser(ctx: StateContext<UserStateModel>) {
    console.log('[Action] InitLocalCacheUser');

    // 策略調整為：
    // 1. 先載入 local cache，如果沒有就抓 server (full)
    // 2. 載入完 cache 再抓 server 更新的資料 (incremental)
    try {
      await ctx.dispatch(new LoadCacheUser()).toPromise();
      console.log('load local cache user success');
      // 再呼叫 getExpertsFromServer 來更新 local cache
      //this.getExpertsFromServer(ctx);

      const mommy_isAuthenticated = await this.store.selectSnapshot(
        AuthState.isMommyAuthenticated
      );
      if (mommy_isAuthenticated) {
        await ctx.dispatch(new RefreshMommyUser()).toPromise();
      }

      const eline_isAuthenticated = await this.store.selectSnapshot(
        AuthState.isElineAuthenticated
      );
      if (eline_isAuthenticated) {
        await ctx.dispatch(new RefreshElineUser()).toPromise();
      }

      const ec_isAuthenticated = await this.store.selectSnapshot(
        AuthState.isECAuthenticated
      );
      if (ec_isAuthenticated) {
        await ctx.dispatch(new RefreshECUser()).toPromise();
      }
    } catch (error) {
      console.warn('LoadCacheUser error', error);
      // 如果沒有 cache, 就去 server 取
      const mommy_isAuthenticated = await this.store.selectSnapshot(
        AuthState.isMommyAuthenticated
      );
      if (mommy_isAuthenticated) {
        await ctx.dispatch(new RefreshMommyUser()).toPromise();
      }

      const eline_isAuthenticated = await this.store.selectSnapshot(
        AuthState.isElineAuthenticated
      );
      if (eline_isAuthenticated) {
        await ctx.dispatch(new RefreshElineUser()).toPromise();
      }

      const ec_isAuthenticated = await this.store.selectSnapshot(
        AuthState.isECAuthenticated
      );
      if (ec_isAuthenticated) {
        await ctx.dispatch(new RefreshECUser()).toPromise();
      }
    }
  }

  @Action(LoadCacheUser)
  async loadCacheUser(ctx: StateContext<UserStateModel>) {
    console.log('[Action] LoadCacheUser');

    const state = ctx.getState();
    let mommy_user: any = await this.storage.get('mommy_user');
    if (mommy_user) {
      mommy_user = this.map_user_object(mommy_user);
    }

    const eline_user: any = await this.storage.get('eline_user');
    const ec_user: any = await this.storage.get('ec_user');

    ctx.patchState({
      mommy_user,
      eline_user,
      ec_user,
      hasCache: true,
    });
  }

  @Action(RefreshMommyUser)
  async refreshMommyUser(ctx: StateContext<UserStateModel>) {
    console.log('[Action] RefreshMommyUser');
    await this.getMommyUserFromServer(ctx);
  }

  @Action(RefreshElineUser)
  async refreshElineUser(ctx: StateContext<UserStateModel>) {
    console.log('[Action] RefreshElineUser');
    await this.getElineUserFromServer(ctx);
  }

  @Action(RefreshECUser)
  async refreshECUser(ctx: StateContext<UserStateModel>) {
    console.log('[Action] RefreshECUser');
    //this.getECUserFromServer(ctx);
  }

  @Action(ClearUser)
  async ClearUser(ctx: StateContext<UserStateModel>) {
    console.log('[Action] ClearUser');
    ctx.patchState({
      mommy_user: null,
      eline_user: null,
      ec_user: null,
      hasCache: false,
    });
  }

  @Action(UpdateMommyUser)
  async UpdateMommyUser(ctx: StateContext<UserStateModel>, { payload }) {
    console.log('[Action] UpdateMommyUser');
    ctx.patchState({
      mommy_user: payload,
    });
  }

  // 更新user profile資料 to server(透過 eline laravel api)
  @Action(UpdateMommyUserProfile)
  async UpdateMommyUserProfile(ctx: StateContext<UserStateModel>, { payload }) {
    console.log('[Action] UpdateMommyUserProfile');

    try {
      const result: any = await this.authService.update_account(payload);
      console.log('result', result);
      ctx.dispatch(new RefreshMommyUser());
    } catch (error2) {
      console.error('UpdateMommyUserProfile error', error2);
      throw error2;
    }
  }

  private async getMommyUserFromServer(ctx: StateContext<UserStateModel>) {
    // try read data from server
    console.log('getMommyUserFromServer');
    try {
      let mommy_user: any = await this.userService.getMy();
      console.log('mommy_user', mommy_user);
      mommy_user = this.map_user_object(mommy_user);
      // await this.storage.set('user', user);
      // await ctx.dispatch(new LoadCacheUser()).toPromise();
      // console.log('load local cache user success');

      ctx.patchState({
        loading: false,
        mommy_user,
        hasCache: true,
      });

      if (mommy_user.member_maternitykits?.length > 0) {
        this.storage.set(
          'memberMaternitykits',
          mommy_user.member_maternitykits
        );
      }
    } catch (error2) {
      console.error('getMy error', error2);
      throw error2;
    }
  }

  private async getElineUserFromServer(ctx: StateContext<UserStateModel>) {
    console.log('getElineUserFromServer');
    try {
      const result: any = await this.elineUserService.get_user_me();
      console.log('get_user_me result', result);
      let eline_user = result.data;

      //整理家人模式
      eline_user = this.elineUserService.map_user_object(eline_user);
      await this.storage.set('eline_user', eline_user);

      ctx.patchState({
        loading: false,
        eline_user,
        hasCache: true,
      });
    } catch (error2) {
      console.error('elineUserService get_user_me error', error2);
      throw error2;
    }
  }

  private map_user_object(mommy_user) {
    // 整理孕期標籤
    // 先判斷預產期, 沒有再判斷lmp_date
    const today = new Date();
    let days = 0;

    try {
      const new_mommy_user = _.cloneDeep(mommy_user);

      if (mommy_user?.basic_info?.baby_due_date) {
        const baby_due_date = parseISO(mommy_user.basic_info.baby_due_date);
        // 預產期 -280天 = 最後一次月經日
        const lmp_date = addDays(baby_due_date, -280);
        days = differenceInDays(today, lmp_date); // left - right date
      } else if (mommy_user?.basic_info?.lmp_date) {
        const lmp_date = parseISO(mommy_user.basic_info.lmp_date);
        days = differenceInDays(today, lmp_date); // left - right date
      } else {
        days = -1;
      }

      let pregnancy = '';
      if (days >= 0 && days < 28) {
        pregnancy = '1個月';
      }
      if (days >= 28 && days < 56) {
        pregnancy = '2個月';
      }
      if (days >= 56 && days < 84) {
        pregnancy = '3個月';
      }
      if (days >= 84 && days < 112) {
        pregnancy = '4個月';
      }
      if (days >= 112 && days < 140) {
        pregnancy = '5個月';
      }
      if (days >= 140 && days < 168) {
        pregnancy = '6個月';
      }
      if (days >= 168 && days < 196) {
        pregnancy = '7個月';
      }
      if (days >= 196 && days < 224) {
        pregnancy = '8個月';
      }
      if (days >= 224 && days < 252) {
        pregnancy = '9個月';
      }
      if (days >= 252 && days < 280) {
        pregnancy = '10個月';
      }

      console.log('pregnancy', pregnancy);

      // 找出對應的 孕期標籤
      if (pregnancy) {
        const pregnancy_tags = this.store.selectSnapshot<CodeTaxonomyInfo[]>(
          TaxonomyState.taxonomyType('文章-孕期標籤')
        );
        const pregnancy_tag = _.filter(pregnancy_tags, (tag) =>
          tag?.taxonomy_name.includes(pregnancy)
        );
        if (pregnancy_tag.length > 0) {
          new_mommy_user.basic_info.pregnancy_tag_taxonomy_id =
            pregnancy_tag[0].taxonomy_id;
          new_mommy_user.basic_info.pregnancy_tag_taxonomy_name =
            pregnancy_tag[0].taxonomy_name;
        } else {
          new_mommy_user.basic_info.pregnancy_tag_taxonomy_id = null;
          new_mommy_user.basic_info.pregnancy_tag_taxonomy_name = null;
        }
      } else {
        new_mommy_user.basic_info.pregnancy_tag_taxonomy_id = null;
        new_mommy_user.basic_info.pregnancy_tag_taxonomy_name = null;
      }

      return new_mommy_user;
    } catch (error) {
      // 若發生錯誤, 則回傳原本的mommy_user
      console.error('map_user_object error', error);
      return mommy_user;
    }
  }
}
