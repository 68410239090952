import { Injectable } from '@angular/core';
import { CodeMaternitykitInfo } from '@mommy/models/CodeMaternitykitInfo.model';
import { APIResponse } from '@mommy/models/Comm.model';
import { Api } from '@mommy/services/api';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class MemberMaternitykitService {
  constructor(private api: Api) {}

  get_list(data?: any): Promise<any> {
    const endpoint = 'v1/member/member-maternitykit';

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.get(endpoint).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-maternitykit list get error', error);
        reject(error);
      }
    });
  }

  delete(member_maternitykit_id: number): Promise<any> {
    const endpoint = `v1/member/member-maternitykit/${member_maternitykit_id}`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.delete(endpoint).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-maternitykit delete error', error);
        reject(error);
      }
    });
  }

  add(maternitykit: CodeMaternitykitInfo): Promise<any> {
    const endpoint = `v1/member/member-maternitykit`;

    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};
        params = maternitykit;

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-maternitykit add error', error);
        reject(error);
      }
    });
  }

  sync(maternitykits: CodeMaternitykitInfo[]): Promise<any> {
    const endpoint = `v1/member/member-maternitykit/maternitykits`;

    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};
        const allowFields = [
          'member_maternitykit_id',
          'item_name',
          'item_desc',
          'item_image_url',
          'maternitykit_id',
          'maternitykit_type',
          'maternitykit_period_type',
          'related_product',
          'done_flag',
          'done_flag_ui',
        ];
        params = _.map(maternitykits, (maternitykit) => {
          return _.pick(maternitykit, allowFields);
        });

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-maternitykit sync error', error);
        reject(error);
      }
    });
  }

  update(maternitykit: CodeMaternitykitInfo): Promise<any> {
    const endpoint = `v1/member/member-maternitykit/${maternitykit.member_maternitykit_id}`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.put(endpoint, maternitykit).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-maternitykit update error', error);
        reject(error);
      }
    });
  }
}
