import { Injectable } from '@angular/core';
import { CodeLookupInfo } from '@mommy/models/CodeLookupInfo.model';
import { LookupService } from '@mommy/services/lookup/lookup.service';
import { StorageService } from '@mommy/services/storage.service';
import {
  Action,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import * as _ from 'lodash';
import {
  SetAppHomeBabyTopActivity,
  SetAppHomeBabyVideo,
  SetAppHomePregnancyTopActivity,
  SetAppHomePregnancyVideo,
} from '../app-home/app-home.actions';
import {
  SetUIPageHomeRecommendBabyPosts,
  SetUIPageHomeRecommendPregnancyPosts,
} from '../app-ui/app-ui.actions';
import { PostState } from '../post/post.state';
import { InitLocalCacheLookup, LoadCacheLookup } from './lookup.actions';

export interface LookupStateModel {
  loading: boolean;
  lookups: CodeLookupInfo[];
  hasCache: boolean;
}

const defaultLookupState = (): LookupStateModel => {
  return {
    loading: false,
    lookups: [],
    hasCache: false,
  };
};

@State<LookupStateModel>({
  name: 'LookupState',
  defaults: defaultLookupState(),
})
@Injectable()
export class LookupState implements NgxsAfterBootstrap {
  constructor(
    private storage: StorageService,
    private lookupSvc: LookupService,
    private store: Store
  ) {}

  async ngxsAfterBootstrap(ctx: StateContext<LookupStateModel | null>) {
    console.log('[LookupState] ngxsAfterBootstrap');

    // const getLookupFromServer = async () => {
    //   // try read data from server
    //   console.log('getLookupFromServer');
    //   try {
    //     const lookups = await this.lookupSvc.getAllLookup();
    //     console.log('lookups', lookups);
    //     await this.storage.set('lookups', lookups);
    //     await ctx.dispatch(new LoadCacheLookup()).toPromise();
    //   } catch (error2) {
    //     console.warn('getAllLookup error', error2);
    //   }
    // };

    // try {
    //   await ctx.dispatch(new LoadCacheLookup()).toPromise();
    //   console.log('load local cache lookups success');
    //   // 再呼叫 getAllLookup 來更新 local cache
    //   getLookupFromServer();
    // } catch (error) {
    //   console.warn('LoadCacheLookup error', error);
    //   // 如果沒有 cache, 就去 server 取
    //   getLookupFromServer();
    // }
  }

  // 百科熱門搜尋關鍵字
  @Selector([LookupState])
  static pediaHotTag(state: LookupStateModel) {
    return _.chain(state.lookups)
      .filter((x) => x.meta_key === 'TOP_ENCYCLOPEDIA_KEYWORDS')
      .first()
      .value();
  }

  // 能不能吃熱門搜尋關鍵字
  @Selector([LookupState])
  static canieatHotTag(state: LookupStateModel) {
    return _.chain(state.lookups)
      .filter((x) => x.meta_key === 'TOP_CAN_OR_NOT_EAT_KEYWORDS')
      .first()
      .value();
  }

  @Action(InitLocalCacheLookup)
  async InitLocalCacheLookup(ctx: StateContext<LookupStateModel>) {
    console.log('[Action] InitLocalCacheLookup');

    const getLookupFromServer = async () => {
      // try read data from server
      console.log('getLookupFromServer');
      try {
        const lookups = await this.lookupSvc.getAllLookup();
        console.log('lookups', lookups);
        await this.storage.set('lookups', lookups);
        await ctx.dispatch(new LoadCacheLookup()).toPromise();
      } catch (error2) {
        console.warn('getAllLookup error', error2);
      }
    };

    try {
      await ctx.dispatch(new LoadCacheLookup()).toPromise();
      console.log('load local cache lookups success');
      // 再呼叫 getAllLookup 來更新 local cache
      getLookupFromServer();
    } catch (error) {
      console.warn('LoadCacheLookup error', error);
      // 如果沒有 cache, 就去 server 取
      getLookupFromServer();
    }
  }

  @Action(LoadCacheLookup)
  async loadCacheLookup(ctx: StateContext<LookupStateModel>) {
    const state = ctx.getState();
    const _lookups: CodeLookupInfo[] = await this.storage.get('lookups');

    if (_lookups) {
      ctx.patchState({
        loading: false,
        lookups: _lookups,
        hasCache: true,
      });

      console.log('loadCacheLookup process recommend posts');
      // 設定首頁 推薦pgc post_ids
      this.process_home_pgc_recommend_posts(_lookups);

      // 設定首頁 熱門活動
      this.process_home_top_activity(_lookups);

      // 設定首頁 推薦視頻
      this.process_home_videos(_lookups);
    } else {
      throw new Error('no cache');
    }
  }

  // 設定首頁 推薦pgc post_ids
  process_home_pgc_recommend_posts(_lookups) {
    console.log('process_home_pgc_recommend_posts');
    const _recommendPostLookup = _.find(_lookups, {
      meta_key: 'HOME_RECOMMEND_ARTICLES',
    });
    if (_recommendPostLookup) {
      const _recommendPostMetaValue = _recommendPostLookup.meta_value;
      const baby_posts = _.chain(_recommendPostMetaValue)
        .filter({ mode: 'BABY' })
        .map('post_id')
        .value();

      const pregnancy_posts = _.chain(_recommendPostMetaValue)
        .filter({ mode: 'PREGNANCR' })
        .map('post_id')
        .value();

      console.log('baby_posts', baby_posts);
      console.log('pregnancy_posts', pregnancy_posts);

      this.store.dispatch(new SetUIPageHomeRecommendBabyPosts(baby_posts));
      this.store.dispatch(
        new SetUIPageHomeRecommendPregnancyPosts(pregnancy_posts)
      );
    }
  }

  // 設定首頁 熱門活動
  process_home_top_activity(_lookups) {
    console.log('process_home_top_activity');
    const _homeTopActivityLookup = _.find(_lookups, {
      meta_key: 'HOME_TOP_ACTIVITY',
    });

    if (
      _homeTopActivityLookup.meta_value &&
      _homeTopActivityLookup.meta_value.length > 0
    ) {
      const _baby_top_activities = [];
      const _pregnancy_top_activities = [];

      _.forEach(_homeTopActivityLookup.meta_value, (item) => {
        if (item.mode === 'BABY') {
          const data = _.find(_lookups, {
            meta_key: 'TOP_ACTIVITY',
            meta_id: item.meta_id,
          });
          if (data) {
            const new_data: any = _.cloneDeep(data.meta_value);
            new_data.meta_id = item.meta_id;
            new_data.background_image_url =
              `url(${data.meta_value.image_url})` +
              ', url(assets/img/default/activity_default.png)';
            _baby_top_activities.push(new_data);
          }
        } else if (item.mode === 'PREGNANCR') {
          const data = _.find(_lookups, {
            meta_key: 'TOP_ACTIVITY',
            meta_id: item.meta_id,
          });
          if (data) {
            const new_data: any = _.cloneDeep(data.meta_value);
            new_data.meta_id = item.meta_id;
            new_data.background_image_url =
              `url(${data.meta_value.image_url})` +
              ', url(assets/img/default/activity_default.png)';
            _pregnancy_top_activities.push(new_data);
          }
        }
      });

      console.log('_baby_top_activities', _baby_top_activities);
      console.log('_pregnancy_top_activities', _pregnancy_top_activities);

      this.store.dispatch(new SetAppHomeBabyTopActivity(_baby_top_activities));
      this.store.dispatch(
        new SetAppHomePregnancyTopActivity(_pregnancy_top_activities)
      );
    }
  }

  // 設定首頁 推薦視頻
  process_home_videos(_lookups) {
    console.log('process_home_videos');
    const _homeVideoLookup = _.find(_lookups, {
      meta_key: 'HOME_VIDEO',
    });

    if (_homeVideoLookup.meta_value && _homeVideoLookup.meta_value.length > 0) {
      const _home_baby_videos = [];
      const _home_pregnancy_videos = [];
      const _posts = this.store.selectSnapshot(PostState.posts);

      _.forEach(_homeVideoLookup.meta_value, (item) => {
        if (item.mode === 'BABY') {
          _home_baby_videos.push({ post_id: item.post_id });
        } else if (item.mode === 'PREGNANCR') {
          _home_pregnancy_videos.push({ post_id: item.post_id });
        }
      });

      console.log('_home_baby_videos', _home_baby_videos);
      console.log('_home_pregnancy_videos', _home_pregnancy_videos);

      this.store.dispatch(new SetAppHomeBabyVideo(_home_baby_videos));
      this.store.dispatch(new SetAppHomePregnancyVideo(_home_pregnancy_videos));
    }
  }
}
