import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { IonTextarea, ModalController, NavParams } from '@ionic/angular';
import { PostCommentInfo } from '@mommy/models/PostCommentInfo.model';
import { VoteCommentInfo } from '@mommy/models/VoteCommentInfo.model';
import { UtilService } from '@mommy/services/util.service';
import {
  AddMemberPostComment,
  ModifyMemberPostComment,
} from '@mommy/state/member-post-comment/member-post-comment.actions';
import {
  AddMemberVoteComment,
  ModifyMemberVoteComment,
} from '@mommy/state/member-vote-comment/member-vote-comment.actions';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';

@Component({
  selector: 'app-edit-comment-modal',
  templateUrl: './edit-comment-modal.page.html',
  styleUrls: ['./edit-comment-modal.page.scss'],
})
export class EditCommentModalPage implements OnInit, AfterViewInit {
  @ViewChild('commentInput') commentInput: IonTextarea;

  comment = '';
  post_id = 0;
  vote_id = 0;

  post_comment_id = 0;
  vote_comment_id = 0;

  obj_type;

  editType;
  _commentObj;

  constructor(
    public modalController: ModalController,
    public navParams: NavParams,
    private store: Store,
    private util: UtilService
  ) {
    this.post_id = this.navParams.get('post_id');
    this.vote_id = this.navParams.get('vote_id');
    this.obj_type = this.navParams.get('obj_type');
    this.editType = navParams.get('editType');
    this._commentObj = navParams.get('comment');
    console.log('_commentObj', this._commentObj);
  }

  ngOnInit() {
    if (this.editType === 'edit') {
      const _comment = _.cloneDeep(this._commentObj);

      if (this.obj_type === 'post') {
        this.post_comment_id = _comment.post_comment_id;
      } else if (this.obj_type === 'vote') {
        this.vote_comment_id = _comment.vote_comment_id;
      }
      this.comment = _comment.comment;
    }
  }

  ngAfterViewInit() {
    console.log('ngAfterViewInit this.commentInput:', this.commentInput);

    setTimeout(() => {
      for (let i = 0; i < 10; i++) {
        setTimeout(() => {
          this.commentInput.setFocus();
        }, 100 * i);
      }
    }, 100);
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  async submit() {
    console.log('submit comment');

    if (this.editType === 'edit') {
      // 修改評論
      if (this.obj_type === 'post') {
        try {
          const data: PostCommentInfo = {};
          data.post_comment_id = this.post_comment_id;
          data.comment = this.comment;

          await this.store
            .dispatch(new ModifyMemberPostComment(data))
            .toPromise();
          console.log('ModifyMemberPostComment success');
        } catch (error) {
          console.error('ModifyMemberPostComment error', error);
          this.util.showToast('發生錯誤');
          return;
        }
      } else if (this.obj_type === 'vote') {
        try {
          const data: VoteCommentInfo = {};
          data.vote_comment_id = this.vote_comment_id;
          data.comment = this.comment;

          await this.store
            .dispatch(new ModifyMemberVoteComment(data))
            .toPromise();
          console.log('ModifyMemberVoteComment success');
        } catch (error) {
          console.error('ModifyMemberVoteComment error', error);
          this.util.showToast('發生錯誤');
          return;
        }
      } else {
        console.error('unknown obj_type', this.obj_type);
      }
    } else {
      // 新增評論
      if (this.obj_type === 'post') {
        try {
          const data: PostCommentInfo = {};
          data.post_id = this.post_id;
          data.comment = this.comment;

          await this.store.dispatch(new AddMemberPostComment(data)).toPromise();
          console.log('AddMemberPostComment success');
        } catch (error) {
          console.error('addMemberPostComment error', error);
          this.util.showToast('發生錯誤');
          return;
        }
      } else if (this.obj_type === 'vote') {
        try {
          const data: VoteCommentInfo = {};
          data.vote_id = this.vote_id;
          data.comment = this.comment;

          await this.store.dispatch(new AddMemberVoteComment(data)).toPromise();
          console.log('AddMemberVoteComment success');
        } catch (error) {
          console.error('AddMemberVoteComment error', error);
          this.util.showToast('發生錯誤');
          return;
        }
      } else {
        console.error('unknown obj_type', this.obj_type);
      }
    }

    this.modalController.dismiss({
      comment: this.comment,
      refresh: true,
    });
  }
}
