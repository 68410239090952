/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ApolloClientService } from '@mommy/services/apollo-client.service';
import { APOLLO_OPTIONS } from 'apollo-angular';

export function resolveApolloClientOptions(
  apolloClientService: ApolloClientService
) {
  return () => apolloClientService.getOptionsPromise();
  // return () => {
  //   return apolloClientService.getOptionsPromise;
  // };
}

export function apolloClientServiceFactory(
  apolloClientService: ApolloClientService
) {
  return apolloClientService.getOptions();
}

@NgModule({
  exports: [HttpClientModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: resolveApolloClientOptions,
      deps: [ApolloClientService],
      multi: true,
    },
    ApolloClientService,
    {
      provide: APOLLO_OPTIONS,
      useFactory: apolloClientServiceFactory,
      deps: [ApolloClientService, APP_INITIALIZER],
    },
  ],
})
export class GraphQLModule {}
