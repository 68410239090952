import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { environment } from '@mommy/environments/environment';
import { ElineUserService } from '@mommy/services/eline/eline-user-service';
import { UtilService } from '@mommy/services/util.service';

@Component({
  selector: 'app-invite-qrcode-modal',
  templateUrl: './invite-qrcode-modal.page.html',
  styleUrls: ['./invite-qrcode-modal.page.scss'],
})
export class InviteQrcodeModalPage implements OnInit {
  display_type: 'INVITE' | 'SHOW_MY_USERCODE';
  user_code = '';
  user_display_name = '';

  qrcode_data = '';
  loading = false;

  constructor(
    public modalController: ModalController,
    public navParams: NavParams,
    private elineUserService: ElineUserService,
    private util: UtilService
  ) {
    this.display_type = this.navParams.get('display_type');
    this.user_code = this.navParams.get('user_code');
    this.user_display_name = this.navParams.get('user_display_name');
  }

  async ngOnInit() {
    await this.getUserCode();

    // 如果是 INVITE 模式，則產生 邀請的 url
    // 如果是 SHOW_MY_USERCODE 模式，則產生 孕關懷的 qrcode
    if (this.display_type === 'INVITE') {
      this.qrcode_data = `${environment.REFERRAL_REGISTER_URL}?referral_code=INVITE_${this.user_code}&openExternalBrowser=1`;
    } else if (this.display_type === 'SHOW_MY_USERCODE') {
      const data: any = {};
      data.user_code = this.user_code;
      data.display_name = this.user_display_name;
      this.qrcode_data = JSON.stringify(data);
    } else {
      console.error('Unknown display_type');
    }
    console.log('qrcode_data', this.qrcode_data);
  }

  getUserCode() {
    return new Promise(async (resolve, reject) => {
      this.loading = true;
      await this.util.sleep(1000);

      this.elineUserService
        .get_user_code()
        .then((resp) => {
          this.user_code = resp.data[0].user_code;
          this.loading = false;
          resolve(this.user_code);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.util.showError('很抱歉,資料讀取失敗,請稍候再試!');
          reject(error);
        });
    });
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
