import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIResponse } from '@mommy/models/Comm.model';
import { Api } from '../api';
import { HANDLE_CACHE } from '../token.interceptor';

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  constructor(private api: Api) {
    console.log('Hello LookupService');
  }

  getAllLookup(): Promise<any> {
    const endpoint = `v1/codes/reference-codes/all-lookups`;

    return new Promise(async (resolve, reject) => {
      try {
        const context = new HttpContext();
        context.set(HANDLE_CACHE, true);

        const result: APIResponse = (await this.api
          .get(endpoint, null, { context })
          .toPromise()) as any;
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        if (error.status !== 304) {
          console.error('getAllLookup error', error);
        }
        reject(error);
      }
    });
  }

  getByMetaCriteria(meta_type: string, meta_key: string): Promise<any> {
    const endpoint = `v1/codes/reference-codes/by-meta-criteria`;

    return new Promise(async (resolve, reject) => {
      try {
        const params: any = {};
        params.meta_type = meta_type;
        params.meta_key = meta_key;

        const result: APIResponse = (await this.api
          .post(endpoint, params)
          .toPromise()) as any;
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        if (error.status !== 304) {
          console.error('getAllLookup error', error);
        }
        reject(error);
      }
    });
  }
}
