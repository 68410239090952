import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sorting-header',
  templateUrl: './sorting-header.component.html',
  styleUrls: ['./sorting-header.component.scss'],
})
export class SortingHeaderComponent implements OnInit {
  @Input() columnName: string;
  @Input() columnDesc: string;
  @Output() sortTypeChanged = new EventEmitter<any>();

  _sort_type: '' | 'asc' | 'desc' = '';

  constructor() {}

  ngOnInit() {}

  changeSortType() {
    console.log('changeSortType');
    if (this._sort_type === '') {
      this._sort_type = 'asc';
      this.sortTypeChanged.emit({
        columnName: this.columnName,
        sortType: 'asc',
      });
    } else if (this._sort_type === 'asc') {
      this._sort_type = 'desc';
      this.sortTypeChanged.emit({
        columnName: this.columnName,
        sortType: 'desc',
      });
    } else if (this._sort_type === 'desc') {
      this._sort_type = '';
      this.sortTypeChanged.emit({ columnName: this.columnName, sortType: '' });
    }
  }
}
