export class GetMemberExpertWatchs {
  static readonly type = '[MemberExpertWatch] GetMemberExpertWatchs';
}

export class AddMemberExpertWatch {
  static readonly type = '[MemberExpertWatch] AddMemberExpertWatch';
  constructor(public expert_id: number) {}
}

export class RemoveMemberExpertWatch {
  static readonly type = '[MemberExpertWatch] RemoveMemberExpertWatch';
  constructor(public expert_id: number) {}
}
