import { Injectable } from '@angular/core';
import { PostInfo } from '@mommy/models/PostInfo.model';
import { PostPregnancyInfo } from '@mommy/models/PostPregnancyInfo.model';
import { PregnancyPostService } from '@mommy/services/pregnancy-post/pregnancy-post.service';
import { StorageService } from '@mommy/services/storage.service';
import { PostState } from '@mommy/state/post/post.state';
import {
  Action,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import * as _ from 'lodash';
import {
  InitLocalCachePregnancyPost,
  LoadCachePregnancyPost,
  RefreshPregnancyPost,
} from './pregnancy-post.actions';

export interface PregnancyPostStateModel {
  loading: boolean;
  pregnancyPosts: PostPregnancyInfo[];
  hasCache: boolean;
}

const defaultPregnancyPostState = (): PregnancyPostStateModel => {
  return {
    loading: false,
    pregnancyPosts: [],
    hasCache: false,
  };
};

@State<PregnancyPostStateModel>({
  name: 'PregnancyPostState',
  defaults: defaultPregnancyPostState(),
})
@Injectable()
export class PregnancyPostState implements NgxsAfterBootstrap {
  constructor(
    private storage: StorageService,
    private pregnancyPostService: PregnancyPostService
  ) {}

  async ngxsAfterBootstrap(ctx: StateContext<PregnancyPostStateModel | null>) {
    console.log('[PregnancyPostState] ngxsAfterBootstrap');

    // try {
    //   await ctx.dispatch(new LoadCacheMaternityKit()).toPromise();
    //   console.log('load local cache maternitykit success');
    //   // 再呼叫 getAllMaternityKit 來更新 local cache
    //   this.getMaternityKitFromServer(ctx);
    // } catch (error) {
    //   console.warn('LoadCacheMaternityKit error', error);
    //   // 如果沒有 cache, 就去 server 取
    //   this.getMaternityKitFromServer(ctx);
    // }
  }

  @Selector()
  static pregnancyPosts(state: PregnancyPostStateModel) {
    return state.pregnancyPosts;
  }

  @Selector([PregnancyPostState.pregnancyPosts, PostState.posts])
  static pregnancyPostsWithRelatedPosts(
    pregnancyPosts: PostPregnancyInfo[],
    posts: PostInfo[]
  ) {
    console.log(
      '[PregnancyPostState] pregnancyPostsWithRelatedPosts',
      pregnancyPosts
    );

    return _.chain(pregnancyPosts)
      .map((post) => {
        const new_post = _.cloneDeep(post);
        // map related_posts
        new_post.related_posts = [];
        if (new_post?.related_post?.post_id) {
          _.forEach(new_post.related_post.post_id, (post_id) => {
            const related_post = _.find(posts, { post_id });
            if (related_post) {
              new_post.related_posts.push(related_post);
            }
          });
        }
        return new_post;
      })
      .value();
  }

  @Action(InitLocalCachePregnancyPost)
  async initLocalCachePregnancyPost(
    ctx: StateContext<PregnancyPostStateModel>
  ) {
    const _pregnancyPosts: any = await this.storage.get('pregnancyPosts');

    if (_pregnancyPosts) {
      // do nothing
    } else {
      this.getPregnancyPostFromServer(ctx);
    }
  }

  @Action(LoadCachePregnancyPost)
  async loadCachePregnancyPost(ctx: StateContext<PregnancyPostStateModel>) {
    console.log('[Action] loadCachePregnancyPost');

    const state = ctx.getState();
    const _pregnancyPosts: any = await this.storage.get('pregnancyPosts');

    if (_pregnancyPosts) {
      ctx.patchState({
        loading: false,
        pregnancyPosts: _pregnancyPosts,
        hasCache: true,
      });
    } else {
      throw new Error('no cache');
    }
  }

  @Action(RefreshPregnancyPost)
  async refreshPregnancyPost(ctx: StateContext<PregnancyPostStateModel>) {
    console.log('[Action] refreshPregnancyPost');
    this.getPregnancyPostFromServer(ctx);
  }

  private async getPregnancyPostFromServer(
    ctx: StateContext<PregnancyPostStateModel>
  ) {
    // try read data from server
    console.log('getPregnancyPostFromServer');
    try {
      const pregnancyPosts =
        await this.pregnancyPostService.getAllPregnancyPost();
      console.log('pregnancyPosts', pregnancyPosts);
      await this.storage.set('pregnancyPosts', pregnancyPosts);
      await ctx.dispatch(new LoadCachePregnancyPost()).toPromise();
      console.log('load local cache pregnancyPosts success');
    } catch (error2) {
      console.warn('getAllPregnancyPost error', error2);
    }
  }
}
