import { Injectable } from '@angular/core';
import { ExpertInfo } from '@mommy/models/ExpertInfo.model';
import { MemberExpertWatchService } from '@mommy/services/member/member-expert-watch.service';
import { StorageService } from '@mommy/services/storage.service';
import { UserService } from '@mommy/services/user/user.service';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { UpdateMommyUser } from '../user/user.actions';
import {
  AddMemberExpertWatch,
  GetMemberExpertWatchs,
  RemoveMemberExpertWatch,
} from './member-expert-watch.actions';

export interface MemberExpertWatchStateModel {
  loading: boolean;
  expert_watchs: ExpertInfo[];
  hasCache: boolean;
}

const defaultMemberExpertWatchState = (): MemberExpertWatchStateModel => {
  return {
    loading: false,
    expert_watchs: [],
    hasCache: false,
  };
};

@State<MemberExpertWatchStateModel>({
  name: 'MemberExpertWatchState',
  defaults: defaultMemberExpertWatchState(),
})
@Injectable()
export class MemberExpertWatchState {
  constructor(
    private storage: StorageService,
    private memberExpertWatchSvc: MemberExpertWatchService,
    private userService: UserService,
    private store: Store
  ) {}

  @Action(GetMemberExpertWatchs)
  async GetMemberExpertWatchs(ctx: StateContext<MemberExpertWatchStateModel>) {
    console.log('[Action] GetMemberExpertWatchs');

    try {
      const data: any = await this.userService.getMemberExpertWatchs();
      console.log('getMemberExpertWatchs success', data);

      ctx.patchState({
        expert_watchs: data.expert_watchs,
      });

      // 更新 user state
      this.store.dispatch(new UpdateMommyUser(data));
    } catch (error) {
      console.warn('getMemberExpertWatchs error', error);
      throw error;
    }
  }

  @Action(AddMemberExpertWatch)
  async AddMemberExpertWatch(
    ctx: StateContext<MemberExpertWatchStateModel>,
    { expert_id }
  ) {
    console.log('[Action] AddMemberExpertWatch');

    try {
      const result = await this.memberExpertWatchSvc.add(expert_id);
      console.log('AddMemberExpertWatch success', result);
      this.store.dispatch(new GetMemberExpertWatchs());
    } catch (error) {
      console.error('AddMemberExpertWatch error', error);
      throw error;
    }
  }

  @Action(RemoveMemberExpertWatch)
  async RemoveMemberExpertWatch(
    ctx: StateContext<MemberExpertWatchStateModel>,
    { expert_id }
  ) {
    console.log('[Action] RemoveMemberExpertWatch');

    try {
      const result = await this.memberExpertWatchSvc.remove(expert_id);
      console.log('RemoveMemberExpertWatch success', result);
      this.store.dispatch(new GetMemberExpertWatchs());
    } catch (error) {
      console.error('RemoveMemberExpertWatch error', error);
      throw error;
    }
  }
}
