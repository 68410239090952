import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { HospitalInfo } from '@mommy/models/HospitalInfo.model';

@Component({
  selector: 'app-non-eline-hospital-modal',
  templateUrl: './non-eline-hospital-modal.page.html',
  styleUrls: ['./non-eline-hospital-modal.page.scss'],
})
export class NonElineHospitalModalPage implements OnInit {
  hospital: HospitalInfo;
  count = 1;

  constructor(
    public modalController: ModalController,
    public navParams: NavParams
  ) {
    this.hospital = this.navParams.get('hospital');
    console.log('this.hospital', this.hospital);
    this.count += this.hospital.eline_count || 0;
  }

  ngOnInit() {}

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
