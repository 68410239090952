import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CodeTaxonomyInfo } from '@mommy/models/CodeTaxonomyInfo.model';
import { CodeZipCodeInfo } from '@mommy/models/CodeZipCodeInfo.model';
import { MyLogEventParams } from '@mommy/models/Comm.model';
import { CapUtilService } from '@mommy/services/caputil.service';
import {
  SetUIPageHospitalSearchCity,
  SetUIPageHospitalSearchDistrict,
  SetUIPageHospitalSearchHardwareEquipments,
  SetUIPageHospitalSearchKeyword,
  SetUIPageHospitalSearchLifeFunctions,
  SetUIPageHospitalSearchMedicalCares,
  SetUIPageHospitalSearchResetAll,
  SetUIPageHospitalSearchSection2,
  SetUIPageHospitalSearchSubjectClasses,
  SetUIPageHospitalSearchTopCategorys,
} from '@mommy/state/app-ui/app-ui.actions';
import { AppUIState } from '@mommy/state/app-ui/app-ui.state';
import { TaxonomyState } from '@mommy/state/taxonomy/taxonomy.state';
import { LoadCacheZipcode } from '@mommy/state/zipcode/zipcode.actions';
import { ZipcodeState } from '@mommy/state/zipcode/zipcode.state';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';

@Component({
  selector: 'app-hospital-filter-modal',
  templateUrl: './hospital-filter-modal.page.html',
  styleUrls: ['./hospital-filter-modal.page.scss'],
})
export class HospitalFilterModalPage implements OnInit {
  // HospitalInfo
  top_categorys?: CodeTaxonomyInfo[];
  subject_classes?: CodeTaxonomyInfo[];
  medical_cares?: CodeTaxonomyInfo[];
  hardware_equipments?: CodeTaxonomyInfo[];
  life_functions?: CodeTaxonomyInfo[];
  section2?: CodeTaxonomyInfo[];

  selected_top_categorys?: any;
  selected_subject_classes?: any;
  selected_medical_cares?: any;
  selected_hardware_equipments?: any;
  selected_life_functions?: any;
  selected_section2?: any;

  searchKeyword = '';

  // zipcode 代碼相關變數
  city = '';
  districts: string[] = [];
  zipCodes: CodeZipCodeInfo[] = [];
  cities: string[] = [];
  // districts: string[] = [];
  selectedCity: string;
  cityDistrict: CodeZipCodeInfo[];

  constructor(
    public modalController: ModalController,
    private store: Store,
    private caputil: CapUtilService
  ) {}

  async ngOnInit() {
    this.resetAll();

    this.top_categorys = this.store.selectSnapshot(
      TaxonomyState.taxonomyType('醫院-熱門分類')
    );
    this.subject_classes = this.store.selectSnapshot(
      TaxonomyState.taxonomyType('醫院-科別專長')
    );
    this.medical_cares = this.store.selectSnapshot(
      TaxonomyState.taxonomyType('醫院-醫療護理')
    );
    this.hardware_equipments = this.store.selectSnapshot(
      TaxonomyState.taxonomyType('醫院-硬體設備')
    );
    this.life_functions = this.store.selectSnapshot(
      TaxonomyState.taxonomyType('醫院-生活機能')
    );
    this.section2 = this.store.selectSnapshot(
      TaxonomyState.taxonomyType('醫院-區域分類')
    );

    console.log('top_categorys', this.top_categorys);
    console.log('subject_classes', this.subject_classes);
    console.log('medical_cares', this.medical_cares);
    console.log('hardware_equipments', this.hardware_equipments);
    console.log('life_functions', this.life_functions);
    console.log('section2', this.section2);

    this.selected_top_categorys = this.store.selectSnapshot(
      AppUIState.page_hospital_search_top_categorys
    );

    this.selected_subject_classes = this.store.selectSnapshot(
      AppUIState.page_hospital_search_subject_classes
    );

    this.selected_medical_cares = this.store.selectSnapshot(
      AppUIState.page_hospital_search_medical_cares
    );

    this.selected_hardware_equipments = this.store.selectSnapshot(
      AppUIState.page_hospital_search_hardware_equipments
    );

    this.selected_life_functions = this.store.selectSnapshot(
      AppUIState.page_hospital_search_life_functions
    );

    this.selected_section2 = this.store.selectSnapshot(
      AppUIState.page_hospital_search_section2
    );

    await this.store.dispatch(new LoadCacheZipcode()).toPromise();
    this.zipCodes = this.store.selectSnapshot<any>(ZipcodeState.zipcodes);
    console.log('zipCodes', this.zipCodes);
    this.cities = _.uniq(_.map(this.zipCodes, 'city'));
    console.log('cities', this.cities);
  }

  resetAll() {
    this.selected_top_categorys = undefined;
    this.selected_subject_classes = undefined;
    this.selected_medical_cares = undefined;
    this.selected_hardware_equipments = undefined;
    this.selected_life_functions = undefined;
    this.selected_section2 = undefined;
    this.selectedCity = undefined;
    this.districts = undefined;
    this.searchKeyword = undefined;

    this.store.dispatch(new SetUIPageHospitalSearchResetAll());
  }

  selectTopCategorys(event) {
    console.log('selectTopCategorys', event.detail.value);
    this.store.dispatch(
      new SetUIPageHospitalSearchTopCategorys(event.detail.value)
    );

    const params: MyLogEventParams = {
      category: '熱門分類',
      action: '點擊',
      label: event.detail.value,
    };
    this.caputil.logEvent('醫院進階搜尋', params);
  }

  selectSubjectClasses(event) {
    console.log('selectSubjectClasses', event.detail.value);
    this.store.dispatch(
      new SetUIPageHospitalSearchSubjectClasses(event.detail.value)
    );

    const params: MyLogEventParams = {
      category: '科別專長',
      action: '點擊',
      label: event.detail.value,
    };
    this.caputil.logEvent('醫院進階搜尋', params);
  }

  selectMedicalCares(event) {
    console.log('selectMedicalCares', event.detail.value);
    this.store.dispatch(
      new SetUIPageHospitalSearchMedicalCares(event.detail.value)
    );

    const params: MyLogEventParams = {
      category: '醫療護理',
      action: '點擊',
      label: event.detail.value,
    };
    this.caputil.logEvent('醫院進階搜尋', params);
  }

  selectHardwareEquipments(event) {
    console.log('selectHardwareEquipments', event.detail.value);
    this.store.dispatch(
      new SetUIPageHospitalSearchHardwareEquipments(event.detail.value)
    );

    const params: MyLogEventParams = {
      category: '硬體設備',
      action: '點擊',
      label: event.detail.value,
    };
    this.caputil.logEvent('醫院進階搜尋', params);
  }

  selectLifeFunctions(event) {
    console.log('selectLifeFunctions', event.detail.value);
    this.store.dispatch(
      new SetUIPageHospitalSearchLifeFunctions(event.detail.value)
    );

    const params: MyLogEventParams = {
      category: '生活機能',
      action: '點擊',
      label: event.detail.value,
    };
    this.caputil.logEvent('醫院進階搜尋', params);
  }

  selectSection2(event) {
    console.log('selectSection2', event.detail.value);
    this.store.dispatch(
      new SetUIPageHospitalSearchSection2(event.detail.value)
    );

    const params: MyLogEventParams = {
      category: '區域',
      action: '點擊',
      label: event.detail.value,
    };
    this.caputil.logEvent('醫院進階搜尋', params);
  }

  selectCity(city) {
    console.log('selectCity', event);
    this.selectedCity = city;
    this.city = city;
    this.cityDistrict = _.filter(this.zipCodes, { city });
  }

  ionCityChange(event) {
    console.log('ionCityChange', event);
    this.selectCity(event.detail.value);
    this.districts = [];

    const params: MyLogEventParams = {
      category: '縣市',
      action: '點擊',
      label: event.detail.value,
    };
    this.caputil.logEvent('醫院進階搜尋', params);
  }

  ionDistrictChange(event) {
    console.log('ionDistrictChange', event);
    const district = _.join(event.detail.value, ',');
    console.log('ionDistrictChange district', district);

    const params: MyLogEventParams = {
      category: '鄉鎮市區',
      action: '點擊',
      label: district,
    };
    this.caputil.logEvent('醫院進階搜尋', params);
  }

  dismiss() {
    console.log('selected_top_categorys', this.selected_top_categorys);
    console.log('selected_subject_classes', this.selected_subject_classes);
    console.log('selected_medical_cares', this.selected_medical_cares);
    console.log(
      'selected_hardware_equipments',
      this.selected_hardware_equipments
    );
    console.log('selected_life_functions', this.selected_life_functions);

    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  doSearch() {
    console.log('doSearch');

    console.log('selected_top_categorys', this.selected_top_categorys);
    console.log('selected_subject_classes', this.selected_subject_classes);
    console.log('selected_medical_cares', this.selected_medical_cares);
    console.log(
      'selected_hardware_equipments',
      this.selected_hardware_equipments
    );
    console.log('selected_life_functions', this.selected_life_functions);
    console.log('selected_section2', this.selected_section2);
    console.log('this.searchKeyword', this.searchKeyword);
    console.log('this.selectedCity', this.selectedCity);
    console.log('this.district', this.districts);

    if (this.selectedCity) {
      this.store.dispatch(new SetUIPageHospitalSearchCity(this.selectedCity));
    }

    if (this.districts) {
      this.store.dispatch(new SetUIPageHospitalSearchDistrict(this.districts));
    }

    if (this.searchKeyword) {
      this.store.dispatch(
        new SetUIPageHospitalSearchKeyword(this.searchKeyword)
      );

      const params: MyLogEventParams = {
        category: '關鍵字',
        action: '點擊',
        label: this.searchKeyword,
      };
      this.caputil.logEvent('醫院進階搜尋', params);
    }
    this.modalController.dismiss({
      doSearch: true,
    });
  }
}
