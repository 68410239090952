import { Injectable } from '@angular/core';
import { MemberBabyInfo } from '@mommy/models/MemberBabyInfo.model';
import { MemberBabyService } from '@mommy/services/member/member-baby.service';
import { StorageService } from '@mommy/services/storage.service';
import { UserService } from '@mommy/services/user/user.service';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { UpdateMommyUser } from '../user/user.actions';
import {
  AddMemberBaby,
  GetMemberBabies,
  ModifyMemberBaby,
  RemoveMemberBaby,
} from './member-baby.actions';

export interface MemberBabyStateModel {
  loading: boolean;
  member_babies: MemberBabyInfo[];
  hasCache: boolean;
}

const defaultMemberBabyState = (): MemberBabyStateModel => {
  return {
    loading: false,
    member_babies: [],
    hasCache: false,
  };
};

@State<MemberBabyStateModel>({
  name: 'MemberBabyState',
  defaults: defaultMemberBabyState(),
})
@Injectable()
export class MemberBabyState {
  constructor(
    private storage: StorageService,
    private memberBabySvc: MemberBabyService,
    private userService: UserService,
    private store: Store
  ) {}

  @Action(GetMemberBabies)
  async GetMemberBabies(ctx: StateContext<MemberBabyStateModel>) {
    console.log('[Action] GetMemberBabies');

    try {
      const data: any = await this.userService.getMemberBabies();
      console.log('GetMemberBabies success', data);

      ctx.patchState({
        member_babies: data.member_babies,
      });

      // 更新 user state
      this.store.dispatch(new UpdateMommyUser(data));
    } catch (error) {
      console.warn('GetMemberBabies error', error);
      throw error;
    }
  }

  @Action(AddMemberBaby)
  async AddMemberBaby(ctx: StateContext<MemberBabyStateModel>, { payload }) {
    console.log('[Action] AddMemberBaby');

    try {
      const result = await this.memberBabySvc.add(payload);
      console.log('AddMemberBaby success', result);
      this.store.dispatch(new GetMemberBabies());
    } catch (error) {
      console.error('AddMemberBaby error', error);
      throw error;
    }
  }

  @Action(RemoveMemberBaby)
  async RemoveMemberBaby(
    ctx: StateContext<MemberBabyStateModel>,
    { member_baby_id }
  ) {
    console.log('[Action] RemoveMemberBaby');

    try {
      const result = await this.memberBabySvc.delete(member_baby_id);
      console.log('RemoveMemberBaby success', result);
      this.store.dispatch(new GetMemberBabies());
    } catch (error) {
      console.error('RemoveMemberBaby error', error);
      throw error;
    }
  }

  @Action(ModifyMemberBaby)
  async ModifyMemberBaby(
    ctx: StateContext<MemberBabyStateModel>,
    action: ModifyMemberBaby
  ) {
    console.log('[Action] ModifyMemberBaby');

    try {
      const result = await this.memberBabySvc.update(action.payload);
      console.log('ModifyMemberBaby success', result);
      this.store.dispatch(new GetMemberBabies());
    } catch (error) {
      console.error('ModifyMemberBaby error', error);
      throw error;
    }
  }
}
