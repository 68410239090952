// 載入已刪除的物件list
export class InitLocalCacheTrashObjects {
  static readonly type = '[AppHome] InitLocalCacheTrashObjects';
}

export class LoadCacheTrashObjects {
  static readonly type = '[AppHome] LoadCacheTrashObjects';
}

export class RefreshTrashObjects {
  static readonly type = '[AppHome] RefreshTrashObjects';
}

export class InitGraphqlData {
  static readonly type = '[AppHome] InitGraphqlData';
}
