export class InitLocalCacheElineHospitals {
  static readonly type = '[ElineHospital] InitLocalCacheElineHospitals';
}

export class LoadCacheElineHospitals {
  static readonly type = '[ElineHospital] LoadCacheElineHospitals';
}

export class RefreshElineHospitals {
  static readonly type = '[ElineHospital] RefreshElineHospitals';
}

export class GetElineHospitalDetail {
  static readonly type = '[ElineHospital] GetElineHospitalDetail';
  constructor(public team_id: number) {}
}
