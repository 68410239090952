import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from '@mommy/environments/environment';
import { AuthGuard } from './services/auth-guard/auth.guard';

const routes: Routes = [
  {
    path: 'search',
    loadChildren: () =>
      import('./pages/search/search.module').then((m) => m.SearchPageModule),
  },
  {
    path: 'search-result/:keyword',
    loadChildren: () =>
      import('./pages/search-result/search-result.module').then(
        (m) => m.SearchResultPageModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'pgc-post/:post_id',
    loadChildren: () =>
      import('./pages/pgc-post/pgc-post.module').then(
        (m) => m.PgcPostPageModule
      ),
  },
  {
    path: 'pgc-post/:post_id/:post_comment_id',
    loadChildren: () =>
      import('./pages/pgc-post/pgc-post.module').then(
        (m) => m.PgcPostPageModule
      ),
  },
  {
    path: 'pregnancy-post',
    loadChildren: () =>
      import('./pages/pregnancy-post/pregnancy-post.module').then(
        (m) => m.PregnancyPostPageModule
      ),
  },
  {
    path: 'utilities',
    loadChildren: () =>
      import('./pages/utilities/utilities.module').then(
        (m) => m.UtilitiesPageModule
      ),
  },

  //#region 專家醫師相關 route ###
  {
    path: 'expert',
    loadChildren: () =>
      import('./pages/expert/expert.module').then((m) => m.ExpertPageModule),
  },
  {
    path: 'expert/:expert_id',
    loadChildren: () =>
      import('./pages/expert/expert.module').then((m) => m.ExpertPageModule),
  },
  {
    path: 'expert-list',
    loadChildren: () =>
      import('./pages/expert-list/expert-list.module').then(
        (m) => m.ExpertListPageModule
      ),
  },
  {
    path: 'expert-list-category',
    loadChildren: () =>
      import('./pages/expert-list-category/expert-list-category.module').then(
        (m) => m.ExpertListCategoryPageModule
      ),
  },
  {
    path: 'expert-search', // 跟醫院搜尋共用page
    loadChildren: () =>
      import('./pages/hospital-search/hospital-search.module').then(
        (m) => m.HospitalSearchPageModule
      ),
  },
  {
    path: 'expert-search-result',
    loadChildren: () =>
      import('./pages/expert-search-result/expert-search-result.module').then(
        (m) => m.ExpertSearchResultPageModule
      ),
  },
  //#endregion 專家醫師相關 route ###

  //#region 百科相關 route ###
  {
    path: 'pedia-list-category',
    loadChildren: () =>
      import('./pages/pedia-list-category/pedia-list-category.module').then(
        (m) => m.PediaListCategoryPageModule
      ),
  },
  {
    path: 'pedia-list-category/:category_id',
    loadChildren: () =>
      import('./pages/pedia-list-category/pedia-list-category.module').then(
        (m) => m.PediaListCategoryPageModule
      ),
  },
  {
    path: 'pedia-category/:taxonomy_id',
    loadChildren: () =>
      import('./pages/pedia-category/pedia-category.module').then(
        (m) => m.PediaCategoryPageModule
      ),
  },
  {
    path: 'pedia-post/:post_id',
    loadChildren: () =>
      import('./pages/pedia-post/pedia-post.module').then(
        (m) => m.PediaPostPageModule
      ),
  },
  //#endregion 百科相關 route ###

  //#region 能不能吃相關 route ###
  {
    path: 'canieat',
    loadChildren: () =>
      import('./pages/canieat/canieat.module').then((m) => m.CanieatPageModule),
  },
  {
    path: 'canieat-list-category',
    loadChildren: () =>
      import('./pages/canieat-list-category/canieat-list-category.module').then(
        (m) => m.CanieatListCategoryPageModule
      ),
  },
  {
    path: 'canieat-list-category/:category_id',
    loadChildren: () =>
      import('./pages/canieat-list-category/canieat-list-category.module').then(
        (m) => m.CanieatListCategoryPageModule
      ),
  },
  {
    path: 'canieat-search',
    loadChildren: () =>
      import('./pages/search/search.module').then((m) => m.SearchPageModule),
  },
  {
    path: 'canieat-search-result/:keyword',
    loadChildren: () =>
      import('./pages/canieat-search-result/canieat-search-result.module').then(
        (m) => m.CanieatSearchResultPageModule
      ),
  },
  {
    path: 'canieat-post',
    loadChildren: () =>
      import('./pages/canieat-post/canieat-post.module').then(
        (m) => m.CanieatPostPageModule
      ),
  },
  {
    path: 'canieat-post/:post_id',
    loadChildren: () =>
      import('./pages/canieat-post/canieat-post.module').then(
        (m) => m.CanieatPostPageModule
      ),
  },
  //#endregion 能不能吃相關 route ###

  //#region 產檢醫院相關 route ###
  {
    path: 'hospital-list',
    loadChildren: () =>
      import('./pages/hospital-list/hospital-list.module').then(
        (m) => m.HospitalListPageModule
      ),
  },
  {
    path: 'hospital-search',
    loadChildren: () =>
      import('./pages/hospital-search/hospital-search.module').then(
        (m) => m.HospitalSearchPageModule
      ),
  },
  {
    path: 'hospital-list-category',
    loadChildren: () =>
      import(
        './pages/hospital-list-category/hospital-list-category.module'
      ).then((m) => m.HospitalListCategoryPageModule),
  },
  {
    path: 'hospital-list-category-result',
    loadChildren: () =>
      import(
        './pages/hospital-list-category-result/hospital-list-category-result.module'
      ).then((m) => m.HospitalListCategoryResultPageModule),
  },
  {
    path: 'hospital-list-category-result/:zipcode',
    loadChildren: () =>
      import(
        './pages/hospital-list-category-result/hospital-list-category-result.module'
      ).then((m) => m.HospitalListCategoryResultPageModule),
  },
  {
    path: 'hospital',
    loadChildren: () =>
      import('./pages/hospital/hospital.module').then(
        (m) => m.HospitalPageModule
      ),
  },
  {
    path: 'hospital/:hospital_id',
    loadChildren: () =>
      import('./pages/hospital/hospital.module').then(
        (m) => m.HospitalPageModule
      ),
  },
  {
    path: 'hospital-expert/:expert_id',
    loadChildren: () =>
      import('./pages/expert/expert.module').then((m) => m.ExpertPageModule),
  },
  {
    path: 'hospital-search-result',
    loadChildren: () =>
      import(
        './pages/hospital-search-result/hospital-search-result.module'
      ).then((m) => m.HospitalSearchResultPageModule),
  },
  //#endregion 產檢醫院相關 route ###

  //#region EC 相關 route ###
  {
    path: 'ec-product/:commodity_id',
    loadChildren: () =>
      import('./pages/ec-product/ec-product.module').then(
        (m) => m.EcProductPageModule
      ),
  },
  {
    path: 'ec-product-special/:commodity_id',
    loadChildren: () =>
      import('./pages/ec-product/ec-product.module').then(
        (m) => m.EcProductPageModule
      ),
  },
  {
    path: 'ec-campaign/:category_id',
    loadChildren: () =>
      import('./pages/ec-campaign/ec-campaign.module').then(
        (m) => m.EcCampaignPageModule
      ),
  },
  {
    path: 'ec-cart',
    loadChildren: () =>
      import('./pages/ec-cart/ec-cart.module').then((m) => m.EcCartPageModule),
    canActivate: [AuthGuard],
    data: {
      types: ['EC'],
    },
  },
  {
    path: 'ec-address-list/:supplier_id',
    loadChildren: () =>
      import('./pages/ec-address-list/ec-address-list.module').then(
        (m) => m.EcAddressListPageModule
      ),
  },
  {
    path: 'ec-address-add',
    loadChildren: () =>
      import('./pages/ec-address-add/ec-address-add.module').then(
        (m) => m.EcAddressAddPageModule
      ),
  },
  {
    path: 'ec-checkout',
    loadChildren: () =>
      import('./pages/ec-checkout/ec-checkout.module').then(
        (m) => m.EcCheckoutPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['EC'],
    },
  },
  {
    path: 'ec-checkout2',
    loadChildren: () =>
      import('./pages/ec-checkout/ec-checkout.module').then(
        (m) => m.EcCheckoutPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['EC'],
    },
  },
  {
    path: 'ec-checkout-result',
    loadChildren: () =>
      import('./pages/ec-checkout-result/ec-checkout-result.module').then(
        (m) => m.EcCheckoutResultPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['EC'],
    },
  },
  {
    path: 'ec-order-detail/:orderBillId/:orderId',
    loadChildren: () =>
      import('./pages/ec-order-detail/ec-order-detail.module').then(
        (m) => m.EcOrderDetailPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['EC'],
    },
  },
  {
    path: 'ec-order-return',
    loadChildren: () =>
      import('./pages/ec-order-return/ec-order-return.module').then(
        (m) => m.EcOrderReturnPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['EC'],
    },
  },
  {
    path: 'ec-search',
    loadChildren: () =>
      import('./pages/ec-search/ec-search.module').then(
        (m) => m.EcSearchPageModule
      ),
  },
  {
    path: 'ec-search-result/:keyword',
    loadChildren: () =>
      import('./pages/ec-search-result/ec-search-result.module').then(
        (m) => m.EcSearchResultPageModule
      ),
  },
  {
    path: 'ec-tag-search-result/:tag',
    loadChildren: () =>
      import('./pages/ec-search-result/ec-search-result.module').then(
        (m) => m.EcSearchResultPageModule
      ),
  },
  {
    path: 'ec-product-list-category/:category_id',
    loadChildren: () =>
      import(
        './pages/ec-product-list-category/ec-product-list-category.module'
      ).then((m) => m.EcProductListCategoryPageModule),
  },
  {
    path: 'member-ec-order',
    loadChildren: () =>
      import('./pages/member-ec-order/member-ec-order.module').then(
        (m) => m.MemberEcOrderPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['MOMMY', 'EC'],
    },
  },
  {
    path: 'member-ec-return',
    loadChildren: () =>
      import('./pages/member-ec-return/member-ec-return.module').then(
        (m) => m.MemberEcReturnPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['MOMMY', 'EC'],
    },
  },
  {
    path: 'ec-order-return-detail/:returnId',
    loadChildren: () =>
      import(
        './pages/ec-order-return-detail/ec-order-return-detail.module'
      ).then((m) => m.EcOrderReturnDetailPageModule),
    canActivate: [AuthGuard],
    data: {
      types: ['MOMMY', 'EC'],
    },
  },
  {
    path: 'payment-fail/:message',
    loadChildren: () =>
      import('./pages/payment/payment-fail/payment-fail.module').then(
        (m) => m.PaymentFailPageModule
      ),
    data: {
      WEB_APP_MODE: false,
    },
  },
  {
    path: 'payment-fail',
    loadChildren: () =>
      import('./pages/payment/payment-fail/payment-fail.module').then(
        (m) => m.PaymentFailPageModule
      ),
    data: {
      WEB_APP_MODE: false,
    },
  },
  {
    path: 'payment-success',
    loadChildren: () =>
      import('./pages/payment/payment-success/payment-success.module').then(
        (m) => m.PaymentSuccessPageModule
      ),
    data: {
      WEB_APP_MODE: false,
    },
  },

  //#endregion EC 相關 route ###
  {
    path: 'maternitykit',
    loadChildren: () =>
      import('./pages/maternitykit/maternitykit.module').then(
        (m) => m.MaternitykitPageModule
      ),
  },
  {
    path: 'baby-diary-post',
    loadChildren: () =>
      import('./pages/baby-diary-post/baby-diary-post.module').then(
        (m) => m.BabyDiaryPostPageModule
      ),
  },
  //#region 投票 相關 route ###
  {
    path: 'vote-list',
    loadChildren: () =>
      import('./pages/vote-list/vote-list.module').then(
        (m) => m.VoteListPageModule
      ),
  },
  {
    path: 'vote-list/:vote_id',
    loadChildren: () =>
      import('./pages/vote-list/vote-list.module').then(
        (m) => m.VoteListPageModule
      ),
  },
  {
    path: 'vote-list-task',
    loadChildren: () =>
      import('./pages/vote-list/vote-list.module').then(
        (m) => m.VoteListPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['MOMMY'],
    },
  },
  {
    path: 'vote-result/:vote_id',
    loadChildren: () =>
      import('./pages/vote-result/vote-result.module').then(
        (m) => m.VoteResultPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['MOMMY'],
    },
  },
  {
    path: 'vote-result/:vote_id/:vote_comment_id',
    loadChildren: () =>
      import('./pages/vote-result/vote-result.module').then(
        (m) => m.VoteResultPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['MOMMY'],
    },
  },
  {
    path: 'vote-add/:vote_id',
    loadChildren: () =>
      import('./pages/vote-add/vote-add.module').then(
        (m) => m.VoteAddPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['MOMMY'],
    },
  },
  {
    path: 'vote-edit/:vote_id/:member_vote_id',
    loadChildren: () =>
      import('./pages/vote-add/vote-add.module').then(
        (m) => m.VoteAddPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['MOMMY'],
    },
  },
  //#endregion 投票 相關 route ###
  {
    path: 'feed-record',
    loadChildren: () =>
      import('./pages/feed-record/feed-record.module').then(
        (m) => m.FeedRecordPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['MOMMY'],
    },
  },
  {
    path: 'grow-record',
    loadChildren: () =>
      import('./pages/grow-record/grow-record.module').then(
        (m) => m.GrowRecordPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['MOMMY'],
    },
  },
  {
    path: 'member-coin',
    loadChildren: () =>
      import('./pages/member-coin/member-coin.module').then(
        (m) => m.MemberCoinPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['MOMMY', 'EC'],
    },
  },

  {
    path: 'member-invite',
    loadChildren: () =>
      import('./pages/member-invite/member-invite.module').then(
        (m) => m.MemberInvitePageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['MOMMY'],
    },
  },
  {
    path: 'member-task',
    loadChildren: () =>
      import('./pages/member-task/member-task.module').then(
        (m) => m.MemberTaskPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['MOMMY'],
    },
  },

  // {
  //   path: 'login',
  //   loadChildren: () =>
  //     import('./pages/auth/login/login.module').then((m) => m.LoginPageModule),
  //   data: {
  //     WEB_APP_MODE: false,
  //   },
  // },
  // {
  //   path: 'register',
  //   loadChildren: () =>
  //     import('./pages/auth/login/login.module').then((m) => m.LoginPageModule),
  //   data: {
  //     WEB_APP_MODE: false,
  //   },
  // },
  {
    path: 'member-watch',
    loadChildren: () =>
      import('./pages/member-watch/member-watch.module').then(
        (m) => m.MemberWatchPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['MOMMY'],
    },
  },
  {
    path: 'member-like',
    loadChildren: () =>
      import('./pages/member-like/member-like.module').then(
        (m) => m.MemberLikePageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['MOMMY'],
    },
  },
  {
    path: 'member-comment',
    loadChildren: () =>
      import('./pages/member-comment/member-comment.module').then(
        (m) => m.MemberCommentPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['MOMMY'],
    },
  },
  {
    path: 'member-keep',
    loadChildren: () =>
      import('./pages/member-keep/member-keep.module').then(
        (m) => m.MemberKeepPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['MOMMY'],
    },
  },
  {
    path: 'lucky-draw',
    loadChildren: () =>
      import('./pages/lucky-draw/lucky-draw.module').then(
        (m) => m.LuckyDrawPageModule
      ),
  },
  {
    path: 'member-info',
    loadChildren: () =>
      import('./pages/member-info/member-info.module').then(
        (m) => m.MemberInfoPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['MOMMY'],
    },
  },
  {
    path: 'member-lucky-hist',
    loadChildren: () =>
      import('./pages/member-lucky-hist/member-lucky-hist.module').then(
        (m) => m.MemberLuckyHistPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['MOMMY'],
    },
  },
  {
    path: 'delete-account',
    loadChildren: () =>
      import('./pages/auth/delete-account/delete-account.module').then(
        (m) => m.DeleteAccountPageModule
      ),
  },
  {
    path: 'video-post/:post_id',
    loadChildren: () =>
      import('./pages/video-post/video-post.module').then(
        (m) => m.VideoPostPageModule
      ),
  },
  {
    path: 'video-post/:post_id/:post_comment_id',
    loadChildren: () =>
      import('./pages/video-post/video-post.module').then(
        (m) => m.VideoPostPageModule
      ),
  },
  {
    path: 'video-post-task/:post_id',
    loadChildren: () =>
      import('./pages/video-post/video-post.module').then(
        (m) => m.VideoPostPageModule
      ),
  },
  {
    path: 'video-list',
    loadChildren: () =>
      import('./pages/video-list/video-list.module').then(
        (m) => m.VideoListPageModule
      ),
  },
  {
    path: 'video-list-task',
    loadChildren: () =>
      import('./pages/video-list-task/video-list-task.module').then(
        (m) => m.VideoListTaskPageModule
      ),
  },
  {
    path: 'login-success',
    loadChildren: () =>
      import('./pages/auth/login-success/login-success.module').then(
        (m) => m.LoginSuccessPageModule
      ),
    data: {
      WEB_APP_MODE: false,
    },
  },
  {
    path: 'chat',
    loadChildren: () =>
      import('./pages/chat/chat.module').then((m) => m.ChatPageModule),
    canActivate: [AuthGuard],
    data: {
      types: ['ELINE'],
    },
  },
  {
    path: 'chat-invite/:channel_id',
    loadChildren: () =>
      import('./pages/chat-invite/chat-invite.module').then(
        (m) => m.ChatInvitePageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['ELINE'],
    },
  },
  {
    path: 'chat-setting/:channel_id',
    loadChildren: () =>
      import('./pages/chat-setting/chat-setting.module').then(
        (m) => m.ChatSettingPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['ELINE'],
    },
  },
  {
    path: 'healtheducation',
    loadChildren: () =>
      import('./pages/healtheducation/healtheducation.module').then(
        (m) => m.HealtheducationPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['ELINE'],
    },
  },
  {
    path: 'healtheducation-detail/:health_education_id',
    loadChildren: () =>
      import('./pages/healtheducationdetail/healtheducationdetail.module').then(
        (m) => m.HealtheducationdetailPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      types: ['ELINE'],
    },
  },
];

export function getRoutes() {
  if (environment.WEB_APP_MODE) {
    return routes;
  } else {
    // 未開放 WEB_APP_MODE, 故要過濾掉一些 route (route.data.WEB_APP_MODE = false)
    return routes.filter(
      (route) => route.data?.WEB_APP_MODE === environment.WEB_APP_MODE
    );
  }
}

@NgModule({
  imports: [
    RouterModule.forRoot(getRoutes(), {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
