export class InitLocalCacheExperts {
  static readonly type = '[Expert] InitLocalCacheExperts';
}

export class LoadCacheExperts {
  static readonly type = '[Expert] LoadCacheExperts';
}

export class RefreshExperts {
  static readonly type = '[Expert] RefreshExperts';
}

export class GetExpertDetail {
  static readonly type = '[Expert] GetExpertDetail';
  constructor(public expert_id: number) {}
}

// 變更顯示專家list的筆數
export class SetExpertListCount {
  static readonly type = '[Expert] SetExpertListCount';
  constructor(public count: number) {}
}

// 更新 ExpertState.experts
export class SetExpertsToExpertState {
  static readonly type = '[Expert] SetExpertsToExpertState';
  constructor(public payload: any) {}
}

// ExpertClick log
export class ExpertClickLog {
  static readonly type = '[Expert] ExpertClickLog';
  constructor(public expert_id: number) {}
}
