export class GetMemberPostKeeps {
  static readonly type = '[MemberPostKeep] GetMemberPostKeeps';
}

export class AddMemberPostKeep {
  static readonly type = '[MemberPostKeep] AddMemberPostKeep';
  constructor(public post_id: number) {}
}

export class RemoveMemberPostKeep {
  static readonly type = '[MemberPostKeep] RemoveMemberPostKeep';
  constructor(public post_id: number) {}
}
