export class LoadAppHome {
  static readonly type = '[AppHome] Load';
}

export class SetAppHomeBabyTopActivity {
  static readonly type = '[AppHome] SetAppHomeBabyTopActivity';
  constructor(public data: any) {}
}

export class SetAppHomePregnancyTopActivity {
  static readonly type = '[AppHome] SetAppHomePregnancyTopActivity';
  constructor(public data: any) {}
}

export class SetAppHomeBabyVideo {
  static readonly type = '[AppHome] SetAppHomeBabyVideo';
  constructor(public data: any) {}
}

export class SetAppHomePregnancyVideo {
  static readonly type = '[AppHome] SetAppHomePregnancyVideo';
  constructor(public data: any) {}
}
