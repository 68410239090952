import { Injectable } from '@angular/core';
import { CodeMaternitykitInfo } from '@mommy/models/CodeMaternitykitInfo.model';
import { MaternityKitService } from '@mommy/services/maternitykit/maternitykit.service';
import { StateUtilsService } from '@mommy/services/state-utils.service';
import { StorageService } from '@mommy/services/storage.service';
import {
  Action,
  createSelector,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import * as _ from 'lodash';
import { MemberMaternitykitService } from './../../services/member/member-maternitykit.service';
import {
  DeleteMemberMaternitykit,
  InitializeMemberMaternitykits,
  InitLocalCacheMaternityKit,
  LoadCacheMaternityKit,
  LoadCacheMemberMaternityKit,
  RefreshMaternityKit,
  SyncMemberMaternitykits,
  UpdateMemberMaternitykit,
} from './maternitykit.actions';

export interface MaternityKitStateModel {
  loading: boolean;
  maternitykits: CodeMaternitykitInfo[];
  hasCache: boolean;
}
export interface MemberMaternityKitStateModel {
  loading: boolean;
  memberMaternitykits: CodeMaternitykitInfo[];
  hasCache: boolean;
}

const defaultMaternityKitState = (): MaternityKitStateModel => {
  return {
    loading: false,
    maternitykits: [],
    hasCache: false,
  };
};

const defaultMemberMaternityKitState = (): MemberMaternityKitStateModel => {
  return {
    loading: false,
    memberMaternitykits: [],
    hasCache: false,
  };
};

@State<MaternityKitStateModel>({
  name: 'MaternityKitState',
  defaults: defaultMaternityKitState(),
})
@Injectable()
export class MaternityKitState implements NgxsAfterBootstrap {
  constructor(
    private storage: StorageService,
    private maternitykitSvc: MaternityKitService
  ) {}

  async ngxsAfterBootstrap(ctx: StateContext<MaternityKitStateModel | null>) {
    console.log('[MaternityKitState] ngxsAfterBootstrap');

    // try {
    //   await ctx.dispatch(new LoadCacheMaternityKit()).toPromise();
    //   console.log('load local cache maternitykit success');
    //   // 再呼叫 getAllMaternityKit 來更新 local cache
    //   this.getMaternityKitFromServer(ctx);
    // } catch (error) {
    //   console.warn('LoadCacheMaternityKit error', error);
    //   // 如果沒有 cache, 就去 server 取
    //   this.getMaternityKitFromServer(ctx);
    // }
  }

  // 建立 dynamic selector, 傳入 type name(ex: 科別專長, 能不能吃分類..) 回傳對應類別的 taxonomy
  // 這個方法支援 memoized selector
  // Note that each of these selectors have their own separate memoization.
  // Even if two dynamic selectors created in this way are provided the same argument, they will have separate memoization.
  static maternitykitType(type: '自然產' | '剖腹產' | '媽咪學苑推薦') {
    return createSelector(
      [MaternityKitState],
      (state: MaternityKitStateModel) => {
        return state.maternitykits.filter(
          (item) => item.maternitykit_type.indexOf(type) > -1
        );
      }
    );
  }

  @Action(InitLocalCacheMaternityKit)
  async initLocalCacheMaternityKit(ctx: StateContext<MaternityKitStateModel>) {
    const _maternitykits: any = await this.storage.get('maternitykits');

    if (_maternitykits) {
      // do nothing
    } else {
      this.getMaternityKitFromServer(ctx);
    }
  }

  @Action(LoadCacheMaternityKit)
  async loadCacheMaternityKit(ctx: StateContext<MaternityKitStateModel>) {
    console.log('[Action] loadCacheMaternityKit');

    const _maternitykits: any = await this.storage.get('maternitykits');

    if (_maternitykits) {
      ctx.setState({
        loading: false,
        maternitykits: _maternitykits,
        hasCache: true,
      });
    } else {
      throw new Error('no cache');
    }
  }

  // @Action(RefreshMemberMaternitykits)
  // async refreshMemberMaternitykits(
  //   ctx: StateContext<MaternityKitStateModel>,
  //   memberMaternitykits: any
  // ) {
  //   ctx.patchState({});
  // }

  @Action(RefreshMaternityKit)
  async refreshMaternityKit(ctx: StateContext<MaternityKitStateModel>) {
    console.log('[Action] refreshMaternityKit');
    this.getMaternityKitFromServer(ctx);
  }

  private async getMaternityKitFromServer(
    ctx: StateContext<MaternityKitStateModel>
  ) {
    // try read data from server
    console.log('getMaternityKitFromServer');
    try {
      const maternitykits = await this.maternitykitSvc.getAllMaternityKit();
      console.log('maternitykits', maternitykits);
      await this.storage.set('maternitykits', maternitykits);
      await ctx.dispatch(new LoadCacheMaternityKit()).toPromise();
      console.log('load local cache maternitykit success');
    } catch (error2) {
      console.warn('getAllMaternityKit error', error2);
    }
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////
//  MemberMaternityKitStateModel
//////////////////////////////////////////////////////////////////////////////////////////////
@State<MemberMaternityKitStateModel>({
  name: 'MemberMaternityKitState',
  defaults: defaultMemberMaternityKitState(),
})
@Injectable()
export class MemberMaternityKitState implements NgxsAfterBootstrap {
  constructor(
    private storage: StorageService,
    private maternitykitSvc: MaternityKitService,
    private stateUtils: StateUtilsService,
    private memberMaternitykitService: MemberMaternitykitService
  ) {}
  async ngxsAfterBootstrap(
    ctx: StateContext<MemberMaternityKitStateModel | null>
  ) {
    console.log('[MemberMaternityKitStateModel] ngxsAfterBootstrap');
  }

  // static memberMaternitykitType() {
  //   return createSelector(
  //     [MemberMaternityKitState],
  //     (state: MemberMaternityKitStateModel) => {
  //       return state.memberMaternitykits;
  //     }
  //   );
  // }

  @Selector([
    MemberMaternityKitState,
    MaternityKitState.maternitykitType('媽咪學苑推薦'),
  ])
  static memberMaternitykitType(
    state: MemberMaternityKitStateModel,
    kit_items: CodeMaternitykitInfo[]
  ) {
    //return state.memberMaternitykits;

    const memberMaternitykits = _.cloneDeep(state.memberMaternitykits);
    const new_memberMaternitykits = _.map(memberMaternitykits, (item) => {
      // 以 maternitykit_id 為 key, 如果有找到, 就替換 related_product
      const _kit_item = _.find(kit_items, {
        maternitykit_id: item.maternitykit_id,
      });

      if (_kit_item) {
        item.related_product = _kit_item.related_product;
      }

      if (item.related_product?.commodity_tag) {
        item.related_commodity_tag = item.related_product?.commodity_tag;
      }
      return item;
    });
    return new_memberMaternitykits;
  }

  @Action(LoadCacheMemberMaternityKit)
  async loadCacheMemberMaternityKit(
    ctx: StateContext<MemberMaternityKitStateModel>
  ) {
    console.log('[Action] loadCacheMemberMaternityKit');

    const _memberMaternitykits: any = await this.storage.get(
      'memberMaternitykits'
    );

    ctx.setState({
      loading: false,
      memberMaternitykits: _memberMaternitykits ? _memberMaternitykits : [],
      hasCache: _memberMaternitykits ? true : false,
    });
  }

  @Action(SyncMemberMaternitykits)
  async syncMemberMaternitykits(
    ctx: StateContext<MemberMaternityKitStateModel>,
    _memberMaternitykits: any
  ) {
    const { memberMaternitykits, isNeedResync } = _memberMaternitykits;
    let syncData = memberMaternitykits;
    console.log('[Action] loadCacheMemberMaternityKit', isNeedResync);
    if (this.stateUtils.isMommyAuthenticated()) {
      syncData = await this.memberMaternitykitService.sync(memberMaternitykits);
    }
    this.storage.set('memberMaternitykits', syncData);
    if (isNeedResync) {
      ctx.patchState({
        memberMaternitykits: syncData,
      });
    }
  }

  @Action(DeleteMemberMaternitykit)
  async deleteMemberMaternitykit(
    ctx: StateContext<MemberMaternityKitStateModel>,
    _memberMaternitykits: any
  ) {
    const { memberMaternitykits, memberMaternitykit } = _memberMaternitykits;
    console.log('[Action] deleteMemberMaternitykit');
    if (memberMaternitykit.member_maternitykit_id > 0) {
      if (this.stateUtils.isMommyAuthenticated) {
        const { member_maternitykit_id } = memberMaternitykit;
        await this.memberMaternitykitService.delete(member_maternitykit_id);
      }
    }
    this.storage.set('memberMaternitykits', memberMaternitykits);
    ctx.patchState({
      memberMaternitykits,
    });
    console.log('deleteMemberMaternitykit', memberMaternitykits);
  }

  @Action(UpdateMemberMaternitykit)
  async updateMemberMaternitykit(
    ctx: StateContext<MemberMaternityKitStateModel>,
    _memberMaternitykits: any
  ) {
    const { memberMaternitykit } = _memberMaternitykits;
    console.log('[Action] updateMemberMaternitykit');

    const state = ctx.getState();
    const memberMaternitykits = _.cloneDeep(state.memberMaternitykits);
    const index = memberMaternitykits.findIndex(
      (item) =>
        item.member_maternitykit_id ===
        memberMaternitykit.member_maternitykit_id
    );

    // 找到的 item 要用 array splice replace 成新的
    if (index > -1) {
      if (memberMaternitykit.done_flag_ui) {
        memberMaternitykit.done_flag = 'Y';
      } else {
        memberMaternitykit.done_flag = 'N';
      }
      memberMaternitykits.splice(index, 1, memberMaternitykit);
    }

    ctx.patchState({
      memberMaternitykits,
    });
    console.log('updateMemberMaternitykit', memberMaternitykits);
  }

  @Action(InitializeMemberMaternitykits)
  async initializeMemberMaternitykits(
    ctx: StateContext<MemberMaternityKitStateModel>,
    _memberMaternitykits: any
  ) {
    const { memberMaternitykits } = _memberMaternitykits;
    console.log('[Action] initializeMemberMaternitykits');

    let initialData = memberMaternitykits;
    if (this.stateUtils.isMommyAuthenticated()) {
      initialData = await this.memberMaternitykitService.sync(
        memberMaternitykits
      );
    }
    this.storage.set('memberMaternitykits', initialData);
    ctx.patchState({
      memberMaternitykits: initialData,
    });
    console.log('initializeMemberMaternitykits', initialData);
  }
}
