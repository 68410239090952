export class InitLocalCacheMaternityKit {
  static readonly type = '[Maternitykit] InitLocalCache';
}

export class LoadCacheMaternityKit {
  static readonly type = '[MaternityKit] LoadCache';
}

export class LoadCacheMemberMaternityKit {
  static readonly type = '[MemberMaternityKit] LoadCacheMember';
}

export class LoadCacheMaternityKitType {
  static readonly type = '[MaternityKit] LoadCacheMaternityKitType';
}

export class RefreshMaternityKit {
  static readonly type = '[MaternityKit] Refresh';
}

export class SyncMemberMaternitykits {
  static readonly type = '[MemberMaternitykits] SyncMemberMaternitykits';
  constructor(
    public memberMaternitykits: any,
    public isNeedResync: boolean = false
  ) {}
}
export class InitializeMemberMaternitykits {
  static readonly type = '[MemberMaternitykits] InitializeMemberMaternitykits';
  constructor(public memberMaternitykits: any) {}
}

export class DeleteMemberMaternitykit {
  static readonly type = '[MemberMaternitykits] deleteMemberMaternitykit';
  constructor(
    public memberMaternitykits: any,
    public memberMaternitykit: any
  ) {}
}

export class UpdateMemberMaternitykit {
  static readonly type = '[MemberMaternitykits] updateMemberMaternitykit';
  constructor(public memberMaternitykit: any) {}
}
