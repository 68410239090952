import { Injectable } from '@angular/core';
import { environment } from '@mommy/environments/environment';
import { CartItemDetailInfo } from '@mommy/models/ec/CartItemDetailInfo.model';
import { CartItemInfo } from '@mommy/models/ec/CartItemInfo.model';
import { CheckOutInfo } from '@mommy/models/ec/CheckOutInfo.model';
import { CustomerBillInfo } from '@mommy/models/ec/CustomerBillInfo.model';
import { CustomerCoinInfo } from '@mommy/models/ec/CustomerCoinInfo.model';
import { CustomerCoinTxnInfo } from '@mommy/models/ec/CustomerCoinTxnInfo.model';
import {
  PaginationInfo,
  QueryBillParams,
  QueryCoinHistParams,
  QueryReturnListParams,
} from '@mommy/models/ec/ECCommon.model';
import { OrderReturnInfo } from '@mommy/models/ec/OrderReturnInfo.model';
import { ECOrderService } from '@mommy/services/ec/ec-order.service';
import { OthersService } from '@mommy/services/others/others.service';
import { StorageService } from '@mommy/services/storage.service';
import { UtilService } from '@mommy/services/util.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { add, format, parseISO } from 'date-fns';
import * as _ from 'lodash';
import {
  AddCommodityToCart,
  ApplyOrderReturn,
  CancelECCustomerBill,
  ClearECOrderState,
  GetCheckoutInfo,
  GetECCustomerBillDetail,
  GetECCustomerBillList,
  GetECCustomerCoinInfoFromServer,
  GetECCustomerCoinTxnList,
  GetECCustomerOrderReturnList,
  GetECOrderCartFromServer,
  RemoveCommodityToCart,
  ResetECCustomerBillListPaganation,
  ResetECCustomerCoinTxnListPaganation,
  ResetECCustomerOrderReturnListPaganation,
  SetCheckoutInfo,
  SetCheckoutRecipientInfo,
  SetCheckoutUseCoinPoint,
  SetECOrderCartToServer,
  UpdateCommoditySpecToCart,
  UpdateCommodityToCart,
} from './ec-order.actions';

export interface ECOrderStateModel {
  memberCoinInfo: CustomerCoinInfo;
  memberCarts: CartItemInfo[];
  memberCartsDetail: CartItemDetailInfo[];
  memberCheckOutInfo: CheckOutInfo;
  memberCheckOutResult: any;
  memberBillList: CustomerBillInfo[]; // 會員 訂單list
  memberBillDetail: CustomerBillInfo; // 會員 訂單detail
  billListPaginationInfo: PaginationInfo; // 會員 訂單list的分頁狀態資料
  memberOrderReturnList: OrderReturnInfo[]; // 會員 退貨單list
  orderReturnListPaginationInfo: PaginationInfo; // 會員 退貨單list的分頁狀態資料

  memberCoinTxnList: CustomerCoinTxnInfo[]; // 會員咪幣交易紀錄
  coinTxnListPaginationInfo: PaginationInfo; // 會員咪幣交易紀錄的分頁狀態資料
}

const defaultECOrderState = (): ECOrderStateModel => {
  return {
    memberCoinInfo: null,
    memberCarts: [],
    memberCartsDetail: [],
    memberCheckOutInfo: null,
    memberCheckOutResult: null,
    memberBillList: [],
    memberBillDetail: null,
    billListPaginationInfo: null,
    memberOrderReturnList: [],
    orderReturnListPaginationInfo: null,
    memberCoinTxnList: [],
    coinTxnListPaginationInfo: null,
  };
};

@State<ECOrderStateModel>({
  name: 'ECOrderState',
  defaults: defaultECOrderState(),
})
@Injectable()
export class ECOrderState {
  constructor(
    private storage: StorageService,
    private ecOrderService: ECOrderService,
    public util: UtilService,
    private othersService: OthersService
  ) {}

  @Selector()
  static _memberCoinInfo(state: ECOrderStateModel) {
    return state.memberCoinInfo;
  }

  @Selector()
  static _memberCarts(state: ECOrderStateModel) {
    return state.memberCarts;
  }

  @Selector()
  static _memberCartsDetail(state: ECOrderStateModel) {
    return state.memberCartsDetail;
  }

  @Selector()
  static _memberCheckOutInfo(state: ECOrderStateModel) {
    return state.memberCheckOutInfo;
  }

  @Selector()
  static _memberCheckOutResult(state: ECOrderStateModel) {
    return state.memberCheckOutResult;
  }

  @Selector()
  static _memberBillList(state: ECOrderStateModel) {
    return state.memberBillList;
  }

  @Selector()
  static _memberBillDetail(state: ECOrderStateModel) {
    return state.memberBillDetail;
  }

  @Selector()
  static _billListPaginationInfo(state: ECOrderStateModel) {
    return state.billListPaginationInfo;
  }

  @Selector()
  static _memberOrderReturnList(state: ECOrderStateModel) {
    return state.memberOrderReturnList;
  }

  @Selector()
  static _orderReturnListPaginationInfo(state: ECOrderStateModel) {
    return state.orderReturnListPaginationInfo;
  }

  @Selector()
  static _memberCoinTxnList(state: ECOrderStateModel) {
    return state.memberCoinTxnList;
  }

  @Selector()
  static _coinTxnListPaginationInfo(state: ECOrderStateModel) {
    return state.coinTxnListPaginationInfo;
  }

  @Selector([ECOrderState._memberCoinInfo])
  static memberCoinInfo(_memberCoinInfo: CustomerCoinInfo) {
    return _memberCoinInfo;
  }

  @Selector([ECOrderState._memberCarts])
  static memberCarts(_memberCarts: CartItemInfo[]) {
    return _memberCarts;
  }

  @Selector([ECOrderState._memberCheckOutInfo])
  static memberCheckOutInfo(_memberCheckOutInfo: CheckOutInfo) {
    const new_memberCheckOutInfo = _.cloneDeep(_memberCheckOutInfo);
    const ratio = _memberCheckOutInfo.custCoinInfo.ratio || 0;

    new_memberCheckOutInfo.supplierCommodity.forEach((supplier) => {
      let orderAmount = 0;
      let maxCoinPoint = 0;

      supplier.item.forEach((item) => {
        item.backgroundImagePath =
          `url(${environment.EC_IMAGE_CDN_URL + item.imagePath})` +
          ', url(assets/img/default/ec_commodity_default.png)';
        orderAmount += item.qty * item.price;
        maxCoinPoint += item.qty * item.maxRedeemedPoints;
      });
      supplier.orderAmount = orderAmount;

      // 計算運費
      if (supplier.orderAmount >= supplier.freeShippingPrice) {
        supplier.shippingFee = 0;
      } else {
        supplier.shippingFee = supplier.shippingFeePrice;
      }

      // 計算此供應商的商品最大可以折抵的咪幣點數
      supplier.maxCoinPoint = maxCoinPoint;
    });

    return new_memberCheckOutInfo;
  }

  @Selector([ECOrderState._memberCartsDetail])
  static memberCartsDetail(_memberCartsDetail: CartItemDetailInfo[]) {
    return _.chain(_memberCartsDetail)
      .map((supplier: any) => {
        const new_supplier = _.cloneDeep(supplier);
        new_supplier.item = _.map(new_supplier.item, (item) => {
          const new_item = _.cloneDeep(item);
          new_item.imagePath = environment.EC_IMAGE_CDN_URL + item.imagePath;
          new_item.backgroundImagePath =
            `url(${environment.EC_IMAGE_CDN_URL + item.imagePath})` +
            ', url(assets/img/default/ec_commodity_default.png)';
          return new_item;
        });
        return new_supplier;
      })
      .value();
  }

  @Selector([ECOrderState._memberCheckOutResult])
  static memberCheckOutResult(_memberCheckOutResult: any) {
    return _memberCheckOutResult;
  }

  @Selector([ECOrderState._billListPaginationInfo])
  static billListPaginationInfo(billListPaginationInfo: PaginationInfo) {
    return billListPaginationInfo;
  }

  // 會員 訂單list(以 Bill 為單位)
  @Selector([ECOrderState._memberBillList])
  static memberBillList(_memberBillList: CustomerBillInfo[]) {
    return _memberBillList;
  }

  // 會員 訂單list(以 Order 為單位)
  @Selector([ECOrderState._memberBillList])
  static ecMemberOrderList(_memberBillList: CustomerBillInfo[]) {
    let memberOrderList = [];

    _.forEach(_memberBillList, (bill) => {
      _.forEach(bill.orders, (order) => {
        memberOrderList.push(order);
      });
    });

    // 依照訂單編號排序
    memberOrderList = _.orderBy(
      memberOrderList,
      ['orderBillId', 'orderId'],
      ['desc', 'desc']
    );
    return memberOrderList;
  }

  // 會員 訂單list(以 Bill 為單位)
  @Selector([ECOrderState._memberBillDetail])
  static memberBillDetail(_memberBillDetail: CustomerBillInfo) {
    return _memberBillDetail;
  }

  // 會員 退貨單list
  @Selector([ECOrderState._memberOrderReturnList])
  static ecMemberOrderReturnList(_memberOrderReturnList: OrderReturnInfo[]) {
    return _memberOrderReturnList;
  }

  @Selector([ECOrderState._orderReturnListPaginationInfo])
  static orderReturnListPaginationInfo(
    orderReturnListPaginationInfo: PaginationInfo
  ) {
    return orderReturnListPaginationInfo;
  }

  // 會員咪幣交易紀錄
  @Selector([ECOrderState._memberCoinTxnList])
  static memberCoinTxnList(_memberCoinTxnList: CustomerCoinTxnInfo[]) {
    return _.chain(_memberCoinTxnList)
      .map((txn) => {
        const new_txn = _.cloneDeep(txn);
        new_txn.createdAt = format(parseISO(txn.createdAt), 'yyyy/MM/dd HH:mm');
        return new_txn;
      })
      .value();
  }

  @Selector([ECOrderState._coinTxnListPaginationInfo])
  static coinTxnListPaginationInfo(_coinTxnListPaginationInfo: PaginationInfo) {
    return _coinTxnListPaginationInfo;
  }

  // ===============================================================================
  //                             Action
  // ===============================================================================

  // 取得會員咪幣資訊(balance)
  @Action(GetECCustomerCoinInfoFromServer)
  async getECCustomerCoinInfoFromServer(ctx: StateContext<ECOrderStateModel>) {
    console.log('[Action] getECCustomerCoinInfoFromServer');

    try {
      const result = await this.ecOrderService.getCustomerCoinInfo();
      console.log('result', result);

      ctx.patchState({
        memberCoinInfo: result,
      });
    } catch (error) {
      console.error('getCustomerCoinInfo error', error);
      throw error;
    }
  }

  // 取得會員咪幣交易紀錄list (線上)
  // 在state處理分頁的邏輯
  @Action(GetECCustomerCoinTxnList)
  async GetECCustomerCoinTxnList(ctx: StateContext<ECOrderStateModel>) {
    console.log('[Action] GetECCustomerCoinTxnList');

    try {
      const state = ctx.getState();
      const coinTxnListPaginationInfo = state.coinTxnListPaginationInfo;

      // 讀取目前state的pageIndex +1 , 如果沒有則設定為1
      let pageIndex = 0;
      if (coinTxnListPaginationInfo) {
        pageIndex = coinTxnListPaginationInfo.pageIndex + 1;

        // 判斷是否已達最後一頁
        if (pageIndex > coinTxnListPaginationInfo.totalPages) {
          // 已超過最後一頁 不處理
          console.warn('已超過最後一頁 不處理');
          return;
        }
      } else {
        pageIndex = 1;
      }

      // 設定EC查詢條件
      const params: QueryCoinHistParams = {};
      params.pageIndex = pageIndex;
      params.pageSize = environment.EC_COIN_HIST_PAGE_SIZE;
      params.queryReq = {};
      // 限制最大抓取一年內的資料
      const startAt = add(new Date(), { days: 365 * -1 });
      const endAt = add(new Date(), { days: 1 });
      params.queryReq.startAt = format(startAt, 'yyyy-MM-dd');
      params.queryReq.endAt = format(endAt, 'yyyy-MM-dd');

      console.log('getCoinTransByCustomerPaging params', params);
      const result: any =
        await this.ecOrderService.getCoinTransByCustomerPaging(params);

      console.log('getCoinTransByCustomerPaging', result);
      const paginationInfo: PaginationInfo = {};
      paginationInfo.pageIndex = result.pageIndex;
      paginationInfo.pageSize = result.pageSize;
      paginationInfo.totalCount = result.totalCount;
      paginationInfo.totalPages = result.totalPages;

      // 合併新的資料到舊的資料
      const new_memberCoinTxnList = _.chain(state.memberCoinTxnList)
        .unionBy(result.rows, 'id')
        .orderBy(['id'], ['desc'])
        .value();

      ctx.patchState({
        memberCoinTxnList: new_memberCoinTxnList,
        coinTxnListPaginationInfo: paginationInfo,
      });
    } catch (error2) {
      console.warn('GetECCustomerCoinTxnList action error', error2);
    }
  }

  // 重置EC咪幣txn list 的分頁資訊
  @Action(ResetECCustomerCoinTxnListPaganation)
  async ResetECCustomerCoinTxnListPaganation(
    ctx: StateContext<ECOrderStateModel>
  ) {
    console.log('[Action] ResetECCustomerCoinTxnListPaganation');
    ctx.patchState({
      memberCoinTxnList: [],
      coinTxnListPaginationInfo: null,
    });
  }

  //#region ========================= 購物車 Actions =========================

  @Action(GetECOrderCartFromServer)
  async GetECOrderCartFromServer(ctx: StateContext<ECOrderStateModel>) {
    console.log('[Action] GetECOrderCartFromServer');

    const { memberCarts } = ctx.getState();
    console.log('memberCarts', memberCarts);
    try {
      const result = await this.ecOrderService.getShippingCartViews();
      console.log('getShippingCartViews result', result);

      if (result) {
        const memberCarts = [];
        _.forEach(result, (supplier: any) => {
          _.forEach(supplier.item, (item) => {
            memberCarts.push({
              commoditySpecId: item.commoditySpecId,
              quantity: item.quantity,
            });
          });
        });
        console.log('memberCarts', memberCarts);

        ctx.patchState({
          memberCarts,
          memberCartsDetail: result,
        });
      }
    } catch (error) {
      console.error('GetECOrderCartFromServer error', error);
      throw error;
    }
  }

  @Action(SetECOrderCartToServer)
  async setECOrderCartToServer(
    ctx: StateContext<ECOrderStateModel>,
    action: SetECOrderCartToServer
  ) {
    const { memberCarts } = ctx.getState();
    console.log('memberCarts', memberCarts);

    try {
      const result = await this.ecOrderService.setShippingCarts(memberCarts);
      console.log('result', result);
    } catch (error) {
      console.error('setECOrderCartToServer error', error);
      throw error;
    }
  }

  @Action(AddCommodityToCart)
  async addCommodityToCart(
    ctx: StateContext<ECOrderStateModel>,
    action: AddCommodityToCart
  ) {
    console.log('[Action] AddCommodityToCart');

    const { payload } = action;
    const { memberCarts } = ctx.getState();

    // 先將 memberCarts deepClone
    // 如果購物車中有此商品，則更新數量
    // 如果購物車中沒有此商品，則新增此商品
    const newMemberCarts = _.cloneDeep(memberCarts);
    const index = _.findIndex(newMemberCarts, {
      commoditySpecId: payload.commoditySpecId,
    });

    if (index > -1) {
      newMemberCarts[index].quantity += payload.quantity;
    } else {
      newMemberCarts.push(payload);
    }
    console.log('newMemberCarts', newMemberCarts);

    ctx.patchState({
      memberCarts: newMemberCarts,
    });

    // 新增 加入商品到購物車的log
    try {
      const result = await this.othersService.addCartLog(
        payload.commoditySpecId
      );
      console.log('addCartLog result', result);
    } catch (error) {
      console.warn('othersService addCartLog error', error);
    }
  }

  @Action(RemoveCommodityToCart)
  async removeCommodityToCart(
    ctx: StateContext<ECOrderStateModel>,
    action: RemoveCommodityToCart
  ) {
    console.log('[Action] RemoveCommodityToCart');

    const { commoditySpecId } = action;
    const { memberCarts } = ctx.getState();

    // 先將 memberCarts deepClone
    // 如果購物車中有此商品，則移除此商品
    const newMemberCarts = _.cloneDeep(memberCarts);
    const index = _.findIndex(newMemberCarts, {
      commoditySpecId: commoditySpecId,
    });

    if (index > -1) {
      newMemberCarts.splice(index, 1);
    }
    console.log('newMemberCarts', newMemberCarts);

    ctx.patchState({
      memberCarts: newMemberCarts,
    });
  }

  @Action(UpdateCommodityToCart)
  async updateCommodityToCart(
    ctx: StateContext<ECOrderStateModel>,
    action: UpdateCommodityToCart
  ) {
    console.log('[Action] UpdateCommodityToCart');

    const { commoditySpecId, quantity } = action;
    const { memberCarts } = ctx.getState();

    // 先將 memberCarts deepClone
    // 如果購物車中有此商品，則更新數量
    const newMemberCarts = _.cloneDeep(memberCarts);
    const index = _.findIndex(newMemberCarts, {
      commoditySpecId: commoditySpecId,
    });

    if (index > -1) {
      newMemberCarts[index].quantity = quantity;
    }
    console.log('newMemberCarts', newMemberCarts);

    ctx.patchState({
      memberCarts: newMemberCarts,
    });
  }

  @Action(UpdateCommoditySpecToCart)
  async updateCommoditySpecToCart(
    ctx: StateContext<ECOrderStateModel>,
    { old_commoditySpecId, new_commoditySpecId }: UpdateCommoditySpecToCart
  ) {
    console.log('[Action] UpdateCommoditySpecToCart');

    const { memberCarts } = ctx.getState();

    // 先將 memberCarts deepClone
    // 如果購物車中有此商品，則更新商品規格
    let newMemberCarts = _.cloneDeep(memberCarts);
    newMemberCarts = _.map(newMemberCarts, (item) => {
      if (item.commoditySpecId === old_commoditySpecId) {
        item.commoditySpecId = new_commoditySpecId;
      }
      return item;
    });
    console.log('newMemberCarts', newMemberCarts);

    ctx.patchState({
      memberCarts: newMemberCarts,
    });
  }

  // ============================================================================
  //#endregion  ========================= 購物車 Actions =========================

  @Action(ClearECOrderState)
  ClearECOrderState(ctx: StateContext<ECOrderStateModel>) {
    console.log('[Action] ClearECOrderState');
    ctx.setState(defaultECOrderState());
  }

  // 取得結帳商品資訊 from server
  @Action(GetCheckoutInfo)
  async getCheckoutInfo(
    ctx: StateContext<ECOrderStateModel>,
    action: GetCheckoutInfo
  ) {
    console.log('[Action] GetCheckoutInfo');

    try {
      ctx.patchState({
        memberCheckOutInfo: null,
      });

      const result = await this.ecOrderService.getCheckOutInfo(action.payload);
      console.log('result', result);

      ctx.patchState({
        memberCheckOutInfo: result,
      });
    } catch (error) {
      console.error('getCheckoutInfo error', error);
      throw error;
    }
  }

  // 設定結帳(CheckOut)商品收件人資訊
  @Action(SetCheckoutRecipientInfo)
  async setCheckoutRecipientInfo(
    ctx: StateContext<ECOrderStateModel>,
    action: SetCheckoutRecipientInfo
  ) {
    console.log('[Action] SetCheckoutRecipientInfo');
    const { memberCheckOutInfo } = ctx.getState();
    const new_memberCheckOutInfo = _.cloneDeep(memberCheckOutInfo);

    new_memberCheckOutInfo.supplierCommodity.forEach((item) => {
      if (item.supplierId === action.supplierId) {
        item.memberShippingInfo = action.memberShippingInfo;
      }
    });

    ctx.patchState({
      memberCheckOutInfo: new_memberCheckOutInfo,
    });
  }

  // 設定結帳(CheckOut) 折抵咪幣點數
  @Action(SetCheckoutUseCoinPoint)
  async setCheckoutUseCoinPoint(
    ctx: StateContext<ECOrderStateModel>,
    action: SetCheckoutUseCoinPoint
  ) {
    console.log('[Action] SetCheckoutUseCoinPoint');
    const { memberCheckOutInfo } = ctx.getState();
    const new_memberCheckOutInfo = _.cloneDeep(memberCheckOutInfo);

    new_memberCheckOutInfo.supplierCommodity.forEach((item) => {
      if (item.supplierId === action.supplierId) {
        item.useCoinPoint = action.coinPoint;
        item.useCoinDiscountAmount =
          action.coinPoint / new_memberCheckOutInfo.custCoinInfo?.ratio;
      }
    });

    ctx.patchState({
      memberCheckOutInfo: new_memberCheckOutInfo,
    });
  }

  // 商品結帳(CheckOut), 產生訂單
  @Action(SetCheckoutInfo)
  async setCheckoutInfo(
    ctx: StateContext<ECOrderStateModel>,
    action: SetCheckoutInfo
  ) {
    console.log('[Action] SetCheckoutInfo');

    try {
      const result = await this.ecOrderService.setCheckOutInfo(action.payload);
      console.log('result', result);

      ctx.patchState({
        memberCheckOutResult: result,
      });

      // 產生訂單成功後, 刪除購物車內對應的商品
      const { memberCarts } = ctx.getState();
      console.log('memberCarts', memberCarts);

      // 整理 訂單內的 commoditySpecId list
      const commoditySpecIdList = [];
      _.forEach(action.payload.items, (supplier) => {
        _.forEach(supplier.commoditys, (commodity) => {
          commoditySpecIdList.push(commodity.commoditySpecId);
        });
      });
      console.log('commoditySpecIdList', commoditySpecIdList);

      const new_memberCarts = _.filter(memberCarts, (item) => {
        return !_.includes(commoditySpecIdList, item.commoditySpecId);
      });
      console.log('new_memberCarts', new_memberCarts);

      ctx.patchState({
        memberCarts: new_memberCarts,
      });
      await ctx.dispatch(new SetECOrderCartToServer()).toPromise();
      await ctx.dispatch(new GetECOrderCartFromServer()).toPromise();
    } catch (error) {
      console.error('setCheckOutInfo error', error);
      throw error;
    }
  }

  // 取得 Customer's 訂單(Bill) list
  @Action(GetECCustomerBillList)
  async getECCustomerBillList(ctx: StateContext<ECOrderStateModel>) {
    console.log('[Action] GetECCustomerBillList');

    try {
      const state = ctx.getState();
      const billListPaginationInfo = state.billListPaginationInfo;

      // 讀取目前state的pageIndex +1 , 如果沒有則設定為1
      let pageIndex = 0;
      if (billListPaginationInfo) {
        pageIndex = billListPaginationInfo.pageIndex + 1;

        // 判斷是否已達最後一頁
        if (pageIndex > billListPaginationInfo.totalPages) {
          // 已超過最後一頁 不處理
          console.warn('已超過最後一頁 不處理');
          return;
        }
      } else {
        pageIndex = 1;
      }

      // 設定EC查詢條件
      const params: QueryBillParams = {};
      params.pageIndex = pageIndex;
      params.pageSize = environment.EC_BILLLIST_PAGE_SIZE;
      params.queryReq = {};

      console.log('getCustomerBillViewByPaging params', params);
      const result: any = await this.ecOrderService.getCustomerBillViewByPaging(
        params
      );

      console.log('getCustomerBillViewByPaging', result);
      const paginationInfo: PaginationInfo = {};
      paginationInfo.pageIndex = result.pageIndex;
      paginationInfo.pageSize = result.pageSize;
      paginationInfo.totalCount = result.totalCount;
      paginationInfo.totalPages = result.totalPages;

      // 合併新的資料到舊的資料
      const newBillList = _.chain(state.memberBillList)
        .unionBy(result.rows, 'orderBillId')
        .orderBy(['orderBillId'], ['desc'])
        .map((item) => {
          const new_item = _.cloneDeep(item);
          const new_orders = _.map(new_item.orders, (order) => {
            const new_order = this.order_map(order, new_item.orderBillId);
            return new_order;
          });
          new_item.orders = new_orders;
          return new_item;
        })
        .value();

      ctx.patchState({
        memberBillList: newBillList,
        billListPaginationInfo: paginationInfo,
      });
    } catch (error2) {
      console.warn('getECCustomerBillList action error', error2);
    }
  }

  // 取得 Customer's 訂單(Bill) Detail
  @Action(GetECCustomerBillDetail)
  async getECCustomerBillDetail(
    ctx: StateContext<ECOrderStateModel>,
    action: GetECCustomerBillDetail
  ) {
    console.log('[Action] GetECCustomerBillDetail');

    const forceRefresh = action.forceRefresh;

    if (!forceRefresh) {
      // 檢查是否已經有資料
      const state = ctx.getState();
      const memberBillDetail = _.find(state.memberBillList, {
        orderBillId: action.orderBillId,
      });
      if (memberBillDetail) {
        console.log('已經有資料');
        ctx.patchState({
          memberBillDetail: memberBillDetail,
        });
        return;
      }
    }

    try {
      const params: QueryBillParams = {};
      params.pageIndex = 1;
      params.pageSize = 1;
      params.queryReq = {};
      params.queryReq.orderBillId = action.orderBillId;

      const result: any =
        await this.ecOrderService.getCustomerBillViewByOrderBillId(params);
      console.log('getCustomerBillDetail', result);
      if (result?.rows?.length > 0) {
        const newBillDetail = _.cloneDeep(result.rows[0]);
        const new_orders = _.map(newBillDetail.orders, (order) => {
          const new_order = this.order_map(order, newBillDetail.orderBillId);
          return new_order;
        });
        newBillDetail.orders = new_orders;

        ctx.patchState({
          memberBillDetail: newBillDetail,
        });
      } else {
        throw new Error('查無此訂單');
      }
    } catch (error) {
      console.error('getECCustomerBillDetail action error', error);
      throw error;
    }
  }

  // 重置EC訂單list的分頁資訊
  @Action(ResetECCustomerBillListPaganation)
  async resetECCustomerBillListPaganation(
    ctx: StateContext<ECOrderStateModel>
  ) {
    console.log('[Action] ResetECCustomerBillListPaganation');
    ctx.patchState({
      billListPaginationInfo: null,
      memberBillList: [],
    });
  }

  //#region ========================= 退貨, 取消訂單 Actions =========================
  // 取消訂單
  @Action(CancelECCustomerBill)
  async cancelECCustomerBill(
    ctx: StateContext<ECOrderStateModel>,
    action: CancelECCustomerBill
  ) {
    console.log('[Action] CancelECCustomerBill');

    try {
      const result: any = await this.ecOrderService.cancelOrder(
        action.orderBillId
      );
      console.log('cancelECCustomerBill', result);
      return result;
    } catch (error) {
      console.error('cancelECCustomerBill action error', error);
      throw error;
    }
  }

  // 申請退貨, 產生退貨單
  @Action(ApplyOrderReturn)
  async applyOrderReturn(
    ctx: StateContext<ECOrderStateModel>,
    action: ApplyOrderReturn
  ) {
    console.log('[Action] ApplyOrderReturn');

    try {
      const result = await this.ecOrderService.applyOrderReturn(action.payload);
      console.log('applyOrderReturn result', result);
      await ctx.dispatch(new GetECCustomerOrderReturnList()).toPromise();
    } catch (error) {
      console.error('applyOrderReturn error', error);
      throw error;
    }
  }

  // 取得 Customer's 退貨單(order return) list
  @Action(GetECCustomerOrderReturnList)
  async GetECCustomerOrderReturnList(ctx: StateContext<ECOrderStateModel>) {
    console.log('[Action] GetECCustomerOrderReturnList');

    try {
      const state = ctx.getState();
      const orderReturnListPaginationInfo = state.orderReturnListPaginationInfo;

      // 讀取目前state的pageIndex +1 , 如果沒有則設定為1
      let pageIndex = 0;
      if (orderReturnListPaginationInfo) {
        pageIndex = orderReturnListPaginationInfo.pageIndex + 1;

        // 判斷是否已達最後一頁
        if (pageIndex > orderReturnListPaginationInfo.totalPages) {
          // 已超過最後一頁 不處理
          console.warn('已超過最後一頁 不處理');
          return;
        }
      } else {
        pageIndex = 1;
      }

      // 設定EC查詢條件
      const params: QueryReturnListParams = {};
      params.pageIndex = pageIndex;
      params.pageSize = environment.EC_BILLLIST_PAGE_SIZE;
      params.queryReq = {};

      console.log('getOrderReturnInfoByPaging params', params);
      const result: any = await this.ecOrderService.getOrderReturnInfoByPaging(
        params
      );

      console.log('getOrderReturnInfoByPaging', result);
      const paginationInfo: PaginationInfo = {};
      paginationInfo.pageIndex = result.pageIndex;
      paginationInfo.pageSize = result.pageSize;
      paginationInfo.totalCount = result.totalCount;
      paginationInfo.totalPages = result.totalPages;

      // 合併新的資料到舊的資料
      const newOrderReturnList = _.chain(state.memberOrderReturnList)
        .unionBy(result.rows, 'returnId')
        .orderBy(['returnId'], ['desc'])
        .map((item) => {
          const new_item = this.order_return_map(item);
          return new_item;
        })
        .value();

      ctx.patchState({
        memberOrderReturnList: newOrderReturnList,
        orderReturnListPaginationInfo: paginationInfo,
      });
    } catch (error2) {
      console.warn('GetECCustomerOrderReturnList action error', error2);
    }
  }

  // 重置EC退貨單list的分頁資訊
  @Action(ResetECCustomerOrderReturnListPaganation)
  async ResetECCustomerOrderReturnListPaganation(
    ctx: StateContext<ECOrderStateModel>
  ) {
    console.log('[Action] ResetECCustomerOrderReturnListPaganation');
    ctx.patchState({
      orderReturnListPaginationInfo: null,
      memberOrderReturnList: [],
    });
  }
  //#endregion ========================= 退貨 Actions =========================

  order_map(order: any, orderBillId: number) {
    const new_order = _.cloneDeep(order);
    new_order.orderBillId = orderBillId; // 訂單所屬的 帳單編號

    // 處理圖片 url
    new_order.commoditys = _.map(new_order.commoditys, (commodity) => {
      const new_commodity = _.cloneDeep(commodity);
      new_commodity.applyReturnQuantity = 0; // 預設申請退貨數量
      new_commodity.imagePath =
        environment.EC_IMAGE_CDN_URL + commodity.imagePath;
      new_commodity.backgroundImagePath =
        `url(${environment.EC_IMAGE_CDN_URL + commodity.imagePath})` +
        ', url(assets/img/default/ec_commodity_default.png)';
      return new_commodity;
    });

    // 處理訂單狀態描述
    // 5: 待付款, 10: 已成立(已付款), 30: 處理中, 40: 理貨中, 50: 已出貨, 60: 訂單完成, 80: 訂單取消
    switch (new_order.status) {
      case 5:
        new_order.statusDesc = '待付款';
        new_order.statusCategory = '待出貨';
        break;
      case 10:
        new_order.statusDesc = '已成立(已付款)';
        new_order.statusCategory = '待出貨';
        break;
      case 30:
        new_order.statusDesc = '處理中';
        new_order.statusCategory = '待出貨';
        break;
      case 40:
        new_order.statusDesc = '理貨中';
        new_order.statusCategory = '待出貨';
        break;
      case 50:
        new_order.statusDesc = '已出貨';
        new_order.statusCategory = '已出貨';
        break;
      case 60:
        new_order.statusDesc = '訂單完成';
        new_order.statusCategory = '已出貨';
        break;
      case 80:
        new_order.statusDesc = '訂單取消';
        new_order.statusCategory = '待出貨';
        break;
      default:
        new_order.statusDesc = '未知';
        break;
    }

    return new_order;
  }

  // 退貨單map
  order_return_map(order: OrderReturnInfo) {
    const new_order = _.cloneDeep(order);

    // 處理圖片 url
    new_order.imagePath = environment.EC_IMAGE_CDN_URL + order.imagePath;
    new_order.backgroundImagePath =
      `url(${environment.EC_IMAGE_CDN_URL + order.imagePath})` +
      ', url(assets/img/default/ec_commodity_default.png)';

    // 處理退貨單狀態描述
    // 10: 等待退換
    // 20: 退換處理中
    // 60: 處理完成
    // 100: 申請取消
    switch (new_order.status) {
      case 10:
        new_order.statusDesc = '等待退換';
        break;
      case 20:
        new_order.statusDesc = '退換處理中';
        break;
      case 60:
        new_order.statusDesc = '處理完成';
        break;
      case 100:
        new_order.statusDesc = '申請取消';
        break;
      default:
        new_order.statusDesc = '未知';
        break;
    }

    // 退貨處理狀態,
    // 0: 尚未處理,
    // 10: 已退款,
    // 11: 已退款-商品收回，信用卡退款,
    // 12: 已退款-商品收回，移轉購物金,
    // 20: 已換貨
    switch (new_order.action) {
      case 0:
        new_order.actionDesc = '尚未處理';
        break;
      case 10:
        new_order.actionDesc = '已退款';
        break;
      case 11:
        new_order.actionDesc = '已退款-商品收回，信用卡退款';
        break;
      case 12:
        new_order.actionDesc = '已退款-商品收回，移轉購物金';
        break;
      case 20:
        new_order.actionDesc = '已換貨';
        break;
      default:
        new_order.actionDesc = '未知';
        break;
    }

    // 退貨原因
    // 10: 到貨時已有毀損
    // 20: 與訂購商品不符
    // 30: 訂單資料有誤
    // 40: 瑕疵品, 請詳細描述(Content)
    // 80: 其他, 請詳細描述(Content)
    switch (new_order.reason) {
      case 10:
        new_order.reasonDesc = '到貨時已有毀損';
        break;
      case 20:
        new_order.reasonDesc = '與訂購商品不符';
        break;
      case 30:
        new_order.reasonDesc = '訂單資料有誤';
        break;
      case 40:
        new_order.reasonDesc = '瑕疵品, 請詳細描述(Content)';
        break;
      case 80:
        new_order.reasonDesc = '其他, 請詳細描述(Content)';
        break;
      default:
        new_order.reasonDesc = '未知';
        break;
    }

    return new_order;
  }
}
