import { Component, Input, OnInit } from '@angular/core';
import { PostInfo } from '@mommy/models/PostInfo.model';
import { format, parseISO } from 'date-fns';

@Component({
  selector: 'app-expert-post-list2',
  templateUrl: './expert-post-list2.component.html',
  styleUrls: ['./expert-post-list2.component.scss'],
})
export class ExpertPostList2Component implements OnInit {
  _post: PostInfo;
  _feature_image: string;
  _posted_at: string;

  constructor() {}

  ngOnInit() {}

  get post(): PostInfo {
    return this._post;
  }

  @Input() set post(value: PostInfo) {
    this._post = value;
    this._feature_image =
      `url(${value.post_feature_image_url})` +
      ', url(assets/img/default/pgc_post_default.png)';
    this._posted_at = format(
      parseISO(value.updated_at || value.post_modified_at),
      'yyyy-MM-dd HH:mm'
    );
  }
}
