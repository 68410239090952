import { Injectable } from '@angular/core';
import { PostCommentInfo } from '@mommy/models/PostCommentInfo.model';
import { MemberPostCommentService } from '@mommy/services/member/member-post-comment.service';
import { StorageService } from '@mommy/services/storage.service';
import { UserService } from '@mommy/services/user/user.service';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  AddMemberPostComment,
  InitLocalCacheMemberPostComments,
  LoadCacheMemberPostComments,
  ModifyMemberPostComment,
  RefreshMemberPostComments,
} from './member-post-comment.actions';

export interface MemberPostCommentStateModel {
  loading: boolean;
  member_post_comments: PostCommentInfo[];
  hasCache: boolean;
}

const defaultMemberPostCommentState = (): MemberPostCommentStateModel => {
  return {
    loading: false,
    member_post_comments: [],
    hasCache: false,
  };
};

@State<MemberPostCommentStateModel>({
  name: 'MemberPostCommentState',
  defaults: defaultMemberPostCommentState(),
})
@Injectable()
export class MemberPostCommentState {
  constructor(
    private storage: StorageService,
    private memberPostCommentSvc: MemberPostCommentService,
    private userService: UserService,
    private store: Store
  ) {}

  @Selector()
  static member_post_comments(state: MemberPostCommentStateModel) {
    return state.member_post_comments;
  }

  @Action(InitLocalCacheMemberPostComments)
  async InitLocalCacheMemberPostComments(
    ctx: StateContext<MemberPostCommentStateModel>
  ) {
    try {
      await ctx.dispatch(new LoadCacheMemberPostComments()).toPromise();
      console.log('load local cache memberPostComments success');
      // 再呼叫 getMemberPostCommentsFromServer 來更新 local cache
      //this.getMemberPostCommentsFromServer(ctx);
      await ctx.dispatch(new RefreshMemberPostComments()).toPromise();
    } catch (error) {
      console.warn('LoadCacheMemberPostComments error', error);
      // 如果沒有 cache, 就去 server 取
      await ctx.dispatch(new RefreshMemberPostComments()).toPromise();
    }
  }

  @Action(LoadCacheMemberPostComments)
  async LoadCacheMemberPostComments(
    ctx: StateContext<MemberPostCommentStateModel>
  ) {
    console.log('[Action] LoadCacheMemberPostComments');

    const _member_post_comments: any = await this.storage.get(
      'member_post_comments'
    );

    if (_member_post_comments) {
      ctx.setState({
        loading: false,
        member_post_comments: _member_post_comments,
        hasCache: true,
      });
    } else {
      throw new Error('no cache');
    }
  }

  @Action(RefreshMemberPostComments)
  async RefreshMemberPostComments(
    ctx: StateContext<MemberPostCommentStateModel>
  ) {
    console.log('[Action] RefreshMemberPostComments');
    this.getMemberPostCommentsFromServer(ctx);
  }

  @Action(AddMemberPostComment)
  async AddMemberPostComment(
    ctx: StateContext<MemberPostCommentStateModel>,
    { payload }
  ) {
    console.log('[Action] AddMemberPostComment');

    try {
      const result = await this.memberPostCommentSvc.add(payload);
      console.log('AddMemberPostComment success', result);
      this.store.dispatch(new RefreshMemberPostComments());
    } catch (error) {
      console.error('AddMemberPostComment error', error);
      throw error;
    }
  }

  @Action(ModifyMemberPostComment)
  async ModifyMemberPostComment(
    ctx: StateContext<MemberPostCommentStateModel>,
    { payload }
  ) {
    console.log('[Action] ModifyMemberPostComment');

    try {
      const result = await this.memberPostCommentSvc.update(payload);
      console.log('ModifyMemberPostComment success', result);
      this.store.dispatch(new RefreshMemberPostComments());
    } catch (error) {
      console.error('ModifyMemberPostComment error', error);
      throw error;
    }
  }

  private async getMemberPostCommentsFromServer(
    ctx: StateContext<MemberPostCommentStateModel>
  ) {
    // try read data from server
    console.log('getMemberPostCommentsFromServer');
    try {
      const member_post_comments =
        await this.userService.getMemberPostComments();
      console.log('member_post_comments', member_post_comments);
      await ctx.dispatch(new LoadCacheMemberPostComments()).toPromise();
      console.log('load local cache member_post_comments success');
    } catch (error2) {
      console.warn('getMemberPostComments error', error2);
    }
  }
}
