import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIResponse } from '@mommy/models/Comm.model';
import { Api } from '../api';
import { HANDLE_CACHE } from '../token.interceptor';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  constructor(private api: Api) {
    console.log('Hello TaskService');
  }

  getAllTasks(): Promise<any> {
    const endpoint = `v1/codes/reference-codes/all-tasks`;

    return new Promise(async (resolve, reject) => {
      try {
        const context = new HttpContext();
        context.set(HANDLE_CACHE, true);

        const result: APIResponse = (await this.api
          .get(endpoint, null, { context })
          .toPromise()) as any;
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        if (error.status !== 304) {
          console.error('getAllTasks error', error);
        }
        reject(error);
      }
    });
  }
}
