export interface ElineUser {
  id?: number;
  user_id?: string;
  account?: string; //病患：身分證號,醫護:帳號
  // username?: string;
  password?: string;
  confirmpassword?: string;
  display_name?: string;
  patient_name?: string; //醫護端顯示的名稱
  cust_name?: string;
  customer_id?: number;
  email?: string;
  team_id?: string; //deprecated for 多團隊
  teams?: any[]; //for 多團隊
  roles?: string;
  avatar?: string;
  team_name?: string;
  team_subname?: string;
  team_avatar?: string;
  enable_notify?: string;
  enable_notify_notdisturb?: string;
  notify_notdisturb_start?: string;
  notify_notdisturb_end?: string;
  cust_type_code?: string;
  cures?: any;
  phone?: string;
  account_type?: string;
  security_question?: any;
  category_code?: string; //病患所屬團隊的類型是H:hospital V:vender
  family?: any[]; //病患端, 陣列第一個元素填寫自己的user_id,後面的擺放加入的家人userid
  family_u?: any[]; //病患端, 陣列第一個元素填寫自己的user_id,後面的擺放加入的家人userid,前面有+'u'
  family_without_self?: any[]; //病患端, 只擺放加入的家人userid
  family_channels?: any[]; //病患端, 擺放加入的家人的channel id
  chat_channel_invite_to?: any[]; //已同意加入的家人channel
  chat_channel_invite_from?: any[]; //家人加入到自己的channel
  tag?: any; // 病患的tag
  baby_due_date?: any; //預產期
  city?: string;
  district?: string;
  lmp_date?: any; //最後一次月經日
  pregnancy?:
    | ''
    | '1個月'
    | '2個月'
    | '3個月'
    | '4個月'
    | '5個月'
    | '6個月'
    | '7個月'
    | '8個月'
    | '9個月'
    | '10個月'; // 孕期, 對應分類的cat_name
}

export interface Team {
  // id?: number;
  // teamid?: string;
  // name?: string;
  // displayname?: string;
  // subname?: string;
  // description?: string;
  // avatar?: string;
  // titleimage?: string;
  // websiteurl?: string;
  // createat?: any;
  // updateat?: any;
  // deleteat?: any;

  team_id?: number;
  team_name?: string;
  team_subname?: string;
  description?: string;
  team_intro?: string;
  avatar?: string;
  title_image?: string;
  website_url?: string;
  created_at?: any;
  updated_at?: any;
  score?: number;
  cust_type_code?: string;
  welcome_message?: string;
  category_code?: string;
  status?: string; //狀態, trial表示正在試用中 不露出在病患端 ,hidden表示團隊不願意露出
  customer_dynamic_profile?: any; // 團隊的個案輪廓設定
  ec_home_url?: string; // 團隊電商網址
  intro?: string;
}

export interface Channel {
  // id?: number;
  // channelid?: string;
  // teamid?: any;
  // name?: string;
  // team_displayname?: string; // for patient (hospital name)
  // user_displayname?: string;  // for hospital (patient username)
  // lastpost?: string;
  // lastpostat?:any;
  // header?: string;
  // team_avatar?: string;
  // user_avatar?: string;
  // userid?: any;
  // members?:any;
  // chatusers?:any;
  // createat?:any;
  // updateat?:any;
  // deleteat?:any;
  // lastunreadmessagekey?:string;
  // unread_cnt?:number;
  // local_msgs?:any; // for chat page initial UI performance issue

  key?: string;
  cid?: string; //channel_id
  tid?: any; //team_id
  tdn?: string; // team_displayname for patient (hospital name)
  udn?: string; // user_displayname for hospital (patient username)
  lp?: string; //lastpost
  lpat?: any; //lastpostat
  ta?: string; //team_avatar
  ua?: string; //user_avatar
  uid?: any; //user_id
  cat?: any; //createat
  uat?: any; //updateat
  urc?: any; //unread_count
  updateat?: any; //updateat
  deleteat?: any; //deleteat
  lastunreadmessagekey?: string; //lastunreadmessagekey
  unread_cnt?: number; //unread_cnt
  local_msgs?: any; // for chat page initial UI performance issue
  displayname?: string;
  avatar?: string;
  cust_type_code?: string;
  patient_cures?: any;
  team_name?: string; //for 多團隊 醫護端使用
  lmtf?: any; // last message team flag,最後一則的訊息是否是team發的 true/false
  family_flag?: any; //是否為家人模式的channel?
  team_avatar?: string; //team avatar,顯示浮水印使用
  options?: any; //放一些參數用 (ex: readonly ...)
  first_chat_flag?: boolean; // 剛剛建立 channel, 進入chat page時用來判斷是否要發送 welcome message
  cures?: any;
  category_code?: string;
  dateformat?: string;
  ec_home_url?: string;
}

export enum MessageType {
  TEXT = <any>'txt',
  PHRASE = <any>'phr',
  PICTURE = <any>'pic',
}

export interface Post {
  // id?: number;
  // postid?: string;
  // userid?: string;
  // username?: string;
  // useravatar?: string;
  // channelid?: string;
  // message?: string;
  // type?: MessageType;
  // pic_url?: string;
  // sentstatus: string;
  // readstatus: string;  //0:未讀 1:已讀
  // createat?: any;
  // updateat?:any;
  // deleteat?:any;
  // $key?:any;
  // key?:any;
  // unreadflag:string; //用來判斷是否顯示"未讀section"
  // actions?:any; //此message帶的actions

  pid?: string; //postid
  m?: string; //message
  t?: MessageType;
  pic?: string; //pic_url
  uid?: any; //userid
  cat?: any; //createat
  uat?: any; //updateat
  un?: string; //username
  rs?: string; //readstatus 0:未讀 1:已讀
  act?: any; //此message帶的actions
  extend?: any; //extend
  $key?: any;
  key?: any;
  fuid?: any; //family userid
  unreadflag: string; //用來判斷是否顯示"未讀section"
  from_type?: string; //訊息是誰發的,user or duty
  ownership?: string; //mine, other
  un2?: string; //username2, map病患端的username顯示(家人模式新增的)
  dat?: any; // deleted_at 訊息回收時間
  duid?: any; // deleted uid 訊息回收的user id
  reMsgId?: string; // reply message id
  reMsg?: any; // reply message object
  links?: any; // message內的url[]
  link_meta?: any; // url link meta
}

export interface ChannelMembers {
  channelid?: string;
  userid?: string;
  roles?: string;
  lastviewat?: any;
  msgcount?: number;
}

export type AuthStatus =
  | 'LOCAL_APP_USER_AUTHENTICATED'
  | 'APP_USER_AUTHENTICATED'
  | 'APP_USER_NOT_AUTHENTICATED'
  | 'UNKNOWN';

// 個案的推播訊息物件

export interface ImageObject {
  imageUrl?: string;
  imageData?: string; // base64 image data, 未上傳前使用 @client
  imageName?: string;
}
