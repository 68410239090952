import { Injectable } from '@angular/core';
import { APIResponse } from '@mommy/models/Comm.model';
import { QueryCommodityParams } from '@mommy/models/ec/ECCommon.model';
import { ECApi } from './ec-api';

@Injectable({
  providedIn: 'root',
})
export class ECCommodityService {
  constructor(private api: ECApi) {
    console.log('Hello ECCommodityService');
  }

  getCommodityCategory(): Promise<any> {
    const endpoint = `Portal/Commodity/GetCommodityCategoryViews`;

    return new Promise(async (resolve, reject) => {
      try {
        const params: any = {};
        params.id = 0; // 列出全部

        const result: APIResponse = await (<any>(
          this.api.put(endpoint, params).toPromise()
        ));
        console.log('result', result);
        resolve(result);
      } catch (error) {
        console.error('getCommodityCategory error', error);
        reject(error);
      }
    });
  }

  // 依照查詢參數, 列出商品
  getCommodityList(params: QueryCommodityParams): Promise<any> {
    const endpoint = `Portal/Commodity/GetCommodityViewsByPaging`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.put(endpoint, params).toPromise()
        ));
        console.log('result', result);
        resolve(result);
      } catch (error) {
        console.error('getCommodityList error', error);
        reject(error);
      }
    });
  }

  // 依照查詢參數, 取得商品詳細資料
  getCommodityDetail(id: number): Promise<any> {
    const endpoint = `Portal/Commodity/GetCommodityInfoView/${id}`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.get(endpoint).toPromise()
        ));
        console.log('result', result);
        resolve(result);
      } catch (error) {
        console.error('getCommodityDetail error', error);
        reject(error);
      }
    });
  }

  // 依照查詢參數, 取得隱形賣場商品詳細資料
  getSpecialCommodityDetail(id: number): Promise<any> {
    const endpoint = `Portal/Commodity/GetCommodityInfoViewBySpecial/${id}`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.get(endpoint).toPromise()
        ));
        console.log('result', result);
        resolve(result);
      } catch (error) {
        console.error('getSpecialCommodityDetail error', error);
        reject(error);
      }
    });
  }
}
