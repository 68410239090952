import { Injectable } from '@angular/core';
import { APIResponse } from '@mommy/models/Comm.model';
import { MemberBabyGrowRecordInfo } from '@mommy/models/MemberBabyGrowRecordInfo.model';
import { Api } from '@mommy/services/api';

@Injectable({
  providedIn: 'root',
})
export class MemberBabyGrowRecordService {
  constructor(private api: Api) {}

  get_list(data?: any): Promise<any> {
    const endpoint = 'v1/member/member-baby-grow-record';

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.get(endpoint).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-baby-grow-record list get error', error);
        reject(error);
      }
    });
  }

  delete(grow_id: number): Promise<any> {
    const endpoint = `v1/member/member-baby-grow-record/${grow_id}`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.delete(endpoint).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-baby-grow-record delete error', error);
        reject(error);
      }
    });
  }

  add(growRecord: MemberBabyGrowRecordInfo): Promise<any> {
    const endpoint = `v1/member/member-baby-grow-record`;

    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};
        params = growRecord;

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-baby-grow-record add error', error);
        reject(error);
      }
    });
  }

  update(growRecord: MemberBabyGrowRecordInfo): Promise<any> {
    const endpoint = `v1/member/member-baby-grow-record/${growRecord.grow_id}`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.put(endpoint, growRecord).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-baby-grow-record update error', error);
        reject(error);
      }
    });
  }
}
