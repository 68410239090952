import { CartItemInfo } from '@mommy/models/ec/CartItemInfo.model';
import {
  CheckOutInfo_Input,
  CheckOutInfo_Input2,
} from '@mommy/models/ec/CheckOutInfo.model';
import { ApplyOrderReturn_Input } from '@mommy/models/ec/OrderReturnInfo.model';
import { MemberShippingInfo } from '@mommy/models/MemberShippingInfo.model';

// 取得會員咪幣資訊
export class GetECCustomerCoinInfoFromServer {
  static readonly type = '[EC] GetECCustomerCoinInfoFromServer';
}

// 取得會員咪幣紀錄 (線上)
// 在state處理分頁的邏輯
export class GetECCustomerCoinTxnList {
  static readonly type = '[EC] GetECCustomerCoinTxnList';
}

// 重置EC咪幣txn list 的分頁資訊
export class ResetECCustomerCoinTxnListPaganation {
  static readonly type = '[EC] ResetECCustomerCoinTxnListPaganation';
}

// #region ===== 購物車 =====
// 取得購物車的資料
export class GetECOrderCartFromServer {
  static readonly type = '[EC] GetECOrderCartFromServer';
}

// 更新EC購物車to server
export class SetECOrderCartToServer {
  static readonly type = '[EC] SetECOrderCartToServer';
}

// 增加商品到購物車
export class AddCommodityToCart {
  static readonly type = '[EC] AddCommodityToCart';
  constructor(public payload: CartItemInfo) {}
}

// 刪除商品到購物車
export class RemoveCommodityToCart {
  static readonly type = '[EC] RemoveCommodityToCart';
  constructor(public commoditySpecId: number) {}
}

// 更新商品數量到購物車
export class UpdateCommodityToCart {
  static readonly type = '[EC] UpdateCommodityToCart';
  constructor(public commoditySpecId: number, public quantity: number) {}
}

// 更新商品規格到購物車
export class UpdateCommoditySpecToCart {
  static readonly type = '[EC] UpdateCommoditySpecToCart';
  constructor(
    public old_commoditySpecId: number,
    public new_commoditySpecId: number
  ) {}
}
// ========================================
// #endregion ===== 購物車 =================

// #region ===== 結帳 CheckOut ============
// 取得結帳(CheckOut)商品資訊
export class GetCheckoutInfo {
  static readonly type = '[EC] GetCheckoutInfo';
  constructor(public payload: CheckOutInfo_Input[]) {}
}

// 設定結帳(CheckOut)商品收件人資訊
export class SetCheckoutRecipientInfo {
  static readonly type = '[EC] SetCheckoutRecipientInfo';
  constructor(
    public supplierId: number,
    public memberShippingInfo: MemberShippingInfo
  ) {}
}

// 設定結帳(CheckOut) 折抵咪幣點數
export class SetCheckoutUseCoinPoint {
  static readonly type = '[EC] SetCheckoutUseCoinPoint';
  constructor(public supplierId: number, public coinPoint: number) {}
}

// 商品結帳(CheckOut), 產生訂單
export class SetCheckoutInfo {
  static readonly type = '[EC] SetCheckoutInfo';
  constructor(public payload: CheckOutInfo_Input2) {}
}

// 付款成功事件
export class ECPaymentSuccess {
  static readonly type = '[EC] ECPaymentSuccess';
}

// 付款失敗事件
export class ECPaymentFail {
  static readonly type = '[EC] ECPaymentFail';
  constructor(public ErrorMessage: string) {}
}

// #endregion ===== 結帳 CheckOut =================

// 取得EC訂單(Bill)list (線上)
// 在state處理分頁的邏輯
export class GetECCustomerBillList {
  static readonly type = '[EC] GetECCustomerBillList';
}

// 重置EC訂單list的分頁資訊
export class ResetECCustomerBillListPaganation {
  static readonly type = '[EC] ResetECCustomerBillListPaganation';
}

// 取得EC訂單(Bill)明細(線上)
// forceRefresh: 是否強制重新取得資料, 否則會先檢查state是否有資料並回傳
export class GetECCustomerBillDetail {
  static readonly type = '[EC] GetECCustomerBillDetail';
  constructor(public orderBillId: number, public forceRefresh?: boolean) {}
}

// 取消訂單
export class CancelECCustomerBill {
  static readonly type = '[EC] CancelECCustomerBill';
  constructor(public orderBillId: number) {}
}

// 退貨申請, 產生退貨單
export class ApplyOrderReturn {
  static readonly type = '[EC] ApplyOrderReturn';
  constructor(public payload: ApplyOrderReturn_Input[]) {}
}

// 取得EC退貨單list (線上)
export class GetECCustomerOrderReturnList {
  static readonly type = '[EC] GetECCustomerOrderReturnList';
}

// 重置EC退貨單list的分頁資訊
export class ResetECCustomerOrderReturnListPaganation {
  static readonly type = '[EC] ResetECCustomerOrderReturnListPaganation';
}

// 清空 EC Order state, 用於登出時
export class ClearECOrderState {
  static readonly type = '[EC] ClearECOrderState';
}
