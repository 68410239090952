import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ExpertInfo } from '@mommy/models/ExpertInfo.model';
import { UtilService } from '@mommy/services/util.service';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-watch-expert-modal',
  templateUrl: './watch-expert-modal.page.html',
  styleUrls: ['./watch-expert-modal.page.scss'],
})
export class WatchExpertModalPage implements OnInit {
  expert: ExpertInfo;
  _expert_tag = '';

  constructor(
    public modalController: ModalController,
    public navParams: NavParams,
    private store: Store,
    private util: UtilService
  ) {
    this.expert = this.navParams.get('expert');
    console.log('expert', this.expert);
  }

  ngOnInit() {
    let _section = '';
    if (this.expert.subject_classes?.length > 0) {
      _section = this.expert.subject_classes[0].taxonomy_name;
    }

    if (this.expert.outpatients?.length > 0) {
      this._expert_tag = `${_section}-${this.expert.outpatients[0]?.outpatient_hospital?.hospital_name}`;
    } else {
      this._expert_tag = `${_section}`;
    }
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  async addWatch() {
    this.util.addExpertWatch(this.expert);
    this.modalController.dismiss({
      addedWatch: true,
    });
  }
}
