/* eslint-disable no-throw-literal */
/* eslint-disable prefer-const */
import { EventEmitter, Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ElineApi } from '../eline-api';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  save_read_flag_changed = new EventEmitter<any>(); // 發送已讀註記給list page, 取代ionic.events

  constructor(public api: ElineApi, public storage: Storage) {}

  //文章的click log
  save_news_click_log(data) {
    let endpoint = 'clicklog';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'ins';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  private extractData(res) {
    console.log(res);
    let body = res; //httpclient , res已經是json
    console.log(body);
    if (body.returnCode === 'OK') {
      return body;
    } else {
      throw { api_error: true, message: body.message };
    }
  }

  // private handleError(error: any) {
  //   console.log(error);
  //   return Observable.throw(error);
  // }
}
