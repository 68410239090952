import { gql } from 'apollo-angular';

export const expertFragment = gql`
  fragment expertBase on ExpertInfo {
    expert_id
    expert_name
    expert_alias_name
    expert_avatar_url
    updated_at
    level
    status
    has_watch
    watch_count
    like_count
    share_count
    keep_count
    top_categorys {
      taxonomy_id
      taxonomy_name
    }
    subject_classes {
      taxonomy_id
      taxonomy_name
    }
    section2 {
      taxonomy_id
      taxonomy_name
    }
    expert2 {
      taxonomy_id
      taxonomy_name
    }
    outpatients {
      hospital_id
      outpatient_hospital {
        hospital_name
      }
      job_title
    }
  }
`;

export const QUERY_EXPERT_DETAIL = gql`
  query getExpert($expert_id: Float!) {
    getExpert(expert_id: $expert_id) {
      ...expertBase
      expertise
      experience
      post_count
      watch_count
      like_count
      share_count
      keep_count
      has_watch
      posts {
        post_id
        post_title
        post_modified_at
        post_feature_image_url
        post_type
      }
      outpatients {
        hospital_id
        job_title
        outpatient_hospital {
          hospital_name
          hospital_avatar_url
        }
      }
    }
  }
  ${expertFragment}
`;

export const QUERY_EXPERT_LIST = gql`
  query getExpertList($updated_at: Float!) {
    getExpertList(updated_at: $updated_at) {
      ...expertBase
    }
  }
  ${expertFragment}
`;
