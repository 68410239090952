import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import videojs from 'video.js';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('target', { static: true }) target: ElementRef;
  @Output() playerEnded = new EventEmitter<any>();
  @Output() playerPlayed = new EventEmitter<any>();
  @Output() taskFinished = new EventEmitter<any>(); // 影片剩餘10sec時 emit event
  taskFinishedEventEmittedFlag = false; // 是否已經發送 event

  @Input() taskFlag: boolean; // 是否為 執行任務的影片觀賞, if true, 將disable progress bar

  // _options: {
  //   fluid: boolean;
  //   aspectRatio: string;
  //   autoplay: boolean;
  //   sources: {
  //     src: string;
  //     type: string;
  //   }[];
  // };

  @Input() options: {
    fluid: boolean;
    aspectRatio: string;
    autoplay: boolean;
    sources: {
      src: string;
      type: string;
    }[];
  };

  player: videojs.Player;
  myPlayer;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    // this.player = videojs(
    //   this.target.nativeElement,
    //   this.options,
    //   function onPlayerReady() {
    //     console.log('onPlayerReady', this);
    //   }
    // );

    console.log('options', this.options);
    this.player = videojs(this.target.nativeElement, this.options, () => {
      console.log('onPlayerReady', this.player);
    });

    this.player.on('ended', () => {
      videojs.log('Awww...over so soon?!');
      console.log('player ended', this.player);
      this.playerEnded.emit(true);
      // const myVideo = videojs(this.target.nativeElement);
      // console.log('target', myVideo);

      // //如過要播放下一隻 video 可以從這裡加上 code
      // // eslint-disable-next-line max-len
      // this.player.src({
      //   type: 'video/mp4',
      //   // eslint-disable-next-line max-len
      //   src: 'https://storage.googleapis.com/mommycaretest.appspot.com/videos/2022-07-16/86 -Eighty Six- [ OP Full ]『Hitorie - 3 minutes 29 seconds _ 3分29秒』【Lyrics】-9c98.mp4',
      // });
      // this.player.play();
    });

    const onTimeUpdate = (event) => {
      // 影片的長度
      const duration = this.player.duration();
      console.log('player.duration()', duration);
      // 目前播放到的進度
      const currentTime = this.player.currentTime();
      console.log('player.currentTime()', currentTime);

      if (duration - currentTime <= 10) {
        if (this.taskFlag && this.taskFinishedEventEmittedFlag === false) {
          console.warn('影片剩餘不到10sec了, emit taskFinished event');
          this.taskFinishedEventEmittedFlag = true;
          this.taskFinished.emit(true);
        }
      }
    };

    this.player.on('play', () => {
      console.log('player play ', this.options.sources);
      setTimeout(() => {
        const duration = this.player.duration();
        console.log('duration', duration);
        this.playerPlayed.emit(duration);
      }, 1000);
    });

    this.player.on('timeupdate', onTimeUpdate);

    // 監聽 volumechange 事件, 以判斷是否靜音 or 開啟聲音
    this.player.on('volumechange', () => {
      console.log('volumechange this.player.muted()', this.player.muted());
      // 儲存目前聲音狀態到 localStorage
      localStorage.setItem('player_muted', this.player.muted());
    });

    // 下一行 如果是執行任務, 可以 disable 播放的progressControl
    console.warn('this.taskFlag', this.taskFlag);
    if (this.taskFlag) {
      this.player.controlBar.progressControl.disable();
    }
  }
  changeUrl(url) {
    console.log('changeUrl', url);
    this.taskFinishedEventEmittedFlag = false; // 換新影片, reset

    this.player.src({
      type: 'video/mp4',
      src: url,
    });
    this.player.play();
  }

  // @Input() set options(value: any) {
  //   if (value) {
  //     this._options = value;
  //     console.log('video _options', value);

  //     this.player = videojs(
  //       this.target.nativeElement,
  //       this.options,
  //       function onPlayerReady() {
  //         console.log('onPlayerReady', this);
  //       }
  //     );
  //   } else {
  //     this._options = null;
  //   }
  // }

  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
