export class LoadChannels {
  static readonly type = '[Channel] LoadChannels';
}

// Inquire badge 計算
export class InquireBadgeCaculate {
  static readonly type = '[Channel] InquireBadgeCaculate';
}

// Inquire badge patient端 計算
export class InquireBadgeCaculatePatient {
  static readonly type = '[Channel] InquireBadgeCaculatePatient';
}

// 更新 channels 資料
export class UpdateChannels {
  static readonly type = '[Channel] UpdateChannels';
  constructor(public channels: any) {}
}

// 進入 channel
export class EnterChannel {
  static readonly type = '[Channel] EnterChannel';
  constructor(public channel: any, public hospital: any, public patient: any) {}
}

// 離開 channel
export class LeaveChannel {
  static readonly type = '[Channel] LeaveChannel';
}

// invoke channel changed (ex: channel invite reply..)
// 要重新 syncUserMe & initChannel..
export class InvokeChannelChanged {
  static readonly type = '[Channel] InvokeChannelChanged';
}

export class MoveToMessageId {
  static readonly type = '[Channel] MoveToMessageId';
  constructor(public message_id: string) {}
}
