import { Injectable } from '@angular/core';
import { PostInfo } from '@mommy/models/PostInfo.model';
import { TopActivityInfo } from '@mommy/models/TopActivityInfo.model';
import { LookupService } from '@mommy/services/lookup/lookup.service';
import { OthersService } from '@mommy/services/others/others.service';
import { StorageService } from '@mommy/services/storage.service';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import * as _ from 'lodash';
import { PostState } from '../post/post.state';
import {
  SetAppHomeBabyTopActivity,
  SetAppHomeBabyVideo,
  SetAppHomePregnancyTopActivity,
  SetAppHomePregnancyVideo,
} from './app-home.actions';

export interface AppHomeStateModel {
  home_baby_videos: PostInfo[];
  home_pregnancy_videos: PostInfo[];
  baby_top_activities: TopActivityInfo[];
  pregnancy_top_activities: TopActivityInfo[];
}

const defaultAppHomeState = (): AppHomeStateModel => {
  return {
    home_baby_videos: [],
    home_pregnancy_videos: [],
    baby_top_activities: [],
    pregnancy_top_activities: [],
  };
};

@State<AppHomeStateModel>({
  name: 'AppHomeState',
  defaults: defaultAppHomeState(),
})
@Injectable()
export class AppHomeState {
  constructor(
    private storage: StorageService,
    private lookupSvc: LookupService,
    private store: Store,
    private othersService: OthersService
  ) {}

  @Selector([AppHomeState, PostState.posts])
  static home_baby_videos(state: AppHomeStateModel, posts: PostInfo[]) {
    // 依照 home_baby_videos 的 id 去 posts 中找, 以便可以讀取到最新的資料
    const overlap_data = _.intersectionBy(
      posts,
      state.home_baby_videos,
      'post_id'
    );
    const overlap_data2 = _.map(overlap_data, (item: PostInfo) => {
      return {
        ...item,
        post_feature_image_url2:
          `url(${item.post_feature_image_url})` +
          ', url(assets/img/default/video_default.png)',
      };
    });
    return _.shuffle(overlap_data2); // 隨機排序
  }

  @Selector([AppHomeState, PostState.posts])
  static home_pregnancy_videos(state: AppHomeStateModel, posts) {
    // 依照 home_baby_videos 的 id 去 posts 中找, 以便可以讀取到最新的資料
    const overlap_data = _.intersectionBy(
      posts,
      state.home_pregnancy_videos,
      'post_id'
    );
    const overlap_data2 = _.map(overlap_data, (item: PostInfo) => {
      return {
        ...item,
        post_feature_image_url2:
          `url(${item.post_feature_image_url})` +
          ', url(assets/img/default/video_default.png)',
      };
    });
    console.log('before home_pregnancy_videos$', overlap_data2);
    return _.shuffle(overlap_data2); // 隨機排序
  }

  @Selector()
  static baby_top_activities(state: AppHomeStateModel) {
    return state.baby_top_activities;
  }

  @Selector()
  static pregnancy_top_activities(state: AppHomeStateModel) {
    return state.pregnancy_top_activities;
  }

  //#region ***** Action *****
  @Action(SetAppHomeBabyVideo)
  SetAppHomeBabyVideo(
    ctx: StateContext<AppHomeStateModel>,
    action: SetAppHomeBabyVideo
  ) {
    const state = ctx.getState();
    ctx.patchState({
      home_baby_videos: action.data,
    });
  }

  @Action(SetAppHomePregnancyVideo)
  SetAppHomePregnancyVideo(
    ctx: StateContext<AppHomeStateModel>,
    action: SetAppHomePregnancyVideo
  ) {
    const state = ctx.getState();
    ctx.patchState({
      home_pregnancy_videos: action.data,
    });
  }

  @Action(SetAppHomeBabyTopActivity)
  SetAppHomeBabyTopActivity(
    ctx: StateContext<AppHomeStateModel>,
    action: SetAppHomeBabyTopActivity
  ) {
    const state = ctx.getState();
    ctx.patchState({
      baby_top_activities: action.data,
    });
  }

  @Action(SetAppHomePregnancyTopActivity)
  SetAppHomePregnancyTopActivity(
    ctx: StateContext<AppHomeStateModel>,
    action: SetAppHomePregnancyTopActivity
  ) {
    const state = ctx.getState();
    ctx.patchState({
      pregnancy_top_activities: action.data,
    });
  }
  //#endregion
}
