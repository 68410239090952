import { Oauth2Params } from '@mommy/models/Comm.model';

export class LoadCacheAuth {
  static readonly type = '[Auth] LoadCacheAuth';
}

export class OAuth2 {
  static readonly type = '[Auth] OAuth2';
  constructor(public payload: Oauth2Params) {}
}

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public payload: any) {}
}

export class LoginEmail {
  static readonly type = '[Auth] LoginEmail';
  constructor(public payload: any) {}
}

// 登入 Line, 需傳入 是for Login or for Register
export class LoginLine {
  static readonly type = '[Auth] LoginLine';
  constructor(public payload: 'Login' | 'Register') {}
}

export class LoginGoogle {
  static readonly type = '[Auth] LoginGoogle';
}

export class LoginApple {
  static readonly type = '[Auth] LoginApple';
}

export class LoginPhone {
  static readonly type = '[Auth] LoginPhone';
  constructor(public payload: any) {}
}

export class LoginPhoneVerifyCode {
  static readonly type = '[Auth] LoginPhoneVerifyCode';
  constructor(public sms_code: any) {}
}

// Android 門號認證完成事件
export class PhoneInstantVerficationUser {
  static readonly type = '[Auth] PhoneInstantVerficationUser';
}

// 登入 mommy 平台的 action
export class LoginMommyOAuth2 {
  static readonly type = '[Auth] LoginMommyOAuth2';
  constructor(
    public payload: any,
    public login_type: 'EMAIL' | 'GOOGLE' | 'APPLE' | 'LINE' | 'PHONE'
  ) {}
}

export class LoginFirebaseWithCustomToken {
  static readonly type = '[Auth] LoginFirebaseWithCustomToken';
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class LogoutForDeleteAccount {
  static readonly type = '[Auth] LogoutForDeleteAccount';
}

export class LogoutDevice {
  static readonly type = '[Auth] LogoutDevice';
  constructor(public device_id: string) {}
}

export class Register {
  static readonly type = '[Auth] Register';
  constructor(public payload: any) {}
}

export class RefreshAccessToken {
  static readonly type = '[Auth] RefreshAccessToken';
}

export class RefreshRefreshToken {
  static readonly type = '[Auth] RefreshRefreshToken';
  constructor(public payload: any) {}
}

// 刪除帳號
export class DeleteAccount {
  static readonly type = '[Auth] DeleteAccount';
}

// 驗證Mommy user token狀態
export class CheckToken {
  static readonly type = '[Auth] CheckToken';
}

// Server api 返回 401
export class Got401 {
  static readonly type = '[Auth] Got401';
}

// Server api 返回 403, 且狀態為停用
export class Got403UserIsPending {
  static readonly type = '[Auth] Got403UserIsPending';
}

// Server api 返回 404, 且使用者找不到(帳號刪除)
export class Got404UserNotFound {
  static readonly type = '[Auth] Got404UserNotFound';
}

// 登入成功事件
export class LoginSuccess {
  static readonly type = '[Auth] LoginSuccess';
}

// 登出成功事件
export class LogOutSuccess {
  static readonly type = '[Auth] LogOutSuccess';
}

// CheckToken成功事件
export class CheckTokenSuccess {
  static readonly type = '[Auth] CheckTokenSuccess';
}
