import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { Platform } from '@ionic/angular';
import { environment } from '@mommy/environments/environment';
import { Oauth2Params } from '@mommy/models/Comm.model';
import { ElineUser } from '@mommy/models/Eline.model';
import { UserInfo } from '@mommy/models/UserInfo.model';
import { AuthService } from '@mommy/services/auth/auth.service';
import { LineUtilService } from '@mommy/services/line/line-util.service';
import { StorageService } from '@mommy/services/storage.service';
import {
  Action,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { differenceInDays, parse } from 'date-fns';
import jwt_decode from 'jwt-decode';
import * as _ from 'lodash';
import { UserState } from '../user/user.state';
import {
  CheckToken,
  DeleteAccount,
  Got401,
  Got403UserIsPending,
  Got404UserNotFound,
  LoadCacheAuth,
  LoginApple,
  LoginEmail,
  LoginFirebaseWithCustomToken,
  LoginGoogle,
  LoginLine,
  LoginMommyOAuth2,
  LoginPhone,
  LoginPhoneVerifyCode,
  Logout,
  LogoutForDeleteAccount,
  RefreshAccessToken,
} from './auth.actions';

export interface AuthStateModel {
  loading: boolean;
  user: UserInfo;
  token: string;
  mommy_token: string;
  mommy_refresh_token: string;
  eline_token: string;
  ec_token: string;
  firebase_token: string;
  hasCache: boolean;
}

const defaultAuthState = (): AuthStateModel => {
  return {
    loading: false,
    user: null,
    token: null,
    mommy_token: null,
    mommy_refresh_token: null,
    eline_token: null,
    ec_token: null,
    firebase_token: null,
    hasCache: false,
  };
};

@State<AuthStateModel>({
  name: 'AuthState',
  defaults: defaultAuthState(),
})
@Injectable()
export class AuthState implements NgxsAfterBootstrap {
  constructor(
    private storage: StorageService,
    private authService: AuthService,
    private lineUtilService: LineUtilService,
    private platform: Platform
  ) {}

  async ngxsAfterBootstrap(ctx: StateContext<AuthStateModel | null>) {
    console.log('[AuthState] ngxsAfterBootstrap');

    // try {
    //   await ctx.dispatch(new LoadCacheMaternityKit()).toPromise();
    //   console.log('load local cache maternitykit success');
    //   // 再呼叫 getAllMaternityKit 來更新 local cache
    //   this.getMaternityKitFromServer(ctx);
    // } catch (error) {
    //   console.warn('LoadCacheMaternityKit error', error);
    //   // 如果沒有 cache, 就去 server 取
    //   this.getMaternityKitFromServer(ctx);
    // }
  }

  @Selector()
  static token(state: AuthStateModel): string | null {
    return state.token;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.token;
  }

  @Selector()
  static mommy_token(state: AuthStateModel): string | null {
    return state.mommy_token;
  }

  @Selector()
  static isMommyAuthenticated(state: AuthStateModel): boolean {
    return !!state.mommy_token;
  }

  @Selector()
  static eline_token(state: AuthStateModel): string | null {
    return state.eline_token;
  }

  @Selector()
  static isElineAuthenticated(state: AuthStateModel): boolean {
    return !!state.eline_token;
  }

  @Selector()
  static ec_token(state: AuthStateModel): string | null {
    return state.ec_token;
  }

  @Selector()
  static isECAuthenticated(state: AuthStateModel): boolean {
    return !!state.ec_token;
  }

  @Selector([AuthState.isElineAuthenticated, UserState.eline_user])
  static elineUserAuthenticateChange(
    isElineAuthenticated: boolean,
    eline_user: ElineUser
  ) {
    console.log(
      '[AuthState] elineUserAuthenticateChange isElineAuthenticated',
      isElineAuthenticated
    );
    console.log(
      '[AuthState] elineUserAuthenticateChange eline_user',
      eline_user
    );

    if (eline_user) {
      return isElineAuthenticated;
    } else {
      // 如果沒有 eline_user, 就return false
      return false;
    }
  }

  @Action(LoadCacheAuth)
  async loadCacheAuth(ctx: StateContext<AuthStateModel>) {
    console.log('[Action] LoadCacheAuth');

    const mommy_token = await this.storage.get('mommy_token');
    const eline_token = await this.storage.get('eline_token');
    const mommy_refresh_token = await this.storage.get('mommy_refresh_token');
    const firebase_token = await this.storage.get('firebase_token');
    const ec_token = await this.storage.get('ec_token');

    ctx.patchState({
      mommy_token,
      eline_token,
      ec_token,
      mommy_refresh_token,
      firebase_token,
      hasCache: true,
    });

    // 檢查token效期, 如果小於90天, 就要重新取得token
    if (mommy_token) {
      const decoded: any = jwt_decode(mommy_token);
      console.log('decoded', decoded);
      const exp = decoded.exp;
      const now = new Date();
      const expDate = new Date(exp * 1000);

      const diff_days = differenceInDays(expDate, now);
      console.log('diff_days', diff_days);

      if (diff_days < environment.ACCESS_TOKEN_EXPIRED_IN_DAYS) {
        // 如果小於90天, 就要重新取得token
        console.log('token will expired in 90 days');
        await ctx.dispatch(new RefreshAccessToken());
      }
    }
  }

  // @Action(OAuth2)
  // async oAuth2(ctx: StateContext<AuthStateModel>, action: OAuth2) {
  //   console.log('[Action] OAuth2');
  //   const state = ctx.getState();

  //   try {
  //     const result = await this.authService.oauth2(action.payload);

  //     const mommy_token = result.token;
  //     const eline_token = result.token;
  //     const mommy_refresh_token = result.refresh_token;
  //     const firebase_token = result.firebase_token;
  //     const ec_token = result.ec_access_token;

  //     await this.storage.set('mommy_token', mommy_token);
  //     await this.storage.set('eline_token', eline_token);
  //     await this.storage.set('mommy_refresh_token', mommy_refresh_token);
  //     await this.storage.set('firebase_token', firebase_token);
  //     await this.storage.set('ec_token', ec_token);

  //     ctx.patchState({
  //       mommy_token,
  //       eline_token,
  //       mommy_refresh_token,
  //       firebase_token,
  //       ec_token,
  //       hasCache: true,
  //     });
  //   } catch (error) {
  //     console.error('[Action] OAuth2 error', error);
  //     throw error;
  //   }
  // }

  @Action(LoginLine)
  async LoginLine(ctx: StateContext<AuthStateModel>, { payload }) {
    console.log('[Action] LoginLine');
    const state = ctx.getState();

    try {
      const result = await this.authService.login_line(payload);
      console.log('[Action] LoginLine result', result);
      this.authService.lineLoginResultPayload = result;
    } catch (error) {
      console.error('[Action] LoginLine error', error);
      throw error;
    }
  }

  @Action(LoginGoogle)
  async loginGoogle(ctx: StateContext<AuthStateModel>) {
    console.log('[Action] LoginGoogle');
    const state = ctx.getState();

    try {
      const result = await this.authService.login_google();
      console.log('[Action] LoginGoogle result', result);
      this.authService.googleLoginResultPayload = result;
    } catch (error) {
      console.error('[Action] LoginGoogle error', error);
      throw error;
    }
  }

  @Action(LoginApple)
  async loginApple(ctx: StateContext<AuthStateModel>) {
    console.log('[Action] LoginApple');
    const state = ctx.getState();

    try {
      const result = await this.authService.login_apple();
      console.log('[Action] LoginApple result', result);
      this.authService.appleLoginResultPayload = result;
    } catch (error) {
      console.error('[Action] LoginApple error', error);
      throw error;
    }
  }

  @Action(LoginPhone)
  async loginPhone(ctx: StateContext<AuthStateModel>, { payload }) {
    console.log('[Action] LoginPhone');
    const state = ctx.getState();

    try {
      const result = await this.authService.login_phone(
        payload.phone_nbr,
        payload.appVerifier
      );
      console.log('[Action] LoginPhone result', result);
      this.authService.verificationId = result.verificationId;
      console.log(
        'this.authService.verificationId',
        this.authService.verificationId
      );
    } catch (error) {
      console.error('[Action] LoginPhone error', error);
      throw error;
    }
  }

  @Action(LoginPhoneVerifyCode)
  async LoginPhoneVerifyCode(ctx: StateContext<AuthStateModel>, { sms_code }) {
    console.log('[Action] LoginPhone');
    const state = ctx.getState();

    try {
      const result = await this.authService.login_phone_verify_code(sms_code);
      console.log('[Action] LoginPhoneVerifyCode result', result);
      this.authService.smsVerifyResultPayload = result;
    } catch (error) {
      console.error('[Action] LoginPhoneVerifyCode error', error);
      throw error;
    }
  }

  @Action(LoginEmail)
  async LoginEmail(ctx: StateContext<AuthStateModel>, { payload }) {
    console.log('[Action] LoginEmail');
    const state = ctx.getState();

    try {
      const result: any = await this.authService.login_email(payload);
      console.log('[Action] LoginEmail result', result);

      const mommy_token = result['auth-token'];
      const eline_token = result['auth-token'];
      const mommy_refresh_token = result['auth-token'];
      const firebase_token = result.firebase_token;
      const ec_token = result.ec_access_token;

      await this.storage.set('mommy_token', mommy_token);
      await this.storage.set('eline_token', eline_token);
      await this.storage.set('mommy_refresh_token', mommy_refresh_token);
      await this.storage.set('firebase_token', firebase_token);
      await this.storage.set('ec_token', ec_token);

      ctx.patchState({
        mommy_token,
        eline_token,
        mommy_refresh_token,
        firebase_token,
        ec_token,
        hasCache: true,
      });
    } catch (error) {
      console.error('[Action] LoginEmail error', error);
      throw error;
    }
  }

  // 依照 login_type, 整理 oauth2 parameters, 呼叫 oauth2 API
  // 取得 token 後, 存入 storage, 再呼叫後續的 actions
  @Action(LoginMommyOAuth2)
  async LoginMommyOAuth2(
    ctx: StateContext<AuthStateModel>,
    { payload, login_type }
  ) {
    console.log('[Action] LoginMommyOAuth2');
    const state = ctx.getState();

    try {
      if (login_type === 'PHONE') {
        payload = this.authService.smsVerifyResultPayload;
      }

      if (login_type === 'GOOGLE') {
        payload = this.authService.googleLoginResultPayload;
      }

      if (login_type === 'APPLE') {
        payload = this.authService.appleLoginResultPayload;
      }

      if (this.platform.is('capacitor') && login_type === 'LINE') {
        payload = this.authService.lineLoginResultPayload;
      }

      const new_payload = await this.processOAuth2Payload(payload, login_type);
      console.log('[Action] LoginMommyOAuth2 new_payload', new_payload);
      const result = await this.authService.oauth2(new_payload);
      console.log('[Action] LoginMommyOAuth2 result', result);

      const mommy_token = result.token;
      const eline_token = result.token;
      const mommy_refresh_token = result.refresh_token;
      const firebase_token = result.firebase_token;
      const ec_token = result.ec_access_token;
      const is_new = result.is_new; // 是否為新用戶
      localStorage.setItem('is_new', is_new);

      await this.storage.set('mommy_token', mommy_token);
      await this.storage.set('eline_token', eline_token);
      await this.storage.set('mommy_refresh_token', mommy_refresh_token);
      await this.storage.set('firebase_token', firebase_token);
      await this.storage.set('ec_token', ec_token);

      ctx.patchState({
        mommy_token,
        eline_token,
        mommy_refresh_token,
        firebase_token,
        ec_token,
        hasCache: true,
      });
    } catch (error) {
      console.error('[Action] LoginMommyOAuth2 error', error);
      throw error;
    }
  }

  @Action(LoginFirebaseWithCustomToken)
  async LoginFirebaseWithCustomToken(ctx: StateContext<AuthStateModel>) {
    console.log('[Action] LoginFirebaseWithCustomToken');
    const state = ctx.getState();

    try {
      const result = await this.authService.firebase_login(
        state.firebase_token
      );
      console.log('[Action] LoginFirebaseWithCustomToken result', result);
    } catch (error) {
      console.error('[Action] LoginFirebaseWithCustomToken error', error);
      throw error;
    }
  }

  @Action(Logout)
  async logout(ctx: StateContext<AuthStateModel>) {
    console.log('[Action] Logout');
    const state = ctx.getState();

    try {
      await this.storage.remove('mommy_token');
      await this.storage.remove('mommy_user');
      await this.storage.remove('eline_token');
      await this.storage.remove('eline_user');
      await this.storage.remove('mommy_refresh_token');
      await this.storage.remove('firebase_token');
      await this.storage.remove('ec_token');
      await this.storage.remove('channels');
      await this.storage.remove('memberMaternitykits');
      await this.storage.remove('member_baby_grow_records');
      await this.storage.remove('member_baby_feed_records');
      await this.storage.remove('member_post_comments');
      await this.storage.remove('member_vote_comments');
      await this.storage.remove('join_default_team_flag');

      // 呼叫 api 登出
      //TODO: logout ec, 請 jerry 包裝在 mommy/eline logout api
      this.authService
        .logout()
        .then((resp) => {
          console.log('mommy/eline logout success');
        })
        .catch((err) => {
          console.warn('mommy/eline logout error', err);
        });

      this.authService
        .firebase_logout()
        .then((resp2) => {
          console.log('firebase_logout success');
        })
        .catch((err2) => {
          console.warn('firebase_logout error', err2);
        });

      ctx.patchState({
        mommy_token: null,
        eline_token: null,
        mommy_refresh_token: null,
        firebase_token: null,
        ec_token: null,
        hasCache: false,
      });
    } catch (error) {
      console.error('[Action] Logout error', error);
      // throw error; // 不throw error, 因為 logout 不一定會成功(ex: 帳號已經被刪除/停用..)
    }
  }

  @Action(LogoutForDeleteAccount)
  async LogoutForDeleteAccount(ctx: StateContext<AuthStateModel>) {
    console.log('[Action] LogoutForDeleteAccount');
    const state = ctx.getState();

    try {
      await this.storage.remove('mommy_token');
      await this.storage.remove('mommy_user');
      await this.storage.remove('eline_token');
      await this.storage.remove('eline_user');
      await this.storage.remove('mommy_refresh_token');
      await this.storage.remove('firebase_token');
      await this.storage.remove('ec_token');
      await this.storage.remove('channels');
      await this.storage.remove('memberMaternitykits');
      await this.storage.remove('member_baby_grow_records');
      await this.storage.remove('member_baby_feed_records');
      await this.storage.remove('member_post_comments');
      await this.storage.remove('member_vote_comments');
      await this.storage.remove('join_default_team_flag');

      // 呼叫 api 登出
      this.authService
        .firebase_logout()
        .then((resp2) => {
          console.log('firebase_logout success');
        })
        .catch((err2) => {
          console.warn('firebase_logout error', err2);
        });

      ctx.patchState({
        mommy_token: null,
        eline_token: null,
        mommy_refresh_token: null,
        firebase_token: null,
        ec_token: null,
        hasCache: false,
      });
    } catch (error) {
      console.error('[Action] Logout error', error);
      // throw error; // 不throw error, 因為 logout 不一定會成功(ex: 帳號已經被刪除/停用..)
    }
  }

  @Action(DeleteAccount)
  async deleteAccount(ctx: StateContext<AuthStateModel>) {
    console.log('[Action] DeleteAccount');
    const state = ctx.getState();

    try {
      const result = await this.authService.delete_account();
      console.log('[Action] DeleteAccount result', result);
    } catch (error) {
      console.error('[Action] DeleteAccount error', error);
      throw error;
    }
  }

  // 當 app 啟動時, 先檢查 storage 是否有 token, 如果有, 則呼叫後續的 actions
  @Action(CheckToken)
  async checkToken(ctx: StateContext<AuthStateModel>) {
    console.log('[Action] CheckToken');
    const state = ctx.getState();

    try {
      const result = await this.authService.checkToken();
      console.log('[Action] CheckToken result', result);
    } catch (error) {
      console.error('[Action] CheckToken error', error);
      throw error;
    }
  }

  @Action(RefreshAccessToken)
  async refreshAccessToken(ctx: StateContext<AuthStateModel>) {
    console.log('[Action] RefreshAccessToken');
    const state = ctx.getState();

    try {
      const result = await this.authService.refreshAccessToken();

      const mommy_token = result.token;
      const eline_token = result.token;
      const mommy_refresh_token = result.refresh_token;
      const firebase_token = result.firebase_token;

      await this.storage.set('mommy_token', mommy_token);
      await this.storage.set('eline_token', eline_token);
      await this.storage.set('mommy_refresh_token', mommy_refresh_token);
      await this.storage.set('firebase_token', firebase_token);

      ctx.patchState({
        mommy_token,
        eline_token,
        mommy_refresh_token,
        firebase_token,
        hasCache: true,
      });
    } catch (error) {
      console.error('[Action] RefreshAccessToken error', error);
      throw error;
    }
  }

  // 從 http interpreter 判斷為 401 後, 呼叫此 action
  @Action(Got401)
  async got401(ctx: StateContext<AuthStateModel>) {
    console.log('[Action] Got401');
  }

  // 從 http interpreter 判斷為 403 and UserIsPending 後, 呼叫此 action
  @Action(Got403UserIsPending)
  async got403UserIsPending(ctx: StateContext<AuthStateModel>) {
    console.log('[Action] Got403UserIsPending');
  }

  // 從 http interpreter 判斷為 404 and user not found 後, 呼叫此 action
  @Action(Got404UserNotFound)
  async got404UserNotFound(ctx: StateContext<AuthStateModel>) {
    console.log('[Action] Got404UserNotFound');
  }

  async processOAuth2Payload(payload: any, login_type: string) {
    console.log('[Action] processOAuth2Payload', payload, login_type);

    const new_payload: Oauth2Params = {};
    new_payload.account_type = login_type;

    const device_id = await Device.getId();
    console.log('device_id', device_id.uuid);
    new_payload.device_id = device_id.uuid;

    // 取得 referral_code 從 localstorage, 並判斷是否有過期
    const referral_code = localStorage.getItem('referral_code');
    const referral_code_expire = localStorage.getItem('referral_code_expire');
    if (referral_code && referral_code_expire) {
      const now = new Date();
      const expire = parse(
        referral_code_expire,
        'yyyy-MM-dd HH:mm:ss',
        new Date()
      );
      if (now < expire) {
        new_payload.referral_code = referral_code;
      } else {
        console.warn('referral_code is expired');
        localStorage.removeItem('referral_code');
        localStorage.removeItem('referral_code_expire');
      }
    }

    if (login_type.toUpperCase() === 'LINE') {
      // 需判斷哪個平台取得的資料, 因為回傳的資料格式不同
      if (this.platform.is('capacitor')) {
        new_payload.email = payload?.email;
        new_payload.line_uid = payload?.userID;
        new_payload.display_name = payload?.displayName;
        new_payload.avatar = payload?.pictureURL;
        new_payload.id_token = payload?.id_token;
      } else {
        // 驗證 state 是否為本機
        const state = payload.state;
        const local_state = localStorage.getItem('line_state');
        console.log('line_state1', state);
        console.log('local_state', local_state);
        if (state !== local_state) {
          throw new Error('state not match');
        } else {
          localStorage.removeItem('line_state');
        }

        const webRegister = payload.webRegister;
        let redirect_url = '';
        if (webRegister) {
          redirect_url = environment.line_callback_register_url;
        } else {
          redirect_url = environment.line_callback_login_url;
        }

        // 呼叫 line API, 取得 line user profile
        const idTokenResult: any = await this.lineUtilService.getLineToken(
          payload.code,
          redirect_url
        );
        console.log('idTokenResult', idTokenResult);

        const lineProfileResult: any =
          await this.lineUtilService.getLineProfile(idTokenResult.id_token);
        console.log('lineProfileResult', lineProfileResult);

        new_payload.line_uid = lineProfileResult.sub;
        new_payload.display_name = lineProfileResult.name;
        new_payload.avatar = lineProfileResult.picture;
        new_payload.id_token = idTokenResult.id_token;
      }
    } else if (login_type.toUpperCase() === 'GOOGLE') {
      // 需判斷哪個平台取得的資料, 因為回傳的資料格式不同
      if (this.platform.is('capacitor')) {
        const profile = payload?.additionalUserInfo.profile;
        new_payload.email = payload?.user?.email;
        new_payload.firebase_uid = payload?.user?.uid;
        new_payload.google_uid = profile?.sub;
        // new_payload.display_name = payload?.user?.displayName;  ios 沒有 displayName, 故統一取 profile.name
        new_payload.display_name = profile?.name;
        new_payload.avatar = profile?.picture;
        new_payload.id_token =
          await this.authService.get_plugin_firebase_id_token();
      } else {
        const providerData = _.find(payload?.user.providerData, {
          providerId: 'google.com',
        });
        new_payload.email = payload?.user?.email;
        new_payload.firebase_uid = payload?.user?.uid;
        new_payload.google_uid = providerData?.uid;
        new_payload.display_name = providerData?.displayName;
        new_payload.avatar = providerData?.photoURL;
        new_payload.id_token = await this.authService.get_firebase_id_token();
      }
    } else if (login_type.toUpperCase() === 'APPLE') {
      // 需判斷哪個平台取得的資料, 因為回傳的資料格式不同
      if (this.platform.is('capacitor')) {
        const profile = payload?.additionalUserInfo.profile;
        new_payload.email = payload?.user?.email;
        new_payload.firebase_uid = payload?.user?.uid;
        new_payload.apple_uid = profile?.sub;
        // apple 登入沒有 displayName, 所以用 email 代替
        new_payload.display_name =
          payload?.user?.displayName ||
          this.get_email_display_name(payload?.user?.email);

        // apple 登入沒有 avatar
        //new_payload.avatar = profile?.picture;
        new_payload.id_token =
          await this.authService.get_plugin_firebase_id_token();
      } else {
        const providerData = _.find(payload?.user.providerData, {
          providerId: 'apple.com',
        });
        new_payload.email = payload?.user?.email;
        new_payload.firebase_uid = payload?.user?.uid;
        new_payload.apple_uid = providerData?.uid;
        // apple 登入沒有 displayName, 所以用 email 代替
        new_payload.display_name =
          providerData?.displayName ||
          this.get_email_display_name(payload?.user?.email);
        new_payload.avatar = providerData?.photoURL;
        new_payload.id_token = await this.authService.get_firebase_id_token();
      }
    } else if (login_type.toUpperCase() === 'PHONE') {
      // 需判斷哪個平台取得的資料, 因為回傳的資料格式不同
      if (this.platform.is('capacitor')) {
        // 要判斷是否為 Instant Verification
        if (this.authService.phoneInstantVerficationUser) {
          const _user = this.authService.phoneInstantVerficationUser;
          new_payload.phone = _user?.phoneNumber;
          new_payload.firebase_uid = _user?.uid;
          new_payload.display_name = this.phoneAuthDisplayName(
            _user?.phoneNumber
          );
          new_payload.id_token =
            await this.authService.get_plugin_firebase_id_token();
        } else {
          // 非 Instant Verification, 輸入sms code
          new_payload.phone = payload?.user?.phoneNumber;
          new_payload.firebase_uid = payload?.user?.uid;
          new_payload.display_name = this.phoneAuthDisplayName(
            payload?.user?.phoneNumber
          );
          new_payload.id_token =
            await this.authService.get_plugin_firebase_id_token();
        }
      } else {
        new_payload.phone = payload?.user?.phoneNumber;
        new_payload.firebase_uid = payload?.user?.uid;
        new_payload.display_name = payload?.user?.phoneNumber;
        new_payload.display_name = this.phoneAuthDisplayName(
          payload?.user?.phoneNumber
        );
        new_payload.id_token = await this.authService.get_firebase_id_token();
      }
    } else if (login_type.toUpperCase() === 'EMAIL') {
      new_payload.email = payload.email;
      new_payload.password = payload.password;
    } else {
      throw new Error('unknown login_type');
    }

    // 最後check是否有 display_name, 如果沒有, 則以 email 的名稱取代
    if (!new_payload.display_name) {
      new_payload.display_name = this.get_email_display_name(new_payload.email);
    }
    return new_payload;
  }

  // 手機認證無法取得用戶的 displayname, 故以 Mom1234 取代(1234為手機後四碼)
  private phoneAuthDisplayName(phoneNbr) {
    return 'Mom' + phoneNbr.substr(phoneNbr.length - 4);
  }

  // 取得 email 的名稱, 如果 parsing error, 則回傳 Mom{隨機四碼數字}
  private get_email_display_name(email) {
    try {
      const email_name = email.split('@')[0];
      return email_name;
    } catch (error) {
      return 'Mom' + Math.floor(Math.random() * 10000);
    }
  }
}
