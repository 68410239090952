import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommodityInfo } from '@mommy/models/ec/CommodityInfo.model';

@Component({
  selector: 'app-product-list-item3',
  templateUrl: './product-list-item3.component.html',
  styleUrls: ['./product-list-item3.component.scss'],
})
export class ProductListItem3Component implements OnInit {
  @Output() commodityOnClick = new EventEmitter<CommodityInfo>();
  @Output() addToCartOnClick = new EventEmitter<CommodityInfo>();

  _commodity: CommodityInfo;
  _backgroundImage;

  constructor() {}

  ngOnInit() {}

  get commodity(): any {
    return this._commodity;
  }

  @Input() set commodity(value: any) {
    console.log('commodity', value);
    if (value) {
      this._commodity = value;
      this._backgroundImage = value.backgroundImagePath;
    } else {
      this._commodity = {};
    }
  }

  commodityClick() {
    this.commodityOnClick.emit(this.commodity);
  }

  addToCart() {
    this.addToCartOnClick.emit(this.commodity);
  }
}
