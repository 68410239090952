import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CodeTaxonomyInfo } from '@mommy/models/CodeTaxonomyInfo.model';
import { MyLogEventParams } from '@mommy/models/Comm.model';
import { CapUtilService } from '@mommy/services/caputil.service';
import {
  SetUIPageExpertSearchExpert2,
  SetUIPageExpertSearchKeyword,
  SetUIPageExpertSearchResetAll,
  SetUIPageExpertSearchSection2,
  SetUIPageExpertSearchSubjectClasses,
  SetUIPageExpertSearchTopCategorys,
} from '@mommy/state/app-ui/app-ui.actions';
import { AppUIState } from '@mommy/state/app-ui/app-ui.state';
import { TaxonomyState } from '@mommy/state/taxonomy/taxonomy.state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-expert-filter-modal',
  templateUrl: './expert-filter-modal.page.html',
  styleUrls: ['./expert-filter-modal.page.scss'],
})
export class ExpertFilterModalPage implements OnInit {
  top_categorys?: CodeTaxonomyInfo[];
  subject_classes?: CodeTaxonomyInfo[];
  expert2?: CodeTaxonomyInfo[];
  section2?: CodeTaxonomyInfo[];

  selected_top_categorys?: any;
  selected_subject_classes?: any;
  selected_expert2?: any;
  selected_section2?: any;

  searchKeyword = '';

  constructor(
    public modalController: ModalController,
    private store: Store,
    private caputil: CapUtilService
  ) {}

  ngOnInit() {
    this.resetAll();

    this.top_categorys = this.store.selectSnapshot(
      TaxonomyState.taxonomyType('專家-熱門分類')
    );
    this.subject_classes = this.store.selectSnapshot(
      TaxonomyState.taxonomyType('專家-科別專長')
    );
    this.expert2 = this.store.selectSnapshot(
      TaxonomyState.taxonomyType('專家-專業人士別')
    );
    this.section2 = this.store.selectSnapshot(
      TaxonomyState.taxonomyType('專家-所在區域')
    );

    console.log('top_categorys', this.top_categorys);
    console.log('subject_classes', this.subject_classes);
    console.log('expert2', this.expert2);
    console.log('section2', this.section2);

    this.selected_top_categorys = this.store.selectSnapshot(
      AppUIState.page_expert_search_top_categorys
    );

    this.selected_subject_classes = this.store.selectSnapshot(
      AppUIState.page_expert_search_subject_classes
    );

    this.selected_expert2 = this.store.selectSnapshot(
      AppUIState.page_expert_search_expert2
    );

    this.selected_section2 = this.store.selectSnapshot(
      AppUIState.page_expert_search_section2
    );
  }

  resetAll() {
    this.selected_top_categorys = undefined;
    this.selected_subject_classes = undefined;
    this.selected_expert2 = undefined;
    this.selected_section2 = undefined;
    this.searchKeyword = undefined;

    this.store.dispatch(new SetUIPageExpertSearchResetAll());
  }

  selectTopCategorys(event) {
    console.log('selectTopCategorys', event.detail.value);
    this.store.dispatch(
      new SetUIPageExpertSearchTopCategorys(event.detail.value)
    );

    const params: MyLogEventParams = {
      category: '熱門分類',
      action: '點擊',
      label: event.detail.value,
    };
    this.caputil.logEvent('專家進階搜尋', params);
  }

  selectSubjectClasses(event) {
    console.log('selectSubjectClasses', event.detail.value);
    this.store.dispatch(
      new SetUIPageExpertSearchSubjectClasses(event.detail.value)
    );

    const params: MyLogEventParams = {
      category: '科別專長',
      action: '點擊',
      label: event.detail.value,
    };
    this.caputil.logEvent('專家進階搜尋', params);
  }

  selectExpert2(event) {
    console.log('selectExpert2', event.detail.value);
    this.store.dispatch(new SetUIPageExpertSearchExpert2(event.detail.value));

    const params: MyLogEventParams = {
      category: '專業人士',
      action: '點擊',
      label: event.detail.value,
    };
    this.caputil.logEvent('專家進階搜尋', params);
  }

  selectSection2(event) {
    console.log('selectSection2', event.detail.value);
    this.store.dispatch(new SetUIPageExpertSearchSection2(event.detail.value));

    const params: MyLogEventParams = {
      category: '區域',
      action: '點擊',
      label: event.detail.value,
    };
    this.caputil.logEvent('專家進階搜尋', params);
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data

    this.modalController.dismiss({
      dismissed: true,
    });
  }

  doSearch() {
    console.log('doSearch');

    console.log('selected_top_categorys', this.selected_top_categorys);
    console.log('selected_subject_classes', this.selected_subject_classes);
    console.log('selected_expert2', this.selected_expert2);
    console.log('selected_section2', this.selected_section2);

    if (this.searchKeyword) {
      this.store.dispatch(new SetUIPageExpertSearchKeyword(this.searchKeyword));

      const params: MyLogEventParams = {
        category: '關鍵字',
        action: '點擊',
        label: this.searchKeyword,
      };
      this.caputil.logEvent('專家進階搜尋', params);
    }
    this.modalController.dismiss({
      doSearch: true,
    });
  }
}
