import { Injectable } from '@angular/core';
import { PostBabyJournalInfo } from '@mommy/models/PostBabyJournalInfo.model';
import { PostInfo } from '@mommy/models/PostInfo.model';
import { BabyDiaryPostService } from '@mommy/services/baby-diary-post/baby-diary-post.service';
import { StorageService } from '@mommy/services/storage.service';
import { PostState } from '@mommy/state/post/post.state';
import {
  Action,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import * as _ from 'lodash';
import {
  InitLocalCacheBabyDiaryPost,
  LoadCacheBabyDiaryPost,
  RefreshBabyDiaryPost,
} from './baby-diary-post.actions';

export interface BabyDiaryPostStateModel {
  loading: boolean;
  babyDiaryPosts: PostBabyJournalInfo[];
  hasCache: boolean;
}

const defaultBabyDiaryPostState = (): BabyDiaryPostStateModel => {
  return {
    loading: false,
    babyDiaryPosts: [],
    hasCache: false,
  };
};

@State<BabyDiaryPostStateModel>({
  name: 'BabyDiaryPostState',
  defaults: defaultBabyDiaryPostState(),
})
@Injectable()
export class BabyDiaryPostState implements NgxsAfterBootstrap {
  constructor(
    private storage: StorageService,
    private babyDiaryPostService: BabyDiaryPostService
  ) {}

  async ngxsAfterBootstrap(ctx: StateContext<BabyDiaryPostStateModel | null>) {
    console.log('[BabyDiaryPostState] ngxsAfterBootstrap');

    // try {
    //   await ctx.dispatch(new LoadCacheMaternityKit()).toPromise();
    //   console.log('load local cache maternitykit success');
    //   // 再呼叫 getAllMaternityKit 來更新 local cache
    //   this.getMaternityKitFromServer(ctx);
    // } catch (error) {
    //   console.warn('LoadCacheMaternityKit error', error);
    //   // 如果沒有 cache, 就去 server 取
    //   this.getMaternityKitFromServer(ctx);
    // }
  }

  @Selector()
  static babyDiaryPosts(state: BabyDiaryPostStateModel) {
    return state.babyDiaryPosts;
  }

  @Selector([BabyDiaryPostState.babyDiaryPosts, PostState.posts])
  static babyDiaryPostsWithRelatedPosts(
    babyDiaryPosts: PostBabyJournalInfo[],
    posts: PostInfo[]
  ) {
    console.log(
      '[BabyDiaryPostState] babyDiaryPostsWithRelatedPosts',
      babyDiaryPosts
    );

    return _.chain(babyDiaryPosts)
      .map((post) => {
        const new_post = _.cloneDeep(post);
        // map related_posts
        new_post.related_posts = [];
        if (new_post?.related_post?.post_id) {
          _.forEach(new_post.related_post.post_id, (post_id) => {
            const related_post = _.find(posts, { post_id });
            if (related_post) {
              new_post.related_posts.push(related_post);
            }
          });
        }
        return new_post;
      })
      .value();
  }

  @Action(InitLocalCacheBabyDiaryPost)
  async initLocalCacheBabyDiaryPost(
    ctx: StateContext<BabyDiaryPostStateModel>
  ) {
    const _babyDiaryPosts: any = await this.storage.get('babyDiaryPosts');

    if (_babyDiaryPosts) {
      // do nothing
    } else {
      this.getBabyDiaryPostsFromServer(ctx);
    }
  }

  @Action(LoadCacheBabyDiaryPost)
  async loadCacheBabyDiaryPost(ctx: StateContext<BabyDiaryPostStateModel>) {
    console.log('[Action] loadCacheBabyDiaryPost');

    const state = ctx.getState();
    const _babyDiaryPosts: any = await this.storage.get('babyDiaryPosts');

    if (_babyDiaryPosts) {
      ctx.patchState({
        loading: false,
        babyDiaryPosts: _babyDiaryPosts,
        hasCache: true,
      });
    } else {
      throw new Error('no cache');
    }
  }

  @Action(RefreshBabyDiaryPost)
  async refreshBabyDiaryPost(ctx: StateContext<BabyDiaryPostStateModel>) {
    console.log('[Action] refreshBabyDiaryPost');
    this.getBabyDiaryPostsFromServer(ctx);
  }

  private async getBabyDiaryPostsFromServer(
    ctx: StateContext<BabyDiaryPostStateModel>
  ) {
    // try read data from server
    console.log('getBabyDiaryPostsFromServer');
    try {
      const babyDiaryPosts =
        await this.babyDiaryPostService.getAllBabyDiaryPost();
      console.log('babyDiaryPosts', babyDiaryPosts);
      await this.storage.set('babyDiaryPosts', babyDiaryPosts);
      await ctx.dispatch(new LoadCacheBabyDiaryPost()).toPromise();
      console.log('load local cache babyDiaryPosts success');
    } catch (error2) {
      console.warn('getAllBabyDiaryPost error', error2);
    }
  }
}
