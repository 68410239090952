import { Injectable } from '@angular/core';
import { environment } from '@mommy/environments/environment';
import { CommodityCategoryInfo } from '@mommy/models/ec/CommodityCategoryInfo.model';
import { CommodityInfo, imageInfo } from '@mommy/models/ec/CommodityInfo.model';
import {
  PaginationInfo,
  QueryCommodityParams,
} from '@mommy/models/ec/ECCommon.model';
import { ECCommodityService } from '@mommy/services/ec/ec-commodity.service';
import { StorageService } from '@mommy/services/storage.service';
import { UtilService } from '@mommy/services/util.service';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import * as _ from 'lodash';
import {
  GetECCommodityCategoryDetail,
  GetECCommodityDetail,
  GetECFavoriteCommodityList,
  GetECHomeCommodityList,
  GetECSpecialCommodityDetail,
  InitLocalCacheECCommodityCategories,
  LoadCacheECCommodityCategories,
  RefreshECCommodityCategories,
  ResetSearchECCommodityListPaganation,
  SearchECCommodityList,
  SearchECCommodityListByTag,
} from './ec-commodity.actions';

export interface ECCommodityStateModel {
  loading: boolean;
  commodityCategories: CommodityCategoryInfo[];
  detailCommodityCategories: CommodityCategoryInfo[]; // 目錄詳細資料 包含商品清單
  homeCommodityList: CommodityInfo[]; // EC首頁的商品list, 不斷地concat
  homePaginationInfo: PaginationInfo; // EC首頁的商品list的分頁狀態資料
  searchCommodityList: CommodityInfo[]; // 搜尋的商品list
  searchCommodityListPaginationInfo: PaginationInfo; // 搜尋的商品list的分頁狀態資料

  favoriteCommodityList: CommodityInfo[]; // 我的最愛商品list, for EC Home熱門活動
  detailCommodities: CommodityInfo[]; // 商品詳細資料
  hasCache: boolean;
}

const defaultECCommodityState = (): ECCommodityStateModel => {
  return {
    loading: false,
    commodityCategories: [],
    detailCommodityCategories: [],
    homeCommodityList: [],
    homePaginationInfo: null,
    searchCommodityList: [],
    searchCommodityListPaginationInfo: null,
    favoriteCommodityList: [],
    detailCommodities: [],
    hasCache: false,
  };
};

@State<ECCommodityStateModel>({
  name: 'ECCommodityState',
  defaults: defaultECCommodityState(),
})
@Injectable()
export class ECCommodityState {
  constructor(
    private storage: StorageService,
    private ecCommodityService: ECCommodityService,
    public util: UtilService
  ) {}

  @Selector()
  static commodityCategories(state: ECCommodityStateModel) {
    return state.commodityCategories;
  }

  @Selector()
  static favoriteCommodityList(state: ECCommodityStateModel) {
    return state.favoriteCommodityList;
  }

  @Selector()
  static searchCommodityList(state: ECCommodityStateModel) {
    return state.searchCommodityList;
  }

  @Selector()
  static homeCommodityList(state: ECCommodityStateModel) {
    return state.homeCommodityList;
  }

  @Selector()
  static homePaginationInfo(state: ECCommodityStateModel) {
    return state.homePaginationInfo;
  }

  // 取得 咪幣兌換目錄 id, 若有多個則取updateAt最新的
  @Selector([ECCommodityState.commodityCategories])
  static getECCoinExchangeCommodityCategoryId(
    commodityCategories: CommodityCategoryInfo[]
  ) {
    const coinExchangeCommodityCategory = _.chain(commodityCategories)
      .filter((commodityCategory) => {
        return commodityCategory.attribute === 20; // 咪幣目錄
      })
      .orderBy(['startAt'], ['desc'])
      .first()
      .value();

    return coinExchangeCommodityCategory.id;
  }

  // 首頁 商品list
  @Selector([ECCommodityState.homeCommodityList])
  static ecHomeCommodityList(homeCommodityList: CommodityInfo[]) {
    return _.chain(homeCommodityList)
      .map((commodity) => {
        const new_commodity = _.cloneDeep(commodity);
        new_commodity.imagePath =
          environment.EC_IMAGE_CDN_URL + commodity.imagePath;
        new_commodity.backgroundImagePath =
          `url(${environment.EC_IMAGE_CDN_URL + commodity.imagePath})` +
          ', url(assets/img/default/ec_commodity_default.png)';
        return new_commodity;
      })
      .orderBy(['startAt'], ['desc'])
      .value();
  }

  // 首頁 熱門活動商品 5 筆
  @Selector([ECCommodityState.favoriteCommodityList])
  static ecFavoriteCommodityList(favoriteCommodityList: CommodityInfo[]) {
    return _.chain(favoriteCommodityList)
      .map((commodity) => {
        const new_commodity = _.cloneDeep(commodity);
        new_commodity.imagePath =
          environment.EC_IMAGE_CDN_URL + commodity.imagePath;
        new_commodity.backgroundImagePath =
          `url(${environment.EC_IMAGE_CDN_URL + commodity.imagePath})` +
          ', url(assets/img/default/ec_commodity_default.png)';
        return new_commodity;
      })
      .orderBy(['startAt'], ['desc'])
      .take(5)
      .value();
  }

  // 查詢結果
  @Selector([ECCommodityState.searchCommodityList])
  static ecSearchCommodityList(searchCommodityList: CommodityInfo[]) {
    return _.chain(searchCommodityList)
      .map((commodity) => {
        const new_commodity = _.cloneDeep(commodity);
        new_commodity.imagePath =
          environment.EC_IMAGE_CDN_URL + commodity.imagePath;
        new_commodity.backgroundImagePath =
          `url(${environment.EC_IMAGE_CDN_URL + commodity.imagePath})` +
          ', url(assets/img/default/ec_commodity_default.png)';
        return new_commodity;
      })
      .orderBy(['startAt'], ['desc'])
      .value();
  }

  @Selector()
  static searchCommodityListPaginationInfo(state: ECCommodityStateModel) {
    return state.searchCommodityListPaginationInfo;
  }

  // 取得 EC 商品目錄 by id
  static commodityCategoryById(category_id: number) {
    return createSelector(
      [ECCommodityState],
      (state: ECCommodityStateModel) => {
        return state.commodityCategories.find(
          (item) => item.id === category_id
        );
      }
    );
  }

  // 取得目錄內的商品list
  static commoditiesByCategoryId(category_id: number) {
    return createSelector(
      [ECCommodityState],
      (state: ECCommodityStateModel) => {
        const commodities = state.detailCommodityCategories.find(
          (item) => item.id === category_id
        )?.commodities;

        return _.map(commodities, (elem: CommodityInfo) => {
          const new_elem = _.cloneDeep(elem);
          new_elem.imagePath = environment.EC_IMAGE_CDN_URL + elem.imagePath;
          new_elem.backgroundImagePath =
            `url(${environment.EC_IMAGE_CDN_URL + elem.imagePath})` +
            ', url(assets/img/default/ec_commodity_default.png)';
          return new_elem;
        });
      }
    );
  }

  // 取得目錄內的商品list分頁資料
  static paginationInfoByCategoryId(category_id: number) {
    return createSelector(
      [ECCommodityState],
      (state: ECCommodityStateModel) => {
        return state.detailCommodityCategories.find(
          (item) => item.id === category_id
        )?.paginationInfo;
      }
    );
  }

  // 列出所有目錄(排除咪幣兌換目錄)
  @Selector([ECCommodityState.commodityCategories])
  static ecCommodityCategories(commodityCategories: CommodityCategoryInfo[]) {
    return _.chain(commodityCategories)
      .cloneDeep()
      .filter((commodityCategory) => {
        return commodityCategory.attribute === 10;
      })
      .map((commodityCategory) => {
        commodityCategory.imagePathBanner =
          environment.EC_IMAGE_CDN_URL + commodityCategory.imagePathBanner;
        commodityCategory.imagePathContent =
          environment.EC_IMAGE_CDN_URL + commodityCategory.imagePathContent;
        commodityCategory.imagePathSquare =
          environment.EC_IMAGE_CDN_URL + commodityCategory.imagePathSquare;
        return commodityCategory;
      })
      .value();
  }

  // 取得 EC 商品詳細資料
  static detailCommodity(commodity_id: number) {
    return createSelector(
      [ECCommodityState],
      (state: ECCommodityStateModel) => {
        const commodity = _.find(state.detailCommodities, { id: commodity_id });
        if (commodity) {
          const new_commodity = _.cloneDeep(commodity);
          new_commodity.image = _.map(commodity.image, (elem: imageInfo) => {
            const new_elem = _.cloneDeep(elem);
            new_elem.imagePath = environment.EC_IMAGE_CDN_URL + elem.imagePath;
            new_elem.backgroundImagePath =
              `url(${environment.EC_IMAGE_CDN_URL + elem.imagePath})` +
              ', url(assets/img/default/ec_commodity_default.png)';
            return new_elem;
          });
          new_commodity.imagePath =
            environment.EC_IMAGE_CDN_URL + commodity.imagePath;
          new_commodity.backgroundImagePath =
            `url(${environment.EC_IMAGE_CDN_URL + commodity.imagePath})` +
            ', url(assets/img/default/ec_commodity_default.png)';
          return new_commodity;
        } else {
          return null;
        }
      }
    );
  }

  //#region ========== Actions ==========
  @Action(InitLocalCacheECCommodityCategories)
  async InitLocalCacheECCommodityCategories(
    ctx: StateContext<ECCommodityStateModel>
  ) {
    console.log('[Action] InitLocalCacheElineHospitals');

    // 策略調整為：
    // 1. 先載入 local cache，如果沒有就抓 server (full)
    // 2. 載入完 cache 再抓 server 更新的資料 (incremental)
    try {
      await ctx.dispatch(new LoadCacheECCommodityCategories()).toPromise();
      console.log('load local cache ECCommodityCategories success');
      // 再呼叫 getECCommodityCategoriesFromServer 來更新 local cache
      await ctx.dispatch(new RefreshECCommodityCategories()).toPromise();
    } catch (error) {
      console.warn('LoadCacheECCommodityCategories error', error);
      // 如果沒有 cache, 就去 server 取
      await ctx.dispatch(new RefreshECCommodityCategories()).toPromise();
    }
  }

  @Action(LoadCacheECCommodityCategories)
  async LoadCacheECCommodityCategories(
    ctx: StateContext<ECCommodityStateModel>
  ) {
    console.log('[Action] LoadCacheECCommodityCategories');

    const state = ctx.getState();
    const _ecCommodityCategories: any = await this.storage.get(
      'ec-commodity-categories'
    );

    // const categories = this.sampleData();

    if (_ecCommodityCategories) {
      ctx.patchState({
        loading: false,
        commodityCategories: _ecCommodityCategories,
        hasCache: true,
      });
    } else {
      throw new Error('no cache');
    }
  }

  @Action(RefreshECCommodityCategories)
  async RefreshECCommodityCategories(ctx: StateContext<ECCommodityStateModel>) {
    console.log('[Action] RefreshECCommodityCategories');
    this.getECCommodityCategoriesFromServer(ctx);
  }

  // 取得EC首頁商品list (不含咪幣兌換商品) (線上)
  // 在此處理分頁的邏輯
  @Action(GetECHomeCommodityList)
  async GetECHomeCommodityList(ctx: StateContext<ECCommodityStateModel>) {
    console.log('[Action] GetECHomeCommodityList');

    try {
      const state = ctx.getState();
      const homePaginationInfo = state.homePaginationInfo;

      // 讀取目前state的pageIndex +1 , 如果沒有則設定為1
      let pageIndex = 0;
      if (homePaginationInfo) {
        pageIndex = homePaginationInfo.pageIndex + 1;

        // 判斷是否已達最後一頁
        if (pageIndex > homePaginationInfo.totalPages) {
          // 已超過最後一頁 不處理
          console.warn('已超過最後一頁 不處理');
          return;
        }
      } else {
        pageIndex = 1;
      }

      // 設定EC查詢條件
      const params: QueryCommodityParams = {};
      params.pageIndex = pageIndex;
      params.pageSize = environment.EC_COMMODITY_PAGE_SIZE;
      params.queryReq = {};
      params.queryReq.commodityCategoryId = 0;
      params.queryReq.categoryAttribute = 10;
      params.queryReq.isFavorite = false;
      params.queryReq.queryString = '';

      console.log('getCommodityList params', params);
      const result: any = await this.ecCommodityService.getCommodityList(
        params
      );

      console.log('getCommodityList', result);
      const paginationInfo: PaginationInfo = {};
      paginationInfo.pageIndex = result.pageIndex;
      paginationInfo.pageSize = result.pageSize;
      paginationInfo.totalCount = result.totalCount;
      paginationInfo.totalPages = result.totalPages;

      // 合併新的資料到舊的資料
      const newCommodityList = _.chain(state.homeCommodityList)
        .unionBy(result.rows, 'id')
        .orderBy(['startAt'], ['desc'])
        .value();

      ctx.patchState({
        homeCommodityList: newCommodityList,
        homePaginationInfo: paginationInfo,
      });
    } catch (error2) {
      console.warn('GetECHomeCommodityList action error', error2);
    }
  }

  // 搜尋商品 (不含咪幣兌換商品) (線上)
  @Action(SearchECCommodityList)
  async SearchECCommodityList(
    ctx: StateContext<ECCommodityStateModel>,
    action: SearchECCommodityList
  ) {
    console.log('[Action] SearchECCommodityList');

    try {
      const state = ctx.getState();
      const searchCommodityListPaginationInfo =
        state.searchCommodityListPaginationInfo;

      // 讀取目前state的pageIndex +1 , 如果沒有則設定為1
      let pageIndex = 0;
      if (searchCommodityListPaginationInfo) {
        pageIndex = searchCommodityListPaginationInfo.pageIndex + 1;

        // 判斷是否已達最後一頁
        if (pageIndex > searchCommodityListPaginationInfo.totalPages) {
          // 已超過最後一頁 不處理
          console.warn('已超過最後一頁 不處理');
          return;
        }
      } else {
        pageIndex = 1;
      }

      // 設定EC查詢條件
      const params: QueryCommodityParams = {};
      params.queryReq = {};
      params.queryReq.commodityCategoryId = 0;
      params.queryReq.categoryAttribute = 10; // 一般商品
      params.queryReq.isFavorite = false;
      params.queryReq.queryString = action.keyword;
      params.pageSize = environment.EC_SEARCH_COMMODITY_PAGE_SIZE;
      params.pageIndex = pageIndex;

      const result: any = await this.ecCommodityService.getCommodityList(
        params
      );

      console.log('SearchECCommodityList result', result);
      const paginationInfo: PaginationInfo = {};
      paginationInfo.pageIndex = result.pageIndex;
      paginationInfo.pageSize = result.pageSize;
      paginationInfo.totalCount = result.totalCount;
      paginationInfo.totalPages = result.totalPages;

      // 合併新的資料到舊的資料
      const newCommodityList = _.chain(state.searchCommodityList)
        .unionBy(result.rows, 'id')
        .orderBy(['startAt'], ['desc'])
        .value();

      ctx.patchState({
        searchCommodityList: newCommodityList,
        searchCommodityListPaginationInfo: paginationInfo,
      });
    } catch (error2) {
      console.error('SearchECCommodityList action error', error2);
      throw error2;
    }
  }

  // 重置EC搜尋商品list的分頁資訊
  @Action(ResetSearchECCommodityListPaganation)
  async ResetSearchECCommodityListPaganation(
    ctx: StateContext<ECCommodityStateModel>
  ) {
    console.log('[Action] ResetSearchECCommodityListPaganation');
    ctx.patchState({
      searchCommodityListPaginationInfo: null,
      searchCommodityList: [],
    });
  }

  // 取得EC Category detail, 包含放在此目錄內的商品list (線上)
  // 在此處理分頁的邏輯
  @Action(GetECCommodityCategoryDetail)
  async GetECCommodityCategoryDetail(
    ctx: StateContext<ECCommodityStateModel>,
    { category_id }: GetECCommodityCategoryDetail
  ) {
    console.log('[Action] GetECCommodityCategoryDetail');
    const state = ctx.getState();

    try {
      const _category = _.find(state.commodityCategories, { id: category_id });
      const _detailCategory = _.find(state.detailCommodityCategories, {
        id: category_id,
      });

      // 讀取目前 category state的 pageIndex +1 , 如果沒有則設定為1
      let pageIndex = 0;
      let _commodities;
      if (_detailCategory) {
        const paginationInfo = _detailCategory.paginationInfo;

        if (paginationInfo) {
          pageIndex = paginationInfo.pageIndex + 1;
          _commodities = _detailCategory.commodities;

          // 判斷是否已達最後一頁
          if (pageIndex > paginationInfo.totalPages) {
            // 已超過最後一頁 不處理
            console.warn('已超過最後一頁 不處理');
            return;
          }
        } else {
          pageIndex = 1;
          _commodities = [];
        }
      } else {
        pageIndex = 1;
        _commodities = [];
      }

      // 設定EC查詢條件
      const params: QueryCommodityParams = {};
      params.queryReq = {};
      params.queryReq.commodityCategoryId = category_id;
      params.queryReq.isFavorite = _category.isFavorite;
      params.pageSize = environment.EC_COMMODITY_PAGE_SIZE;
      params.pageIndex = pageIndex;

      const result: any = await this.ecCommodityService.getCommodityList(
        params
      );
      console.log('detailCommodityCategories', result);
      const paginationInfo: PaginationInfo = {};
      paginationInfo.pageIndex = result.pageIndex;
      paginationInfo.pageSize = result.pageSize;
      paginationInfo.totalCount = result.totalCount;
      paginationInfo.totalPages = result.totalPages;

      _commodities = _commodities.concat(result.rows);

      const new_category = _.cloneDeep(_category);
      new_category.paginationInfo = paginationInfo;
      new_category.commodities = _commodities;

      const _detailCommodityCategories = state.detailCommodityCategories;
      const new_detailCommodityCategories = _.cloneDeep(
        _detailCommodityCategories
      );

      const index = _.findIndex(new_detailCommodityCategories, {
        id: category_id,
      });
      if (index > -1) {
        new_detailCommodityCategories.splice(index, 1, new_category);
      } else {
        new_detailCommodityCategories.push(new_category);
      }

      ctx.patchState({
        detailCommodityCategories: new_detailCommodityCategories,
      });

      console.log('GetECCommodityCategoryDetail success');
    } catch (error) {
      console.warn('GetECCommodityCategoryDetail action error', error);
    }

    // // 先載入 local cache
    // const _commodityCategory: any = await this.storage.get(
    //   'commodityCategory-' + action.category_id
    // );

    // // await this.util.sleep(3000);
    // const _detailCommodityCategories = state.detailCommodityCategories;
    // const new_detailCommodityCategories = _.cloneDeep(
    //   _detailCommodityCategories
    // );

    // await this.getCommodityCategoryDetailFromServer(ctx, action.category_id);
  }

  @Action(GetECCommodityDetail)
  async GetECCommodityDetail(
    ctx: StateContext<ECCommodityStateModel>,
    action: GetECCommodityDetail
  ) {
    console.log('[Action] GetECCommodityDetail');
    const state = ctx.getState();

    // 先載入 local cache
    const _commodity: any = await this.storage.get(
      'commodity-' + action.commodity_id
    );

    const _detailCommodities = state.detailCommodities;
    const new_detailCommodities = _.cloneDeep(_detailCommodities);

    if (_commodity) {
      // 先判斷 detailCommodities 是否已經存在, 如果有就置換, 沒有就新增至 detailCommodities
      const index = _.findIndex(new_detailCommodities, {
        id: action.commodity_id,
      });
      if (index > -1) {
        new_detailCommodities.splice(index, 1, _commodity);
      } else {
        new_detailCommodities.push(_commodity);
      }

      ctx.patchState({
        detailCommodities: new_detailCommodities,
      });

      this.getCommodityDetailFromServer(ctx, action.commodity_id, false); // 再呼叫 API 來更新資料
    } else {
      // 如果沒有 cache, 就去 server 取
      await this.getCommodityDetailFromServer(ctx, action.commodity_id, false);
    }
  }

  @Action(GetECSpecialCommodityDetail)
  async GetECSpecialCommodityDetail(
    ctx: StateContext<ECCommodityStateModel>,
    action: GetECSpecialCommodityDetail
  ) {
    console.log('[Action] GetECSpecialCommodityDetail');
    const state = ctx.getState();

    // 先載入 local cache
    const _commodity: any = await this.storage.get(
      'commodity-' + action.commodity_id
    );

    const _detailCommodities = state.detailCommodities;
    const new_detailCommodities = _.cloneDeep(_detailCommodities);

    if (_commodity) {
      // 先判斷 detailCommodities 是否已經存在, 如果有就置換, 沒有就新增至 detailCommodities
      const index = _.findIndex(new_detailCommodities, {
        id: action.commodity_id,
      });
      if (index > -1) {
        new_detailCommodities.splice(index, 1, _commodity);
      } else {
        new_detailCommodities.push(_commodity);
      }

      ctx.patchState({
        detailCommodities: new_detailCommodities,
      });

      this.getCommodityDetailFromServer(ctx, action.commodity_id, true); // 再呼叫 API 來更新資料
    } else {
      // 如果沒有 cache, 就去 server 取
      await this.getCommodityDetailFromServer(ctx, action.commodity_id, true);
    }
  }

  @Action(GetECFavoriteCommodityList)
  async GetECFavoriteCommodityList(
    ctx: StateContext<ECCommodityStateModel>,
    action: GetECFavoriteCommodityList
  ) {
    console.log('[Action] GetECFavoriteCommodityList');
    const state = ctx.getState();

    // 先載入 local cache
    const _favoriteCommodityList: any = await this.storage.get(
      'favoriteCommodityList'
    );
    const new_favoriteCommodityList = _.cloneDeep(_favoriteCommodityList);
    if (new_favoriteCommodityList) {
      ctx.patchState({
        favoriteCommodityList: new_favoriteCommodityList,
      });
      this.getECFavoriteCommodityListFromServer(ctx); // 再呼叫 API 來更新資料
    } else {
      this.getECFavoriteCommodityListFromServer(ctx); // 再呼叫 API 來更新資料
    }
  }
  //#endregion

  private async getECCommodityCategoriesFromServer(
    ctx: StateContext<ECCommodityStateModel>
  ) {
    // try read data from server
    console.log('getECCommodityCategoriesFromServer');
    try {
      const ecCommodityCategories: any =
        await this.ecCommodityService.getCommodityCategory();
      console.log('ecCommodityCategories', ecCommodityCategories);
      await this.storage.set('ec-commodity-categories', ecCommodityCategories);

      ctx.patchState({
        loading: false,
        commodityCategories: ecCommodityCategories,
        hasCache: true,
      });
      console.log('load local cache commodityCategories success');
    } catch (error2) {
      console.warn('getCommodityCategory error', error2);
    }
  }

  // 查詢單一目錄內的商品list
  // private async getCommodityCategoryDetailFromServer(
  //   ctx: StateContext<ECCommodityStateModel>,
  //   category_id: number
  // ) {
  //   // try read data from server
  //   console.log('getCommodityCategoryDetailFromServer');

  //   try {
  //     const state = ctx.getState();
  //     const _category = _.find(state.commodityCategories, { id: category_id });
  //     const _detailCategory = _.find(state.detailCommodityCategories, {
  //       id: category_id,
  //     });

  //     let _pageIndex = 1;
  //     let _commodities;
  //     if (_detailCategory) {
  //       const params = _detailCategory.queryParams;
  //       _pageIndex = params.pageIndex + 1;
  //       _commodities = _detailCategory.commodities;
  //     } else {
  //       _commodities = [];
  //     }

  //     const params: QueryCommodityParams = {};
  //     params.queryReq = {};
  //     params.queryReq.commodityCategoryId = category_id;
  //     params.queryReq.isFavorite = _category.isFavorite;
  //     params.pageSize = 10;
  //     params.pageIndex = _pageIndex;

  //     const result: any = await this.ecCommodityService.getCommodityList(
  //       params
  //     );
  //     console.log('detailCommodityCategories', result);
  //     _commodities = _commodities.concat(result.rows);

  //     const new_category = _.cloneDeep(_category);
  //     new_category.commodities = _commodities;
  //     new_category.queryParams = params;
  //     new_category.totalCount = result.totalCount;
  //     new_category.pageIndex = result.pageIndex;
  //     new_category.pageSize = result.pageSize;
  //     new_category.totalPages = result.totalPages;

  //     const _detailCommodityCategories = state.detailCommodityCategories;
  //     const new_detailCommodityCategories = _.cloneDeep(
  //       _detailCommodityCategories
  //     );

  //     const index = _.findIndex(new_detailCommodityCategories, {
  //       id: category_id,
  //     });
  //     if (index > -1) {
  //       new_detailCommodityCategories.splice(index, 1, new_category);
  //     } else {
  //       new_detailCommodityCategories.push(new_category);
  //     }

  //     ctx.patchState({
  //       detailCommodityCategories: new_detailCommodityCategories,
  //     });

  //     console.log('load local cache detailCommodityCategories success');
  //   } catch (error2) {
  //     console.error('getCommodityCategoryDetail error', error2);
  //   }
  // }

  // 查詢單一商品詳細資料
  private async getCommodityDetailFromServer(
    ctx: StateContext<ECCommodityStateModel>,
    commodity_id: number,
    specialCommodity: boolean
  ) {
    // try read data from server
    console.log(
      'getCommodityDetailFromServer specialCommodity',
      specialCommodity
    );

    try {
      let commodity;
      if (specialCommodity) {
        commodity = await this.ecCommodityService.getSpecialCommodityDetail(
          commodity_id
        );
      } else {
        commodity = await this.ecCommodityService.getCommodityDetail(
          commodity_id
        );
      }

      console.log('commodity', commodity);
      await this.storage.set('commodity-' + commodity, commodity);

      const state = ctx.getState();
      const _detailCommodities = state.detailCommodities;
      const new_detailCommodities = _.cloneDeep(_detailCommodities);

      const index = _.findIndex(new_detailCommodities, { id: commodity_id });
      if (index > -1) {
        new_detailCommodities.splice(index, 1, commodity);
      } else {
        new_detailCommodities.push(commodity);
      }

      ctx.patchState({
        detailCommodities: new_detailCommodities,
      });
    } catch (error2) {
      console.error('getCommodityDetail error', error2);
      throw error2;
    }
  }

  // 查詢首頁我的最愛的商品list
  private async getECFavoriteCommodityListFromServer(
    ctx: StateContext<ECCommodityStateModel>
  ) {
    // try read data from server
    console.log('getECFavoriteCommodityListFromServer');

    try {
      const params: QueryCommodityParams = {};
      params.queryReq = {};
      params.queryReq.commodityCategoryId = 0;
      params.queryReq.isFavorite = true;
      params.pageSize = 20;
      params.pageIndex = 1;

      const result: any = await this.ecCommodityService.getCommodityList(
        params
      );
      console.log('getCommodityList result', result);
      await this.storage.set('favoriteCommodityList', result.rows);

      ctx.patchState({
        loading: false,
        favoriteCommodityList: result.rows,
        hasCache: true,
      });
      console.log('get favoriteCommodityList success');
    } catch (error2) {
      console.error('getFavoriteCommodityList error', error2);
    }
  }

  // 搜尋商品 by tag(不含咪幣兌換商品) (線上)
  @Action(SearchECCommodityListByTag)
  async SearchECCommodityListByTag(
    ctx: StateContext<ECCommodityStateModel>,
    action: SearchECCommodityListByTag
  ) {
    console.log('[Action] SearchECCommodityListByTag');

    try {
      const state = ctx.getState();
      const searchCommodityListPaginationInfo =
        state.searchCommodityListPaginationInfo;

      // 讀取目前state的pageIndex +1 , 如果沒有則設定為1
      let pageIndex = 0;
      if (searchCommodityListPaginationInfo) {
        pageIndex = searchCommodityListPaginationInfo.pageIndex + 1;

        // 判斷是否已達最後一頁
        if (pageIndex > searchCommodityListPaginationInfo.totalPages) {
          // 已超過最後一頁 不處理
          console.warn('已超過最後一頁 不處理');
          return;
        }
      } else {
        pageIndex = 1;
      }

      // 設定EC查詢條件
      const params: QueryCommodityParams = {};
      params.queryReq = {};
      params.queryReq.commodityCategoryId = 0;
      params.queryReq.categoryAttribute = 10; // 一般商品
      params.queryReq.isFavorite = false;
      params.queryReq.tagName = action.tag;
      params.pageSize = environment.EC_SEARCH_COMMODITY_PAGE_SIZE;
      params.pageIndex = pageIndex;

      const result: any = await this.ecCommodityService.getCommodityList(
        params
      );

      console.log('SearchECCommodityList result', result);
      const paginationInfo: PaginationInfo = {};
      paginationInfo.pageIndex = result.pageIndex;
      paginationInfo.pageSize = result.pageSize;
      paginationInfo.totalCount = result.totalCount;
      paginationInfo.totalPages = result.totalPages;

      // 合併新的資料到舊的資料
      const newCommodityList = _.chain(state.searchCommodityList)
        .unionBy(result.rows, 'id')
        .orderBy(['startAt'], ['desc'])
        .value();

      ctx.patchState({
        searchCommodityList: newCommodityList,
        searchCommodityListPaginationInfo: paginationInfo,
      });
    } catch (error2) {
      console.error('SearchECCommodityList action error', error2);
      throw error2;
    }
  }
}
