import { Injectable } from '@angular/core';
import { CacheService } from '@mommy/services/cache.service';
import { UtilService } from '@mommy/services/util.service';
import { Apollo } from 'apollo-angular';
import { take } from 'rxjs/operators';
import { AppSettings } from './../../app.settings';
import { QUERY_EXPERT_DETAIL, QUERY_EXPERT_LIST } from './expert.graphql';

@Injectable({ providedIn: 'root' })
export class ExpertService {
  constructor(
    private apollo: Apollo,
    private util: UtilService,
    private cacheService: CacheService
  ) {}

  // 取得專家列表
  getExpertList() {
    console.log('getExpertList');
    return new Promise(async (resolve, reject) => {
      let cursor = await this.cacheService.getDataCursor2(
        AppSettings.CACHE_KEY_EXPERT_LIST
      );
      console.warn('experts cursor', cursor);
      cursor = cursor ? cursor : 0;

      this.apollo
        .watchQuery<{ getExpertList: any[] }>({
          query: QUERY_EXPERT_LIST,
          fetchPolicy: 'network-only',
          variables: {
            updated_at: cursor, //最近一筆的timestamp
          },
        })
        .valueChanges.pipe(take(1))
        .subscribe(
          async ({ data, loading }) => {
            console.log('loading', loading);
            console.log('data', data);

            if (data) {
              // loading = false; //如果有data,表示有cache
              if (data?.getExpertList.length > 0) {
                try {
                  const new_data = await this.cacheService.mergeData2(
                    data?.getExpertList,
                    AppSettings.CACHE_KEY_EXPERT_LIST,
                    'expert_id'
                  );
                  resolve(new_data);
                } catch (error) {
                  console.error('cacheService merge data error', error);
                  reject(-1);
                }
              } else {
                console.log('no new data need to merge..');
                reject('no new data need to merge');
              }
            } else {
              reject('data is null');
            }
          },
          ({ graphQLErrors, networkError }) => {
            const msg = this.util.getErrorDesc(graphQLErrors, networkError);
            this.util.showToast(msg, '', 0, true);
            reject(-1);
          }
        );
    });
  }

  // 取得專家資料
  getExpertDetail(expert_id: number) {
    return new Promise(async (resolve, reject) => {
      this.apollo
        .watchQuery<{ getExpert: any[] }>({
          query: QUERY_EXPERT_DETAIL,
          fetchPolicy: 'network-only',
          variables: {
            expert_id,
          },
        })
        .valueChanges.pipe(take(1))
        .subscribe(
          ({ data, loading }) => {
            console.log('loading', loading);
            console.log('data', data);

            if (data) {
              // loading = false; //如果有data,表示有cache
              if (data?.getExpert) {
                resolve(data?.getExpert);
              } else {
                console.log('no new data need to merge..');
                reject('no new data need to merge');
              }
            } else {
              reject('data is null');
            }
          },
          ({ graphQLErrors, networkError }) => {
            console.log('graphQLErrors', graphQLErrors);
            console.log('networkError', networkError);
            reject(-1);
          }
        );
    });
  }
}
