/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable no-var */
/* eslint-disable prefer-const */
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
// import { LogProvider } from '../providers/logProvider';
// model/interface
// import {Channel,Post} from '../models/chat';
import { Storage } from '@ionic/storage';
import { Channel, ElineUser, Post } from '@mommy/models/Eline.model';
import * as firebase from 'firebase/compat/app';
import * as _ from 'lodash';
import { forkJoin, merge } from 'rxjs';
// import { MediatorProvider } from '../providers/mediatorProvider';
// import { User, GlobalStatictVar } from '../shared/interfaces';
import { map, scan, take } from 'rxjs/operators';

const MESSAGES_REF = '/messages';
// const USERS_REF = '/users';
const CHANNEL_REF = '/channels';
const ISSUE_REF = '/issues';
const SUPPORT_REF = '/support';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  // connectionRef: any;
  // loggedinUser: User;
  page_count = 20;
  // fbstorage: firebase.storage.Storage;
  // fbstorageRef: firebase.storage.Reference;
  // imagesRef;

  constructor(
    public afDB: AngularFireDatabase,
    // public events: Events,
    public storage: Storage // private af_storage: AngularFireStorage
  ) {
    console.log('hello ChatService');
  }

  createChannel(app: firebase.default.app.App, channel: Channel) {
    let ref = app.database().ref(`${CHANNEL_REF}/${channel.cid}`);
    let channelRef = this.afDB.object(ref);
    return channelRef.set(channel);
  }

  getChannel(app: firebase.default.app.App, channel_id: string) {
    let ref = app.database().ref(`${CHANNEL_REF}/${channel_id}`);
    let channelRef = this.afDB.object(ref).valueChanges().pipe(take(1));
    return channelRef;
  }

  updateChannel(app: firebase.default.app.App, channel_id: string, data: any) {
    let ref = app.database().ref(`${CHANNEL_REF}/${channel_id}`);
    let channelRef = this.afDB.object(ref);
    return channelRef.update(data);
  }

  updateChannelunReadCount(
    app: firebase.default.app.App,
    channel_id: string,
    data: any
  ) {
    console.warn('updateChannelunReadCount---');
    console.warn(data);
    let ref = app.database().ref(`${CHANNEL_REF}/${channel_id}/urc`); //unreadcount
    let channelunReadCountRef = this.afDB.object(ref);
    return channelunReadCountRef.update(data);
  }

  updateChannelLastMessage(
    app: firebase.default.app.App,
    channel_id: string,
    msg: string,
    ts?: any
  ) {
    console.warn('updateChannelLastMessage---');
    console.warn(msg);
    console.warn(ts);

    var data = {};
    let uat_ts = firebase.default.database.ServerValue.TIMESTAMP;
    if (ts) {
      data = { lp: msg, lpat: ts, uat: uat_ts };
    } else {
      data = { lp: msg, lpat: uat_ts, uat: uat_ts };
    }
    console.log(data);
    let ref = app.database().ref(`${CHANNEL_REF}/${channel_id}`);
    let channelRef = this.afDB.object(ref);
    return channelRef.update(data);
  }

  getChannelList_by_user_id(app: firebase.default.app.App, user_id: string) {
    let ref = app.database().ref(`${CHANNEL_REF}/`);
    // let channelListRef = this.afDB.list(ref,{
    //   query: {
    //     // orderByChild: 'members/' + user_id,
    //     // equalTo: true
    //     orderByChild: 'uid',
    //     equalTo: user_id
    //   }
    // });
    let channelListRef = this.afDB.list(ref, (ref2) => {
      return ref2.orderByChild('uid').equalTo(user_id);
    });
    return channelListRef.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((c) => ({
          key: c.payload.key,
          ...(c.payload.val() as object),
        }));
      })
    );
  }

  // 可以處理家人模式 users: ['u87','u122'], 第一個放自己, 第二個放加入的家人userid
  getChannelList_by_user_id_v2(
    app: firebase.default.app.App,
    family: any[],
    take_once?: boolean
  ) {
    console.log('getChannelList_by_user_id_v2');
    console.log('family', family);

    if (family.length === 1) {
      return this.getChannelList_by_user_id(app, family[0]);
    } else {
      if (take_once) {
        //多個user, 用rxjs merge operator處理
        // take once可以使用以下的方式
        // forJoin會將observable完成的統一發送出去
        var observables1 = [];
        _.forEach(family, (elem) => {
          var ob = this.getChannelList_by_user_id(app, elem).pipe(take(1));
          observables1.push(ob);
          console.log('check111-observables.push');
        });
        console.log('check111-Observable.merge');
        return forkJoin(observables1).pipe(
          map((val) => {
            console.log('aaaaa', val);
            return _.flatten(val); // 將陣列拉平
          })
        );
      } else {
        //多個users, 用rxjs merge & scan operator處理
        var observables2 = [];
        _.forEach(family, (elem) => {
          var ob = this.getChannelList_by_user_id(app, elem);
          observables2.push(ob);
          console.log('check111-observables.push');
        });
        console.log('check111-Observable.merge');
        return merge(...observables2).pipe(
          scan((accu: any, curr: any) => {
            console.log('accu', accu);
            console.log('curr', curr);

            //去掉key相同的element, 後面的unionBy在append new element(更新的資料)
            var a = _.filter(accu, (elem) => {
              return !_.some(curr, { key: elem.key });
            });
            console.log('a', a);

            return _.unionBy(a, curr, 'key');
          }, [])
        );
      }
    }
  }

  getChannelList_by_channel_id(
    app: firebase.default.app.App,
    channel_id: string
  ) {
    let ref = app.database().ref(`${CHANNEL_REF}/`);
    // let channelListRef = this.afDB.list(ref,{
    //   query: {
    //     // orderByChild: 'members/' + user_id,
    //     // equalTo: true
    //     orderByChild: 'uid',
    //     equalTo: user_id
    //   }
    // });
    let channelListRef = this.afDB.list(ref, (ref2) => {
      return ref2.orderByChild('cid').equalTo(channel_id);
    });
    return channelListRef.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((c) => ({
          key: c.payload.key,
          ...(c.payload.val() as object),
        }));
      })
    );
  }

  // 可以處理家人模式 users: u87, channels:['2-105','3-105'] 第一個放自己, 第二個放加入的家人channel_ids
  getChannelList_by_user_id_and_family_channels(
    app: firebase.default.app.App,
    user_id: string,
    channels: any[],
    take_once?: boolean
  ) {
    console.log('getChannelList_by_user_id_and_family_channels');
    console.log('user_id', user_id);
    console.log('channels', channels);

    if (channels.length === 0) {
      return this.getChannelList_by_user_id(app, user_id);
    } else {
      if (take_once) {
        //多個user, 用rxjs merge operator處理
        // take once可以使用以下的方式
        // forJoin會將observable完成的統一發送出去
        var observables1 = [];
        var user_observable = this.getChannelList_by_user_id(app, user_id);
        observables1.push(user_observable);
        _.forEach(channels, (elem) => {
          var ob = this.getChannelList_by_channel_id(app, elem).pipe(take(1));
          observables1.push(ob);
          console.log('check111-observables.push');
        });
        console.log('check111-Observable.merge');
        return forkJoin(observables1).pipe(
          map((val) => {
            console.log('aaaaa', val);
            return _.flatten(val); // 將陣列拉平
          })
        );
      } else {
        //多個users, 用rxjs merge & scan operator處理
        var observables2 = [];
        var user_observable2 = this.getChannelList_by_user_id(app, user_id);
        observables2.push(user_observable2);
        _.forEach(channels, (elem) => {
          var ob = this.getChannelList_by_channel_id(app, elem);
          observables2.push(ob);
          console.log('check111-observables.push');
        });
        console.log('check111-Observable.merge');
        return merge(...observables2).pipe(
          scan((accu: any, curr: any) => {
            console.log('accu', accu);
            console.log('curr', curr);

            //去掉key相同的element, 後面的unionBy在append new element(更新的資料)
            var a = _.filter(accu, (elem) => {
              return !_.some(curr, { key: elem.key });
            });
            console.log('a', a);

            return _.unionBy(a, curr, 'key');
          }, [])
        );
      }
    }
  }

  getChannelList_by_teamid(app: firebase.default.app.App, teamid: string) {
    console.log('teamid', teamid);
    let ref = app.database().ref(`${CHANNEL_REF}/`);
    // let channelListRef = this.afDB.list(ref,{
    //   query: {
    //     orderByChild: 'tid',
    //     equalTo: teamid
    //   }
    // });
    let channelListRef = this.afDB.list(ref, (ref2) => {
      return ref2.orderByChild('tid').equalTo(teamid);
    });
    console.log('channelListRef', channelListRef);
    return channelListRef.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((c) => ({
          key: c.payload.key,
          ...(c.payload.val() as object),
        }));
      })
    );

    // let channelListRef = this.afDB.list(`${CHANNEL_REF}/`,{
    //   query: {
    //     orderByChild: 'teamid',
    //     equalTo: teamid
    //   }
    // });
    // console.log('channelListRef',channelListRef);
    // return channelListRef;
  }

  getChannelList_by_teams(
    app: firebase.default.app.App,
    _teams: any[],
    take_once?: boolean
  ) {
    console.log('_teams', _teams);
    //過濾掉 support team
    let teams = _.filter(_teams, (elem) => {
      return elem.duty_type !== 'support';
    });
    console.log('teams', teams);

    if (teams.length === 1) {
      return this.getChannelList_by_teamid(app, teams[0].team_id);
    } else {
      if (take_once) {
        //多個team, 用rxjs merge operator處理
        // take once可以使用以下的方式
        // forJoin會將observable完成的統一發送出去
        var observables1 = [];
        _.forEach(teams, (elem) => {
          var ob = this.getChannelList_by_teamid(app, elem.team_id).pipe(
            take(1)
          );
          observables1.push(ob);
          console.log('check111-observables.push');
        });
        console.log('check111-Observable.merge');
        return forkJoin(observables1).pipe(
          map((val) => {
            console.log('aaaaa', val);
            return _.flatten(val); // 將陣列拉平
          })
        );
      } else {
        //多個team, 用rxjs merge & scan operator處理
        var observables2 = [];
        _.forEach(teams, (elem) => {
          var ob = this.getChannelList_by_teamid(app, elem.team_id);
          observables2.push(ob);
          console.log('check111-observables.push');
        });
        console.log('check111-Observable.merge');
        return merge(...observables2).pipe(
          scan((accu: any, curr: any) => {
            console.log('accu', accu);
            console.log('curr', curr);

            //去掉key相同的element, 後面的unionBy在append new element(更新的資料)
            var a = _.filter(accu, (elem) => {
              return !_.some(curr, { key: elem.key });
            });
            console.log('a', a);

            return _.unionBy(a, curr, 'key');
          }, [])
        );
      }
    }
  }

  get_single_message(
    app: firebase.default.app.App,
    channel_id: string,
    message_id: string
  ) {
    let ref = app.database().ref(`${MESSAGES_REF}/${channel_id}/${message_id}`);
    let messageRef = this.afDB.object(ref);
    return messageRef.valueChanges();
  }

  sendMessage(
    app: firebase.default.app.App,
    channel_id: string,
    message: Post,
    type?: string,
    mention_arry?: any
  ) {
    return new Promise((resolve, reject) => {
      let ref = app.database().ref(`${MESSAGES_REF}/${channel_id}/`);
      let messageRef = this.afDB.list(ref);
      console.log(message);

      messageRef.push(message).then(
        (res) => {
          console.log(res.key);
          // console.log(res.cat); //undefined

          // update channels / issues 's updateat by new message's createat
          // 取得剛剛上傳的message data,取出createat資訊
          this.get_single_message(app, channel_id, res.key)
            .pipe(take(1))
            .subscribe((res2: any) => {
              console.log('single message', res2);
              let createat = res2.cat;

              if (type === 'issue') {
                // 更新issue的資訊
                // if (mention_arry === undefined) {
                //   mention_arry = []; //firebase不接受 undefined
                // }
                // this.updateIssueupdateat_and_mention(app, channel_id, createat, mention_arry)
                //   .then(() => console.log('updateIssue done.'))
                //   .catch((error) => console.log(error));
              } else {
                // 更新channel的資訊
                this.updateChannelLastMessage(
                  app,
                  channel_id,
                  message.m,
                  createat
                )
                  .then(() => console.log('updateChannelLastMessage done.'))
                  .catch((error) => console.log(error));
              }
            });

          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
      // .catch((err)=>{
      //   reject(err);
      // })
    });
  }

  sendNotificationRequest(app: firebase.default.app.App, toUserid: string) {
    let ref = app.database().ref(`notificationRequests`);
    let notificationRef = this.afDB.list(ref);
    return notificationRef.push(toUserid);
  }

  // 發送需要重新計算該channel的unread_count
  sendChannelUnReadCountCalcRequest(
    app: firebase.default.app.App,
    channel_id: string,
    toUserid: string
  ) {
    console.log('sendChannelUnReadCountCalcRequest');
    let ref = app.database().ref(`ChannelUnReadCountCalcRequest`);
    let channelUnReadCountCalcRequestRef = this.afDB.list(ref);
    return channelUnReadCountCalcRequestRef.push({ channel_id, toUserid });
  }

  // 發送 getUrlMetaRequest
  sendGetUrlMetaRequest(app: firebase.default.app.App, path_pieces: any) {
    let ref = app.database().ref(`getUrlMetaRequest`);
    let getUrlMetaRef = this.afDB.list(ref);
    return getUrlMetaRef.push(path_pieces);
  }

  updateMessageReadStatus(
    app: firebase.default.app.App,
    channel_id: string,
    messageKey: string
  ) {
    function check_key_exist(path, key) {
      console.log(path);
      console.log(key);

      return new Promise((resolve, reject) => {
        try {
          let ref = app.database().ref(path);
          ref.once('value', function (snapshot) {
            console.log(snapshot);
            console.log(key);
            console.log(snapshot.hasChild(key));
            if (snapshot.hasChild(key)) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
        } catch (error) {
          reject(error);
        }
      });
    }

    return check_key_exist(`${MESSAGES_REF}/${channel_id}`, messageKey).then(
      (res) => {
        if (res === true) {
          console.log('check_key_exist == true');
          let ref = app
            .database()
            .ref(`${MESSAGES_REF}/${channel_id}/${messageKey}`);
          let messageRef = this.afDB.object(ref);
          let ts = firebase.default.database.ServerValue.TIMESTAMP;
          return messageRef.update({ rs: '1', uat: ts }); //readstatus , updateat
        } else {
          console.log('check_key_exist == false');
          // let ref = app.database().ref(`${MESSAGES_REF}/${channel_id}/${messageKey}`);
          // let messageRef = this.afDB.object(ref);
          // let ts = firebase.database.ServerValue.TIMESTAMP;
          // return messageRef.update({rs:'1',uat:ts}); //readstatus , updateat
        }
      }
    );
  }

  // 將回覆訊息's del flag update to true
  updateMessageReplyMsgToDelete(
    app: firebase.default.app.App,
    channel_id: string,
    messageKey: string,
    reMsg: any
  ) {
    function check_key_exist(path, key) {
      console.log(path);
      console.log(key);

      return new Promise((resolve, reject) => {
        try {
          let ref = app.database().ref(path);
          ref.once('value', function (snapshot) {
            console.log(snapshot);
            console.log(key);
            console.log(snapshot.hasChild(key));
            if (snapshot.hasChild(key)) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
        } catch (error) {
          reject(error);
        }
      });
    }

    return check_key_exist(`${MESSAGES_REF}/${channel_id}`, messageKey).then(
      (res) => {
        if (res === true) {
          console.log('check_key_exist == true');
          let ref = app
            .database()
            .ref(`${MESSAGES_REF}/${channel_id}/${messageKey}`);
          let messageRef = this.afDB.object(ref);
          let ts = firebase.default.database.ServerValue.TIMESTAMP;
          return messageRef.update({ reMsg, uat: ts }); //reMsg , updateat
        } else {
          console.log('check_key_exist == false');
          // let ref = app.database().ref(`${MESSAGES_REF}/${channel_id}/${messageKey}`);
          // let messageRef = this.afDB.object(ref);
          // let ts = firebase.database.ServerValue.TIMESTAMP;
          // return messageRef.update({rs:'1',uat:ts}); //readstatus , updateat
        }
      }
    );
  }

  // 回收訊息
  deleteMessage(
    app: firebase.default.app.App,
    channel_id: string,
    messageKey: string,
    user_id,
    deletedMessageWord: string
  ) {
    console.log('deleteMessage');

    return new Promise((resolve, reject) => {
      function check_key_exist(path, key) {
        console.log(path);
        console.log(key);

        return new Promise((resolve2, reject2) => {
          try {
            let ref = app.database().ref(path);
            ref.once('value', function (snapshot) {
              console.log(snapshot);
              console.log(key);
              console.log(snapshot.hasChild(key));
              if (snapshot.hasChild(key)) {
                resolve2(true);
              } else {
                resolve2(false);
              }
            });
          } catch (error) {
            reject2(error);
          }
        });
      }

      return check_key_exist(`${MESSAGES_REF}/${channel_id}`, messageKey).then(
        (res) => {
          if (res === true) {
            console.log('check_key_exist == true');
            let ref = app
              .database()
              .ref(`${MESSAGES_REF}/${channel_id}/${messageKey}`);
            let messageRef = this.afDB.object(ref);
            let ts = firebase.default.database.ServerValue.TIMESTAMP;
            var data: any = {};
            data.dat = ts; //deleted at
            data.uat = ts; //updated at
            data.m = deletedMessageWord;
            data.duid = user_id;

            messageRef
              .update(data)
              .then((resp) => {
                // 更新channel的資訊
                this.updateChannelLastMessage(
                  app,
                  channel_id,
                  deletedMessageWord
                )
                  .then(() => {
                    console.log('updateChannelLastMessage done.');
                    resolve('OK');
                  })
                  .catch((error) => {
                    console.log(error);
                    reject(error);
                  });
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            console.log('check_key_exist == false');
          }
        }
      );
    });
  }

  // 更新問卷的回覆狀態 message.extend.qs = 1
  updateMessageQuestionStatusToDone(
    app: firebase.default.app.App,
    channel_id: string,
    messageKey: string,
    extendObj: any
  ) {
    console.warn('updateMessageQuestionStatus---');
    extendObj.qs = 1; //set qs = 1 (已回覆)
    let ts = firebase.default.database.ServerValue.TIMESTAMP;
    let data = { extend: extendObj, uat: ts };
    let ref = app.database().ref(`${MESSAGES_REF}/${channel_id}/${messageKey}`);
    let messageRef = this.afDB.object(ref);
    return messageRef.update(data);
  }

  // updateMessageStatus(key, message, newStatus) {
  //   let currentUserRef = this.angularFire.database.object(`${CHATS_REF}/${message.to}/${message.from}/${key}`);
  //   currentUserRef.update({ status: newStatus });
  // }

  //Mesages queries
  // getMessages(channel_id:string) {
  //   console.log('getMessages, channel_id = ' + channel_id);
  //   return this.afDB.list(`${MESSAGES_REF}/${channel_id}`,{
  //     query:{
  //       orderByChild:'createat',
  //       limitToLast: this.page_count,
  //     }
  //   });
  // }
  getMessages(
    app: firebase.default.app.App,
    channel_id: string,
    msg_count: number
  ) {
    console.log(
      'getMessages, channel_id = ' + channel_id + ' ,msg_count = ' + msg_count
    );
    let ref = app.database().ref(`${MESSAGES_REF}/${channel_id}`);
    // return this.afDB.list(ref,{
    //   query:{
    //     orderByChild:'cat', //createat
    //     limitToLast: msg_count,
    //   }
    // });
    return this.afDB
      .list(ref, (ref2) => {
        return ref2
          .orderByChild('cat') //createat
          .limitToLast(msg_count);
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((c) => ({
            key: c.payload.key,
            ...(c.payload.val() as object),
          }));
        })
      );
  }

  getMessagesStartAt(
    app: firebase.default.app.App,
    channel_id: string,
    startAt: number
  ) {
    console.log(
      'getMessages, channel_id = ' + channel_id + ' ,startAt = ' + startAt
    );
    let ref = app.database().ref(`${MESSAGES_REF}/${channel_id}`);
    // return this.afDB.list(ref,{
    //   query:{
    //     orderByChild:'cat',  //createat
    //     startAt: startAt,
    //   }
    // });
    return this.afDB
      .list(ref, (ref2) => {
        return ref2
          .orderByChild('cat') //createat
          .startAt(startAt);
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((c) => ({
            key: c.payload.key,
            ...(c.payload.val() as object),
          }));
        })
      );
  }

  getMessagesUpdateAt(
    app: firebase.default.app.App,
    channel_id: string,
    updateAt: number
  ) {
    console.log(
      'getMessages, channel_id = ' + channel_id + ' ,updateAt = ' + updateAt
    );
    let ref = app.database().ref(`${MESSAGES_REF}/${channel_id}`);
    // return this.afDB.list(ref,{
    //   query:{
    //     orderByChild:'uat',  //updateat
    //     startAt: updateAt,
    //   }
    // });
    return this.afDB
      .list(ref, (ref2) => {
        return ref2
          .orderByChild('uat') //updateat
          .startAt(updateAt);
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((c) => ({
            key: c.payload.key,
            ...(c.payload.val() as object),
          }));
        })
      );
  }

  getOldMessages(
    app: firebase.default.app.App,
    channel_id: string,
    createat: number
  ) {
    console.log(
      'getOldMessages, channel_id = ' + channel_id + ' createat = ' + createat
    );
    let ref = app.database().ref(`${MESSAGES_REF}/${channel_id}`);
    // return this.afDB.list(ref,{
    //   query:{
    //     orderByChild:'cat',  //createat
    //     endAt: createat,
    //     limitToLast: this.page_count,
    //   }
    // });
    return this.afDB
      .list(ref, (ref2) => {
        return ref2
          .orderByChild('cat') //createat
          .endAt(createat)
          .limitToLast(this.page_count);
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((c) => ({
            key: c.payload.key,
            ...(c.payload.val() as object),
          }));
        })
      );
  }

  //如果unread_cnt>0,則嘗試check是否需要sync data from firebase to local
  //如果有sync data,則儲存於channelid-temp,待click channel再merge to local lastmessage中
  //避免與chat page的savechannel發生衝突
  sync_channel_data(
    app: firebase.default.app.App,
    channel: Channel,
    user: ElineUser
  ) {
    console.log('sync_channel_data');
    console.log(channel);
    console.log(user);

    // 判斷local last message create at 是否與channel's lastpostat相同,相同則不需要sync
    // 取得lastlocalmessage createat
    let lastlocalmessage_createat;
    console.log('this.storage.get');

    this.storage
      .get(channel.cid + '-lastlocalmsgcreateat')
      .then((data) => {
        lastlocalmessage_createat = data;
        console.log('lastlocalmessage_createat', lastlocalmessage_createat);

        if (
          lastlocalmessage_createat === undefined ||
          lastlocalmessage_createat == null
        ) {
          console.log(
            'lastlocalmessage_createat == undefined || lastlocalmessage_createat == null'
          );
          // 取得lastlocalmessage
          this.storage
            .get(channel.cid)
            .then((data2) => {
              if (data2 === undefined || data2 == null) {
                lastlocalmessage_createat = 0;
                if (lastlocalmessage_createat !== channel.lp) {
                  this.get_firebase_message_once(
                    app,
                    channel.cid,
                    lastlocalmessage_createat,
                    user
                  );
                }
              } else {
                lastlocalmessage_createat = (<any>_.last(data2)).cat;
                if (lastlocalmessage_createat !== channel.lpat) {
                  this.get_firebase_message_once(
                    app,
                    channel.cid,
                    lastlocalmessage_createat,
                    user
                  );
                }
              }
            })
            .catch((err) => console.warn(err));
        } else {
          console.log('lastlocalmessage_createat', lastlocalmessage_createat);
          console.log('channel.lastpostat', channel.lpat);

          if (lastlocalmessage_createat !== channel.lpat) {
            console.log('lastlocalmessage_createat !== channel.lastpostat');
            this.get_firebase_message_once(
              app,
              channel.cid,
              lastlocalmessage_createat,
              user
            );
          } else {
            console.log(
              'lastlocalmessage_createat == channel.lastpostat , so do not get data from firebase'
            );
          }
        }
      })
      .catch((err) => console.warn(err));
    console.log('this.storage.get done.');
  }

  get_firebase_message_once(
    app: firebase.default.app.App,
    channel_id: string,
    updateAt: number,
    user: ElineUser
  ) {
    console.log('get_firebase_message_once');
    console.log(user);

    // for多團隊調整
    var teams = []; //組成 ['t1','t2'] 陣列
    _.forEach(user.teams, (elem) => {
      teams.push('t' + elem.team_id);
    });

    this.getMessagesUpdateAt(app, channel_id, updateAt)
      .pipe(take(1))
      .subscribe((msgs: any) => {
        console.log('getMessagesUpdateAt', msgs);
        msgs.map((e) => {
          //e.ownership = 'u' + user.user_id == e.uid || 't' + user.team_id == e.uid ? 'mine' : 'other';
          // e.key = e.$key;
          // for多團隊調整 , 家人模式調整
          if (_.includes(user.family_u, e.uid) || _.includes(teams, e.uid)) {
            e.ownership = 'mine';
          } else {
            e.ownership = 'other';
          }
        });

        //merge data
        this.storage
          .get(channel_id + '-temp')
          .then((data) => {
            let new_msgs = _.union(data, msgs);

            this.storage.set(channel_id + '-temp', new_msgs).then(() => {
              console.log('save channel_id-temp done.');
            });

            console.log(new_msgs);

            //let last_msg:any = _.last(_.sortBy(msgs,["createat"],["asc"]));
            let last_msg: any = _.last(
              _.sortBy(_.filter(new_msgs, 'cat'), ['cat'], ['asc'])
            );
            console.log('last_msg', last_msg);

            if (last_msg) {
              console.log('last_msg_createat', last_msg.cat);

              this.storage
                .set(channel_id + '-lastlocalmsgcreateat', last_msg.cat)
                .then(() => {
                  console.log('save last_msg_createat done.');
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
  }

  // getUnReadMessagesRef(uid, uid2) {
  //   return firebase.database().ref(`${CHATS_REF}/${uid}/${uid2}`).orderByChild('status').equalTo(GlobalStatictVar.MSG_STATUS_UN_READ);
  // }

  //END of messages queries
  // addContact(uid, user) {
  //   let currentUserRef = this.angularFire.database.list(`${CONTACT_REF}/${uid}`);
  //   currentUserRef.push({
  //     uid: user.uid,
  //     username: user.username,
  //     email: user.email,
  //     photo: user.photo
  //   });
  // }
}
