import { Injectable } from '@angular/core';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import {
  InAppBrowser,
  InAppBrowserOptions,
} from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Http } from '@capacitor-community/http';
import { Media } from '@capacitor-community/media';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { Browser } from '@capacitor/browser';
import {
  Camera,
  CameraResultType,
  CameraSource,
  ImageOptions,
} from '@capacitor/camera';
import { HttpOptions } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Geolocation, Position } from '@capacitor/geolocation';
import { Share } from '@capacitor/share';
import { Badge } from '@capawesome/capacitor-badge';
import { Platform } from '@ionic/angular';
import { environment } from '@mommy/environments/environment';
import {
  ECPaymentFail,
  ECPaymentSuccess,
} from '@mommy/state/ec/ec-order/ec-order.actions';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class CapUtilService {
  constructor(
    private platform: Platform,
    private iab: InAppBrowser,
    private store: Store,
    private callNumber: CallNumber
  ) {}

  async iab_browser(url: string) {
    // windowName 預設 _blank, 雖然是 inappbrowser, 但是連結到 app相關的 link, 會自動開啟 app (故不用 _system)
    // 1. 測試  www.home123.tw, 裡面的 googleplay link, 會自動開啟 app
    // 2. facebook messager link, 會自動開啟 app
    // *3. 目前沒有發現有偵測 url changed event 的方法, 可能要搭配 Capacitor/App.addListener('appUrlOpen', (data: any) => { ... })
    // *4. 目前沒有發現有 form post 的方法(要處理 EC payment) => cordova inappbrowser plugin 有支援
    console.log('iab_browser', url);

    await Browser.removeAllListeners();
    await Browser.addListener('browserFinished', () => {
      console.log('browserFinished');
    });
    await Browser.addListener('browserPageLoaded', () => {
      console.log('browserPageLoaded');
    });

    await Browser.open({
      url: url,
      windowName: '_blank',
      toolbarColor: '#726e94',
    });
  }

  // 啟動相機/相簿取得照片 from:camera , photo
  async take_photo(
    from: 'camera' | 'photos' | 'file',
    imageOptions?: ImageOptions
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        if (from === 'file') {
        } else {
          // 檢查是否有權限
          const checkPermissions = await Camera.checkPermissions();
          console.log(
            'Camera.checkPermissions',
            JSON.stringify(checkPermissions)
          );

          if (checkPermissions[from] !== 'granted') {
            console.log(
              'Camera.checkPermissions',
              `${from} not granted , try to requestPermissions`
            );

            // 請求權限
            const requestPermissions = await Camera.requestPermissions();
            console.log(
              'Camera.requestPermissions',
              JSON.stringify(requestPermissions)
            );
            // {"camera":"granted","photos":"prompt"}
            if (requestPermissions[from] !== 'granted') {
              reject('沒有權限');
              return;
            } else {
              console.log('Camera.requestPermissions granted');
            }
          } else {
            console.log('Camera.checkPermissions', `${from} granted`);
          }

          // 取得照片
          let _imageOptions: ImageOptions;
          if (!imageOptions) {
            _imageOptions = {
              quality: 50,
              allowEditing: false,
              resultType: CameraResultType.Base64,
            };
          } else {
            _imageOptions = imageOptions;
          }

          if (from === 'camera') {
            _imageOptions.source = CameraSource.Camera;
          } else if (from === 'photos') {
            _imageOptions.source = CameraSource.Photos;
          } else {
            _imageOptions.source = CameraSource.Prompt;
          }

          const image = await Camera.getPhoto(_imageOptions);

          console.log('Camera.getPhoto result: ', image);

          // image.webPath will contain a path that can be set as an image src.
          // You can access the original file using image.path, which can be
          // passed to the Filesystem API to read the raw data of the image,
          // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
          const base64Image = 'data:image/jpeg;base64,' + image.base64String;
          resolve(base64Image);

          // if (!navigatorObject.camera) {
          //   reject('您的裝置不支援這個的功能!');
          //   return;
          // }

          // // default config
          // let camera_param: CameraOptions = {};
          // camera_param.quality = 50; // 原 eline 是設定 50
          // // camera_param.targetWidth = 640;
          // // camera_param.targetHeight = 640;

          // console.log('camera_param', camera_param);
          // // alert(JSON.stringify(camera_param));

          // camera_param.encodingType = this.camera.EncodingType.JPEG;
          // camera_param.destinationType = this.camera.DestinationType.DATA_URL;
          // camera_param.correctOrientation = true;

          // if (cameraOption) {
          //   camera_param = { ...camera_param, ...cameraOption }; // 傳入的參數,覆蓋default參數
          // }

          // if (from === 'camera') {
          //   camera_param.sourceType = this.camera.PictureSourceType.CAMERA;
          // } else if (from === 'photo') {
          //   camera_param.sourceType = this.camera.PictureSourceType.PHOTOLIBRARY;
          // }

          // this.camera.getPicture(camera_param).then(
          //   (imageData) => {
          //     // imageData is either a base64 encoded string or a file URI
          //     // If it's base64:
          //     // this.myticket.close_image1_data = 'data:image/jpg;base64,' + imageData;

          //     //強制在data binding, ionic2 應該不需要

          //     const base64Image = 'data:image/jpeg;base64,' + imageData;

          //     resolve(base64Image);
          //   },
          //   (err) => {
          //     // Handle error
          //     const n = err.indexOf('cancelled');
          //     const m = err.indexOf('No Image Selected');
          //     const o = err.indexOf('No Image Selected');
          //     const p = err.indexOf('has no access to assets');
          //     if (!(n >= 0 || m >= 0 || o >= 0 || p >= 0)) {
          //       reject(err);
          //     } else {
          //       console.log(err);
          //       reject('cancel');
          //     }
          //   }
          // );
        }
      } catch (err) {
        const n = err.indexOf('cancelled');
        const m = err.indexOf('No Image Selected');
        const o = err.indexOf('No image picked');
        const p = err.indexOf('has no access to assets');
        if (!(n >= 0 || m >= 0 || o >= 0 || p >= 0)) {
          console.error('take_photo error', err);
          reject(err);
        } else {
          reject('cancel');
        }
      }
    });
  }

  //信用卡付款 form post
  async iab_to_payment(orderBillCode: string) {
    console.log('iab_to_payment _blank', orderBillCode);

    const payment_url = environment.EC_PAYMENT_URL;

    const options: InAppBrowserOptions = {
      hidenavigationbuttons: 'yes',
      location: 'no',
      toolbar: 'yes',
      backButtonCanClose: true,
      closebuttoncaption: '關閉',
    };

    let pageContent = `
    <html>

    <body>
      <form id="myForm" action="${payment_url}" method="post">
          <input type="text"  id="orderBillCode" name="orderBillCode" value="${orderBillCode}" style="display:none">
          <input type="submit" value="submit" style="display:none"/>
      </form>
      <script type="text/javascript">document.getElementById("myForm").submit();</script>
    </body>

    </html>
    `;

    let pageContentUrl = 'data:text/html;base64,' + btoa(pageContent); //如果內容有中文,btoa會失敗

    // 如果platform is not cordova and debug = true 就使用 get 方式
    if (this.platform.is('capacitor')) {
      const browser = this.iab.create(pageContentUrl, '_blank', options);

      // ios的行為比較怪, loadstop好像要等頁面換頁後才會callback,所以也把loadstart的網址拿來判斷
      browser.on('loadstart').subscribe((val) => {
        // {"type":"loadstart","url":"https://mommy_app_iab/payment_success"}
        console.log('browser loadstart, check url', val);
        console.log('browser loadstart, check url', JSON.stringify(val));
        if (val.url.match('mommycareyou.com/#/payment-success')) {
          browser.close();
          this.store.dispatch(new ECPaymentSuccess());
        } else if (val.url.match('mommycareyou.com/#/payment-fail')) {
          browser.close();
          this.store.dispatch(new ECPaymentFail('ERROR'));
        }
      });

      // ios的行為比較怪, loadstop好像要等頁面換頁後才會callback
      browser.on('loadstop').subscribe((val) => {
        console.log('browser loadstop, check url', val);
        console.log('browser loadstop, check url', JSON.stringify(val));
        if (val.url.match('mommycareyou.com/#/payment-success')) {
          browser.close();
          this.store.dispatch(new ECPaymentSuccess());
        } else if (val.url.match('mommycareyou.com/#/payment-fail')) {
          browser.close();
          this.store.dispatch(new ECPaymentFail('ERROR'));
        } else {
          // do nothing
        }
      });

      browser.on('loaderror').subscribe((val) => {
        // {"code":-1003,"message":"A server with the specified hostname could not be found.","type":"loaderror","url":"https://ccore.newebpay.com/MPG/mpg_gateway/mpg_return_url?Status=SUCCESS"}
        console.log('browser loaderror, check url', val);
        console.log('browser loaderror, check url', JSON.stringify(val));
        if (val.url.match('mommy_app_iab/payment_success')) {
          browser.close();
          this.store.dispatch(new ECPaymentSuccess());
        } else if (val.url.match('mommy_app_iab/payment_fail')) {
          browser.close();
          this.store.dispatch(new ECPaymentFail('ERROR'));
        } else {
          // do nothing
        }
      });

      return browser;
    } else {
      console.log('not capacitor...');
      this.store.dispatch(new ECPaymentSuccess());

      // if (this.globals.VENDER_PAYMENT_DEBUG) {
      //   var url =
      //     this.globals.VENDER_PAYMENT_URL +
      //     `?groupbuy_instance_txn_id=${txn_id}&api-key=${api_key}&auth-token=${auth_token}&site_code=${site_code}`;

      //   console.log('url', url);
      //   window.open(url, '_blank');
      //   // const browser: ThemeableBrowserObject = this.themeableBrowser.create(url, '_blank', options);
      // }
    }
  }

  async cordova_iab_browser(url: string) {
    console.log('cordova_iab_browser _blank', url);

    const options: InAppBrowserOptions = {
      hidenavigationbuttons: 'yes',
      location: 'no',
      toolbar: 'yes',
      backButtonCanClose: true,
      closebuttoncaption: '關閉',
    };

    const browser = this.iab.create(url, '_blank', options);
  }

  async cordova_iab_browser_with_system(url: string) {
    console.log('cordova_iab_browser _system', url);

    const options: InAppBrowserOptions = {
      hidenavigationbuttons: 'yes',
      location: 'no',
      toolbar: 'yes',
      backButtonCanClose: true,
    };

    const browser = this.iab.create(url, '_system', options);
  }

  async cordova_iab_show_img(url: string) {
    console.log('cordova_iab_browser _blank');

    const options: InAppBrowserOptions = {
      hidenavigationbuttons: 'no',
      location: 'no',
      hideurlbar: 'yes',
      toolbar: 'yes',
      enableViewportScale: 'yes',
      backButtonCanClose: true,
      closebuttoncaption: '關閉',
    };

    const browser = this.iab.create(url, '_blank', options);
  }

  async share_content(title: string, text: string, url: string) {
    url = `${environment.SHARELINK_DOMAIN}` + url;
    console.log('share_content', title, text, url);

    await Share.share({
      title: title,
      text: text,
      url: url,
    });
  }

  // // 啟動相機掃描 Qrcode
  // async startScan() {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const permissionResult = await this.checkCameraPermission();
  //       console.log('startScan permissionResult', permissionResult);

  //       // this.barcodeScanner
  //       //   .scan()
  //       //   .then((barcodeData) => {
  //       //     console.log('Barcode data', barcodeData);
  //       //     if (barcodeData.cancelled) {
  //       //       reject('cancel');
  //       //     } else {
  //       //       resolve(barcodeData.text);
  //       //     }
  //       //   })
  //       //   .catch((err) => {
  //       //     console.log('Error', err);
  //       //     reject(err);
  //       //   });

  //       CapacitorBarcodeScanner.hideBackground();
  //       document.querySelector('body').classList.add('scanner-active');

  //       const result = await CapacitorBarcodeScanner.startScan();
  //       document.querySelector('body').classList.remove('scanner-active');
  //       console.log('startScan result', result);
  //       resolve(result.content);
  //     } catch (error) {
  //       console.log('startScan error', error);
  //       reject(error);
  //     }
  //   });
  // }

  // async checkCameraPermission() {
  //   console.log('checkCameraPermission');

  //   return new Promise(async (resolve, reject) => {
  //     const status = await CapacitorBarcodeScanner.checkPermission({
  //       force: true,
  //     });
  //     if (status.granted) {
  //       resolve(true);
  //     } else if (status.denied) {
  //       const alert = await this.alertController.create({
  //         header: '相機權限',
  //         message: '請開啟相機權限',
  //         buttons: [
  //           {
  //             text: '取消',
  //             role: 'cancel',
  //           },
  //           {
  //             text: '確定',
  //             handler: () => {
  //               CapacitorBarcodeScanner.openAppSettings();
  //               resolve(false);
  //             },
  //           },
  //         ],
  //       });

  //       await alert.present();
  //     } else {
  //       resolve(false);
  //     }
  //   });
  // }

  async phoneCallNumber(phoneNumber: string) {
    console.log('phoneCallNumber', phoneNumber);
    this.callNumber
      .callNumber(phoneNumber, true)
      .then((res) => {
        console.log('callNumber res', res);
      })
      .catch((err) => {
        console.error('callNumber err', err);
      });
  }

  // 取得目前裝置的經緯度
  async getCurrentPosition(): Promise<Position> {
    return new Promise(async (resolve, reject) => {
      // check permission
      try {
        const permissionResult = await Geolocation.checkPermissions();
        console.log('checkPermissions result', permissionResult);
      } catch (error) {
        console.error('checkPermissions error', error);
        // request permission
        try {
          const permissionResult = await Geolocation.requestPermissions();
          console.log('requestPermissions result', permissionResult);
        } catch (error) {
          console.error('getCurrentPosition error', error);
          reject(error);
          return;
        }
      }

      // 取得目前裝置的經緯度
      try {
        const position = await Geolocation.getCurrentPosition();
        console.log('getCurrentPosition position', position);
        resolve(position);
      } catch (error) {
        console.log('getCurrentPosition error', error);
        reject(error);
      }
    });
  }

  // 取得裝置的 device info
  get_device_info() {
    return new Promise(async (resolve, reject) => {
      const info = await Device.getInfo();
      const device_id = await Device.getId();
      const model = info.model;
      const platform = info.platform;
      const device_data = JSON.stringify(info);

      localStorage.device_id = device_id?.uuid;
      localStorage.device_data = device_data;
      localStorage.device_name = model;
      localStorage.device_platform = platform.toUpperCase();
      resolve('OK');
    });
  }

  // 儲存影像到裝置的相簿
  async save_photo(image_url: string) {
    console.log('save_photo image_url', image_url);

    return new Promise(async (resolve, reject) => {
      try {
        try {
          const permissionResult = await this.checkFileSystemPermission();
          console.log('save_photo permissionResult', permissionResult);
        } catch (error) {
          console.error('save_photo checkFileSystemPermission error', error);
          const requestPermissionResult = await Filesystem.requestPermissions();
          console.log(
            'save_photo requestPermissionResult',
            requestPermissionResult
          );

          if (requestPermissionResult.publicStorage !== 'granted') {
            reject('requestPermissions error');
            return;
          }
        }

        const url = image_url;

        // 先將影像儲存到 Cache folder
        const photoTempFile = `${new Date().getTime()}.jpeg`;
        const photoAsBase64 = await this._readAsBase64(url);
        console.log('save_photo photoTempFile', photoTempFile);
        console.log('save_photo photoAsBase64', photoAsBase64);

        const photoTemp = await Filesystem.writeFile({
          path: photoTempFile,
          data: photoAsBase64,
          directory: Directory.Cache,
        });
        console.log('save_photo photoTemp done', photoTemp);

        const albumName = 'ELINE';
        let albumIdentifier = '';

        // 檢查是否有相簿
        if (this.platform.is('ios')) {
          let albums = await Media.getAlbums();
          albumIdentifier =
            albums.albums.find((x) => x.name === albumName)?.identifier || null;

          if (!albumIdentifier) {
            // 建立相簿
            const album = await Media.createAlbum({ name: albumName });
            albums = await Media.getAlbums();
            albumIdentifier =
              albums.albums.find((x) => x.name === albumName)?.identifier ||
              null;
          }
        } else if (this.platform.is('android')) {
          console.log('android 採用workaround作法 ');
        } else {
          reject('platform error');
          return;
        }

        // 將影像儲存到相簿
        if (this.platform.is('ios')) {
          const saveResult = await Media.savePhoto({
            path: photoTemp.uri,
            album: albumIdentifier,
          });
          console.log('save_photo ios saveResult', saveResult);
        } else if (this.platform.is('android')) {
          // workaround, 利用 capacitor fileSystem 直接寫入
          await Filesystem.writeFile({
            recursive: true,
            data: photoAsBase64,
            path: `${albumName}/${photoTempFile}`,
            directory: Directory.Documents,
          });
          console.log('save_photo android save done');
        }
        resolve(true);
      } catch (error) {
        console.error('save_photo error', error);
        reject(error);
      }
    });
  }

  async checkFileSystemPermission() {
    console.log('checkFileSystemPermission');

    return new Promise(async (resolve, reject) => {
      const status = await Filesystem.checkPermissions();
      if (status.publicStorage === 'granted') {
        resolve(true);
      } else if (status.publicStorage === 'denied') {
        reject(false);
      } else {
        resolve(false);
      }
    });
  }

  private async _readAsBase64(url: string): Promise<string> {
    // Fetch the photo, read as a blob, then convert to base64 format
    // const response = await window.fetch(url, {
    //   mode: 'no-cors',
    // });

    // console.log('save_photo response', response);
    // const blob = await response.blob();

    // const option: HttpOptions = {
    //   url: url,
    //   responseType: 'blob',
    // };

    return new Promise(async (resolve, reject) => {
      try {
        const option: HttpOptions = {
          url: url,
          responseType: 'blob',
        };

        //const response = await CapacitorHttp.get(option);
        const response = await Http.get(option);
        console.log('save_photo response', response);
        const blob = await response.data;
        // 目前的版本, 取回的就是 base64, 不是blob, 故不用轉換
        resolve(blob);
      } catch (error) {
        console.error('save_photo _readAsBase64 error', error);
        reject(error);
      }
    });

    // console.log('save_photo blob', blob);
    // return await this._convertBlobToBase64(blob);
  }

  private async _convertBlobToBase64(blob: Blob): Promise<string> {
    // return new Promise((resolve, reject) => {
    //   const reader = new FileReader();
    //   reader.onerror = reject;
    //   reader.onload = () => resolve(reader.result as string);
    //   reader.readAsDataURL(blob);
    // });

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data: any = reader.result;
        console.log('save_photo base64data', base64data);
        resolve(base64data);
      };
    });
  }

  //#region ========================= firebase analytics =========================
  async setCurrentScreen(screenName: string) {
    console.log('setCurrentScreen', screenName);
    await FirebaseAnalytics.setCurrentScreen({
      screenName,
      screenClassOverride: screenName,
    });
  }

  async logEvent(eventName: string, params: any) {
    console.log('logEvent', eventName, params);
    await FirebaseAnalytics.logEvent({ name: eventName, params });
  }

  async setUserId(userId: string) {
    console.log('setUserId', userId);
    await FirebaseAnalytics.setUserId({ userId });
  }

  async setUserProperty(key: string, value: string) {
    console.log('setUserProperty', { key, value });
    await FirebaseAnalytics.setUserProperty({ key, value });
  }
  //#endregion ========================= firebase analytics =========================

  //#region ========================= badge =========================
  async setBadgeCount(count: number) {
    console.log('setBadgeCount', count);
    await Badge.set({ count });
  }

  async clearBadgeCount() {
    console.log('clearBadgeCount');
    await Badge.clear();
  }

  //#endregion ========================= badge =========================
}
