import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { AlertController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-barcode-scanner-modal',
  templateUrl: './barcode-scanner-modal.component.html',
  styleUrls: ['./barcode-scanner-modal.component.scss'],
})
export class BarcodeScannerModalComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  result = null;
  scanActive = false;
  constructor(
    private alertController: AlertController,
    public modalController: ModalController
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    BarcodeScanner.prepare();
    setTimeout(() => {
      this.startScanner();
    }, 0);
  }

  ngOnDestroy() {
    BarcodeScanner.stopScan();
    document.querySelector('body').classList.remove('scanner-active');
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  // 啟動相機掃描 Qrcode
  async startScanner() {
    try {
      const allowed = await this.checkCameraPermission();
      // const allowed = true;
      console.log('startScanner permissionResult', allowed);

      if (allowed) {
        BarcodeScanner.hideBackground();
        document.querySelector('body').classList.add('scanner-active');

        this.scanActive = true;
        const result = await BarcodeScanner.startScan();
        console.log('startScan result', result);
        document.querySelector('body').classList.remove('scanner-active');
        this.scanActive = false;

        if (result?.hasContent) {
          this.result = result.content;
          this.modalController.dismiss({
            content: result.content,
          });
        } else {
          this.modalController.dismiss({
            content: null,
          });
        }
      }
    } catch (error) {
      console.log('startScan error', error);
    }
  }

  async checkCameraPermission() {
    console.log('checkCameraPermission');

    return new Promise(async (resolve, reject) => {
      const status = await BarcodeScanner.checkPermission({
        force: true,
      });
      if (status.granted) {
        resolve(true);
      } else if (status.denied) {
        const alert = await this.alertController.create({
          header: '相機權限',
          message: '請開啟相機權限',
          buttons: [
            {
              text: '取消',
              role: 'cancel',
            },
            {
              text: '確定',
              handler: () => {
                BarcodeScanner.openAppSettings();
                resolve(false);
              },
            },
          ],
        });

        await alert.present();
      } else {
        resolve(false);
      }
    });
  }

  stopScanner() {
    BarcodeScanner.stopScan();
    this.scanActive = false;
    document.querySelector('body').classList.remove('scanner-active');

    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
