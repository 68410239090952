import { Injectable } from '@angular/core';
import { CodeTaxonomyInfo } from '@mommy/models/CodeTaxonomyInfo.model';
import { TrashObject } from '@mommy/models/Comm.model';
import { ExpertInfo } from '@mommy/models/ExpertInfo.model';
import { MyInfo } from '@mommy/models/MyInfo.model';
import { PgcPostScore, PostInfo } from '@mommy/models/PostInfo.model';
import { OthersService } from '@mommy/services/others/others.service';
import { PostService } from '@mommy/services/post/post.service';
import { StorageService } from '@mommy/services/storage.service';
import { UtilService } from '@mommy/services/util.service';
import { UserState } from '@mommy/state/user/user.state';
import {
  Action,
  createSelector,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { AppSettings } from 'app/app.settings';
import * as _ from 'lodash';
import { AppInitState } from '../app-init/app-init.state';
import { AppUIState } from '../app-ui/app-ui.state';
import { ExpertState } from '../expert/expert.state';
import {
  AddPgcPostReportCommentId,
  AddPgcPostReportId,
  CalculatePgcPostScore,
  GetFilterPosts,
  GetPostDetail,
  InitLocalCachePosts,
  LoadCachePosts,
  PostClickLog,
  PostShareLog,
  RefreshPosts,
  SetPostsToPostState,
} from './post.action';

export interface PostStateModel {
  loading: boolean;
  posts: PostInfo[];
  detailPosts: PostInfo[];
  filter_posts: PostInfo[]; // 線上查詢的posts
  hasCache: boolean;
  pgcPostScore: PgcPostScore[];
  reportPostIds: number[]; // 被檢舉的post id
  reportPostCommentIds: number[]; // 被檢舉的post comment id
}

const defaultPostState = (): PostStateModel => {
  return {
    loading: false,
    posts: [],
    detailPosts: [],
    filter_posts: [],
    hasCache: false,
    pgcPostScore: [],
    reportPostIds: [],
    reportPostCommentIds: [],
  };
};

@State<PostStateModel>({
  name: 'PostState',
  defaults: defaultPostState(),
})
@Injectable()
export class PostState implements NgxsAfterBootstrap {
  constructor(
    private storage: StorageService,
    private postService: PostService,
    private util: UtilService,
    private store: Store,
    private othersService: OthersService
  ) {}

  async ngxsAfterBootstrap(ctx: StateContext<PostStateModel | null>) {
    console.log('[PostState] ngxsAfterBootstrap');

    // try {
    //   await ctx.dispatch(new LoadCacheMaternityKit()).toPromise();
    //   console.log('load local cache maternitykit success');
    //   // 再呼叫 getAllMaternityKit 來更新 local cache
    //   this.getMaternityKitFromServer(ctx);
    // } catch (error) {
    //   console.warn('LoadCacheMaternityKit error', error);
    //   // 如果沒有 cache, 就去 server 取
    //   this.getMaternityKitFromServer(ctx);
    // }
  }

  // // PGC 首頁 推薦列表, 依據 更新時間排序, 取前 n 筆 (由 首頁控制要撈取幾筆)
  // static pgcRecommendTopN(n: number) {
  //   return createSelector([PostState], (state: PostStateModel) => {
  //     return _.chain(state.posts)
  //       .filter((post) => post.post_type === 'pgc')
  //       .orderBy(['updated_at'], ['desc'])
  //       .take(n)
  //       .value();
  //   });
  // }

  // // PGC 首頁 推薦列表, 依據 更新時間排序, 取前 n 筆 (由 state.homePgcCount 控制要撈取幾筆)
  // @Selector()
  // static pgcRecommendTopNSelector(state: PostStateModel) {
  //   return _.chain(state.posts)
  //     .filter((post) => post.post_type === 'pgc')
  //     .orderBy(['updated_at'], ['desc'])
  //     .take(state.homePgcCount)
  //     .value();
  // }

  // @Selector()
  // static posts(state: PostStateModel) {
  //   return state.posts;
  // }

  // 文章 state join AppInitState.post_trash_objects
  @Selector([PostState, AppInitState.post_trash_objects])
  static posts(state: PostStateModel, trash_objects: TrashObject[]) {
    return _.chain(state.posts)
      .filter((post) => {
        return (
          _.findIndex(trash_objects, {
            object_id: post.post_id,
            trash_type: 'POST',
          }) === -1
        );
      })
      .filter((post) => {
        // 過濾掉被檢舉的文章
        const index = _.findIndex(state.reportPostIds, (id) => {
          return id === post.post_id;
        });
        return index === -1;
      })
      .value();
  }

  // 建立 dynamic selector, 傳入 post_id, 回傳對應的 post
  // 這個方法支援 memoized selector
  // Note that each of these selectors have their own separate memoization.
  // Even if two dynamic selectors created in this way are provided the same argument, they will have separate memoization.
  static detailPost(post_id: number) {
    return createSelector([PostState], (state: PostStateModel) => {
      const post = _.cloneDeep(_.find(state.detailPosts, { post_id }));

      if (post) {
        // 過濾掉被檢舉的評論
        if (state.reportPostCommentIds.length > 0) {
          const new_comments = _.filter(post.comments, (comment) => {
            const index = _.findIndex(state.reportPostCommentIds, (id) => {
              return id === comment.post_comment_id;
            });
            return index === -1;
          });
          post.comments = new_comments;
          post.comment_count = new_comments.length;
        }
      }
      return post;
    });
  }

  // PGC文章
  @Selector([PostState.posts])
  static pgcPosts(posts: PostInfo[]) {
    return _.chain(posts)
      .filter((post) => post.post_type === 'pgc專家')
      .filter((post) => post.post_status === 'publish')
      .value();
  }

  // PGC文章 score
  @Selector()
  static pgcPostScore(state: PostStateModel) {
    return state.pgcPostScore;
  }

  // PGC 熱門瀏覽 top 5
  @Selector([PostState.pgcPosts])
  static pgcTopViewCount(posts: PostInfo[]) {
    return _.chain(posts).orderBy(['view_count'], ['desc']).take(5).value();
  }

  // 百科 最近更新 top 5
  @Selector([PostState.posts])
  static pediaRecentUpdate(posts: PostInfo[]) {
    return _.chain(posts)
      .filter((post) => post.post_type === 'pedia百科')
      .filter((post) => post.post_status === 'publish')
      .orderBy(['updated_at'], ['desc'])
      .take(5)
      .value();
  }

  // 百科 by 分類查詢 (依照更新時間排序)
  static pediaByCategory(taxonomy_id: number) {
    return createSelector([PostState.posts], (posts: PostInfo[]) => {
      return _.chain(posts)
        .filter((post) => post.post_type === 'pedia')
        .filter(
          (post) =>
            post.encyclopedia_hierarchy_taxonomy.taxonomy_id === taxonomy_id
        )
        .orderBy(['updated_at'], ['desc'])
        .value();
    });
  }

  // 能不能吃 熱門瀏覽 top 5
  @Selector([PostState.posts])
  static canieatTopViewCount(posts: PostInfo[]) {
    return _.chain(posts)
      .filter((post) => post.post_type === '能不能吃')
      .filter((post) => post.post_status === 'publish')
      .orderBy(['view_count'], ['desc'])
      .take(5)
      .value();
  }

  // 能不能吃 by 分類查詢 (依照更新時間排序)
  @Selector([PostState.posts, AppUIState.params])
  static canEatByCategoryId(posts: PostInfo[], params: any) {
    console.log('[PostState] canEatByCategoryId', params);

    if (
      params.page === 'CanieatListCategoryPage' &&
      params.post_type &&
      params.taxonomy_id
    ) {
      return _.chain(posts)
        .filter((post) => post.post_type === params.post_type)
        .filter(
          (post) =>
            post.can_or_not_eat_taxonomy?.taxonomy_id === params.taxonomy_id &&
            post.post_status === 'publish'
        )
        .orderBy(['updated_at'], ['desc'])
        .value();
    }
  }

  // 能不能吃 by keyword online 查詢 (依照更新時間排序)
  @Selector()
  static canieatPostsBySearch(state: PostStateModel) {
    return _.chain(state.filter_posts)
      .filter((post) => post.post_type === '能不能吃')
      .filter((post) => post.post_status === 'publish')
      .orderBy(['updated_at'], ['desc'])
      .value();
  }

  // 首頁查詢 by keyword online 查詢 (依照更新時間排序)
  @Selector()
  static postsBySearch(state: PostStateModel) {
    return _.chain(state.filter_posts)
      .filter((post) => post.post_type !== '能不能吃')
      .filter((post) => post.post_status === 'publish')
      .orderBy(['updated_at'], ['desc'])
      .value();
  }

  // 百科分類結果頁-相關百科 (依照更新時間排序)
  @Selector([
    PostState.posts,
    ExpertState.experts,
    AppUIState.page_pedia_category_taxonomy,
  ])
  static pediaRelatedPedia(
    posts: PostInfo[],
    experts: ExpertInfo[],
    page_pedia_category_taxonomy: CodeTaxonomyInfo
  ) {
    console.log('[PostState] pediaRelatedPedia', page_pedia_category_taxonomy);
    console.log('[PostState] posts', posts);
    return _.chain(posts)
      .filter((post) => post.post_type === 'pedia百科')
      .filter(
        (post) =>
          post.encyclopedia_hierarchy_taxonomy?.taxonomy_id ===
          page_pedia_category_taxonomy.taxonomy_id
      )
      .filter((post) => post.post_status === 'publish')
      .orderBy(['updated_at'], ['desc'])
      .map((post) => {
        // 置換作者
        const expert = _.find(experts, {
          expert_id: post.post_author?.expert_id,
        });
        if (expert) {
          const new_post = _.cloneDeep(post);
          new_post.post_author = expert;
          return new_post;
        } else {
          return post;
        }
      })
      .value();
  }

  // 百科分類結果頁-相關醫師專欄 (使用上面的 pediaRelatedPedia, 找出這些百科文章內的延伸閱讀post list)
  @Selector([PostState.pediaRelatedPedia, ExpertState.experts])
  static pediaRelatedPgc(posts: PostInfo[], experts: ExpertInfo[]) {
    console.log('[PostState] pediaRelatedPgc', posts);
    return _.chain(posts)
      .map((x) => x.related_posts)
      .flatten()
      .unionBy('post_id')
      .map((post) => {
        // 置換作者
        const expert = _.find(experts, {
          expert_id: post.post_author?.expert_id,
        });
        if (expert) {
          const new_post = _.cloneDeep(post);
          new_post.post_author = expert;
          return new_post;
        } else {
          return post;
        }
      })
      .value();
  }

  // 首頁：推薦PGC文章機制, 搭配UI的顯示筆數
  @Selector([
    PostState.pgcPosts,
    PostState.pgcPostScore,
    ExpertState.experts,
    AppUIState.page_home_recommend_post_count,
  ])
  static homeRecommendPosts(
    posts: PostInfo[],
    pgcPostScore: PgcPostScore[],
    experts: ExpertInfo[],
    recommend_post_count: number
  ) {
    console.log('[PostState] homeRecommendPosts', posts);
    console.log('[PostState] homeRecommendPosts pgcPostScore', pgcPostScore);
    console.log(
      '[PostState] homeRecommendPosts recommend_post_count',
      recommend_post_count
    );

    return _.chain(posts)
      .map((post) => {
        const new_post = _.cloneDeep(post);
        // 置換作者
        const expert = _.find(experts, {
          expert_id: post.post_author?.expert_id,
        });
        if (expert) {
          new_post.post_author = expert;
        }
        // 置換推薦分數
        const pgc_post_score = _.find(pgcPostScore, { post_id: post.post_id });
        if (pgc_post_score) {
          new_post.pgc_score = pgc_post_score.pgc_score;
        }
        return new_post;
      })
      .orderBy(['pgc_score', 'updated_at'], ['desc', 'desc'])
      .take(recommend_post_count)
      .value();
  }

  // 首頁：關注的專家PGC文章機制
  @Selector([PostState.pgcPosts, ExpertState.experts, UserState.mommy_user])
  static homeWatchExpertPosts(
    posts: PostInfo[],
    experts: ExpertInfo[],
    mommy_user: MyInfo
  ) {
    console.log('[PostState] homeWatchExpertPosts', posts);
    console.log('[PostState] homeWatchExpertPosts mommy_user', mommy_user);

    return _.chain(posts)
      .map((post) => {
        const new_post = _.cloneDeep(post);
        // 置換作者
        const expert = _.find(experts, {
          expert_id: post.post_author?.expert_id,
        });
        if (expert) {
          new_post.post_author = expert;
        }
        return new_post;
      })
      .filter((post) => {
        const exist_post = _.find(mommy_user?.expert_watchs, {
          expert_id: post.post_author?.expert_id,
        });
        return !!exist_post;
      })
      .orderBy(['updated_at'], ['desc'])
      .value();
  }

  // Video 文章
  @Selector([PostState.posts])
  static videoPosts(posts: PostInfo[]) {
    return _.chain(posts)
      .filter((post) => post.post_type === 'video視頻')
      .filter((post) => post.post_status === 'publish')
      .orderBy(['updated_at'], ['desc'])
      .value();
  }

  // video 文章 and 孕期標籤id
  static videoPostsByPregnancyTagId(taxonomy_id: number) {
    return createSelector([PostState.posts], (posts: PostInfo[]) => {
      return _.chain(posts)
        .filter((post) => post.post_type === 'video視頻')
        .filter((post) => post.post_status === 'publish')
        .filter((post) => {
          const exist_tag = _.find(post.pregnancy_tag_taxonomy, {
            taxonomy_id,
          });
          return !!exist_tag;
        })
        .orderBy(['updated_at'], ['desc'])
        .value();
    });
  }

  @Action(InitLocalCachePosts)
  async initLocalCachePosts(ctx: StateContext<PostStateModel>) {
    console.log('[Action] InitLocalCachePosts');

    // 策略調整為：
    // 1. 先載入 local cache，如果沒有就抓 server (full)
    // 2. 載入完 cache 再抓 server 更新的資料 (incremental)
    try {
      await ctx.dispatch(new LoadCachePosts()).toPromise();
      console.log('load local cache posts success');
      // 再呼叫 getPostsFromServer 來更新 local cache
      //this.getExpertsFromServer(ctx);
      await ctx.dispatch(new RefreshPosts()).toPromise();
    } catch (error) {
      console.warn('LoadCacheExperts error', error);
      // 如果沒有 cache, 就去 server 取
      //this.getPostsFromServer(ctx);
      await ctx.dispatch(new RefreshPosts()).toPromise();
    }
  }

  @Action(LoadCachePosts)
  async loadCachePosts(ctx: StateContext<PostStateModel>) {
    console.log('[Action] LoadCachePosts');

    const state = ctx.getState();
    const _posts: any = await this.storage.get(AppSettings.CACHE_KEY_POST_LIST);
    let _pgc_post_score: any = await this.storage.get(
      AppSettings.CACHE_KEY_PGC_POST_SCORE_LIST
    );
    if (!_pgc_post_score) {
      _pgc_post_score = [];
    }

    // 處理本機 檢舉文章的紀錄
    let _reportPostIds: any = await this.storage.get('reportPostIds');
    if (!_reportPostIds) {
      _reportPostIds = [];
    }

    // 處理本機 檢舉文章評論的紀錄
    let _reportPostCommentIds: any = await this.storage.get(
      'reportPostCommentIds'
    );
    if (!_reportPostCommentIds) {
      _reportPostCommentIds = [];
    }

    if (_posts) {
      ctx.patchState({
        loading: false,
        posts: _posts,
        pgcPostScore: _pgc_post_score,
        reportPostIds: _reportPostIds,
        reportPostCommentIds: _reportPostCommentIds,
        hasCache: true,
      });
    } else {
      throw new Error('no cache');
    }
  }

  @Action(RefreshPosts)
  async refreshPosts(ctx: StateContext<PostStateModel>) {
    console.log('[Action] RefreshPosts');
    this.getPostsFromServer(ctx);
  }

  // 取得 post 詳細資料 線上
  @Action(GetPostDetail)
  async getPostDetail(
    ctx: StateContext<PostStateModel>,
    action: GetPostDetail
  ) {
    console.log('[Action] GetPostDetail');
    const state = ctx.getState();

    // 先查閱 local cache 是否有 detail 的資料, 如果有就先回傳
    const _post: any = await this.storage.get('post-' + action.post_id);

    const _detailPosts = state.detailPosts;
    const new_detailPosts = _.cloneDeep(_detailPosts);

    if (_post) {
      // 先判斷 detailPosts 是否已經存在, 如果有就置換, 沒有就新增至 detailPosts
      const index = _.findIndex(new_detailPosts, { post_id: action.post_id });
      if (index > -1) {
        new_detailPosts.splice(index, 1, _post);
      } else {
        new_detailPosts.push(_post);
      }

      ctx.patchState({
        detailPosts: new_detailPosts,
      });

      this.getPostDetailFromServer(ctx, action.post_id); // 再呼叫 getPostDetail 來更新資料
    } else {
      // 如果沒有 cache, 就去 server 取
      await this.getPostDetailFromServer(ctx, action.post_id);
    }
  }

  // // 變更首頁顯示推薦PGC的筆數
  // @Action(SetHomePgcCount)
  // setHomePgcCount(ctx: StateContext<PostStateModel>, action: SetHomePgcCount) {
  //   console.log('[Action] SetHomePgcCount');
  //   ctx.patchState({
  //     homePgcCount: action.count,
  //   });
  // }

  // 全文檢索查詢 線上
  @Action(GetFilterPosts)
  async getFilterPosts(
    ctx: StateContext<PostStateModel>,
    action: GetFilterPosts
  ) {
    console.log('[Action] GetFilterPosts');

    try {
      ctx.patchState({
        filter_posts: null,
      });

      const post: any = await this.postService.getPostListFilter(
        action.filter_value
      );
      console.log('post', post);

      ctx.patchState({
        filter_posts: post,
      });
    } catch (error2) {
      console.warn('getPostListFilter error', error2);
    }
  }

  // 計算 pgc post score, 並儲存回 local storage
  // 1. 預計為每篇文章計算一個 m-score
  // 2. 依照 member's 目前模式(懷孕模式,育兒模式)
  // 3. 依照平台推薦、深度合作、其他..等邏輯 給予 post score
  // 4. 依照 score, updated_at 排序 推薦文章
  @Action(CalculatePgcPostScore)
  calculatePgcPostScore(ctx: StateContext<PostStateModel>) {
    console.log('[Action] CalculatePgcPostScore');
    const state = ctx.getState();

    // 取得目前 app ui mode
    const app_ui_mode = this.store.selectSnapshot(AppUIState.app_ui_mode);

    let page_home_recommend_post_ids;
    if (app_ui_mode === 'baby') {
      page_home_recommend_post_ids = this.store.selectSnapshot(
        AppUIState.page_home_recommend_baby_post_ids
      );
    } else if (app_ui_mode === 'pregnancy') {
      page_home_recommend_post_ids = this.store.selectSnapshot(
        AppUIState.page_home_recommend_pregnancy_post_ids
      );
    } else {
      page_home_recommend_post_ids = [];
    }

    const experts = this.store.selectSnapshot(ExpertState.experts);

    const _pgcPostScore: any = _.chain(state.posts)
      .filter((post: any) => post.post_type === 'pgc專家')
      .map((post: any) => {
        let pgc_score = 0;
        // 是否為平台推薦
        if (page_home_recommend_post_ids.indexOf(post.post_id) > -1) {
          pgc_score += 100;
        }

        // 作者是否為深度合作
        const author = _.find(experts, {
          expert_id: post.post_author?.expert_id,
        });
        if (author && author.level === 'SPECIAL') {
          pgc_score += 30;
        } else {
          // 10%機率 隨機提成
          if (Math.random() < 0.1) {
            pgc_score += 30;
          }
        }

        // 作者是否符合 app ui mode
        if (app_ui_mode === 'pregnancy') {
          const section = _.map(author?.subject_classes, 'taxonomy_name').join(
            ','
          );
          if (section.indexOf('婦科') > -1 || section.indexOf('婦科') > -1) {
            pgc_score += 30;
          }
        } else if (app_ui_mode === 'baby') {
          const section = _.map(author.subject_classes, 'taxonomy_name').join(
            ','
          );
          if (section.indexOf('小兒科') > -1) {
            pgc_score += 30;
          }
        }
        console.log('pgc_score', post.post_id, pgc_score);
        const pgc_post_score = { post_id: post.post_id, pgc_score };
        return pgc_post_score;
      })
      .value();

    console.log('_pgcPostScore', _pgcPostScore);

    // 儲存新的 pgc_post_score 至 local storage
    this.storage.set(AppSettings.CACHE_KEY_PGC_POST_SCORE_LIST, _pgcPostScore);
    ctx.patchState({
      pgcPostScore: _pgcPostScore,
    });
  }

  @Action(SetPostsToPostState)
  async SetPostsToPostState(
    ctx: StateContext<PostStateModel>,
    action: SetPostsToPostState
  ) {
    console.log('[Action] SetPostsToPostState');
    const state = ctx.getState();

    ctx.patchState({
      posts: action.payload,
    });
  }

  @Action(PostClickLog)
  async PostClickLog(
    ctx: StateContext<PostStateModel>,
    { post_id }: PostClickLog
  ) {
    console.log('[Action] PostClickLog');

    try {
      const result: any = await this.othersService.postClickLog(post_id);
      console.log('postClickLog result', result);
    } catch (error) {
      console.error('postClickLog error', error);
    }
  }

  @Action(PostShareLog)
  async PostShareLog(
    ctx: StateContext<PostStateModel>,
    { post_id }: PostShareLog
  ) {
    console.log('[Action] PostShareLog');

    try {
      const result: any = await this.othersService.postShareLog(post_id);
      console.log('PostShareLog result', result);
    } catch (error) {
      console.error('PostShareLog error', error);
    }
  }

  // 增加 pgc文章檢舉id 的紀錄
  @Action(AddPgcPostReportId)
  async AddPgcPostReportId(
    ctx: StateContext<PostStateModel>,
    { post_id }: AddPgcPostReportId
  ) {
    console.log('[Action] AddPgcPostReportId');

    const state = ctx.getState();

    const _reportPostIds = _.cloneDeep(state.reportPostIds);
    _reportPostIds.push(post_id);

    await this.storage.set('reportPostIds', _reportPostIds);

    ctx.patchState({
      reportPostIds: _reportPostIds,
    });
  }

  // 增加 pgc文章檢舉評論id 的紀錄
  @Action(AddPgcPostReportCommentId)
  async AddPgcPostReportCommentId(
    ctx: StateContext<PostStateModel>,
    { comment_id }: AddPgcPostReportCommentId
  ) {
    console.log('[Action] AddPgcPostReportCommentId');

    const state = ctx.getState();

    const _reportPostCommentIds = _.cloneDeep(state.reportPostCommentIds);
    _reportPostCommentIds.push(comment_id);

    await this.storage.set('reportPostCommentIds', _reportPostCommentIds);

    ctx.patchState({
      reportPostCommentIds: _reportPostCommentIds,
    });
  }

  private async getPostsFromServer(ctx: StateContext<PostStateModel>) {
    // try read data from server
    console.log('getPostsFromServer');
    try {
      const posts: any = await this.postService.getPostList();
      console.log('posts', posts);

      ctx.patchState({
        loading: false,
        posts,
        hasCache: true,
      });
    } catch (error2) {
      console.warn('getPostList error', error2);
    }
  }

  private async getPostDetailFromServer(
    ctx: StateContext<PostStateModel>,
    post_id: number
  ) {
    // try read data from server
    console.log('getPostDetailFromServer');
    try {
      const post = await this.postService.getPostDetail(post_id);
      console.log('post', post);
      await this.storage.set('post-' + post_id, post);

      const state = ctx.getState();
      const _detailPosts = state.detailPosts;
      const new_detailPosts = _.cloneDeep(_detailPosts);

      const index = _.findIndex(new_detailPosts, { post_id });
      if (index > -1) {
        new_detailPosts.splice(index, 1, post);
      } else {
        new_detailPosts.push(post);
      }

      ctx.patchState({
        detailPosts: new_detailPosts,
      });
    } catch (error2) {
      console.warn('getPostDetail error', error2);
    }
  }
}
