import { Injectable } from '@angular/core';
import { Channel, Team } from '@mommy/models/Eline.model';
import { StorageService } from '@mommy/services/storage.service';
// import { UpdateInquireUnReadCount } from '@mommy/state/app/app.actions';
import { ElineHospitalState } from '@mommy/state/eline-hospital/eline-hospital.state';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import * as _ from 'lodash';
import {
  EnterChannel,
  InquireBadgeCaculate,
  InquireBadgeCaculatePatient,
  LeaveChannel,
  LoadChannels,
  UpdateChannels,
} from './channel.actions';

export interface ChannelStateModel {
  loading: boolean;
  channels: any;
  selectedChannel: any;
  selectedHospital: any; // 從醫療團隊page enter chat channel
  selectedPatient: any; // 從病患detail page enter chat channel
  hasCache: boolean;
}

const defaultChannelState = (): ChannelStateModel => {
  return {
    loading: false,
    channels: undefined,
    selectedChannel: undefined,
    selectedHospital: undefined,
    selectedPatient: undefined,
    hasCache: false,
  };
};
@State<ChannelStateModel>({
  name: 'ChannelState',
  defaults: defaultChannelState(),
})
@Injectable()
export class ChannelState {
  constructor(private storage: StorageService) {}

  @Selector()
  static channels(state: ChannelStateModel) {
    return state.channels;
  }

  // 諮詢的 channels list
  @Selector([ChannelState.channels, ElineHospitalState.hospitals])
  static inquireChannels(channels: Channel[], hospitals: Team[]) {
    console.log('[ChannelState] inquireChannels channels', channels);
    console.log('[ChannelState] inquireChannels hospitals', hospitals);

    // 合併 firebase's channel 和 eline's team data
    return _.map(channels, (channel) => {
      const _h: Team = _.find(hospitals, { team_id: channel.tid });
      const new_channel = _.cloneDeep(channel);

      if (_h) {
        new_channel.avatar = _h.avatar;
        new_channel.team_avatar = _h.avatar;
        new_channel.category_code = _h.category_code; // 團隊類型H:hospital; V:vender
        new_channel.team_name = _h.team_name;
        if (_h.team_name) {
          new_channel.displayname = _h.team_name;
        }
      } else {
        new_channel.avatar = channel.ta; // team avatar
        // new_channel.team_avatar = channel.avatar; // team avatar
      }
      return new_channel;
    });
  }

  @Action(LoadChannels)
  async loadChannels(context: StateContext<ChannelStateModel>) {
    console.log('[Action] loadChannels');
    const data_key = `channels`;

    let channels;
    try {
      channels = await this.storage.get(data_key);
      if (channels) {
        return context.patchState({ channels, hasCache: true });
      } else {
        return context.patchState({ channels, hasCache: false });
      }
    } catch (error) {
      console.error('storage.get error,', error);
    }
  }

  @Action(InquireBadgeCaculate)
  async inquireBadgeCaculate(context: StateContext<ChannelStateModel>) {
    console.log('[Action] inquireBadgeCaculate');
    const state = context.getState();

    let total_unread_count = 0;
    _.forEach(state.channels, (elem) => {
      if (elem.urc === undefined) {
        //unreadcount
        elem.unread_cnt = 0;
      } else {
        if (elem.urc['t' + elem.tid] === undefined) {
          elem.unread_cnt = 0;
        } else {
          elem.unread_cnt = elem.urc['t' + elem.tid];
        }
      }

      total_unread_count += elem.unread_cnt;
    });

    // return context.dispatch(new UpdateInquireUnReadCount(total_unread_count));
  }

  @Action(InquireBadgeCaculatePatient)
  async inquireBadgeCaculatePatient(context: StateContext<ChannelStateModel>) {
    console.log('[Action] InquireBadgeCaculatePatient');
    const state = context.getState();

    let total_unread_count = 0;
    _.forEach(state.channels, (elem) => {
      if (elem.urc === undefined) {
        //unreadcount
        elem.unread_cnt = 0;
      } else {
        let usr_unread_cnt = 0;
        let sys_unread_cnt = 0;
        if (elem.urc['u' + elem.uid] === undefined) {
          usr_unread_cnt = 0;
          // elem.unread_cnt = 0;
        } else {
          usr_unread_cnt = elem.urc['u' + elem.uid];
          // elem.unread_cnt = elem.urc['u'+elem.uid];
        }

        //get sys發送的unread_count
        if (elem.urc['sys'] === undefined) {
          sys_unread_cnt = 0;
        } else {
          sys_unread_cnt = elem.urc['sys'];
        }

        elem.unread_cnt = usr_unread_cnt + sys_unread_cnt;
      }

      total_unread_count += elem.unread_cnt;
    });

    // return context.dispatch(new UpdateInquireUnReadCount(total_unread_count));
  }

  @Action(UpdateChannels)
  async updateChannels(
    context: StateContext<ChannelStateModel>,
    { channels }: UpdateChannels
  ) {
    console.log('[Action] updateChannels');
    const data_key = `channels`;

    try {
      // 將 readtimedb 取得的值 assing to store
      await this.saveChannels(channels);
      return context.patchState({ channels });
    } catch (error) {
      console.error('updateChannels action error:', error);
      context.patchState({ loading: false });
      throw new Error(error);
    }
  }

  @Action(EnterChannel)
  async enterChannel(
    context: StateContext<ChannelStateModel>,
    { channel, hospital, patient }: EnterChannel
  ) {
    console.log('[Action] enterChannel');

    try {
      // 將 readtimedb 取得的值 assing to store
      return context.patchState({
        selectedChannel: channel,
        selectedHospital: hospital,
        selectedPatient: patient,
      });
    } catch (error) {
      console.error('enterChannel action error:', error);
      throw new Error(error);
    }
  }

  @Action(LeaveChannel)
  async leaveChannel(context: StateContext<ChannelStateModel>) {
    console.log('[Action] leaveChannel');

    try {
      // 將 readtimedb 取得的值 assing to store
      return context.patchState({ selectedChannel: undefined });
    } catch (error) {
      console.error('leaveChannel action error:', error);
      throw new Error(error);
    }
  }

  private saveChannels(channels) {
    if (channels) {
      const _channel = Object.assign([], channels);
      _.each(_channel, (elem) => {
        delete elem.$exists; //localforage無法儲存有function object, 故需要刪除它,ps:ionic storage會使用到localforage
      });
      // console.log(_channel);
      this.storage.set('channels', _channel);
    } else {
      console.warn('channels is null, ignore..');
    }
  }
}
