import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { QRCodeModule } from 'angularx-qrcode';
import { SwiperModule } from 'swiper/angular';
import { CanieatPostListComponent } from './components/canieat-post-list/canieat-post-list.component';
import { CommentItemComponent } from './components/comment-item/comment-item.component';
import { EcOrderInfoComponent } from './components/ec-order-info/ec-order-info.component';
import { EcOrderReturnInfoComponent } from './components/ec-order-return-info/ec-order-return-info.component';
import { ExpertListItemComponent } from './components/expert-list-item/expert-list-item.component';
import { ExpertPostListComponent } from './components/expert-post-list/expert-post-list.component';
import { ExpertPostList2Component } from './components/expert-post-list2/expert-post-list2.component';
import { ExpertRelatedProductComponent } from './components/expert-related-product/expert-related-product.component';
import { ExpertTitleComponent } from './components/expert-title/expert-title.component';
import { HospitalListItemComponent } from './components/hospital-list-item/hospital-list-item.component';
import { NumberPickerComponent } from './components/number-picker/number-picker.component';
import { PediaPostListComponent } from './components/pedia-post-list/pedia-post-list.component';
import { ProductListItemComponent } from './components/product-list-item/product-list-item.component';
import { ProductListItem2Component } from './components/product-list-item2/product-list-item2.component';
import { ProductListItem3Component } from './components/product-list-item3/product-list-item3.component';
import { RegisterRewardToastComponent } from './components/register-reward-toast/register-reward-toast.component';
import { RelatedPgcPostComponent } from './components/related-pgc-post/related-pgc-post.component';
import { RelatedPostComponent } from './components/related-post/related-post.component';
import { RelatedProductComponent } from './components/related-product/related-product.component';
import { SkeletonContentComponent } from './components/skeleton-content/skeleton-content.component';
import { SortingHeaderComponent } from './components/sorting-header/sorting-header.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { VideoPostListComponent } from './components/video-post-list/video-post-list.component';
import { VideoPostList2Component } from './components/video-post-list2/video-post-list2.component';
import { DateFormatPipe } from './date-format.pipe';
import { EllipsisDirective } from './ellipsis.directive';
import { InView } from './InView.directive';
import { LinkCheckDirective } from './linkcheck.directive';
import { LongPressDirective } from './long-press.directive';
import { AddCartModalPage } from './modal/add-cart-modal/add-cart-modal.page';
import { BarcodeScannerModalComponent } from './modal/barcode-scanner-modal/barcode-scanner-modal.component';
import { EcReturnReasonModalPage } from './modal/ec-return-reason-modal/ec-return-reason-modal.page';
import { EditCommentModalPage } from './modal/edit-comment-modal/edit-comment-modal.page';
import { ExpertFilterModalPage } from './modal/expert-filter-modal/expert-filter-modal.page';
import { GetCoinModalPage } from './modal/get-coin-modal/get-coin-modal.page';
import { GetCoinModal2Page } from './modal/get-coin-modal2/get-coin-modal2.page';
import { GetLottoModalPage } from './modal/get-lotto-modal/get-lotto-modal.page';
import { HospitalFilterModalPage } from './modal/hospital-filter-modal/hospital-filter-modal.page';
import { ImageModalPage } from './modal/image-modal/image-modal.page';
import { InviteQrcodeModalPage } from './modal/invite-qrcode-modal/invite-qrcode-modal.page';
import { LoginPopupModalPage } from './modal/login-popup-modal/login-popup-modal.page';
import { NonElineHospitalModalPage } from './modal/non-eline-hospital-modal/non-eline-hospital-modal.page';
import { ReportCommentModalPage } from './modal/report-comment-modal/report-comment-modal.page';
import { SwitchBabyModalPage } from './modal/switch-baby-modal/switch-baby-modal.page';
import { UseCoinModalPage } from './modal/use-coin-modal/use-coin-modal.page';
import { WatchExpertModalPage } from './modal/watch-expert-modal/watch-expert-modal.page';
import { ImgFallbackDirective } from './ngx-img-fallback.directive';
import { SafePipe } from './safe.pipe';
import { StripHtmlPipe } from './striphtml.pipe';
import { TimeAgoPipe } from './time-ago.pipe';

@NgModule({
  declarations: [
    ExpertPostListComponent,
    ExpertPostList2Component,
    ExpertTitleComponent,
    PediaPostListComponent,
    RelatedPostComponent,
    VideoPostListComponent,
    VideoPostList2Component,
    RelatedProductComponent,
    ExpertRelatedProductComponent,
    CanieatPostListComponent,
    HospitalListItemComponent,
    ExpertListItemComponent,
    NumberPickerComponent,
    RelatedPgcPostComponent,
    ProductListItemComponent,
    ProductListItem2Component,
    ProductListItem3Component,
    EcOrderInfoComponent,
    EcOrderReturnInfoComponent,
    SortingHeaderComponent,
    RegisterRewardToastComponent,
    SkeletonContentComponent,
    CommentItemComponent,
    VideoPlayerComponent,
    NonElineHospitalModalPage,
    WatchExpertModalPage,
    HospitalFilterModalPage,
    GetCoinModalPage,
    AddCartModalPage,
    UseCoinModalPage,
    GetLottoModalPage,
    EcReturnReasonModalPage,
    InviteQrcodeModalPage,
    SwitchBabyModalPage,
    GetCoinModal2Page,
    ExpertFilterModalPage,
    EditCommentModalPage,
    LoginPopupModalPage,
    ImageModalPage,
    BarcodeScannerModalComponent,
    ReportCommentModalPage,
    SafePipe,
    TimeAgoPipe,
    DateFormatPipe,
    StripHtmlPipe,
    InView,
    LinkCheckDirective,
    LongPressDirective,
    ImgFallbackDirective,
    EllipsisDirective,
  ],
  imports: [IonicModule, SwiperModule, CommonModule, FormsModule, QRCodeModule],
  exports: [
    ExpertPostListComponent,
    ExpertPostList2Component,
    ExpertTitleComponent,
    PediaPostListComponent,
    RelatedPostComponent,
    VideoPostListComponent,
    VideoPostList2Component,
    RelatedProductComponent,
    ExpertRelatedProductComponent,
    CanieatPostListComponent,
    HospitalListItemComponent,
    ExpertListItemComponent,
    NumberPickerComponent,
    RelatedPgcPostComponent,
    ProductListItemComponent,
    ProductListItem2Component,
    ProductListItem3Component,
    EcOrderInfoComponent,
    EcOrderReturnInfoComponent,
    SortingHeaderComponent,
    RegisterRewardToastComponent,
    SkeletonContentComponent,
    CommentItemComponent,
    VideoPlayerComponent,
    NonElineHospitalModalPage,
    WatchExpertModalPage,
    HospitalFilterModalPage,
    GetCoinModalPage,
    AddCartModalPage,
    UseCoinModalPage,
    GetLottoModalPage,
    EcReturnReasonModalPage,
    InviteQrcodeModalPage,
    SwitchBabyModalPage,
    GetCoinModal2Page,
    ExpertFilterModalPage,
    EditCommentModalPage,
    LoginPopupModalPage,
    ImageModalPage,
    BarcodeScannerModalComponent,
    ReportCommentModalPage,
    SafePipe,
    TimeAgoPipe,
    DateFormatPipe,
    StripHtmlPipe,
    InView,
    LinkCheckDirective,
    LongPressDirective,
    ImgFallbackDirective,
    EllipsisDirective,
  ],
})
export class ComponentsModule {}
