import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CheckOutInfo } from '@mommy/models/ec/CheckOutInfo.model';
import { CustomerCoinInfo } from '@mommy/models/ec/CustomerCoinInfo.model';
import { UtilService } from '@mommy/services/util.service';
import { SetCheckoutUseCoinPoint } from '@mommy/state/ec/ec-order/ec-order.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-use-coin-modal',
  templateUrl: './use-coin-modal.page.html',
  styleUrls: ['./use-coin-modal.page.scss'],
})
export class UseCoinModalPage implements OnInit {
  memberCheckOutInfo: CheckOutInfo;
  custCoinInfo: CustomerCoinInfo;

  supplierId: number; // 要折抵的供應商id
  _maxCoinPoint: number = 0; // 此供應商最大可折抵咪幣
  _maxDiscountAmount: number = 0; // 此供應商最大可折抵金額

  _totalCoinPoint: number = 0; // 此會員目前在此供應商可用的咪幣點數 (會員咪幣點數 - 其他供應商已折抵的咪幣點數)
  _restCoinPoint: number = 0; // 剩餘咪幣點數

  // ion-ranger
  _step = 20; // ion-ranger step, 折抵咪幣匯率
  _max = 0; // ion-ranger max, 此供應商最大可折抵咪幣
  _useCoinPoint: number = 0; // ion-ranger value

  constructor(
    public modalController: ModalController,
    public navParams: NavParams,
    private store: Store,
    private util: UtilService
  ) {
    this.memberCheckOutInfo = this.navParams.get('memberCheckOutInfo');
    this.supplierId = this.navParams.get('supplierId');
    this.custCoinInfo = this.memberCheckOutInfo.custCoinInfo;

    console.log('memberCheckOutInfo', this.memberCheckOutInfo);
    console.log('supplierId', this.supplierId);
    console.log('custCoinInfo', this.custCoinInfo);

    let otherSupplierUseCoinPoint = 0; // 其他供應商已折抵的咪幣點數
    this.memberCheckOutInfo.supplierCommodity.forEach((supplier) => {
      if (supplier.supplierId !== this.supplierId) {
        otherSupplierUseCoinPoint += supplier.useCoinPoint || 0;
      }
    });

    // 會員於此供應商可用的咪幣點數
    this._totalCoinPoint =
      this.memberCheckOutInfo.custCoinInfo.redeemedPoints -
      otherSupplierUseCoinPoint;

    // 計算在此供應商最大可折抵咪幣
    this.memberCheckOutInfo.supplierCommodity.forEach((supplier) => {
      if (supplier.supplierId === this.supplierId) {
        this._maxCoinPoint = supplier?.maxCoinPoint || 0;
        this._useCoinPoint = supplier?.useCoinPoint || 0;

        // 此訂單最大可以折抵的咪幣, 不能超過此供應商最大可折抵咪幣 或是 會員咪幣點數
        if (this._maxCoinPoint > this._totalCoinPoint) {
          // 會員咪幣點數 小於 此供應商最大可折抵咪幣 => 會員咪幣點數計算
          if (
            this._totalCoinPoint > 0 &&
            this.memberCheckOutInfo.custCoinInfo?.ratio > 0
          ) {
            this._maxDiscountAmount = Math.floor(
              this._totalCoinPoint / this.memberCheckOutInfo.custCoinInfo?.ratio
            );
            this._max =
              this._maxDiscountAmount *
              this.memberCheckOutInfo.custCoinInfo?.ratio;
            this._maxCoinPoint = this._max;
          }
          this._step = this.memberCheckOutInfo.custCoinInfo?.ratio;
        } else {
          // 會員咪幣點數 大於 此供應商最大可折抵咪幣 => 此供應商最大可折抵咪幣計算
          if (
            this._maxCoinPoint > 0 &&
            this.memberCheckOutInfo.custCoinInfo?.ratio > 0
          ) {
            this._maxDiscountAmount =
              this._maxCoinPoint / this.memberCheckOutInfo.custCoinInfo?.ratio;
          }

          this._step = this.memberCheckOutInfo.custCoinInfo?.ratio;
          this._max = this._maxCoinPoint;
        }
      }
    });

    // const supplier = _.find(this.memberCheckOutInfo.supplierCommodity, {
    //   supplierId: this.supplierId,
    // });
    // console.log('supplier', supplier);
    // this._maxCoinPoint = supplier?.maxCoinPoint || 0;
    // this._useCoinPoint = supplier?.useCoinPoint || 0;

    // if (this._maxCoinPoint > 0 && this.memberCheckOutInfo.coinRate > 0) {
    //   this._maxDiscountAmount =
    //     this._maxCoinPoint / this.memberCheckOutInfo.coinRate;
    // }

    // this._step = this.memberCheckOutInfo.coinRate;
    // this._max = this._maxCoinPoint;
  }

  ngOnInit() {}

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  onRangeChange(event) {
    console.log('onRangeChange', event);
    this._useCoinPoint = event.detail.value;
    this._restCoinPoint = this._totalCoinPoint - this._useCoinPoint;
  }

  submit() {
    console.log('submit', this._useCoinPoint);

    // 判斷是否用戶的點數是否足夠
    if (this._useCoinPoint > this._maxCoinPoint) {
      this.util.showErrorWithToash('咪幣點數不足');
      return;
    }

    this.store.dispatch(
      new SetCheckoutUseCoinPoint(this.supplierId, this._useCoinPoint)
    );
    this.modalController.dismiss({
      submit: true,
    });
  }
}
