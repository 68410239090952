import { UpdateUserProfileInput } from '@mommy/models/MyInfo.model';

export class InitLocalCacheUser {
  static readonly type = '[User] InitLocalCacheUser';
}

export class LoadCacheUser {
  static readonly type = '[User] LoadCacheUser';
}

// 清除 user state (for logout..)
export class ClearUser {
  static readonly type = '[User] ClearUser';
}

export class RefreshMommyUser {
  static readonly type = '[User] RefreshMommyUser';
}

export class RefreshElineUser {
  static readonly type = '[User] RefreshElineUser';
}

export class RefreshECUser {
  static readonly type = '[User] RefreshECUser';
}

export class UpdateMommyUser {
  static readonly type = '[User] UpdateMommyUser';
  constructor(public payload: any) {}
}

// 更新user profile資料 to server
export class UpdateMommyUserProfile {
  static readonly type = '[User] UpdateMommyUserProfile';
  constructor(public payload: UpdateUserProfileInput) {}
}
