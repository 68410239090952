export class InitLocalCacheTasks {
  static readonly type = '[Task] InitLocalCacheTasks';
}

export class LoadCacheTasks {
  static readonly type = '[Task] LoadCacheTasks';
}

export class RefreshTasks {
  static readonly type = '[Task] RefreshTasks';
}

// 領取獎勵
export class RetriveAward {
  static readonly type = '[Task] RetriveAward';
  constructor(public member_task_id: number) {}
}
