import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { LineLogin } from '@awesome-cordova-plugins/line-login/ngx';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Platform } from '@ionic/angular';
import { environment } from '@mommy/environments/environment';
import { APIResponse, Oauth2Params } from '@mommy/models/Comm.model';
import { ElineApi } from '@mommy/services/eline-api';
import { StorageService } from '@mommy/services/storage.service';
import { Store } from '@ngxs/store';
import firebase from 'firebase/compat/app';
import { v4 as uuidv4 } from 'uuid';
import { Api } from '../api';
import { IGNORE_GOT401ACTION, IGNORE_TOKEN } from '../token.interceptor';

@Injectable({ providedIn: 'root' })
export class AuthService {
  verificationId; // SMS認證的參數(暫存使用)
  smsVerifyResultPayload; // 簡訊驗證結果(暫存使用)
  phoneInstantVerficationUser; // 門號登入立即驗證完成後取得的User(暫存使用)
  googleLoginResultPayload; // google login popup result(暫存使用)
  appleLoginResultPayload; // apple login popup result(暫存使用)
  lineLoginResultPayload; // line login popup result(暫存使用)

  constructor(
    private api: Api,
    private elineApi: ElineApi,
    private storage: StorageService,
    public afAuth: AngularFireAuth,
    public platform: Platform,
    private store: Store,
    private lineLogin: LineLogin
  ) {
    console.log('Hello AuthService');
  }

  // 取得 firebase user's idToken(web)
  get_firebase_id_token(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await this.afAuth.currentUser;
        const idToken = await user.getIdToken(true);
        console.log('get_firebase_id_token', idToken);
        resolve(idToken);
      } catch (error) {
        console.error('get_firebase_id_token error', error);
        reject(error);
      }
    });
  }

  // 取得 firebase user's idToken(capacitor)
  get_plugin_firebase_id_token(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const idToken = await FirebaseAuthentication.getIdToken({
          forceRefresh: true,
        });
        console.log('get_firebase_id_token', idToken);
        resolve(idToken.token);
      } catch (error) {
        console.error('get_firebase_id_token error', error);
        reject(error);
      }
    });
  }

  firebase_login(custom_token): Promise<any> {
    console.log('custom_token:' + custom_token);
    return this.afAuth.signInWithCustomToken(custom_token);
  }

  firebase_logout() {
    console.log('firebase_logout');
    return this.afAuth.signOut();
  }

  async login_line(action_type: 'Login' | 'Register') {
    console.log('login_line');

    return new Promise(async (resolve, reject) => {
      if (this.platform.is('capacitor')) {
        // iOS 會報錯, 如果多次 initialize
        // this.lineLogin.initialize({
        //   channel_id: environment.line_client_id,
        // });
        // console.log('lineLogin.initialize done');

        this.lineLogin
          .login()
          .then((result) => {
            console.log('login_line result', result);
            resolve(result);
          })
          .catch((error) => {
            console.error('login_line error', error);
            reject(error);
          });
      } else {
        // 產生 random uuid state, nonce
        const state = uuidv4();
        const nonce = uuidv4();

        localStorage.setItem('line_state', state);

        let line_auth_url = '';

        if (action_type === 'Login') {
          // eslint-disable-next-line max-len
          line_auth_url = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${environment.line_client_id}&redirect_uri=${environment.line_callback_login_url}&scope=${environment.line_scope}&state=${state}&nonce=${nonce}`;
        } else if (action_type === 'Register') {
          // eslint-disable-next-line max-len
          line_auth_url = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${environment.line_client_id}&redirect_uri=${environment.line_callback_register_url}&scope=${environment.line_scope}&state=${state}&nonce=${nonce}`;
        } else {
          console.warn('login_line action_type error');
          reject('login_line action_type error');
          return;
        }

        console.log('line_auth_url', line_auth_url);
        window.location.href = line_auth_url;

        resolve(true);
      }
    });
  }

  async login_google() {
    console.log('login_google');

    if (this.platform.is('capacitor')) {
      const result = await FirebaseAuthentication.signInWithGoogle();
      console.log('login_google result', result);
      return result;
    } else {
      return this.afAuth.signInWithPopup(
        new firebase.auth.GoogleAuthProvider()
      );
    }
  }

  async login_apple() {
    console.log('login_apple');
    const provider = new firebase.auth.OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');

    if (this.platform.is('capacitor')) {
      // TODO: 這邊 Peter's A52s android 發生錯誤, 另外一台 android 平板就正常, 目前不知道為什麼
      // if (this.platform.is('ios')) {
      //   const result = await FirebaseAuthentication.signInWithApple();
      //   console.log('login_apple result', result);
      //   return result;
      // } else {
      //   return this.afAuth.signInWithRedirect(provider);
      // }
      const result = await FirebaseAuthentication.signInWithApple();

      // // 再取一次 IdToken
      // const idToken = await FirebaseAuthentication.getIdToken({
      //   forceRefresh: true,
      // });
      // console.log('login_apple getIdToken result', idToken.token);
      // result.credential.idToken = idToken.token;
      console.log('login_apple result', result);

      const userResult = await FirebaseAuthentication.getCurrentUser();
      console.log('login_apple getCurrentUser result', userResult);

      const idToken = await FirebaseAuthentication.getIdToken({
        forceRefresh: true,
      });
      console.log('login_apple getIdToken result', idToken.token);

      return result;
    } else {
      return this.afAuth.signInWithPopup(provider);
    }
  }

  // 會判斷 platform 是 web or cordova
  login_phone(phone_nbr, appVerifier) {
    console.log('login_phone', phone_nbr);
    firebase.auth().languageCode = 'zh-TW';

    if (this.platform.is('capacitor')) {
      // 設置一個 listener, android有機制可以驗證是否可以登入
      // 目前 capacitor-firebase plugin 好像有問題, pending
      // this.phoneInstantVerficationUser = null;
      // const listener = FirebaseAuthentication.addListener(
      //   'authStateChange',
      //   async (change) => {
      //     console.log('authStateChange', change);
      //     if (change.user) {
      //       // 登入成功
      //       console.log('authStateChange user', change.user);
      //       // 取得 idToken
      //       const idToken = await FirebaseAuthentication.getIdToken({
      //         forceRefresh: true,
      //       });
      //       this.phoneInstantVerficationUser = change.user;
      //       this.phoneInstantVerficationUser.idToken = idToken;
      //       console.log(
      //         'this.phoneInstantVerficationUser',
      //         this.phoneInstantVerficationUser
      //       );
      //       this.store.dispatch(new PhoneInstantVerficationUser());
      //       listener.remove();
      //     }
      //   }
      // );

      return FirebaseAuthentication.signInWithPhoneNumber({
        phoneNumber: phone_nbr,
      });
    } else {
      // web
      return firebase.auth().signInWithPhoneNumber(phone_nbr, appVerifier);
    }
  }

  // 驗證手機號碼的簡訊碼
  login_phone_verify_code(sms_code) {
    console.log('login_phone_verify_code', sms_code);

    if (this.platform.is('capacitor')) {
      console.log(
        'login_phone_verify_code this.verificationId',
        this.verificationId
      );
      return FirebaseAuthentication.signInWithPhoneNumber({
        verificationId: this.verificationId,
        verificationCode: sms_code,
      });
    } else {
      // web
      const credential = firebase.auth.PhoneAuthProvider.credential(
        this.verificationId,
        sms_code
      );
      return firebase.auth().signInWithCredential(credential);
    }
  }

  login_email(data) {
    const endpoint = 'login2';
    console.log(data);

    return new Promise(async (resolve, reject) => {
      const params: any = {};
      params.method = 'login2';
      params.data = data;

      try {
        const result: any = await this.elineApi.post(endpoint, params);
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('login2 error', error);
        reject(error);
      }
    });
  }

  // mommy3 oauth2
  oauth2(params: Oauth2Params): Promise<any> {
    console.log('oauth2');
    const endpoint = `v1/auth/oauth2`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('oauth2 error', error);
        reject(error);
      }
    });
  }

  // refreshToken(): Observable<any> {
  //   console.log('refreshToken');

  //   return new Observable((observer) => {
  //     // console.log('call currentUser()');
  //     of('token');
  //   });
  // }

  // // mommy3 refresh token, 必須使用 Observable, 因為 token.intecpetor 會使用
  // refreshToken(): Observable<any> {
  //   const endpoint = `v1/auth/refresh-token`;

  //   return from(this.storage.get('mommy_refresh_token')).pipe(
  //     switchMap((refresh_token) => {
  //       return this.api.post(endpoint, { refresh_token });
  //     })
  //   );
  // }

  refreshAccessToken(): Promise<any> {
    const endpoint = `v1/auth/refresh-token`;

    return new Promise(async (resolve, reject) => {
      try {
        const refresh_token = await this.storage.get('mommy_refresh_token');
        const params: any = {};
        params.refresh_token = refresh_token;

        const context = new HttpContext();
        context.set(IGNORE_TOKEN, true);

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params, { context }).toPromise()
        ));
        console.log('refreshAccessToken result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('refreshAccessToken error', error);
        reject(error);
      }
    });
  }

  // mommy3 check-token, 以處理帳號停權, 帳號刪除等應用情境
  checkToken(): Promise<any> {
    const endpoint = `v1/auth/check-token`;

    return new Promise(async (resolve, reject) => {
      try {
        const refresh_token = await this.storage.get('mommy_refresh_token');
        const params: any = {};
        params.refresh_token = refresh_token;

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        //TODO: 要判斷是否為 網路問題
        console.error('checkToken error', error);
        reject(error);
      }
    });
  }

  // mommy3 logout (all device)
  logout(): Promise<any> {
    const endpoint = `v1/auth/logout`;

    return new Promise(async (resolve, reject) => {
      try {
        const params: any = {};

        // 因為token有問題所以需要登出, 但token有問題時呼叫logout api會回傳 401, 導致loop, 因此加入 ignore_got401action 參數,
        const context = new HttpContext();
        context.set(IGNORE_GOT401ACTION, true);

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params, { context }).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('logout error', error);
        reject(error);
      }
    });
  }

  // mommy3 logout (this device)
  logout_device(): Promise<any> {
    const endpoint = `v1/auth/logout-device`;

    return new Promise(async (resolve, reject) => {
      try {
        const device_id = await this.storage.get('device_id');
        const params: any = {};
        params.device_id = device_id;

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('logout_device error', error);
        reject(error);
      }
    });
  }

  // mommy3 delete_account
  delete_account(): Promise<any> {
    const endpoint = `v1/auth/delete-account`;

    return new Promise(async (resolve, reject) => {
      try {
        const params: any = {};

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('delete_account error', error);
        reject(error);
      }
    });
  }

  // 更新會員 profile 資料 (有任務偵測的埋點)
  update_account(data: any) {
    console.log('update_account');
    let endpoint = 'v1/auth/update-account';

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.post(endpoint, data).toPromise()
        ));
        console.log('result', result);

        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('update_account error', error);
        reject(error);
      }
    });
  }
}
