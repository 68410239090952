import { VoteCommentInfo } from '@mommy/models/VoteCommentInfo.model';

export class InitLocalCacheMemberVoteComments {
  static readonly type = '[MemberVoteComment] InitLocalCacheMemberVoteComments';
}

export class LoadCacheMemberVoteComments {
  static readonly type = '[MemberVoteComment] LoadCacheMemberVoteComments';
}

export class RefreshMemberVoteComments {
  static readonly type = '[MemberVoteComment] RefreshMemberVoteComments';
}

export class AddMemberVoteComment {
  static readonly type = '[MemberVoteComment] AddMemberVoteComment';
  constructor(public payload: VoteCommentInfo) {}
}

export class ModifyMemberVoteComment {
  static readonly type = '[MemberVoteComment] ModifyMemberVoteComment';
  constructor(public payload: VoteCommentInfo) {}
}
