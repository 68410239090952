import { Injectable } from '@angular/core';
import { CodeTaxonomyInfo } from '@mommy/models/CodeTaxonomyInfo.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  SetAPPUIMode,
  SetUIPageExpertListListCount,
  SetUIPageExpertSearchExpert2,
  SetUIPageExpertSearchKeyword,
  SetUIPageExpertSearchListCount,
  SetUIPageExpertSearchResetAll,
  SetUIPageExpertSearchSection2,
  SetUIPageExpertSearchSubjectClasses,
  SetUIPageExpertSearchTopCategorys,
  SetUIPageHomeRecommendBabyPosts,
  SetUIPageHomeRecommendPostCount,
  SetUIPageHomeRecommendPregnancyPosts,
  SetUIPageHospitalListListCount,
  SetUIPageHospitalListZipCode,
  SetUIPageHospitalSearchCity,
  SetUIPageHospitalSearchDistrict,
  SetUIPageHospitalSearchHardwareEquipments,
  SetUIPageHospitalSearchKeyword,
  SetUIPageHospitalSearchLifeFunctions,
  SetUIPageHospitalSearchListCount,
  SetUIPageHospitalSearchMedicalCares,
  SetUIPageHospitalSearchResetAll,
  SetUIPageHospitalSearchSection2,
  SetUIPageHospitalSearchSubjectClasses,
  SetUIPageHospitalSearchTopCategorys,
  SetUIPageLatLon,
  SetUIParams,
  SetUIPediaCategoryTaxonomy,
} from './app-ui.actions';

export interface AppUIStateModel {
  params: any;

  page_expert_search_keyword: string;
  page_expert_search_list_count: number;
  page_hospital_search_keyword: string;
  page_hospital_search_list_count: number;
  page_expert_list_list_count: number;
  page_hospital_list_list_count: number;
  page_hospital_list_category_result_zipcode: string;

  // 醫院查詢頁分類過濾條件
  page_hospital_search_top_categorys: CodeTaxonomyInfo[];
  page_hospital_search_subject_classes: CodeTaxonomyInfo[];
  page_hospital_search_medical_cares: CodeTaxonomyInfo[];
  page_hospital_search_hardware_equipments: CodeTaxonomyInfo[];
  page_hospital_search_life_functions: CodeTaxonomyInfo[];
  page_hospital_search_section2: CodeTaxonomyInfo[];
  page_hospital_search_city: string;
  page_hospital_search_district: string[];

  // 專家查詢頁分類過濾條件
  page_expert_search_top_categorys: CodeTaxonomyInfo[];
  page_expert_search_subject_classes: CodeTaxonomyInfo[];
  page_expert_search_expert2: CodeTaxonomyInfo[];
  page_expert_search_section2: CodeTaxonomyInfo[];

  // 百科分類結果頁的分類
  page_pedia_category_taxonomy: CodeTaxonomyInfo;

  // 首頁 推薦的PGC post_ids
  page_home_recommend_baby_post_ids: number[]; //baby mode
  page_home_recommend_pregnancy_post_ids: number[]; //pregnancy mode
  page_home_recommend_post_count: number;

  // APP UI Mode
  app_ui_mode: 'pregnancy' | 'baby';

  user_lat: number;
  user_lon: number;
}

const defaultAppUIState = (): AppUIStateModel => {
  return {
    params: {},

    page_expert_search_keyword: '',
    page_expert_search_list_count: 10,
    page_hospital_search_keyword: '',
    page_hospital_search_list_count: 10,
    page_expert_list_list_count: 10,
    page_hospital_list_list_count: 10,
    page_hospital_list_category_result_zipcode: '',

    // 醫院查詢頁分類過濾條件
    page_hospital_search_top_categorys: [],
    page_hospital_search_subject_classes: [],
    page_hospital_search_medical_cares: [],
    page_hospital_search_hardware_equipments: [],
    page_hospital_search_life_functions: [],
    page_hospital_search_section2: [],
    page_hospital_search_city: '',
    page_hospital_search_district: [],

    // 專家查詢頁分類過濾條件
    page_expert_search_top_categorys: [],
    page_expert_search_subject_classes: [],
    page_expert_search_expert2: [],
    page_expert_search_section2: [],

    // 百科分類結果頁的分類
    page_pedia_category_taxonomy: null,

    // 首頁 推薦的PGC post_ids
    page_home_recommend_baby_post_ids: [],
    page_home_recommend_pregnancy_post_ids: [],
    page_home_recommend_post_count: 15,

    // APP UI Mode
    app_ui_mode: 'baby',

    user_lat: 0,
    user_lon: 0,
  };
};

@State<AppUIStateModel>({
  name: 'AppUIState',
  defaults: defaultAppUIState(),
})
@Injectable()
export class AppUIState {
  constructor() {}

  @Selector()
  static params(state: AppUIStateModel) {
    return state.params;
  }

  @Selector()
  static page_expert_search_keyword(state: AppUIStateModel) {
    return state.page_expert_search_keyword;
  }

  @Selector()
  static page_expert_search_list_count(state: AppUIStateModel) {
    return state.page_expert_search_list_count;
  }

  @Selector()
  static page_hospital_search_keyword(state: AppUIStateModel) {
    return state.page_hospital_search_keyword;
  }

  @Selector()
  static page_hospital_search_list_count(state: AppUIStateModel) {
    return state.page_hospital_search_list_count;
  }

  @Selector()
  static page_expert_list_list_count(state: AppUIStateModel) {
    return state.page_expert_list_list_count;
  }

  @Selector()
  static page_hospital_list_list_count(state: AppUIStateModel) {
    return state.page_hospital_list_list_count;
  }

  @Selector()
  static page_hospital_list_category_result_zipcode(state: AppUIStateModel) {
    return state.page_hospital_list_category_result_zipcode;
  }

  //#region   === 醫院查詢頁分類過濾條件 ===
  @Selector()
  static page_hospital_search_top_categorys(state: AppUIStateModel) {
    return state.page_hospital_search_top_categorys;
  }

  @Selector()
  static page_hospital_search_subject_classes(state: AppUIStateModel) {
    return state.page_hospital_search_subject_classes;
  }

  @Selector()
  static page_hospital_search_medical_cares(state: AppUIStateModel) {
    return state.page_hospital_search_medical_cares;
  }

  @Selector()
  static page_hospital_search_hardware_equipments(state: AppUIStateModel) {
    return state.page_hospital_search_hardware_equipments;
  }

  @Selector()
  static page_hospital_search_life_functions(state: AppUIStateModel) {
    return state.page_hospital_search_life_functions;
  }

  @Selector()
  static page_hospital_search_section2(state: AppUIStateModel) {
    return state.page_hospital_search_section2;
  }

  @Selector()
  static page_hospital_search_city(state: AppUIStateModel) {
    return state.page_hospital_search_city;
  }

  @Selector()
  static page_hospital_search_district(state: AppUIStateModel) {
    return state.page_hospital_search_district;
  }

  @Selector()
  static page_pedia_category_taxonomy(state: AppUIStateModel) {
    return state.page_pedia_category_taxonomy;
  }

  // 回傳是否有設定醫院篩選條件
  @Selector()
  static page_hospital_search_has_conditions(state: AppUIStateModel) {
    return (
      state.page_hospital_search_top_categorys.length > 0 ||
      state.page_hospital_search_subject_classes.length > 0 ||
      state.page_hospital_search_medical_cares.length > 0 ||
      state.page_hospital_search_hardware_equipments.length > 0 ||
      state.page_hospital_search_life_functions.length > 0 ||
      state.page_hospital_search_section2.length > 0
    );
  }
  //#endregion === 醫院查詢頁分類過濾條件 ===

  //#region   === 專家查詢頁分類過濾條件 ===
  @Selector()
  static page_expert_search_top_categorys(state: AppUIStateModel) {
    return state.page_expert_search_top_categorys;
  }

  @Selector()
  static page_expert_search_subject_classes(state: AppUIStateModel) {
    return state.page_expert_search_subject_classes;
  }

  @Selector()
  static page_expert_search_expert2(state: AppUIStateModel) {
    return state.page_expert_search_expert2;
  }

  @Selector()
  static page_expert_search_section2(state: AppUIStateModel) {
    return state.page_expert_search_section2;
  }

  // 回傳是否有設定醫院篩選條件
  @Selector()
  static page_expert_search_has_conditions(state: AppUIStateModel) {
    return (
      state.page_expert_search_top_categorys.length > 0 ||
      state.page_expert_search_subject_classes.length > 0 ||
      state.page_expert_search_expert2.length > 0 ||
      state.page_expert_search_section2.length > 0
    );
  }
  //#endregion === 專家查詢頁分類過濾條件 ===

  @Selector()
  static user_lat(state: AppUIStateModel) {
    return state.user_lat;
  }

  @Selector()
  static user_lon(state: AppUIStateModel) {
    return state.user_lon;
  }

  @Selector()
  static page_home_recommend_baby_post_ids(state: AppUIStateModel) {
    return state.page_home_recommend_baby_post_ids;
  }

  @Selector()
  static page_home_recommend_pregnancy_post_ids(state: AppUIStateModel) {
    return state.page_home_recommend_pregnancy_post_ids;
  }

  @Selector()
  static page_home_recommend_post_count(state: AppUIStateModel) {
    return state.page_home_recommend_post_count;
  }

  @Selector()
  static app_ui_mode(state: AppUIStateModel) {
    return state.app_ui_mode;
  }

  //#region ======================== Action Handlers ========================
  @Action(SetUIPageExpertSearchKeyword)
  SetUIPageExpertSearchKeyword(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageExpertSearchKeyword
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_expert_search_keyword: action.keyword,
    });
  }

  @Action(SetUIPageExpertSearchListCount)
  SetUIPageExpertSearchListCount(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageExpertSearchListCount
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_expert_search_list_count: action.count,
    });
  }

  @Action(SetUIPageHospitalSearchKeyword)
  SetUIPageHospitalSearchKeyword(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageHospitalSearchKeyword
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_hospital_search_keyword: action.keyword,
    });
  }

  @Action(SetUIPageHospitalSearchListCount)
  SetUIPageHospitalSearchListCount(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageHospitalSearchListCount
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_hospital_search_list_count: action.count,
    });
  }

  @Action(SetUIPageExpertListListCount)
  SetUIPageExpertListListCount(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageExpertListListCount
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_expert_list_list_count: action.count,
    });
  }

  @Action(SetUIPageHospitalListListCount)
  SetUIPageHospitalListListCount(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageHospitalListListCount
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_hospital_list_list_count: action.count,
    });
  }

  @Action(SetUIPageHospitalListZipCode)
  SetUIPageHospitalListZipCode(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageHospitalListZipCode
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_hospital_list_category_result_zipcode: action.zipcode,
    });
  }

  //#region  === 醫院查詢頁分類過濾條件 ===
  @Action(SetUIPageHospitalSearchTopCategorys)
  SetUIPageHospitalSearchTopCategorys(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageHospitalSearchTopCategorys
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_hospital_search_top_categorys: action.top_categorys,
    });
  }

  @Action(SetUIPageHospitalSearchSubjectClasses)
  SetUIPageHospitalSearchSubjectClasses(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageHospitalSearchSubjectClasses
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_hospital_search_subject_classes: action.subject_classes,
    });
  }

  @Action(SetUIPageHospitalSearchMedicalCares)
  SetUIPageHospitalSearchMedicalCares(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageHospitalSearchMedicalCares
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_hospital_search_medical_cares: action.medical_cares,
    });
  }

  @Action(SetUIPageHospitalSearchHardwareEquipments)
  SetUIPageHospitalSearchHardwareEquipments(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageHospitalSearchHardwareEquipments
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_hospital_search_hardware_equipments: action.hardware_equipments,
    });
  }

  @Action(SetUIPageHospitalSearchLifeFunctions)
  SetUIPageHospitalSearchLifeFunctions(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageHospitalSearchLifeFunctions
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_hospital_search_life_functions: action.life_functions,
    });
  }

  @Action(SetUIPageHospitalSearchSection2)
  SetUIPageHospitalSearchSection2(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageHospitalSearchSection2
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_hospital_search_section2: action.section2,
    });
  }

  @Action(SetUIPageHospitalSearchCity)
  SetUIPageHospitalSearchCity(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageHospitalSearchCity
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_hospital_search_city: action.city,
    });
  }

  @Action(SetUIPageHospitalSearchDistrict)
  SetUIPageHospitalSearchDistrict(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageHospitalSearchDistrict
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_hospital_search_district: action.district,
    });
  }

  @Action(SetUIPageHospitalSearchResetAll)
  SetUIPageHospitalSearchResetAll(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageHospitalSearchResetAll
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_hospital_search_top_categorys: [],
      page_hospital_search_subject_classes: [],
      page_hospital_search_medical_cares: [],
      page_hospital_search_hardware_equipments: [],
      page_hospital_search_life_functions: [],
      page_hospital_search_section2: [],
      page_hospital_search_city: '',
      page_hospital_search_district: [],
      page_hospital_search_keyword: '',
    });
  }

  //#endregion === 醫院查詢頁分類過濾條件 ===

  //#region === 專家查詢頁分類過濾條件 ===
  @Action(SetUIPageExpertSearchTopCategorys)
  SetUIPageExpertSearchTopCategorys(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageExpertSearchTopCategorys
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_expert_search_top_categorys: action.top_categorys,
    });
  }

  @Action(SetUIPageExpertSearchSubjectClasses)
  SetUIPageExpertSearchSubjectClasses(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageExpertSearchSubjectClasses
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_expert_search_subject_classes: action.subject_classes,
    });
  }

  @Action(SetUIPageExpertSearchExpert2)
  SetUIPageExpertSearchExpert2(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageExpertSearchExpert2
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_expert_search_expert2: action.expert2,
    });
  }

  @Action(SetUIPageExpertSearchSection2)
  SetUIPageExpertSearchSection2(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageExpertSearchSection2
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_expert_search_section2: action.section2,
    });
  }

  @Action(SetUIPageExpertSearchResetAll)
  SetUIPageExpertSearchResetAll(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageExpertSearchResetAll
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_expert_search_top_categorys: [],
      page_expert_search_subject_classes: [],
      page_expert_search_expert2: [],
      page_expert_search_section2: [],
      page_expert_search_keyword: '',
    });
  }
  //#endregion === 專家查詢頁分類過濾條件 ===

  @Action(SetUIPageLatLon)
  SetUIPageLatLon(ctx: StateContext<AppUIStateModel>, action: SetUIPageLatLon) {
    const state = ctx.getState();
    ctx.patchState({
      user_lat: action.lat,
      user_lon: action.lon,
    });
  }

  @Action(SetUIParams)
  SetUIParams(ctx: StateContext<AppUIStateModel>, action: SetUIParams) {
    const state = ctx.getState();
    ctx.patchState({
      params: action.params,
    });
  }

  @Action(SetUIPediaCategoryTaxonomy)
  SetUIPediaCategoryTaxonomy(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPediaCategoryTaxonomy
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_pedia_category_taxonomy: action.taxonomy,
    });
  }

  @Action(SetUIPageHomeRecommendBabyPosts)
  SetUIPageHomeRecommendBabyPosts(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageHomeRecommendBabyPosts
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_home_recommend_baby_post_ids: action.post_ids,
    });
  }

  @Action(SetUIPageHomeRecommendPregnancyPosts)
  SetUIPageHomeRecommendPregnancyPosts(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageHomeRecommendPregnancyPosts
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_home_recommend_pregnancy_post_ids: action.post_ids,
    });
  }

  @Action(SetUIPageHomeRecommendPostCount)
  SetUIPageHomeRecommendPostCount(
    ctx: StateContext<AppUIStateModel>,
    action: SetUIPageHomeRecommendPostCount
  ) {
    const state = ctx.getState();
    ctx.patchState({
      page_home_recommend_post_count: action.post_count,
    });
  }

  @Action(SetAPPUIMode)
  SetAPPUIMode(ctx: StateContext<AppUIStateModel>, action: SetAPPUIMode) {
    const state = ctx.getState();
    ctx.patchState({
      app_ui_mode: action.mode,
    });
  }

  //#endregion ======================== Action Handlers ========================
}
