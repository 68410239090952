import { Injectable } from '@angular/core';
import { PostInfo } from '@mommy/models/PostInfo.model';
import { MemberPostLikeService } from '@mommy/services/member/member-post-like.service';
import { StorageService } from '@mommy/services/storage.service';
import { UserService } from '@mommy/services/user/user.service';
import { UtilService } from '@mommy/services/util.service';
import { Action, State, StateContext, Store } from '@ngxs/store';
import * as _ from 'lodash';
import { PostState } from '../post/post.state';
import { UpdateMommyUser } from '../user/user.actions';
import { UserState } from '../user/user.state';
import {
  AddMemberPostLike,
  GetMemberPostLikes,
  RemoveMemberPostLike,
} from './member-post-like.actions';

export interface MemberPostLikeStateModel {
  loading: boolean;
  post_likes: PostInfo[];
  hasCache: boolean;
}

const defaultMemberPostLikeState = (): MemberPostLikeStateModel => {
  return {
    loading: false,
    post_likes: [],
    hasCache: false,
  };
};

@State<MemberPostLikeStateModel>({
  name: 'MemberPostLikeState',
  defaults: defaultMemberPostLikeState(),
})
@Injectable()
export class MemberPostLikeState {
  constructor(
    private storage: StorageService,
    private memberPostLikeSvc: MemberPostLikeService,
    private userService: UserService,
    private store: Store,
    private util: UtilService
  ) {}

  @Action(GetMemberPostLikes)
  async GetMemberPostLikes(ctx: StateContext<MemberPostLikeStateModel>) {
    console.log('[Action] GetMemberPostLikes');

    try {
      const data: any = await this.userService.getMemberPostLikes();
      console.log('GetMemberPostLikes success', data);

      ctx.patchState({
        post_likes: data.post_likes,
      });

      // 更新 user state
      this.store.dispatch(new UpdateMommyUser(data));
    } catch (error) {
      console.warn('GetMemberPostLikes error', error);
      throw error;
    }
  }

  @Action(AddMemberPostLike)
  async AddMemberPostLike(
    ctx: StateContext<MemberPostLikeStateModel>,
    { post_id }
  ) {
    console.log('[Action] AddMemberPostLike');

    // 新更新 mommyuser state, 再呼叫 api 更新至 server
    const mommy_user = _.cloneDeep(
      this.store.selectSnapshot(UserState.mommy_user)
    );
    const post_likes = mommy_user.post_likes;
    const post = _.find(this.store.selectSnapshot(PostState.posts), {
      post_id: post_id,
    });
    post_likes.push({ post_id, post_info: post });
    mommy_user.post_likes = post_likes;
    console.log('mommy_user', mommy_user);

    // 更新 user state
    this.store.dispatch(new UpdateMommyUser(mommy_user));

    try {
      const result = await this.memberPostLikeSvc.add(post_id);
      console.log('AddMemberPostLike success', result);
      this.store.dispatch(new GetMemberPostLikes());
    } catch (error) {
      console.error('AddMemberPostLike error', error);
      throw error;
    }
  }

  @Action(RemoveMemberPostLike)
  async RemoveMemberPostLike(
    ctx: StateContext<MemberPostLikeStateModel>,
    { post_id }
  ) {
    console.log('[Action] RemoveMemberPostLike');

    // 新更新 mommyuser state, 再呼叫 api 更新至 server
    const mommy_user = _.cloneDeep(
      this.store.selectSnapshot(UserState.mommy_user)
    );
    let post_likes = mommy_user.post_likes;
    const post = _.find(this.store.selectSnapshot(PostState.posts), {
      post_id: post_id,
    });
    post_likes = _.filter(post_likes, (item) => item.post_id !== post_id);
    mommy_user.post_likes = post_likes;
    console.log('mommy_user', mommy_user);

    // 更新 user state
    this.store.dispatch(new UpdateMommyUser(mommy_user));

    try {
      const result = await this.memberPostLikeSvc.remove(post_id);
      console.log('RemoveMemberPostLike success', result);
      this.store.dispatch(new GetMemberPostLikes());
    } catch (error) {
      console.error('RemoveMemberPostLike error', error);
      throw error;
    }
  }
}
