import { Injectable } from '@angular/core';
import { MemberActionLogService } from '@mommy/services/member/member-action-log.service';
import { Action, State, StateContext, Store } from '@ngxs/store';
import {
  MemberHospitalElineClick,
  MemberPostClick,
  MemberSignIn,
  MemberWatchVideoDone,
} from './member-action-log.actions';

export interface MemberActionLogStateModel {
  loading: boolean;
  hasCache: boolean;
}

const defaultMemberActionLogState = (): MemberActionLogStateModel => {
  return {
    loading: false,
    hasCache: false,
  };
};

@State<MemberActionLogStateModel>({
  name: 'MemberActionLogState',
  defaults: defaultMemberActionLogState(),
})
@Injectable()
export class MemberActionLogState {
  constructor(
    private memberActionLogService: MemberActionLogService,
    private store: Store
  ) {}

  @Action(MemberSignIn)
  async MemberSignIn(ctx: StateContext<MemberActionLogStateModel>) {
    console.log('[Action] MemberSignIn');

    try {
      const result = await this.memberActionLogService.signIn();
      console.log('MemberSignIn success', result);
    } catch (error) {
      console.error('memberActionLogService error', error);
      throw error;
    }
  }

  @Action(MemberWatchVideoDone)
  async MemberWatchVideoDone(
    ctx: StateContext<MemberActionLogStateModel>,
    { post_id, member_pregnancy_tag_taxonomy_id }: MemberWatchVideoDone
  ) {
    console.log('[Action] MemberWatchVideoDone');

    try {
      const result = await this.memberActionLogService.videoDone(
        post_id,
        member_pregnancy_tag_taxonomy_id
      );
      console.log('videoDone success', result);
    } catch (error) {
      console.error('videoDone error', error);
      throw error;
    }
  }

  @Action(MemberHospitalElineClick)
  async MemberHospitalElineClick(
    ctx: StateContext<MemberActionLogStateModel>,
    { hospital_id }: MemberHospitalElineClick
  ) {
    console.log('[Action] MemberHospitalElineClick');

    try {
      const result = await this.memberActionLogService.hospitalElineClick(
        hospital_id
      );
      console.log('hospitalElineClick success', result);
    } catch (error) {
      console.error('hospitalElineClick error', error);
      throw error;
    }
  }

  @Action(MemberPostClick)
  async MemberPostClick(
    ctx: StateContext<MemberActionLogStateModel>,
    { post_id }: MemberPostClick
  ) {
    console.log('[Action] MemberPostClick');

    try {
      const result = await this.memberActionLogService.postClick(post_id);
      console.log('postClick success', result);
    } catch (error) {
      console.error('postClick error', error);
      throw error;
    }
  }
}
