import { Component, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LineLogin } from '@awesome-cordova-plugins/line-login/ngx';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Network } from '@capacitor/network';
import { SplashScreen } from '@capacitor/splash-screen';
import { NavController, Platform, ToastController } from '@ionic/angular';
import { environment } from '@mommy/environments/environment';
import { MemberActionLogService } from '@mommy/services/member/member-action-log.service';
import { StateUtilsService } from '@mommy/services/state-utils.service';
import { StorageService } from '@mommy/services/storage.service';
import { UtilService } from '@mommy/services/util.service';
import { AuthState } from '@mommy/state/auth/auth.state';
import { UserState } from '@mommy/state/user/user.state';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import * as fb from 'firebase/compat/app';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { debounceTime, take, throttleTime } from 'rxjs/operators';
import { CapUtilService } from './services/caputil.service';
import { ChatService } from './services/eline/chat-service';
import {
  CheckChannelParams,
  MyChatService,
} from './services/eline/mychat-service';
import { NetworkService } from './services/network.service';
import { PushNotificationService } from './services/push-notification.service';
import {
  CheckToken,
  CheckTokenSuccess,
  DeleteAccount,
  Got401,
  Got403UserIsPending,
  Got404UserNotFound,
  LoadCacheAuth,
  LoginSuccess,
  Logout,
  LogoutForDeleteAccount,
  LogOutSuccess,
} from './state/auth/auth.actions';
import {
  InvokeChannelChanged,
  LoadChannels,
  UpdateChannels,
} from './state/channel/channel.actions';
import {
  ClearECOrderState,
  GetECCustomerCoinInfoFromServer,
  GetECOrderCartFromServer,
} from './state/ec/ec-order/ec-order.actions';
import { MemberSignIn } from './state/member-action-log/member-action-log.actions';
import { ClearUser, RefreshElineUser } from './state/user/user.actions';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @Select(UserState.eline_user) eline_user$;
  @Select(AuthState.elineUserAuthenticateChange) isElineAuthenticated$;
  @Select(AuthState.isMommyAuthenticated) isMommyAuthenticated$;

  version = ''; //app版本編號
  currentPath = ''; // 紀錄目前的url path

  channel_subscription: Subscription;
  max_firebase_sync_count = 9999; //避免無窮迴圈sync data from firebase,設定一個最大值
  fbapp: fb.default.app.App;

  constructor(
    public navCtrl: NavController,
    private platform: Platform,

    private storage: StorageService,
    private store: Store,
    private router: Router,
    public toastCtrl: ToastController,
    private stateUtils: StateUtilsService,
    private util: UtilService,
    private caputil: CapUtilService,
    private chatsvc: ChatService,
    private actions$: Actions,
    private memberActionLogService: MemberActionLogService,
    private networkService: NetworkService,
    private pushService: PushNotificationService,
    private lineLogin: LineLogin,
    private zone: NgZone,
    private myChatService: MyChatService
  ) {
    //this.showSplash(); // 目前都透過 capacitor.config.json 來設定 splash screen, 這部分先註解掉
    this.initializeApp();
    this.version = environment.WWW_VERSION;

    this.router.events.subscribe((e) => {
      //console.log('router.events', e);
      if (e instanceof NavigationEnd) {
        console.log('router.events url', e.url);
        this.currentPath = e.url;

        // // 如果 currentPath 是 /pgc-post/{id}, 則用regex 取出id值
        // if (this.currentPath.startsWith('/pgc-post/')) {
        //   const post_id = this.currentPath.match(/\/pgc-post\/(\d+)/)[1];
        //   console.log('post_id', post_id);
        //   const posts = this.store.selectSnapshot(PostState.posts);
        //   // console.log('posts', posts);
        //   const post = _.find(this.store.selectSnapshot(PostState.posts), {
        //     post_id: parseInt(post_id, 10),
        //   });
        //   // const post = this.stateUtils.getPostById(parseInt(post_id, 10));
        //   console.log('post', post);
        //   this.store.dispatch(new SetSelectedPost(post));
        // }
      }
    });
  }

  async showSplash() {
    console.log('showSplash');

    await this.platform.ready();

    const lottie = (window as any).lottie;

    // ios 會透過此方式來顯示 lottie splash, 故 config 內的 LottieAnimationLocation 設為 "" (空字串)
    // android 會透過 default config 的方式來顯示 lottie splash
    if (this.platform.is('ios') && lottie) {
      console.log('do showSplash');
      await lottie.splashscreen.hide();
      await lottie.splashscreen.show(
        'public/assets/lottie-splash.json',
        false,
        1000,
        1000
      );
    }
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      console.log('appUrlOpen', event);
      this.zone.run(async () => {
        // Example url: https://app.dev.mommycareyou.com/seo/pgc-post/1
        // slug = /#pgc-post/1

        // 先判斷是否為 deeplink url, 如果是才處理, 否則不處理
        if (event.url.startsWith(environment.APP_DEEPLINK_DOMAIN)) {
          const slug = event.url.split(environment.APP_DEEPLINK_DOMAIN).pop();
          if (slug) {
            console.log('slug', slug);
            await this.navCtrl.navigateRoot('/tabs/home');
            this.navCtrl.navigateForward(slug.replace('/seo', ''));
          }
          // If no match, do nothing - let regular routing
          // logic take over
        } else {
          console.log('appUrlOpen, not deeplink url, open with inappbrower');
          this.caputil.iab_browser(event.url);
        }
      });
    });

    this.platform.ready().then(async () => {
      this.lineLogin.initialize({
        channel_id: environment.line_client_id,
      });
      console.log('lineLogin.initialize done');

      await SplashScreen.hide();
      console.log('platform ready: SplashScreen.hide()');

      // 初始化app資料
      this.stateUtils.initAppState();
      this.init_channels();
      this.auth_actions_monitor();
      this.checkUserStatus();

      console.log('platform ready: networkService.init_service()');
      this.networkService.init_service();

      // 沒登入也要init firebasepush_plugin, 因為門號auth需要使用 push通知
      this.pushService.init_push_notification();

      // app啟動後, 預設將 video 聲音關閉
      localStorage.setItem('player_muted', 'true');

      // this.statusBar.styleDefault();
      // //this.splashScreen.hide();
      // // 15sec一定關閉splashscreen
      // setTimeout(() => {
      //   SplashScreen.hide();
      // }, 15000);
      // // 紀錄 environment
      // let proj_env = await this.storage.get('proj_env');
      // if (!proj_env) {
      //   await this.storage.set('proj_env', environment.firebase.projectId);
      // }
      // this.init_network_monitor();
      // App.addListener('appUrlOpen', data => {
      //   console.log('App opened with URL:', data);
      //   this.zone.run(async () => {
      //     if (this.authService.isLogined) {
      //       console.log('已登入,ignore..');
      //     } else {
      //       const waitDynamicLink = await this.storage.get('WAIT_DYNAMIC_LINK');
      //       console.log('waitDynamicLink', waitDynamicLink);
      //       if (waitDynamicLink) {
      //         this.processSigninByEmailLink(data.url);
      //       }
      //     }
      //   });
      // });
      // this.authService.initAuthStateMonitor().then(resp => {
      //   if (resp) {
      //     // 已經login
      //     console.log('已經login');
      //     this.gotoInitPage();
      //     setTimeout(() => {
      //       SplashScreen.hide();
      //     }, 3000);
      //   } else {
      //     // 尚未login
      //     console.log('to welcome...');
      //     this.navCtrl.navigateRoot('/welcome', { animated: true, animationDirection: 'forward' });
      //     setTimeout(() => {
      //       SplashScreen.hide();
      //     }, 1000);
      //   }
      //   // 監控狀態變更
      //   this.authService.isAuthenticated.subscribe(val => {
      //     console.log('authService.isAuthenticated val', val);
      //     if (val) {
      //       console.log('auth狀態變成 authenticated.');
      //       this.firebaseUtil.setUserId(this.authService.userId);
      //     } else {
      //       console.log('auth狀態變成 not authenticated.');
      //       console.log('this.authService.loginProcess', this.authService.loginProcess);
      //       if (this.authService.loginProcess) {
      //         console.log('do nothing');
      //       } else {
      //         setTimeout(() => {
      //           console.log('to welcome...');
      //           this.navCtrl.navigateRoot('/welcome', { animated: true, animationDirection: 'forward' });
      //         }, 100);
      //       }
      //     }
      //   });
      // });
    });

    this.platform.resume.subscribe(async () => {
      console.log('Platform Resume...');
      this.checkUserStatus();

      console.log('platform resume: networkService.init_service()');
      this.networkService.init_service();
      this.pushService.init_push_notification();
    });
  }

  auth_actions_monitor() {
    // api 返回 401 狀態, 執行登出動作, 在提示用戶登入訊息
    this.actions$
      .pipe(ofActionDispatched(Got401))
      .pipe(throttleTime(10000))
      .subscribe(async () => {
        console.log('got Got401 action event!');
        this.store.dispatch([new Logout(), new ClearUser()]);
        const result = await this.util.showYconfirm(
          '帳號登入權杖已失效',
          '帳號登入權杖已失效，請重新登入',
          'mommy-alert-class'
        );
      });

    // api 返回 403 and UserIsPending 狀態, 執行登出動作, 在提示用戶訊息
    this.actions$
      .pipe(ofActionDispatched(Got403UserIsPending))
      .pipe(throttleTime(10000))
      .subscribe(async () => {
        console.log('got Got403UserIsPending action event!');
        this.store.dispatch([new Logout(), new ClearUser()]);
        const result = await this.util.showYconfirm(
          '帳號異常',
          '由於您的帳號異常，系統已自動登出',
          'mommy-alert-class'
        );
      });

    // api 返回 404 and user not found 狀態, 執行登出動作, 在提示用戶訊息
    this.actions$
      .pipe(ofActionDispatched(Got404UserNotFound))
      .pipe(throttleTime(10000))
      .subscribe(async () => {
        console.log('got Got404UserNotFound action event!');
        this.store.dispatch([new Logout(), new ClearUser()]);
        const result = await this.util.showYconfirm(
          '帳號不存在',
          '帳號不存在，請重新登入',
          'mommy-alert-class'
        );
      });

    // 用戶執行 delete account, 執行登出動作
    this.actions$
      .pipe(ofActionDispatched(DeleteAccount))
      .pipe(throttleTime(1000))
      .subscribe(async () => {
        console.log('got DeleteAccount action event!');
        this.store
          .dispatch([new LogoutForDeleteAccount(), new ClearUser()])
          .toPromise();
        console.log('LogoutForDeleteAccount, ClearUser done!');
      });

    // 用戶登入成功/CheckToken成功事件,
    // 執行 (1) 簽到動作, (2) 取得咪幣 (3) 取得EC購物車 (4) 設定孕關懷推播 (5) firebase analytics setUserId
    this.actions$
      .pipe(ofActionDispatched(LoginSuccess, CheckTokenSuccess))
      .subscribe(async (value) => {
        console.log('got LoginSuccess action event!', value);
        this.store.dispatch(new MemberSignIn());
        this.store.dispatch(new GetECCustomerCoinInfoFromServer());
        this.store.dispatch(new GetECOrderCartFromServer());
        const elineUser = this.stateUtils.getElineUser();
        this.pushService.set_firebase_notify(elineUser);
        this.caputil.setUserId(elineUser.user_id);
      });

    // 用戶登出成功事件,
    // 執行 (1) EC購物車/訂單..等state 清空 (2) 設定孕關懷推播->remove
    this.actions$
      .pipe(ofActionDispatched(LogOutSuccess))
      .subscribe(async (value) => {
        console.log('got LogOutSuccess action event!', value);
        this.store.dispatch(new ClearECOrderState());
        await this.pushService.fcm_deleteInstance();
        // 刪除後需要重新 init, 以利收到活動推播
        this.pushService.init_push_notification();
      });

    // 用戶 孕關懷 channel 異動事件,
    this.actions$
      .pipe(ofActionDispatched(InvokeChannelChanged))
      .subscribe(async () => {
        console.log('got InvokeChannelChanged action event!');
        this.store
          .dispatch(new RefreshElineUser())
          .toPromise()
          .then(async (resp) => {
            console.log('重新init_channels');
            const elineUser = this.stateUtils.getElineUser();
            this.init_channels();
            this.pushService.set_firebase_notify(elineUser);
          });
      });
  }

  init_channels() {
    console.log('init_channels @app.component');

    // 訂閱 auth_state. eline_user$, 有登入的情況下, 才載入 channel 資料
    this.isElineAuthenticated$.subscribe(async (value) => {
      console.log('isElineAuthenticated$', value);
      if (value) {
        // 已經登入, 先載入 local cache 的 channel 資料
        this.store
          .dispatch(new LoadChannels())
          .toPromise()
          .then(() => {
            console.log('load local cache channels success');
            // 計算 inquire badge count action => 在 tabs.ts 中訂閱計算
          });

        this.eline_user$.subscribe(async (eline_user) => {
          console.log('this.eline_user$', eline_user);

          if (eline_user) {
            // for mommycare需求：預設加入媽咪學苑的team
            try {
              await this.join_default_team(eline_user);
            } catch (error) {
              console.error('join_default_team error, ignore', error);
            }

            // 再載入 firebase 的 channel 資料
            if (this.channel_subscription) {
              this.channel_subscription.unsubscribe();
            }

            this.fbapp = fb.default.app(); // get default app

            this.channel_subscription = this.chatsvc
              .getChannelList_by_user_id_and_family_channels(
                this.fbapp,
                eline_user.user_id,
                eline_user.family_channels
              )
              .pipe(take(this.max_firebase_sync_count))
              .pipe(debounceTime(2000)) // 若有大量快速的 channel update, 應該可以有較好的體驗
              .subscribe((data_channels) => {
                console.log('data_channels==>', data_channels);

                data_channels.map((elem: any) => {
                  elem.displayname = elem.tdn; //team_displayname
                  elem.avatar = elem.ta; //team_avatar

                  //判斷是否為家人的channel
                  if (eline_user.family_without_self.includes(elem.uid)) {
                    elem.family_flag = true;
                  }

                  // 如果unread_cnt>0,則嘗試check是否需要sync data from firebase to local

                  // performance考量,暫時先不處理,再觀察看看影響情況
                  // if(elem.unread_cnt > 0 ){
                  //   this.chatsvc.sync_channel_data(this.fbapp,elem,this.user);
                  // }

                  // date format
                  elem.dateformat = this.util.mydateformat(elem.lpat); //lastpostat
                });

                const channels = _.orderBy(data_channels, 'lpat', 'desc'); //lastpostat
                console.log(channels);
                this.store
                  .dispatch(new UpdateChannels(channels))
                  .toPromise()
                  .then((resp) => {
                    // this.store.dispatch(new InquireBadgeCaculatePatient());
                  });
                // this.saveChannels(channels);

                /* 發送至tabs頁面去顯示badge count */
                // this.store.dispatch(new UpdateInquireUnReadCount(total_unread_count));
                // this.events.publish('total_unread_count', total_unread_count);
                // localStorage.setItem('total_unread_count', JSON.stringify(total_unread_count)); //切換頁面時不會有之前的event,所以要從loclstorage取得,未來可以考慮從ngstore來處理
                // this.util.saveLocalStorage();
              });
          } else {
            console.log('eline_user is null, unsubscribe channels');
            this.channel_subscription.unsubscribe();
          }
        });
      } else {
        // 尚未登入, 不載入 channel 資料
        console.log('eline_user$ not login');
      }
    });
  }

  // 檢查使用者目前的狀態(ex: 正常/停用/已刪帳號..,)
  async checkUserStatus() {
    console.log('checkUserStatus @app.component');

    await this.store.dispatch(new LoadCacheAuth()).toPromise();
    const isMommyAuthenticated = this.stateUtils.isMommyAuthenticated();

    if (isMommyAuthenticated) {
      try {
        await this.store.dispatch(new CheckToken()).toPromise();
        console.log('checkUserStatus success');

        // 觸發 CheckToken 成功事件 -> 簽到,取得EC購物車...etc
        this.store.dispatch(new CheckTokenSuccess()).toPromise();
      } catch (error) {
        console.error('CheckToken error', error);
      }
    }
  }

  async init_network_monitor() {
    await Network.removeAllListeners();
    Network.addListener('networkStatusChange', (status) => {
      console.log('Network status changed', status);
      if (status.connected) {
        console.log('network connected!');
        this.util.showToast('你的網路已恢復');
      } else {
        console.log('network disconnected!');
        this.util.showToast('你的網路是離線狀態');
      }
    });
  }

  // for mommycare需求：預設加入媽咪學苑的team
  join_default_team(eline_user) {
    console.warn('join_default_team');

    return new Promise(async (resolve, reject) => {
      try {
        const join_default_team_flag = await this.storage.get(
          'join_default_team_flag'
        );
        console.log('join_default_team_flag', join_default_team_flag);

        if (!join_default_team_flag) {
          const default_hospital = this.stateUtils.getElineHospital(
            environment.DEFAULT_TEAM_ID
          );
          const params: CheckChannelParams = {};
          params.selectedHospital = default_hospital;
          params.user = eline_user;
          params.createChannelIfNotExist = true;
          params.sendWelcomeMessage = true; // for 加入預設團隊時才使用

          let channel;
          try {
            channel = await this.myChatService.checkChannelInstance(params);
            console.log('check_result', channel);
            await this.storage.set('join_default_team_flag', true);
            resolve('OK');
          } catch (error) {
            console.error('checkChannelInstance error', error);
            reject('ERROR');
          }
        } else {
          console.log('已經處理過了');
          resolve('OK');
        }
      } catch (error) {
        console.log('join_default_team error, ignore', error);
        reject('ERROR');
      }
    });
  }
}
