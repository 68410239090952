import { MemberBabyInfo } from '@mommy/models/MemberBabyInfo.model';

export class GetMemberBabies {
  static readonly type = '[MemberBaby] GetMemberBabies';
}

export class AddMemberBaby {
  static readonly type = '[MemberBaby] AddMemberBaby';
  constructor(public payload: MemberBabyInfo) {}
}

export class ModifyMemberBaby {
  static readonly type = '[MemberBaby] ModifyMemberBaby';
  constructor(public payload: MemberBabyInfo) {}
}

export class RemoveMemberBaby {
  static readonly type = '[MemberBaby] RemoveMemberBaby';
  constructor(public member_baby_id: number) {}
}
