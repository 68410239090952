import { PostCommentInfo } from '@mommy/models/PostCommentInfo.model';

export class InitLocalCacheMemberPostComments {
  static readonly type = '[MemberPostComment] InitLocalCacheMemberPostComments';
}

export class LoadCacheMemberPostComments {
  static readonly type = '[MemberPostComment] LoadCacheMemberPostComments';
}

export class RefreshMemberPostComments {
  static readonly type = '[MemberPostComment] RefreshMemberPostComments';
}

export class AddMemberPostComment {
  static readonly type = '[MemberPostComment] AddMemberPostComment';
  constructor(public payload: PostCommentInfo) {}
}

export class ModifyMemberPostComment {
  static readonly type = '[MemberPostComment] ModifyMemberPostComment';
  constructor(public payload: PostCommentInfo) {}
}
