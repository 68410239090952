import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-get-coin-modal2',
  templateUrl: './get-coin-modal2.page.html',
  styleUrls: ['./get-coin-modal2.page.scss'],
})
export class GetCoinModal2Page implements OnInit {
  item_type: 'GOODS' | 'COIN';
  item_level;
  item_name;
  item_point;

  constructor(
    public modalController: ModalController,
    public navParams: NavParams
  ) {
    this.item_type = this.navParams.get('item_type');
    this.item_level = this.navParams.get('item_level');
    this.item_name = this.navParams.get('item_name');
  }

  ngOnInit() {}

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  gotoCoin() {
    this.modalController.dismiss({
      gotoCoin: true,
    });
  }
}
