import { Injectable } from '@angular/core';
import { APIResponse } from '@mommy/models/Comm.model';
import { Api } from '../api';

@Injectable({
  providedIn: 'root',
})
export class MemberActionLogService {
  constructor(private api: Api) {
    console.log('Hello MemberActionLogService');
  }

  // 簽到紀錄
  signIn(): Promise<any> {
    const endpoint = `v1/member/action-log/sign-in`;

    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('action-log sign-in error', error);
        reject(error);
      }
    });
  }

  // 會員影片完成紀錄 for 孕婦任務
  videoDone(
    post_id: number,
    member_pregnancy_tag_taxonomy_id: number
  ): Promise<any> {
    const endpoint = `v1/member/action-log/video-done`;

    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};
        params = {
          post_id,
          member_pregnancy_tag_taxonomy_id,
        };

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('action-log video-done error', error);
        reject(error);
      }
    });
  }

  // Eline 諮詢按鈕點擊紀錄
  hospitalElineClick(hospital_id: number): Promise<any> {
    const endpoint = `v1/member/action-log/hospital-eline-click`;

    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};
        params = {
          object_id: hospital_id,
        };

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('action-log hospital-eline-click error', error);
        reject(error);
      }
    });
  }

  // 文章點擊紀錄
  postClick(post_id: number): Promise<any> {
    const endpoint = `v1/member/action-log/post-click`;

    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};
        params = {
          object_id: post_id,
        };

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('action-log post-click error', error);
        reject(error);
      }
    });
  }
}
