import { Pipe, PipeTransform } from '@angular/core';
import format from 'date-fns/format';

@Pipe({ name: 'dfnsFormat', pure: false })
export class DateFormatPipe implements PipeTransform {
  transform(inputDate: any, dateFormat: string): string {
    try {
      const dd = new Date(inputDate);
      return format(dd, dateFormat);
    } catch (error) {
      return '';
    }
  }
}
