import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PostCommentInfo } from '@mommy/models/PostCommentInfo.model';
import { VoteCommentInfo } from '@mommy/models/VoteCommentInfo.model';
import { UserState } from '@mommy/state/user/user.state';
import { Store } from '@ngxs/store';
import { format, parseISO } from 'date-fns';

@Component({
  selector: 'app-comment-item',
  templateUrl: './comment-item.component.html',
  styleUrls: ['./comment-item.component.scss'],
})
export class CommentItemComponent implements OnInit {
  @Output() editPostCommentOnClick = new EventEmitter<any>();
  @Output() editVoteCommentOnClick = new EventEmitter<any>();
  @Output() reportPostCommentOnClick = new EventEmitter<any>();
  @Output() reportVoteCommentOnClick = new EventEmitter<any>();

  _comment;
  _type: string;
  _vote_id;
  _post_id;
  _comment_created_at;
  _isAuthor = false;

  constructor(private store: Store) {}

  ngOnInit() {}

  get comment(): any {
    return this._comment;
  }

  @Input() set postComment(value: PostCommentInfo) {
    this._comment = value;
    this._type = 'post';

    console.log('comment', value);
    this.map_comment(value);
  }

  @Input() set voteComment(value: VoteCommentInfo) {
    this._comment = value;
    this._type = 'vote';

    console.log('comment', value);
    this.map_comment(value);
  }

  map_comment(comment) {
    let created_at;
    try {
      created_at = format(parseISO(comment.comment_modified_at), 'yyyy-MM-dd');
      if (comment.created_at !== comment.comment_modified_at) {
        created_at = `${created_at}(已編輯)`;
      }
    } catch (error) {
      created_at = format(
        new Date(parseInt(comment.comment_modified_at, 10)),
        'yyyy-MM-dd'
      );
    }

    this._comment_created_at = created_at;

    // 判斷是否為作者
    const mommy_user = this.store.selectSnapshot(UserState.mommy_user);
    if (mommy_user) {
      if (mommy_user?.basic_info?.user_id === comment?.comment_user?.user_id) {
        this._isAuthor = true;
      }
    } else {
      this._isAuthor = false;
    }
  }

  edit() {
    if (this._type === 'post') {
      this.editPostCommentOnClick.emit(this._comment);
    } else if (this._type === 'vote') {
      this.editVoteCommentOnClick.emit(this._comment);
    } else {
      console.warn('not support');
    }
  }

  reportComment() {
    if (this._type === 'post') {
      this.reportPostCommentOnClick.emit(this._comment);
    } else if (this._type === 'vote') {
      this.reportVoteCommentOnClick.emit(this._comment);
    } else {
      console.warn('not support');
    }
  }
}
