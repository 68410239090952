import { Injectable } from '@angular/core';
import { MemberBabyGrowRecordInfo } from '@mommy/models/MemberBabyGrowRecordInfo.model';
import { MemberBabyGrowRecordService } from '@mommy/services/member/member-baby-grow-record.service';
import { StorageService } from '@mommy/services/storage.service';
import { UserService } from '@mommy/services/user/user.service';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import * as _ from 'lodash';
import {
  AddMemberBabyGrowRecord,
  ChangeMemberBaby,
  GetMemberBabyGrowRecords,
  ModifyMemberBabyGrowRecord,
  RefreshMemberBabyGrowRecords,
  RemoveMemberBabyGrowRecord,
} from './member-baby-grow-record.actions';

export interface MemberBabyGrowRecordStateModel {
  loading: boolean;
  member_baby_grow_records: MemberBabyGrowRecordInfo[];
  selected_member_baby: null;
  hasCache: boolean;
}

const defaultMemberBabyGrowRecordState = (): MemberBabyGrowRecordStateModel => {
  return {
    loading: false,
    member_baby_grow_records: [],
    selected_member_baby: null,
    hasCache: false,
  };
};

@State<MemberBabyGrowRecordStateModel>({
  name: 'MemberBabyGrowRecordState',
  defaults: defaultMemberBabyGrowRecordState(),
})
@Injectable()
export class MemberBabyGrowRecordState {
  constructor(
    private storage: StorageService,
    private memberBabyGrowRecordSvc: MemberBabyGrowRecordService,
    private userService: UserService,
    private store: Store
  ) {}

  @Selector()
  static selected_member_baby(state: MemberBabyGrowRecordStateModel) {
    return state.selected_member_baby;
  }

  @Selector()
  static member_baby_grow_records(state: MemberBabyGrowRecordStateModel) {
    return state.member_baby_grow_records;
  }

  // 寶寶生長紀錄
  @Selector([
    MemberBabyGrowRecordState.member_baby_grow_records,
    MemberBabyGrowRecordState.selected_member_baby,
  ])
  static memberBabyGrowRecordsWithSelectedBaby(
    member_baby_grow_records: MemberBabyGrowRecordInfo[],
    selected_member_baby: any
  ) {
    console.log(
      '[MemberBabyGrowRecordState] memberBabyGrowRecordsWithSelectedBaby member_baby_grow_records',
      member_baby_grow_records
    );
    console.log(
      '[MemberBabyGrowRecordState] memberBabyGrowRecordsWithSelectedBaby selected_member_baby',
      selected_member_baby
    );

    return _.chain(member_baby_grow_records)
      .filter(
        (member_baby_grow_record: MemberBabyGrowRecordInfo) =>
          member_baby_grow_record.member_baby_id ===
          selected_member_baby?.member_baby_id
      )
      .orderBy('record_date', 'desc')
      .value();
  }

  @Action(GetMemberBabyGrowRecords)
  async GetMemberBabyGrowRecords(
    ctx: StateContext<MemberBabyGrowRecordStateModel>
  ) {
    console.log('[Action] GetMemberBabyGrowRecords');

    try {
      const member_baby_grow_records = await this.storage.get(
        'member_baby_grow_records'
      );

      // 先更新 cache 的資料, 再載入server的資料
      ctx.patchState({
        member_baby_grow_records,
      });

      this.store.dispatch(new RefreshMemberBabyGrowRecords());
    } catch (error) {
      console.warn('GetMemberBabyGrowRecords error', error);
      throw error;
    }
  }

  @Action(AddMemberBabyGrowRecord)
  async AddMemberBabyGrowRecord(
    ctx: StateContext<MemberBabyGrowRecordStateModel>,
    { payload }
  ) {
    console.log('[Action] AddMemberBabyGrowRecord');

    try {
      const result = await this.memberBabyGrowRecordSvc.add(payload);
      console.log('AddMemberBabyGrowRecord success', result);
      this.store.dispatch(new RefreshMemberBabyGrowRecords());
    } catch (error) {
      console.error('AddMemberBabyGrowRecord error', error);
      throw error;
    }
  }

  @Action(RemoveMemberBabyGrowRecord)
  async RemoveMemberBabyGrowRecord(
    ctx: StateContext<MemberBabyGrowRecordStateModel>,
    { grow_id }
  ) {
    console.log('[Action] RemoveMemberBabyGrowRecord');

    try {
      const result = await this.memberBabyGrowRecordSvc.delete(grow_id);
      console.log('RemoveMemberBabyGrowRecord success', result);
      this.store.dispatch(new RefreshMemberBabyGrowRecords());
    } catch (error) {
      console.error('RemoveMemberBabyGrowRecord error', error);
      throw error;
    }
  }

  @Action(ModifyMemberBabyGrowRecord)
  async ModifyMemberBabyGrowRecord(
    ctx: StateContext<MemberBabyGrowRecordStateModel>,
    action: ModifyMemberBabyGrowRecord
  ) {
    console.log('[Action] ModifyMemberBabyGrowRecord');

    try {
      const result = await this.memberBabyGrowRecordSvc.update(action.payload);
      console.log('ModifyMemberBabyGrowRecord success', result);
      this.store.dispatch(new RefreshMemberBabyGrowRecords());
    } catch (error) {
      console.error('ModifyMemberBabyGrowRecord error', error);
      throw error;
    }
  }

  @Action(ChangeMemberBaby)
  async ChangeMemberBaby(
    ctx: StateContext<MemberBabyGrowRecordStateModel>,
    { member_baby }
  ) {
    console.log('[Action] ChangeMemberBaby');
    console.log(member_baby);

    ctx.patchState({
      selected_member_baby: member_baby,
    });
    // this.storage.set('selected_member_baby', member_baby);
    // this.store.dispatch(new GetMemberBabyGrowRecords());
  }

  @Action(RefreshMemberBabyGrowRecords)
  async RefreshMemberBabyGrowRecords(
    ctx: StateContext<MemberBabyGrowRecordStateModel>
  ) {
    console.log('[Action] RefreshMemberBabyGrowRecords');

    try {
      // 呼叫 api 取得最新的資料
      const data: any = await this.memberBabyGrowRecordSvc.get_list();

      const _data = _.orderBy(data, ['record_date'], ['desc']);

      // 儲存資料到 cache
      this.storage.set('member_baby_grow_records', _data);

      ctx.patchState({
        member_baby_grow_records: _data,
      });
    } catch (error) {
      console.warn('GetMemberBabyGrowRecords error', error);
      throw error;
    }
  }
}
