import { MemberBabyFeedRecordInfo } from '@mommy/models/MemberBabyFeedRecordInfo.model';
import { MemberBabyInfo } from '@mommy/models/MemberBabyInfo.model';

export class GetMemberBabyFeedRecords {
  static readonly type = '[MemberBabyFeedRecord] GetMemberBabyFeedRecords';
}

export class RefreshMemberBabyFeedRecords {
  static readonly type = '[MemberBabyFeedRecord] RefreshMemberBabyFeedRecords';
}

export class AddMemberBabyFeedRecord {
  static readonly type = '[MemberBabyFeedRecord] AddMemberBabyFeedRecord';
  constructor(public payload: MemberBabyFeedRecordInfo) {}
}

export class ModifyMemberBabyFeedRecord {
  static readonly type = '[MemberBabyFeedRecord] ModifyMemberBabyFeedRecord';
  constructor(public payload: MemberBabyFeedRecordInfo) {}
}

export class RemoveMemberBabyFeedRecord {
  static readonly type = '[MemberBabyFeedRecord] RemoveMemberBabyFeedRecord';
  constructor(public feed_id: number) {}
}

export class ChangeMemberBaby {
  static readonly type = '[MemberBabyFeedRecord] ChangeMemberBaby';
  constructor(public member_baby: MemberBabyInfo) {}
}
