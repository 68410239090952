import { Injectable } from '@angular/core';
import { APIResponse } from '@mommy/models/Comm.model';
import { MemberVoteInfo } from '@mommy/models/MemberVoteInfo.model';
import { Api } from '@mommy/services/api';

@Injectable({
  providedIn: 'root',
})
export class MemberVoteService {
  constructor(private api: Api) {}

  get_list(data?: any): Promise<any> {
    const endpoint = 'v1/member/member-vote';

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.get(endpoint).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-vote list get error', error);
        reject(error);
      }
    });
  }

  delete(member_vote_id: number): Promise<any> {
    const endpoint = `v1/member/member-vote/${member_vote_id}`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.delete(endpoint).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-vote delete error', error);
        reject(error);
      }
    });
  }

  add(memberVote: MemberVoteInfo): Promise<any> {
    const endpoint = `v1/member/member-vote`;

    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};
        params = memberVote;

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-vote add error', error);
        reject(error);
      }
    });
  }

  update(memberVote: MemberVoteInfo): Promise<any> {
    const endpoint = `v1/member/member-vote/${memberVote.member_vote_id}`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.put(endpoint, memberVote).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-vote update error', error);
        reject(error);
      }
    });
  }
}
