import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';
import {
  AlertController,
  NavController,
  Platform,
  ToastController,
} from '@ionic/angular';
import * as fb from 'firebase/compat/app';
import { timer } from 'rxjs/internal/observable/timer';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { skipUntil } from 'rxjs/internal/operators/skipUntil';
import { Subject } from 'rxjs/internal/Subject';
import { Subscription } from 'rxjs/internal/Subscription';
import { StateUtilsService } from './state-utils.service';
import { UtilService } from './util.service';

declare var navigator: any;
declare var Connection: any;

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  disconnectSubscription: Subscription;
  connectSubscription: Subscription;
  dvc_network_flag = true;

  rx_subject_dvc_networkstatus: Subject<boolean>;
  rx_subject_fb_networkstatus: Subject<boolean>;
  networkSubscription: Subscription;
  alertcountdownSubscription: Subscription;
  alertcountdown_monitor_secords: number = 10;
  networkToast: any;
  reload_confirm_prompt: any; // 提示是否要重新載入app的alert controller

  fb_connection_monitor: any;
  fb_connected_flag: boolean = true;

  fbapp: fb.default.app.App;

  constructor(
    private platform: Platform,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    public navCtrl: NavController,
    private stateUtil: StateUtilsService,
    private util: UtilService
  ) {
    console.log('hello NetworkService');
    this.fbapp = fb.default.app();

    this.rx_subject_fb_networkstatus = new Subject();
    this.rx_subject_dvc_networkstatus = new Subject();

    // app 回到前景
    this.platform.resume.subscribe(async () => {
      console.log('[Network Service] platform resume..');
    });

    // app 退到背景
    this.platform.pause.subscribe(() => {
      console.log('[Network Service] platform pause...');

      // // 以下這行應該是解決 2.0 版, 在背景放一陣子後 啟動(回到前景), 容易出現離線的訊息
      // this.networkSubscription.unsubscribe();

      // //退到背景時先消失,等resume時再check connection
      // try {
      //   this.networkToast.dismiss();
      // } catch (error) {
      //   // console.log(error);
      // }
      // try {
      //   this.reload_confirm_prompt.dismiss();
      // } catch (error) {
      //   // console.log(error);
      // }

      // 以下兩個變數都沒有在使用了
      // try {
      //   console.log('this.reinitialize_monitor_subscription.unsubscribe();');
      //   this.reinitialize_monitor_subscription.unsubscribe();
      // } catch (error) {
      //   console.log(error);
      // }

      // try {
      //   console.log('this.reload_monitor_subscription.unsubscribe();');
      //   this.reload_monitor_subscription.unsubscribe();
      // } catch (error) {
      //   console.log(error);
      // }

      // try {
      //   console.log('this.alertcountdownSubscription.unsubscribe();');
      //   this.alertcountdownSubscription.unsubscribe();
      // } catch (error) {
      //   console.log(error);
      // }

      // let now = new Date();
      // localStorage.setItem('pause_time', now.getTime().toString());
    });
  }

  init_service() {
    console.log('init_service');

    this.init_network_monitor();
    this.init_device_network_monitor();
    this.init_firebase_connection_monitor();
    this.monitor_network_early_period();
  }

  // 依照網路狀態的變化來do action
  private init_network_monitor() {
    console.log('init_network_monitor');
    /*  監控網路狀態的變化,依照dvc network status & firebase connection status,顯示不同的msg
        v1(dvc)  v2(fb)  result
        -------------------------
        T        T       T ==> 1
        T        F       f ==> 2
        F        T       F ==> 4
        F        F       F ==> 3
    */
    try {
      this.networkSubscription.unsubscribe();
    } catch (error) {
      // console.log(error);
    }

    this.networkSubscription = this.rx_subject_fb_networkstatus
      .pipe(skipUntil(timer(5000)))
      .pipe(debounceTime(3000))
      .subscribe((val) => {
        console.log('network status change:', val);
        if (val == false) {
          try {
            // //有登入才顯示
            // const user = this.stateUtil.getUser();
            // if (user) {
            //   this.presentToast(2);
            //   // 開始倒數ex:10 sec,如果此狀態超過ex:10sec未回復,顯示訊息
            //   // 如果已恢復連線狀態,則取消倒數
            //   try {
            //     this.alertcountdownSubscription.unsubscribe();
            //   } catch (error) {
            //     console.log(error);
            //   }
            //   this.alertcountdownSubscription = interval(1000 /*ms*/)
            //     .pipe(take(this.alertcountdown_monitor_secords))
            //     .pipe(
            //       takeWhile(() => {
            //         return this.fb_connected_flag === false;
            //       })
            //     )
            //     .subscribe((val) => {
            //       console.log(val);
            //       if (val >= this.alertcountdown_monitor_secords - 1) {
            //         this.do_confirm_reload();
            //       }
            //     });
            // }
          } catch (error) {
            console.log(error);
          }
        } else {
          try {
            this.networkToast.dismiss();
          } catch (error) {
            // console.log(error);
          }
          try {
            this.reload_confirm_prompt.dismiss();
          } catch (error) {
            // console.log(error);
          }
        }
      });
  }

  // 顯示網路失去連線的提示訊息
  private async presentToast(type) {
    let msg = '目前伺服器失去連線,嘗試連線中..';
    if (type == 2) {
      msg = '目前伺服器失去連線,嘗試連線中..';
    } else if (type == 3) {
      msg = '未連接至網路..';
    }

    try {
      await this.networkToast.dismiss();
    } catch (error) {
      console.log(error);
    }
    //目前伺服器失去連線,嘗試連線中..
    //未連接至網路..
    this.networkToast = await this.toastCtrl.create({
      message: msg,
      position: 'top',
      color: 'dark',
      keyboardClose: true,
      buttons: [{ text: '關閉', role: 'cancel' }],
    });

    this.networkToast.onDidDismiss((data, role) => {
      console.log(data);
      console.log(role);
      console.log('Dismissed toast');
    });

    await this.networkToast.present();
    // this.do_confirm_reload();
  }

  private async do_confirm_reload() {
    console.log('do_confirm_reload..');
    console.log('this.reload_confirm_prompt', this.reload_confirm_prompt);

    let prompt = async () => {
      this.reload_confirm_prompt = await this.alertCtrl.create({
        header: '訊息',
        message:
          '偵測到伺服器無法連線!<br>請確定網路是否正常連線?<br>或是否重新載入APP? 或是繼續等待?',
        buttons: [
          {
            text: '繼續等待',
            handler: () => {
              console.log('cancel clicked');
            },
          },
          {
            text: '重新載入APP',
            handler: () => {
              console.log('Agree clicked, logout...');
              // this.splashScreen.show();
              // TODO: 待測試
              localStorage.setItem('reload', 'YES');
              this.navCtrl.navigateRoot('/app-start');
            },
          },
        ],
      });
      this.reload_confirm_prompt.present();
    };

    if (this.reload_confirm_prompt) {
      console.log('已經建立了,dismiss then prompt..');
      try {
        this.reload_confirm_prompt.dismiss();
      } catch (error) {}
      prompt();
    } else {
      prompt();
    }
  }

  private monitor_network_early_period() {
    var counter = 15;
    var Countdown = setInterval(() => {
      // console.log(counter);
      // console.log('fb_connected_flag',this.fb_connected_flag);
      if (this.fb_connected_flag === true) {
        this.rx_subject_fb_networkstatus.next(true);
        // this.rx_subject_networkstatus.next(false);
      } else {
        this.rx_subject_fb_networkstatus.next(false);
        // this.rx_subject_networkstatus.next(true);
      }
      counter--;
      if (counter === 0) {
        console.log('Countdown 0');
        clearInterval(Countdown);
      }
    }, 1000);
  }

  // 啟動裝置的網路狀態監控
  private async init_device_network_monitor() {
    console.log('init_device_network_monitor()');
    // 1.browser會error
    // 2.先透過checkDeviceConnection取得裝置當前的連線類型,如果是none表示沒有連線
    // 3.之後連線/斷線需要網路狀態改變才會trigger
    try {
      this.checkDeviceConnection();
    } catch (error) {
      console.log(error);
    }

    await Network.removeAllListeners();
    Network.addListener('networkStatusChange', (status) => {
      console.log('Network status changed', status);
      if (status.connected) {
        console.log('network connected!');

        if (status.connected !== this.dvc_network_flag) {
          this.util.showToast('你的網路已恢復');
        }
        this.dvc_network_flag = true;

        // this.rx_subject_dvc_networkstatus.next(true);
      } else {
        console.log('network disconnected!');
        if (status.connected !== this.dvc_network_flag) {
          this.util.showToast('你的網路是離線狀態');
        }
        this.dvc_network_flag = false;

        // this.rx_subject_dvc_networkstatus.next(false);
      }
    });
  }

  // 啟動firebase的連線狀態監控
  private init_firebase_connection_monitor() {
    console.log('init_firebase_connection_monitor');
    if (this.fb_connection_monitor) {
      this.fb_connection_monitor.off('value', (snap) => {
        console.log('off fb_connection_monitor event');
        console.log(snap);
      });
    }
    this.fb_connection_monitor = fb.default
      .database(this.fbapp)
      .ref('.info/connected');
    this.fb_connection_monitor.on('value', (snap) => {
      console.log(snap);
      // console.log('appstatus == resume');
      if (snap.val() === true) {
        console.log('snap == true, online...');
        this.fb_connected_flag = true;
        this.rx_subject_fb_networkstatus.next(true);
        // this.rx_subject_networkstatus.next(false);
      } else {
        console.log('snap == false , offline...');
        this.fb_connected_flag = false;
        this.rx_subject_fb_networkstatus.next(false);
        //TODO: 改成使用observable, 斷線超過10秒才顯示訊息, 若10秒內恢復, 則需要取消倒數
        // this.networkNotAvailable = true;
        // this.rx_subject_networkstatus.next(true);
      }
    });
  }

  get_firebase_connection_once() {
    return new Promise((resolve, reject) => {
      let fb_connection_once = fb.default
        .database(this.fbapp)
        .ref('.info/connected');
      fb_connection_once.once(
        'value',
        (snap) => {
          console.log(snap);
          // console.log('appstatus == resume');
          if (snap.val() === true) {
            console.log('snap == true, online...');
            resolve(true);
          } else {
            console.log('snap == false , offline...');
            resolve(false);
          }
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    });
  }

  private async checkDeviceConnection() {
    console.log('checkConnection...');
    console.log('isCordova', this.platform.is('cordova'));

    // // 如果在browser run, network plugin不會有value,故手動模擬
    // if (this.platform.is('cordova') == false) {
    //   //browser
    //   console.log('running in browser..');
    //   console.log('device network status set to true');
    //   this.dvc_network_flag = true;
    //   this.rx_subject_dvc_networkstatus.next(true);
    //   return;
    // }

    // var networkState = navigator.connection.type;

    // var states = {};
    // states[Connection.UNKNOWN] = 'Unknown connection';
    // states[Connection.ETHERNET] = 'Ethernet connection';
    // states[Connection.WIFI] = 'WiFi connection';
    // states[Connection.CELL_2G] = 'Cell 2G connection';
    // states[Connection.CELL_3G] = 'Cell 3G connection';
    // states[Connection.CELL_4G] = 'Cell 4G connection';
    // states[Connection.CELL] = 'Cell generic connection';
    // states[Connection.NONE] = 'No network connection';

    // console.log('Connection type: ' + states[networkState]);

    // if (states[networkState] == states[Connection.NONE]) {
    //   this.dvc_network_flag = false;
    //   this.rx_subject_dvc_networkstatus.next(false);
    // } else {
    //   this.dvc_network_flag = true;
    //   this.rx_subject_dvc_networkstatus.next(true);
    // }

    const status = await Network.getStatus();
    console.log('Network.getStatus result: ', status);

    if (status.connected == true) {
      this.dvc_network_flag = true;
      this.rx_subject_dvc_networkstatus.next(true);
    } else {
      this.dvc_network_flag = false;
      this.rx_subject_dvc_networkstatus.next(false);
    }
  } //end checkConnection
}
