/* eslint-disable no-bitwise */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { EventEmitter, Injectable } from '@angular/core';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { Platform } from '@ionic/angular';
import firebase from 'firebase/compat/app';
import 'firebase/compat/performance';
import 'firebase/compat/storage';

@Injectable({
  providedIn: 'root',
})
export class FirebaseUtilsService {
  upload_image_progress = new EventEmitter<number>();

  constructor(public platform: Platform, private firebaseX: FirebaseX) {}

  //<-- firebase analytics -->
  setScreenName(screenName) {
    if (this.platform.is('cordova')) {
      this.firebaseX
        .setScreenName(screenName)
        .then(() => {
          console.log('setScreenName success.');
        })
        .catch((error) => {
          console.log('setScreenName failed.', error);
        });
    }
  }

  // setCrashlyticsUserId() {
  //   if(this.platform.is('cordova')) {
  //     var site_code = this.localStorageService.get('site_code');
  //     var member_id = this.localStorageService.get('member_id');
  //     (<any>window).FirebasePlugin.setCrashlyticsUserId(site_code + '-' + member_id);
  //   }
  // }

  async setUserId(userId) {
    console.log('setUserId', userId);

    if (this.platform.is('cordova')) {
      this.firebaseX
        .setUserId(userId)
        .then(() => {
          console.log('set UserId done');
        })
        .catch((error) => {
          console.log('set UserId failed,', error);
        });
    }
  }

  // setUserProperty() {
  //   if(this.platform.is('cordova')) {
  //     var site_code = this.localStorageService.get('site_code');
  //     (<any>window).FirebasePlugin.setUserProperty('site_code',site_code);
  //   }
  // }

  logEvent(tag, params) {
    if (this.platform.is('cordova')) {
      (<any>window).FirebasePlugin.logEvent(
        tag,
        params,
        function () {
          console.log('logEvent success.');
        },
        function (error) {
          console.log('logEvent failed.', error);
        }
      );
    }
  }

  logError(error, stack) {
    console.log('logError error', error);
    console.log('logError stack', stack);
    this.firebaseX
      .logError(error, stack)
      .then(() => {
        console.log('Sent JS exception');
      })
      .catch((error2) => {
        console.error('Failed to send JS exception', error2);
      });
  }
  //<-- firebase analytics -->

  uploadPictureByBase64_with_promise(
    path: string,
    base64file: string,
    file_type: 'jpg' | 'png'
  ): Promise<string> {
    // file_type: jpg(相片,壓縮效果好),因為photoviewer不支援png,故都以jpg處理/*png(簽名,支援透明)*/

    return new Promise((resolve, reject) => {
      const today = new Date();
      const yyyy = today.getFullYear().toString();
      const mm = (today.getMonth() + 1).toString();
      const dd = today.getDate().toString();
      const mmChars = mm.split('');
      const ddChars = dd.split('');
      const today_string =
        yyyy +
        '-' +
        (mmChars[1] ? mm : '0' + mmChars[0]) +
        '-' +
        (ddChars[1] ? dd : '0' + ddChars[0]);

      try {
        const fbstorage = firebase.storage();
        const fbstorageRef = fbstorage.ref();
        const site_imagesRef = fbstorageRef.child('images/' + path);
        const filename = this.generateUUID() + '.' + file_type;
        const spaceRef = site_imagesRef.child(filename);

        const metadata = {
          contentType: 'image/jpeg',
        };
        // Base64 formatted string
        console.log('spaceRef.putString');
        const uploadTask = spaceRef.putString(base64file, 'base64', metadata);

        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // See below for more detail
            const upload_progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + upload_progress + '% done');
            this.upload_image_progress.emit(upload_progress);
          },
          (error) => {
            // Handle unsuccessful uploads, alert with error message
            console.error('firebase upload error', error);
            reject(error);
          },
          () => {
            // Handle successful uploads on complete
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log('File available at', downloadURL);
              if (downloadURL) {
                resolve(downloadURL);
              } else {
                reject('downloadURL is null');
              }
            });
          }
        );
      } catch (error) {
        console.error('firebase upload error:', error);
        reject(error);
      }
    });
  }

  generateUUID() {
    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        // tslint:disable-next-line: no-bitwise
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        // tslint:disable-next-line: no-bitwise
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  }
}
