export class InitLocalCacheBabyDiaryPost {
  static readonly type = '[BabyDiaryPost] InitLocalCache';
}

export class LoadCacheBabyDiaryPost {
  static readonly type = '[BabyDiaryPost] LoadCache';
}

export class RefreshBabyDiaryPost {
  static readonly type = '[BabyDiaryPost] Refresh';
}
