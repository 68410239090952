import { Injectable } from '@angular/core';
import { MemberBabyFeedRecordInfo } from '@mommy/models/MemberBabyFeedRecordInfo.model';
import { MemberBabyGrowRecordInfo } from '@mommy/models/MemberBabyGrowRecordInfo.model';
import { MemberBabyFeedRecordService } from '@mommy/services/member/member-baby-feed-record.service';
import { StorageService } from '@mommy/services/storage.service';
import { UserService } from '@mommy/services/user/user.service';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import * as _ from 'lodash';
import {
  MemberBabyGrowRecordState,
  MemberBabyGrowRecordStateModel,
} from '../member-baby-grow-record/member-baby-grow-record.state';
import {
  AddMemberBabyFeedRecord,
  ChangeMemberBaby,
  GetMemberBabyFeedRecords,
  ModifyMemberBabyFeedRecord,
  RefreshMemberBabyFeedRecords,
  RemoveMemberBabyFeedRecord,
} from './member-baby-feed-record.actions';

export interface MemberBabyFeedRecordStateModel {
  loading: boolean;
  member_baby_feed_records: MemberBabyFeedRecordInfo[];
  selected_member_baby: null;
  hasCache: boolean;
}

const defaultMemberBabyFeedRecordState = (): MemberBabyFeedRecordStateModel => {
  return {
    loading: false,
    member_baby_feed_records: [],
    selected_member_baby: null,
    hasCache: false,
  };
};

@State<MemberBabyFeedRecordStateModel>({
  name: 'MemberBabyFeedRecordState',
  defaults: defaultMemberBabyFeedRecordState(),
})
@Injectable()
export class MemberBabyFeedRecordState {
  constructor(
    private storage: StorageService,
    private memberBabyFeedRecordSvc: MemberBabyFeedRecordService,
    private userService: UserService,
    private store: Store
  ) {}

  @Selector()
  static selected_member_baby(state: MemberBabyFeedRecordStateModel) {
    return state.selected_member_baby;
  }

  @Selector()
  static member_baby_feed_records(state: MemberBabyFeedRecordStateModel) {
    return state.member_baby_feed_records;
  }

  @Selector()
  static member_baby_grow_records(state: MemberBabyGrowRecordStateModel) {
    return state.member_baby_grow_records;
  }

  // 寶寶餵養紀錄
  @Selector([
    MemberBabyFeedRecordState.member_baby_feed_records,
    MemberBabyFeedRecordState.selected_member_baby,
  ])
  static memberBabyFeedRecordsWithSelectedBaby(
    member_baby_feed_records: MemberBabyFeedRecordInfo[],
    selected_member_baby: any
  ) {
    console.log(
      '[MemberBabyFeedRecordState] memberBabyFeedRecordsWithSelectedBaby member_baby_feed_records',
      member_baby_feed_records
    );
    console.log(
      '[MemberBabyFeedRecordState] memberBabyFeedRecordsWithSelectedBaby selected_member_baby',
      selected_member_baby
    );

    return _.chain(member_baby_feed_records)
      .filter(
        (member_baby_feed_record: MemberBabyFeedRecordInfo) =>
          member_baby_feed_record.member_baby_id ===
          selected_member_baby?.member_baby_id
      )
      .orderBy(['record_date', 'record_time'], ['desc', 'asc'])
      .value();
  }

  @Selector([
    MemberBabyGrowRecordState.member_baby_grow_records,
    MemberBabyFeedRecordState.selected_member_baby,
  ])
  static memberBabyGrowRecordsWithSelectedBaby(
    member_baby_grow_records: MemberBabyGrowRecordInfo[],
    selected_member_baby: any
  ) {
    console.log(
      '[MemberBabyGrowRecordState] memberBabyGrowRecordsWithSelectedBaby member_baby_grow_records',
      member_baby_grow_records
    );
    console.log(
      '[MemberBabyFeedRecordState] memberBabyGrowRecordsWithSelectedBaby selected_member_baby',
      selected_member_baby
    );

    return _.chain(member_baby_grow_records)
      .filter(
        (member_baby_grow_record: MemberBabyGrowRecordInfo) =>
          member_baby_grow_record.member_baby_id ===
          selected_member_baby?.member_baby_id
      )
      .orderBy(['record_date'], ['desc'])
      .value();
  }

  @Action(GetMemberBabyFeedRecords)
  async GetMemberBabyFeedRecords(
    ctx: StateContext<MemberBabyFeedRecordStateModel>
  ) {
    console.log('[Action] GetMemberBabyFeedRecords');

    try {
      const member_baby_feed_records = await this.storage.get(
        'member_baby_feed_records'
      );

      // 先更新 cache 的資料, 再載入server的資料
      ctx.patchState({
        member_baby_feed_records,
      });

      this.store.dispatch(new RefreshMemberBabyFeedRecords());
    } catch (error) {
      console.warn('GetMemberBabyFeedRecords error', error);
      throw error;
    }
  }

  @Action(AddMemberBabyFeedRecord)
  async AddMemberBabyFeedRecord(
    ctx: StateContext<MemberBabyFeedRecordStateModel>,
    { payload }
  ) {
    console.log('[Action] AddMemberBabyFeedRecord');

    try {
      const result = await this.memberBabyFeedRecordSvc.add(payload);
      console.log('AddMemberBabyFeedRecord success', result);
      this.store.dispatch(new RefreshMemberBabyFeedRecords());
    } catch (error) {
      console.error('AddMemberBabyFeedRecord error', error);
      throw error;
    }
  }

  @Action(RemoveMemberBabyFeedRecord)
  async RemoveMemberBabyFeedRecord(
    ctx: StateContext<MemberBabyFeedRecordStateModel>,
    { feed_id }
  ) {
    console.log('[Action] RemoveMemberBabyFeedRecord');

    try {
      const result = await this.memberBabyFeedRecordSvc.delete(feed_id);
      console.log('RemoveMemberBabyFeedRecord success', result);
      this.store.dispatch(new RefreshMemberBabyFeedRecords());
    } catch (error) {
      console.error('RemoveMemberBabyFeedRecord error', error);
      throw error;
    }
  }

  @Action(ModifyMemberBabyFeedRecord)
  async ModifyMemberBabyFeedRecord(
    ctx: StateContext<MemberBabyFeedRecordStateModel>,
    { payload }
  ) {
    console.log('[Action] ModifyMemberBabyFeedRecord');

    try {
      const result = await this.memberBabyFeedRecordSvc.update(payload);
      console.log('ModifyMemberBabyFeedRecord success', result);
      this.store.dispatch(new RefreshMemberBabyFeedRecords());
    } catch (error) {
      console.error('ModifyMemberBabyFeedRecord error', error);
      throw error;
    }
  }

  @Action(ChangeMemberBaby)
  async ChangeMemberBaby(
    ctx: StateContext<MemberBabyFeedRecordStateModel>,
    { member_baby }
  ) {
    console.log('[Action] ChangeMemberBaby');
    console.log(member_baby);

    ctx.patchState({
      selected_member_baby: member_baby,
    });

    // this.storage.set('selected_member_baby', member_baby);
    // this.store.dispatch(new GetMemberBabyFeedRecords());
  }

  @Action(RefreshMemberBabyFeedRecords)
  async RefreshMemberBabyFeedRecords(
    ctx: StateContext<MemberBabyFeedRecordStateModel>
  ) {
    console.log('[Action] RefreshMemberBabyFeedRecords');

    try {
      // 呼叫 api 取得最新的資料
      const data: any = await this.memberBabyFeedRecordSvc.get_list();

      const _data = _.orderBy(data, ['record_date'], ['desc']);

      // 儲存資料到 cache
      this.storage.set('member_baby_feed_records', _data);

      ctx.patchState({
        member_baby_feed_records: _data,
      });
    } catch (error) {
      console.warn('GetMemberBabyFeedRecords error', error);
      throw error;
    }
  }
}
