import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-number-picker',
  templateUrl: './number-picker.component.html',
  styleUrls: ['./number-picker.component.scss'],
})
export class NumberPickerComponent implements OnInit {
  @Input() size: 'small' | 'large';
  @Output() numberChange = new EventEmitter<number>();

  _number = 1;
  constructor() {}

  ngOnInit() {}

  get number(): number {
    return this._number;
  }

  @Input() set number(value: number) {
    this._number = value;
  }

  increment() {
    this._number++;
    this.numberChange.emit(this._number);
  }

  // 當數字減到 0 時，就停止減
  decrement() {
    if (this._number > 0) {
      this._number--;
      this.numberChange.emit(this._number);
    }
  }
}
