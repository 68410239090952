import { Injectable } from '@angular/core';
import { PostInfo } from '@mommy/models/PostInfo.model';
import { MemberPostKeepService } from '@mommy/services/member/member-post-keep.service';
import { StorageService } from '@mommy/services/storage.service';
import { UserService } from '@mommy/services/user/user.service';
import { UtilService } from '@mommy/services/util.service';
import { Action, State, StateContext, Store } from '@ngxs/store';
import * as _ from 'lodash';
import { PostState } from '../post/post.state';
import { UpdateMommyUser } from '../user/user.actions';
import { UserState } from '../user/user.state';
import {
  AddMemberPostKeep,
  GetMemberPostKeeps,
  RemoveMemberPostKeep,
} from './member-post-keep.actions';

export interface MemberPostKeepStateModel {
  loading: boolean;
  post_keeps: PostInfo[];
  hasCache: boolean;
}

const defaultMemberPostKeepState = (): MemberPostKeepStateModel => {
  return {
    loading: false,
    post_keeps: [],
    hasCache: false,
  };
};

@State<MemberPostKeepStateModel>({
  name: 'MemberPostKeepState',
  defaults: defaultMemberPostKeepState(),
})
@Injectable()
export class MemberPostKeepState {
  constructor(
    private storage: StorageService,
    private memberPostKeepSvc: MemberPostKeepService,
    private userService: UserService,
    private store: Store,
    private util: UtilService
  ) {}

  @Action(GetMemberPostKeeps)
  async GetMemberPostKeeps(ctx: StateContext<MemberPostKeepStateModel>) {
    console.log('[Action] GetMemberPostKeeps');

    try {
      const data: any = await this.userService.getMemberPostKeeps();
      console.log('GetMemberPostKeeps success', data);

      ctx.patchState({
        post_keeps: data.post_keeps,
      });

      // 更新 user state
      this.store.dispatch(new UpdateMommyUser(data));
    } catch (error) {
      console.warn('GetMemberPostKeeps error', error);
      throw error;
    }
  }

  @Action(AddMemberPostKeep)
  async AddMemberPostKeep(
    ctx: StateContext<MemberPostKeepStateModel>,
    { post_id }
  ) {
    console.log('[Action] AddMemberPostKeep');

    // 新更新 mommyuser state, 再呼叫 api 更新至 server
    const mommy_user = _.cloneDeep(
      this.store.selectSnapshot(UserState.mommy_user)
    );
    const post_keeps = mommy_user.post_keeps;
    const post = _.find(this.store.selectSnapshot(PostState.posts), {
      post_id: post_id,
    });
    post_keeps.push({ post_id, post_info: post });
    mommy_user.post_keeps = post_keeps;
    console.log('mommy_user', mommy_user);

    // 更新 user state
    this.store.dispatch(new UpdateMommyUser(mommy_user));

    try {
      const result = await this.memberPostKeepSvc.add(post_id);
      console.log('AddMemberPostKeep success', result);
      this.store.dispatch(new GetMemberPostKeeps());
    } catch (error) {
      console.error('AddMemberPostKeep error', error);
      throw error;
    }
  }

  @Action(RemoveMemberPostKeep)
  async RemoveMemberPostKeep(
    ctx: StateContext<MemberPostKeepStateModel>,
    { post_id }
  ) {
    console.log('[Action] RemoveMemberPostKeep');

    // 新更新 mommyuser state, 再呼叫 api 更新至 server
    const mommy_user = _.cloneDeep(
      this.store.selectSnapshot(UserState.mommy_user)
    );
    let post_keeps = mommy_user.post_keeps;
    const post = _.find(this.store.selectSnapshot(PostState.posts), {
      post_id: post_id,
    });
    post_keeps = _.filter(post_keeps, (item) => item.post_id !== post_id);
    mommy_user.post_keeps = post_keeps;
    console.log('mommy_user', mommy_user);

    // 更新 user state
    this.store.dispatch(new UpdateMommyUser(mommy_user));

    try {
      const result = await this.memberPostKeepSvc.remove(post_id);
      console.log('RemoveMemberPostKeep success', result);
      this.store.dispatch(new GetMemberPostKeeps());
    } catch (error) {
      console.error('RemoveMemberPostKeep error', error);
      throw error;
    }
  }
}
