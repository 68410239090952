import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-report-comment-modal',
  templateUrl: './report-comment-modal.page.html',
  styleUrls: ['./report-comment-modal.page.scss'],
})
export class ReportCommentModalPage implements OnInit {
  post_id = 0;
  vote_id = 0;

  post_comment_id = 0;
  vote_comment_id = 0;

  obj_type;

  _commentObj;

  constructor(
    public modalController: ModalController,
    public navParams: NavParams
  ) {
    this.post_id = this.navParams.get('post_id');
    this.vote_id = this.navParams.get('vote_id');
    this.obj_type = this.navParams.get('obj_type');
    this._commentObj = navParams.get('comment');
    console.log('_commentObj', this._commentObj);
  }

  ngOnInit() {}

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  report() {
    this.modalController.dismiss({
      reported: true,
    });
  }
}
