import { Injectable } from '@angular/core';
import { CacheService } from '@mommy/services/cache.service';
import { UtilService } from '@mommy/services/util.service';
import { Apollo } from 'apollo-angular';
import { AppSettings } from 'app/app.settings';
import { take } from 'rxjs/operators';
import {
  QUERY_POST_DETAIL,
  QUERY_POST_LIST,
  QUERY_POST_LIST_FILTER,
} from './post.graphql';

@Injectable({ providedIn: 'root' })
export class PostService {
  constructor(
    private apollo: Apollo,
    private util: UtilService,
    private cacheService: CacheService
  ) {}

  // 取得文章列表 incremental
  getPostList() {
    console.log('getPostList');
    return new Promise(async (resolve, reject) => {
      let cursor = await this.cacheService.getDataCursor2(
        AppSettings.CACHE_KEY_POST_LIST
      );
      console.warn('posts cursor', cursor);
      cursor = cursor ? cursor : 0;

      this.apollo
        .watchQuery<{ getPostList: any[] }>({
          query: QUERY_POST_LIST,
          fetchPolicy: 'network-only',
          variables: {
            updated_at: cursor, //最近一筆的timestamp
          },
        })
        .valueChanges.pipe(take(1))
        .subscribe(
          async ({ data, loading }) => {
            console.log('loading', loading);
            console.log('data', data);

            if (data) {
              // loading = false; //如果有data,表示有cache
              if (data?.getPostList.length > 0) {
                try {
                  const new_data = await this.cacheService.mergeData2(
                    data?.getPostList,
                    AppSettings.CACHE_KEY_POST_LIST,
                    'post_id'
                  );
                  resolve(new_data);
                } catch (error) {
                  console.error('cacheService merge data error', error);
                  reject(-1);
                }
              } else {
                console.log('no new data need to merge..');
                reject('no new data need to merge');
              }
            } else {
              reject('data is null');
            }
          },
          ({ graphQLErrors, networkError }) => {
            const msg = this.util.getErrorDesc(graphQLErrors, networkError);
            this.util.showToast(msg, '', 0, true);
            reject(-1);
          }
        );
    });
  }

  // 取得文章資料
  getPostDetail(post_id: number) {
    return new Promise(async (resolve, reject) => {
      this.apollo
        .watchQuery<{ getPost: any[] }>({
          query: QUERY_POST_DETAIL,
          fetchPolicy: 'network-only',
          variables: {
            post_id,
          },
        })
        .valueChanges.pipe(take(1))
        .subscribe(
          ({ data, loading }) => {
            console.log('loading', loading);
            console.log('data', data);

            if (data) {
              // loading = false; //如果有data,表示有cache
              if (data?.getPost) {
                resolve(data?.getPost);
              } else {
                console.log('no new data need to merge..');
                reject('no new data need to merge');
              }
            } else {
              reject('data is null');
            }
          },
          ({ graphQLErrors, networkError }) => {
            console.log('graphQLErrors', graphQLErrors);
            console.log('networkError', networkError);
            reject(-1);
          }
        );
    });
  }

  // 全文檢索文章
  getPostListFilter(filter_value: string) {
    console.log('getPostListFilter');
    return new Promise(async (resolve, reject) => {
      this.apollo
        .watchQuery<{ getPostListByFilter: any[] }>({
          query: QUERY_POST_LIST_FILTER,
          fetchPolicy: 'network-only',
          variables: {
            filter_value,
          },
        })
        .valueChanges.pipe(take(1))
        .subscribe(
          async ({ data, loading }) => {
            console.log('loading', loading);
            console.log('data', data);

            if (data) {
              // loading = false; //如果有data,表示有cache
              if (data?.getPostListByFilter.length > 0) {
                resolve(data?.getPostListByFilter);
              } else {
                reject('no data found');
              }
            } else {
              reject('data is null');
            }
          },
          ({ graphQLErrors, networkError }) => {
            const msg = this.util.getErrorDesc(graphQLErrors, networkError);
            this.util.showToast(msg, '', 0, true);
            reject(-1);
          }
        );
    });
  }
}
