import { Injectable } from '@angular/core';
import { APIResponse } from '@mommy/models/Comm.model';
import { Api } from '@mommy/services/api';

@Injectable({
  providedIn: 'root',
})
export class MemberExpertWatchService {
  constructor(private api: Api) {}

  add(expert_id: number): Promise<any> {
    const endpoint = `v1/member/action-log/expert-watch`;

    return new Promise(async (resolve, reject) => {
      try {
        const params: any = {};
        params.object_id = expert_id;

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-expert-watch add error', error);
        reject(error);
      }
    });
  }

  remove(expert_id: number): Promise<any> {
    const endpoint = `v1/member/action-log/expert-watch/remove`;

    return new Promise(async (resolve, reject) => {
      try {
        const params: any = {};
        params.object_id = expert_id;

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-expert-watch remove error', error);
        reject(error);
      }
    });
  }
}
