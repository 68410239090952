/* eslint-disable no-throw-literal */
/* eslint-disable prefer-const */
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Team } from '@mommy/models/Eline.model';
// import * as firebase from 'firebase';
import { Storage } from '@ionic/storage';
import { Store } from '@ngxs/store';
import { format } from 'date-fns';
import { Observable } from 'rxjs/internal/Observable';
import { ElineApi } from '../eline-api';
import { NewsService } from './news-service';

@Injectable({
  providedIn: 'root',
})
export class ElineHospitalService {
  selectedHospital: Team; // UI選擇到的 team
  selectedHealthEducationParam: any; // UI選擇到的衛教item

  constructor(
    private platform: Platform,
    public api: ElineApi,
    private storage: Storage,
    private store: Store,
    private newssvc: NewsService
  ) {
    console.log('Hello ElineHospitalService');
  }

  get_hospitals(data?: any): Promise<any> {
    let endpoint = 'appv1/hospital_list';

    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.api.get(endpoint);
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });

    // let endpoint = 'hospital';

    // return new Promise(async (resolve, reject) => {
    //   let params: any = {};
    //   params.method = 'sel';
    //   params.data = data;

    //   // 因為 eline 的 hospital api 之前都必須要登入時才能取得資料,
    //   // 但是 mommy3 未登入時, 取得資料會有問題, 因此加入 ignore_got401action 參數,
    //   const context = new HttpContext();
    //   context.set(IGNORE_GOT401ACTION, true);

    //   try {
    //     const result = await this.api.post(endpoint, params, { context });
    //     resolve(this.extractData(result));
    //   } catch (error) {
    //     reject(error);
    //   }
    // });
  }

  query_hospitals(data?: any): Promise<any> {
    let endpoint = 'query_hospital';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'sel';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  get_hospital_intro(data?: any): Promise<any> {
    let endpoint = 'hospital/intro';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'sel';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  //取得衛教資訊
  get_healtheducation(data?: any): Promise<any> {
    let endpoint = 'healtheducation';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'sel';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  //取得衛教資訊content2, 擴充public_code, for share to line/fb使用
  get_healtheducation_content2(data?: any): Promise<any> {
    let endpoint = 'healtheducation/content2';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'sel';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  // v2版,增加判斷家人模式
  get_phrasedata_by_patient_and_hospital_v2(data?: any): Promise<any> {
    let endpoint = 'phrase2';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'sel';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  set_pressure_and_flow_rate(data?: any): Promise<any> {
    let endpoint = 'datacollection';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'ins';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  link_hospital(data?: any): Promise<any> {
    let endpoint = 'linkhospital2';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'linkhospital';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  // 綁定病患
  link_customer(data?: any): Promise<any> {
    let endpoint = 'linkcustomer';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'linkcustomer';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  get_customer_cure(data?: any): Promise<any> {
    let endpoint = 'cure';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'sel';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  // 取得問卷的設定資料
  get_question_setting(data?: any): Promise<any> {
    let endpoint = 'questionsetting';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'sel';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  // 回傳問卷的設定資料
  set_question_answer(data?: any): Promise<any> {
    let endpoint = 'questionanswer';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'upser';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  // 查詢問卷的設定資料
  get_question_answer(data?: any): Promise<any> {
    let endpoint = 'questionanswer';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'sel';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  // 儲存推薦團隊的click log
  save_recommend_team_click_log(team_id, user_id) {
    console.log('save_recommend_team_click_log');

    let params: any = {};
    params.content_type = 'RECOMMEND_TEAM';
    params.content_id = team_id;
    params.user_id = user_id;
    params.click_at = format(new Date(), 'YYYY-MM-DD HH:mm:ss');

    this.newssvc
      .save_news_click_log(params)
      .then((res) => {
        console.log('success..', res);
      })
      .catch((error) => {
        console.error('save_news_click_log error', error);
      });
  }

  // 42-2, 取得channel的家人list
  get_chat_relation(data: any, auth_token?) {
    let endpoint = 'appv1/channel/relation_duty';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'sel';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  private extractData(res) {
    console.log(res);
    let body = res; //httpclient , res已經是json
    console.log(body);
    if (body.returnCode === 'OK') {
      return body;
    } else {
      // throw '' + body.message;
      throw { api_error: true, message: body.message };
    }
  }

  private handleError(error: any) {
    console.log(error);
    return Observable.throw(error);
  }

  /*****************************************************************/
  /*  以下為 refactor */
  /*****************************************************************/

  // 取得 hospitals list
  fetchHospitals() {
    return new Promise(async (resolve, reject) => {
      let hospitals;
      try {
        hospitals = await this.get_hospitals();
        console.log('get_hospitals result', hospitals);
        resolve(hospitals);
      } catch (error) {
        console.error('get_hospitals error:', error);
        reject(error);
      }
    });
  }
}
