import { Injectable } from '@angular/core';
import { APIResponse } from '@mommy/models/Comm.model';
import { MemberBabyFeedRecordInfo } from '@mommy/models/MemberBabyFeedRecordInfo.model';
import { Api } from '@mommy/services/api';

@Injectable({
  providedIn: 'root',
})
export class MemberBabyFeedRecordService {
  constructor(private api: Api) {}

  get_list(data?: any): Promise<any> {
    const endpoint = 'v1/member/member-baby-feed-record';

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.get(endpoint).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-baby-feed-record list get error', error);
        reject(error);
      }
    });
  }

  delete(feed_id: number): Promise<any> {
    const endpoint = `v1/member/member-baby-feed-record/${feed_id}`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.delete(endpoint).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-baby-feed-record delete error', error);
        reject(error);
      }
    });
  }

  add(feedRecord: MemberBabyFeedRecordInfo): Promise<any> {
    const endpoint = `v1/member/member-baby-feed-record`;

    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};
        params = feedRecord;

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-baby-feed-record add error', error);
        reject(error);
      }
    });
  }

  update(feedRecord: MemberBabyFeedRecordInfo): Promise<any> {
    const endpoint = `v1/member/member-baby-feed-record/${feedRecord.feed_id}`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.put(endpoint, feedRecord).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('member-baby-feed-record update error', error);
        reject(error);
      }
    });
  }
}
