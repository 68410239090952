import { gql } from 'apollo-angular';

export const voteFragment = gql`
  fragment voteBase on VoteInfo {
    vote_id
    vote_title
    vote_desc
    vote_status
    vote_start_date
    vote_feature_image_url
    vote_count
    view_count
    max_answer_count
    pregnancy_tag_taxonomy {
      taxonomy_id
      taxonomy_name
    }
    created_at
    updated_at
  }
`;

export const QUERY_VOTE_DETAIL = gql`
  query getVote($vote_id: Float!) {
    getVote(vote_id: $vote_id) {
      ...voteBase
      options
      vote_comments {
        comment
        vote_comment_id
        user_id
        comment_user {
          avatar
          display_name
          user_id
        }
        created_at
        comment_modified_at
      }
    }
  }
  ${voteFragment}
`;

export const QUERY_VOTE_LIST = gql`
  query {
    getVoteList {
      ...voteBase
    }
  }
  ${voteFragment}
`;
