import { Injectable } from '@angular/core';
import { CodeTaxonomyInfo } from '@mommy/models/CodeTaxonomyInfo.model';
import { TrashObject } from '@mommy/models/Comm.model';
import { ExpertInfo } from '@mommy/models/ExpertInfo.model';
import { ExpertService } from '@mommy/services/expert/expert.service';
import { OthersService } from '@mommy/services/others/others.service';
import { StorageService } from '@mommy/services/storage.service';
import { UtilService } from '@mommy/services/util.service';
import {
  Action,
  createSelector,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import * as _ from 'lodash';
import { AppSettings } from '../../app.settings';
import { AppInitState } from '../app-init/app-init.state';
import { AppUIState } from '../app-ui/app-ui.state';
import { AuthState, AuthStateModel } from '../auth/auth.state';
import { UserState, UserStateModel } from '../user/user.state';
import {
  ExpertClickLog,
  GetExpertDetail,
  InitLocalCacheExperts,
  LoadCacheExperts,
  RefreshExperts,
  SetExpertsToExpertState,
} from './expert.actions';

export interface ExpertStateModel {
  loading: boolean;
  experts: ExpertInfo[];
  detailExperts: ExpertInfo[];
  hasCache: boolean;
}

const defaultExpertState = (): ExpertStateModel => {
  return {
    loading: false,
    experts: [],
    detailExperts: [],
    hasCache: false,
  };
};

@State<ExpertStateModel>({
  name: 'ExpertState',
  defaults: defaultExpertState(),
})
@Injectable()
export class ExpertState implements NgxsAfterBootstrap {
  constructor(
    private storage: StorageService,
    private expertService: ExpertService,
    private util: UtilService,
    private othersService: OthersService
  ) {}

  async ngxsAfterBootstrap(ctx: StateContext<ExpertStateModel | null>) {
    console.log('[ExpertState] ngxsAfterBootstrap');

    // try {
    //   await ctx.dispatch(new LoadCacheMaternityKit()).toPromise();
    //   console.log('load local cache maternitykit success');
    //   // 再呼叫 getAllMaternityKit 來更新 local cache
    //   this.getMaternityKitFromServer(ctx);
    // } catch (error) {
    //   console.warn('LoadCacheMaternityKit error', error);
    //   // 如果沒有 cache, 就去 server 取
    //   this.getMaternityKitFromServer(ctx);
    // }
  }

  // 建立 dynamic selector, 傳入 expert_id, 回傳對應的 expert
  // 這個方法支援 memoized selector
  // Note that each of these selectors have their own separate memoization.
  // Even if two dynamic selectors created in this way are provided the same argument, they will have separate memoization.
  static detailExpert(expert_id: number) {
    return createSelector([ExpertState], (state: ExpertStateModel) => {
      return _.find(state.detailExperts, { expert_id });
    });
  }

  static expertById(expert_id: number) {
    return createSelector([ExpertState], (state: ExpertStateModel) => {
      return _.find(state.experts, { expert_id });
    });
  }

  // @Selector([ExpertState])
  // static experts(state: ExpertStateModel) {
  //   return state.experts;
  // }

  // 專家 state join AppInitState.expert_trash_objects
  @Selector([ExpertState, AppInitState.expert_trash_objects])
  static experts(state: ExpertStateModel, trash_objects: TrashObject[]) {
    return _.chain(state.experts)
      .filter((expert) => {
        return (
          _.findIndex(trash_objects, {
            object_id: expert.expert_id,
            trash_type: 'EXPERT',
          }) === -1
        );
      })
      .value();
  }

  // 首頁 最新更新的4個專家
  @Selector([ExpertState.experts])
  static latestFourExperts(experts: ExpertInfo[]) {
    return _.chain(experts).orderBy(['updated_at'], ['desc']).take(4).value();
  }

  // 專家列表頁, 依據 更新時間排序, 取前 n 筆 (由 AppUIState.page_expert_list_list_count 控制要撈取幾筆)
  // left join UserState 的關注清單
  @Selector([
    ExpertState.experts,
    AuthState,
    UserState,
    AppUIState.page_expert_list_list_count,
  ])
  static expertListTopNSelector(
    experts: ExpertInfo[],
    authState: AuthStateModel,
    userState: UserStateModel,
    page_expert_list_list_count: number
  ) {
    console.log('expertListTopNSelector');
    console.log('authState.mommy_token', authState.mommy_token);
    console.log('userState.mommy_user', userState.mommy_user);
    console.log('page_expert_list_list_count', page_expert_list_list_count);

    // 將有關注的專家列出, 在產出的資料 join 出 has_watch value
    let _watchExperts = [];
    if (
      authState.mommy_token &&
      userState.mommy_user.expert_watchs?.length > 0
    ) {
      _watchExperts = _.cloneDeep(userState.mommy_user.expert_watchs);
      console.log('_watchExperts', _watchExperts);
    }

    return _.chain(experts)
      .map((expert) => {
        if (_.includes(_watchExperts, expert.expert_id)) {
          expert = { ...expert, has_watch: true };
        }
        return expert;
      })
      .orderBy(['updated_at'], ['desc'])
      .take(page_expert_list_list_count)
      .value();
  }

  // 專家搜尋頁, 依據 關鍵字/熱門分類/科別專長/...  過濾, order by 更新時間
  @Selector([
    ExpertState.experts,
    AppUIState.page_expert_search_keyword,
    AppUIState.page_expert_search_list_count,
    AppUIState.page_expert_search_top_categorys,
    AppUIState.page_expert_search_subject_classes,
    AppUIState.page_expert_search_expert2,
    AppUIState.page_expert_search_section2,
  ])
  static expertListWithConditions(
    experts: ExpertInfo[],
    page_expert_search_keyword: string,
    page_expert_search_list_count: number,
    page_expert_search_top_categorys: CodeTaxonomyInfo[],
    page_expert_search_subject_classes: CodeTaxonomyInfo[],
    page_expert_search_expert2: CodeTaxonomyInfo[],
    page_expert_search_section2: CodeTaxonomyInfo[]
  ) {
    console.log('expertList');
    console.log('page_expert_search_keyword', page_expert_search_keyword);
    console.log('page_expert_search_list_count', page_expert_search_list_count);

    return (
      _.chain(experts)
        .filter((expert) => {
          // 關鍵字
          if (page_expert_search_keyword) {
            return expert.expert_name.indexOf(page_expert_search_keyword) > -1;
          } else {
            return true;
          }
        })
        .filter((expert) => {
          // 熱門分類
          if (page_expert_search_top_categorys.length > 0) {
            return (
              _.intersectionBy(
                expert.top_categorys,
                page_expert_search_top_categorys,
                'taxonomy_id'
              ).length > 0
            );
          } else {
            return true;
          }
        })
        .filter((expert) => {
          // 科別專長
          if (page_expert_search_subject_classes.length > 0) {
            return (
              _.intersectionBy(
                expert.subject_classes,
                page_expert_search_subject_classes,
                'taxonomy_id'
              ).length > 0
            );
          } else {
            return true;
          }
        })
        .filter((expert) => {
          // 專業人士
          if (page_expert_search_expert2.length > 0) {
            return (
              _.intersectionBy(
                expert.expert2,
                page_expert_search_expert2,
                'taxonomy_id'
              ).length > 0
            );
          } else {
            return true;
          }
        })
        .filter((expert) => {
          // 所在區域
          if (page_expert_search_section2.length > 0) {
            return (
              _.intersectionBy(
                expert.section2,
                page_expert_search_section2,
                'taxonomy_id'
              ).length > 0
            );
          } else {
            return true;
          }
        })
        .orderBy(['updated_at'], ['desc'])
        // .take(page_expert_search_list_count) // 先移除限制筆數, 先全部顯示
        .value()
    );
  }

  //#region ======================== Action ========================
  @Action(InitLocalCacheExperts)
  async initLocalCacheExperts(ctx: StateContext<ExpertStateModel>) {
    console.log('[Action] InitLocalCacheExperts');

    // const _experts: any = await this.storage.get(
    //   AppSettings.CACHE_KEY_EXPERT_LIST
    // );

    // if (_experts) {
    //   // do nothing
    // } else {
    //   this.getExpertsFromServer(ctx);
    // }

    // 策略調整為：
    // 1. 先載入 local cache，如果沒有就抓 server (full)
    // 2. 載入完 cache 再抓 server 更新的資料 (incremental)
    try {
      await ctx.dispatch(new LoadCacheExperts()).toPromise();
      console.log('load local cache experts success');
      // 再呼叫 getExpertsFromServer 來更新 local cache
      //this.getExpertsFromServer(ctx);
      await ctx.dispatch(new RefreshExperts()).toPromise();
    } catch (error) {
      console.warn('LoadCacheExperts error', error);
      // 如果沒有 cache, 就去 server 取
      //this.getExpertsFromServer(ctx);
      await ctx.dispatch(new RefreshExperts()).toPromise();
    }
  }

  @Action(LoadCacheExperts)
  async loadCacheExperts(ctx: StateContext<ExpertStateModel>) {
    console.log('[Action] LoadCacheExperts');

    const state = ctx.getState();
    const _experts: any = await this.storage.get(
      AppSettings.CACHE_KEY_EXPERT_LIST
    );

    if (_experts) {
      ctx.patchState({
        loading: false,
        experts: _experts,
        hasCache: true,
      });
    } else {
      throw new Error('no cache');
    }
  }

  @Action(RefreshExperts)
  async refreshExperts(ctx: StateContext<ExpertStateModel>) {
    console.log('[Action] RefreshExperts');
    this.getExpertsFromServer(ctx);
  }

  @Action(GetExpertDetail)
  async getExpertDetail(
    ctx: StateContext<ExpertStateModel>,
    action: GetExpertDetail
  ) {
    console.log('[Action] GetExpertDetail');
    const state = ctx.getState();

    // 先載入 local cache
    const _expert: any = await this.storage.get('expert-' + action.expert_id);

    // await this.util.sleep(3000);
    const _detailExperts = state.detailExperts;
    const new_detailExperts = _.cloneDeep(_detailExperts);

    if (_expert) {
      // 先判斷 detailExperts 是否已經存在, 如果有就置換, 沒有就新增至 detailExperts
      const index = _.findIndex(new_detailExperts, {
        expert_id: action.expert_id,
      });
      if (index > -1) {
        new_detailExperts.splice(index, 1, _expert);
      } else {
        new_detailExperts.push(_expert);
      }

      ctx.patchState({
        detailExperts: new_detailExperts,
      });

      this.getExpertDetailFromServer(ctx, action.expert_id); // 再呼叫 getExpertDetail 來更新資料
    } else {
      // 如果沒有 cache, 就去 server 取
      await this.getExpertDetailFromServer(ctx, action.expert_id);
    }
  }

  @Action(SetExpertsToExpertState)
  async setExpertsToExpertState(
    ctx: StateContext<ExpertStateModel>,
    action: SetExpertsToExpertState
  ) {
    console.log('[Action] SetExpertsToExpertState');
    const state = ctx.getState();

    ctx.patchState({
      experts: action.payload,
    });
  }

  @Action(ExpertClickLog)
  async ExpertClickLog(
    ctx: StateContext<ExpertStateModel>,
    { expert_id }: ExpertClickLog
  ) {
    console.log('[Action] ExpertClickLog');

    try {
      const result: any = await this.othersService.expertClickLog(expert_id);
      console.log('expertClickLog result', result);
    } catch (error) {
      console.error('expertClickLog error', error);
    }
  }
  //#endregion ======================== Action ========================

  private async getExpertsFromServer(ctx: StateContext<ExpertStateModel>) {
    // try read data from server
    console.log('getExpertsFromServer');
    try {
      const experts: any = await this.expertService.getExpertList();
      console.log('experts', experts);
      // await this.storage.set(AppSettings.CACHE_KEY_EXPERT_LIST, experts);
      // await ctx.dispatch(new LoadCacheExperts()).toPromise();
      // console.log('load local cache experts success');

      ctx.patchState({
        loading: false,
        experts,
        hasCache: true,
      });
    } catch (error2) {
      console.warn('getExpertList error', error2);
    }
  }

  private async getExpertDetailFromServer(
    ctx: StateContext<ExpertStateModel>,
    expert_id: number
  ) {
    // try read data from server
    console.log('getExpertDetailFromServer');
    try {
      const expert = await this.expertService.getExpertDetail(expert_id);
      console.log('expert', expert);
      await this.storage.set('expert-' + expert_id, expert);

      const state = ctx.getState();
      const _detailExperts = state.detailExperts;
      const new_detailExperts = _.cloneDeep(_detailExperts);

      const index = _.findIndex(new_detailExperts, { expert_id });
      if (index > -1) {
        new_detailExperts.splice(index, 1, expert);
      } else {
        new_detailExperts.push(expert);
      }

      ctx.patchState({
        detailExperts: new_detailExperts,
      });
    } catch (error2) {
      console.warn('getExpertDetail error', error2);
    }
  }
}
