import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MemberBabyInfo } from '@mommy/models/MemberBabyInfo.model';
import { MyInfo } from '@mommy/models/MyInfo.model';
import { UserState } from '@mommy/state/user/user.state';
import { Select } from '@ngxs/store';
import { intervalToDuration, parse } from 'date-fns';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-switch-baby-modal',
  templateUrl: './switch-baby-modal.page.html',
  styleUrls: ['./switch-baby-modal.page.scss'],
})
export class SwitchBabyModalPage implements OnInit {
  @Select(UserState.mommy_user) mommy_user$;
  private ngUnsubscribe = new Subject();
  myInfo: MyInfo = {};

  babys: MemberBabyInfo[] = [];

  constructor(public modalController: ModalController) {}

  ngOnInit() {
    this.mommy_user$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((mommy_user) => {
        console.log('mommy_user$', mommy_user);
        // 計算寶寶的年齡
        const today = new Date();
        const new_babyies = _.map(mommy_user?.member_babies, (baby) => {
          const b_day = parse(baby.birthday, 'yyyy-MM-dd', new Date());
          const babyDuration = intervalToDuration({
            start: b_day,
            end: today,
          });

          const months = babyDuration.years * 12 + babyDuration.months;
          const new_baby = _.cloneDeep(baby);
          new_baby.birthday_duration_desc = `${months} 個月 ${babyDuration.days} 天`;
          return new_baby;
        });
        this.babys = new_babyies || [];
      });
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  selectBaby(baby: MemberBabyInfo) {
    console.log('selectBaby', baby);
    this.modalController.dismiss({
      dismissed: true,
      member_baby: baby,
    });
  }
}
