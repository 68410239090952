export class MemberSignIn {
  static readonly type = '[MemberActionLog] MemberSignIn';
}

export class MemberWatchVideoDone {
  static readonly type = '[MemberActionLog] MemberWatchVideoDone';
  constructor(
    public post_id: number,
    public member_pregnancy_tag_taxonomy_id: number
  ) {}
}

export class MemberHospitalElineClick {
  static readonly type = '[MemberActionLog] MemberHospitalElineClick';
  constructor(public hospital_id: number) {}
}

export class MemberPostClick {
  static readonly type = '[MemberActionLog] MemberPostClick';
  constructor(public post_id: number) {}
}
