import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ExpertInfo } from '@mommy/models/ExpertInfo.model';
import { UserState } from '@mommy/state/user/user.state';
import { Select } from '@ngxs/store';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-expert-title',
  templateUrl: './expert-title.component.html',
  styleUrls: ['./expert-title.component.scss'],
})
export class ExpertTitleComponent implements OnInit, OnDestroy {
  @Select(UserState.mommy_user) mommy_user$;
  private ngUnsubscribe = new Subject();

  @Output() expertAddWatchOnClick = new EventEmitter<ExpertInfo>();
  @Output() expertRemoveWatchOnClick = new EventEmitter<ExpertInfo>();
  _expert: ExpertInfo;
  _expert_tag: string; // 副標題
  _fans_count: number;
  _like_keep_count: number;
  _has_watch = false;

  constructor() {}

  ngOnInit() {
    // user$, 訂閱 user 資料, 以判斷是否有加入關注
    this.mommy_user$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((mommy_user) => {
        console.log('mommy_user$', mommy_user);

        if (mommy_user) {
          const expert = _.find(mommy_user.expert_watchs, {
            expert_id: this._expert.expert_id,
          });
          if (expert) {
            this._has_watch = true;
          } else {
            this._has_watch = false;
          }
        } else {
          this._has_watch = false;
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get expert(): ExpertInfo {
    return this._expert;
  }

  @Input() set expert(value: ExpertInfo) {
    if (value) {
      this._expert = value;

      let _section = '';
      if (value.subject_classes?.length > 0) {
        _section = value.subject_classes[0].taxonomy_name;
      }

      if (value.outpatients?.length > 0) {
        this._expert_tag = `${_section}-${value.outpatients[0]?.outpatient_hospital?.hospital_name}`;
      } else {
        this._expert_tag = `${_section}`;
      }

      this._fans_count = value.watch_count || 0;
      const _like_count = value.like_count || 0;
      const _keep_count = value.keep_count || 0;
      this._like_keep_count = _like_count + _keep_count;
      console.log('this._like_keep_count', this._like_keep_count);
    } else {
      this._expert = {};
    }
  }

  addWatchClick(event) {
    event.stopPropagation();
    this.expertAddWatchOnClick.emit(this._expert);
  }

  removeWatchClick(event) {
    event.stopPropagation();
    this.expertRemoveWatchOnClick.emit(this._expert);
  }
}
