import { Injectable } from '@angular/core';
import { APIResponse } from '@mommy/models/Comm.model';
import { Api } from '@mommy/services/api';

@Injectable({
  providedIn: 'root',
})
export class LuckyService {
  constructor(private api: Api) {}

  get_list(data?: any): Promise<any> {
    const endpoint = 'v1/platform/code-lucky2';

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.get(endpoint).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('code-lucky2 list get error', error);
        reject(error);
      }
    });
  }

  draw(data: any): Promise<any> {
    const endpoint = 'v1/member/member-lucky2/draw';

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.post(endpoint, data).toPromise()
        ));
        console.log('draw result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('draw list get error', error);
        reject(error);
      }
    });
  }

  get_Hist_list(data?: any): Promise<any> {
    const endpoint = 'v1/member/member-lucky2';

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.get(endpoint).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('code-lucky2 list get error', error);
        reject(error);
      }
    });
  }
  editData(id: number, data?: any): Promise<any> {
    const endpoint = `v1/member/member-lucky2/${id}`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.put(endpoint,data).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('editData error', error);
        reject(error);
      }
    });
  }
}
