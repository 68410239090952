import { Pipe, PipeTransform } from '@angular/core';
import { formatDistance, parseISO } from 'date-fns';
import zhTW from 'date-fns/locale/zh-TW';

@Pipe({ name: 'timeAgo' })
export class TimeAgoPipe implements PipeTransform {
  public transform(inputDate: any): string {
    // console.log('[TimeAgoPipe] inputDate: ', inputDate);
    try {
      let past;
      if (typeof inputDate === 'string') {
        past = parseISO(inputDate);
      } else {
        past = new Date(inputDate);
      }
      const now = +new Date();

      // console.log('[TimeAgoPipe] past: ', past);
      // console.log('[TimeAgoPipe] now: ', now);

      const result = formatDistance(past, now, {
        locale: zhTW,
      });

      return result;
    } catch (error) {
      return 'Invalid date';
    }
  }
}
