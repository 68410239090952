import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { AppHomeState } from './app-home/app-home.state';
import { AppInitState } from './app-init/app-init.state';
import { AppUIState } from './app-ui/app-ui.state';
import { AuthState } from './auth/auth.state';
import { BabyDiaryPostState } from './baby-diary-post/baby-diary-post.state';
import { ChannelState } from './channel/channel.state';
import { ECCommodityState } from './ec/ec-commodity/ec-commodity.state';
import { ECOrderState } from './ec/ec-order/ec-order.state';
import { ElineHospitalState } from './eline-hospital/eline-hospital.state';
import { ExpertState } from './expert/expert.state';
import { HospitalState } from './hospital/hospital.state';
import { LookupState } from './lookup/lookup.state';
import {
  MaternityKitState,
  MemberMaternityKitState,
} from './maternitykit/maternitykit.state';
import { MemberActionLogState } from './member-action-log/member-action-log.state';
import { MemberBabyFeedRecordState } from './member-baby-feed-record/member-baby-feed-record.state';
import { MemberBabyGrowRecordState } from './member-baby-grow-record/member-baby-grow-record.state';
import { MemberBabyState } from './member-baby/member-baby.state';
import { MemberExpertWatchState } from './member-expert-watch/member-expert-watch.state';
import { MemberPostCommentState } from './member-post-comment/member-post-comment.state';
import { MemberPostKeepState } from './member-post-keep/member-post-keep.state';
import { MemberPostLikeState } from './member-post-like/member-post-like.state';
import { MemberShippingInfoState } from './member-shipping-info/member-shipping-info.state';
import { MemberVoteCommentState } from './member-vote-comment/member-vote-comment.state';

import { ngxsConfig } from './ngxs.config';
import { PostState } from './post/post.state';
import { PregnancyPostState } from './pregnancy-post/pregnancy-post.state';
import { TaskState } from './task/task.state';
import { TaxonomyState } from './taxonomy/taxonomy.state';
import { UserState } from './user/user.state';
import { VoteState } from './vote/vote.state';
import { ZipcodeState } from './zipcode/zipcode.state';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forRoot(
      [
        AppInitState,
        AppHomeState,
        AppUIState,
        AuthState,
        UserState,
        TaxonomyState,
        TaskState,
        LookupState,
        ZipcodeState,
        MaternityKitState,
        MemberMaternityKitState,
        BabyDiaryPostState,
        PregnancyPostState,
        HospitalState,
        ExpertState,
        PostState,
        MemberActionLogState,
        MemberBabyState,
        MemberExpertWatchState,
        MemberPostKeepState,
        MemberPostLikeState,
        MemberPostCommentState,
        MemberVoteCommentState,
        MemberBabyGrowRecordState,
        MemberBabyFeedRecordState,
        MemberShippingInfoState,
        VoteState,
        ElineHospitalState,
        ChannelState,
        ECCommodityState,
        ECOrderState,
      ],
      ngxsConfig
    ),
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
  exports: [NgxsModule],
})
export class StateModule {}
