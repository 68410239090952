import { gql } from 'apollo-angular';

export const hospitalFragment = gql`
  fragment hospitalBase on HospitalInfo {
    hospital_id
    hospital_name
    hospital_avatar_url
    hospital_feature_image_url
    latitude
    longitude
    zip_code
    phone_number
    city
    district
    address
    updated_at
    status
    level
    team_id
    eline_enable
    top_categorys {
      taxonomy_id
      taxonomy_name
    }
    subject_classes {
      taxonomy_id
      taxonomy_name
    }
    medical_cares {
      taxonomy_id
      taxonomy_name
    }
    hardware_equipments {
      taxonomy_id
      taxonomy_name
    }
    life_functions {
      taxonomy_id
      taxonomy_name
    }
    section2 {
      taxonomy_id
      taxonomy_name
    }
  }
`;

export const QUERY_HOSPITAL_DETAIL = gql`
  query getHospital($hospital_id: Float!) {
    getHospital(hospital_id: $hospital_id) {
      ...hospitalBase
      hospital_desc
      outpatient_time
      phone_number2
      email
      ig_url
      telegram
      whatsapp
      line
      website_url
      facebook_url
      view_count
      eline_count
      outpatients {
        expert_id
        outpatient
        job_title
        outpatient_expert {
          expert_name
          expert_avatar_url
          subject_classes {
            taxonomy_id
            taxonomy_name
          }
        }
      }
    }
  }
  ${hospitalFragment}
`;

export const QUERY_HOSPITAL_LIST = gql`
  query getHospitalList($updated_at: Float!) {
    getHospitalList(updated_at: $updated_at) {
      ...hospitalBase
    }
  }
  ${hospitalFragment}
`;
