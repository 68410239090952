export class GetMemberPostLikes {
  static readonly type = '[MemberPostLike] GetMemberPostLikes';
}

export class AddMemberPostLike {
  static readonly type = '[MemberPostLike] AddMemberPostLike';
  constructor(public post_id: number) {}
}

export class RemoveMemberPostLike {
  static readonly type = '[MemberPostLike] RemoveMemberPostLike';
  constructor(public post_id: number) {}
}
