import { Component, Input, OnInit } from '@angular/core';
import { OrderInfo } from '@mommy/models/ec/CustomerBillInfo.model';
import { format, parseISO } from 'date-fns';
import * as _ from 'lodash';

@Component({
  selector: 'app-ec-order-info',
  templateUrl: './ec-order-info.component.html',
  styleUrls: ['./ec-order-info.component.scss'],
})
export class EcOrderInfoComponent implements OnInit {
  _order: OrderInfo;
  _purchaseAt;
  _commoditys_total = 0;
  _shipping_total = 0;
  _discount_total = 0;

  _display: 'all' | '' = '';

  constructor() {}

  ngOnInit() {}

  get order(): any {
    return this._order;
  }

  @Input() set order(value: OrderInfo) {
    console.log('order', value);
    this._order = value;
    if (value) {
      this._purchaseAt = format(
        parseISO(value?.purchaseAt),
        'yyyy/MM/dd HH:mm'
      );

      const commoditys_amount = _.find(value.orderTotals, {
        typeName: '商品總計',
      });
      this._commoditys_total = commoditys_amount?.price || 0;

      const shipping_amount = _.find(value.orderTotals, { typeName: '運費' });
      this._shipping_total = shipping_amount?.price || 0;

      const discount_amount = _.find(value.orderTotals, { typeName: '折扣' });
      this._discount_total = discount_amount?.price || 0;
    }
  }

  get display(): any {
    return this._display;
  }

  @Input() set display(value: 'all' | '') {
    console.log('display', value);
    this._display = value || '';
  }
}
