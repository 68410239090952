import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mommy/environments/environment';
import { IGNORE_TOKEN } from '../token.interceptor';

/**
 * Api is a generic REST Api handler. Set your API url first.
 */
@Injectable({
  providedIn: 'root',
})
export class LineUtilService {
  url = '';
  debug_url = '';

  myheader: HttpHeaders = new HttpHeaders();

  constructor(public http: HttpClient) {}

  getLineToken(code, redirect_uri) {
    console.log('getLineToken code', code, redirect_uri);

    // const formData: any = new FormData();
    // formData.append('code', code);
    // formData.append('grant_type', 'authorization_code');
    // formData.append('redirect_uri', environment.line_callback_url);
    // formData.append('client_id', environment.line_client_id);

    const body = new URLSearchParams();
    body.set('code', code);
    body.set('grant_type', 'authorization_code');
    body.set('redirect_uri', redirect_uri);
    body.set('client_id', environment.line_client_id);
    body.set('client_secret', environment.line_client_secret);

    const context = new HttpContext();
    context.set(IGNORE_TOKEN, true);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );

    const url = 'https://api.line.me/oauth2/v2.1/token';
    return this.http
      .post(url, body.toString(), { headers, context })
      .toPromise();
  }

  getLineProfile(id_token) {
    console.log('getLineProfile id_token', id_token);

    const url = 'https://api.line.me/oauth2/v2.1/verify';

    const context = new HttpContext();
    context.set(IGNORE_TOKEN, true);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const body = new URLSearchParams();
    body.set('id_token', id_token);
    body.set('client_id', environment.line_client_id);

    return this.http
      .post(url, body.toString(), { context, headers })
      .toPromise();
  }

  set_to_debug() {
    console.log('api url set to testing environment...');
    this.url = this.debug_url;
  }

  get(endpoint: string, params?: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
      };
    }

    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      // eslint-disable-next-line guard-for-in
      for (const k in params) {
        reqOpts.params.set(k, params[k]);
      }
    }

    return this.http.get(this.url + '/' + endpoint, reqOpts).toPromise();
  }

  post(endpoint: string, body: any, options?) {
    console.log('api post endpoint', this.url + '/' + endpoint);
    console.log('api post body', body);
    console.log('api post options', options);
    return this.http.post(this.url + '/' + endpoint, body, options).toPromise();
  }

  // put(endpoint: string, body: any, options?: RequestOptions) {
  //   return this.http.put(this.url + '/' + endpoint, body, options);
  // }

  // delete(endpoint: string, body: any, options?: RequestOptions) {
  //   return this.http.post(this.url + '/' + endpoint, body, options);
  // }

  // patch(endpoint: string, body: any, options?: RequestOptions) {
  //   return this.http.put(this.url + '/' + endpoint, body, options);
  // }
}
