import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CodeTaxonomyInfo } from '@mommy/models/CodeTaxonomyInfo.model';
import { HospitalInfo } from '@mommy/models/HospitalInfo.model';

@Component({
  selector: 'app-hospital-list-item',
  templateUrl: './hospital-list-item.component.html',
  styleUrls: ['./hospital-list-item.component.scss'],
})
export class HospitalListItemComponent implements OnInit {
  @Output() hospitalOnClick = new EventEmitter<HospitalInfo>();
  @Output() hospitalPhoneOnClick = new EventEmitter<HospitalInfo>();
  @Output() hospitalMapOnClick = new EventEmitter<HospitalInfo>();

  _hospital: HospitalInfo;
  _address: string;
  _top_categorys: CodeTaxonomyInfo[] = [];
  _badge_icon = '';

  constructor() {}

  ngOnInit() {}

  get hospital(): HospitalInfo {
    return this._hospital;
  }

  @Input() set hospital(value: HospitalInfo) {
    console.log('hospital', value);
    this._hospital = value;
    this._address = `${value.city}${value.district}${value.address}`;

    // // 處理熱門分類 badge icon
    // this._top_categorys = [];
    // _.forEach(value.top_categorys, (elem) => {
    //   elem = {
    //     ...elem,
    //     icon: AppSettings.HospitalBadgeDict[elem.taxonomy_name],
    //   };
    //   this._top_categorys.push(elem);
    // });

    // // 優先挑選 學苑推薦 > 孕關懷特約診所 > 其他
    // if (_.find(this._top_categorys, { taxonomy_name: '學苑推薦' })) {
    //   this._badge_icon = AppSettings.HospitalBadgeDict['學苑推薦'];
    // } else if (
    //   _.find(this._top_categorys, { taxonomy_name: '孕關懷特約診所' })
    // ) {
    //   this._badge_icon = AppSettings.HospitalBadgeDict['孕關懷特約診所'];
    // } else {
    //   if (this._top_categorys.length > 0) {
    //     this._badge_icon = this._top_categorys[0].icon;
    //   }
    // }
  }

  hospitalClick() {
    this.hospitalOnClick.emit(this._hospital);
  }

  hospitalPhoneClick() {
    this.hospitalPhoneOnClick.emit(this._hospital);
  }

  hospitalMapClick() {
    this.hospitalMapOnClick.emit(this._hospital);
  }
}
