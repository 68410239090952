import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mommy/environments/environment';

@Injectable()
export class ApiOmProvider {
  news_url = '';
  omstatus_url = '';
  google_doc_url = '';
  latest_app_version_check_url = ''; //檢查app是否為最新版本

  constructor(public http: HttpClient) {
    console.log('Hello ApiOmProvider Provider');

    // this.latest_app_version_check_url = this.global.COMMON_SERVER_URL2 + 'app_version.json';
    // this.news_url = this.global.COMMON_SERVER_URL2 + 'news.json';
    this.omstatus_url = environment.OM_STATUS_URL;
    // this.google_doc_url = this.global.COMMON_SERVER_URL2 + 'googledoc.json';
  }

  // get_latest_app_version() {

  //   return new Promise((resolve, reject) => {
  //     console.log('get_latest_app_version');

  //     this.http.get(this.latest_app_version_check_url + '?' + new Date().getTime(), { observe: 'response' }).toPromise()
  //       .then((response: any) => {
  //         var results: any = {};
  //         results.data = response.body;
  //         results.headers = response.headers;
  //         results.status = response.status;
  //         resolve(results);
  //       })
  //       .catch((error) => {
  //         console.log("get_latest_app_version," + error);
  //         reject(error);
  //       })
  //   });

  // }

  // get_news_data() {

  //   return new Promise((resolve, reject) => {

  //     this.http.get(this.news_url + '?' + new Date().getTime(), { observe: 'response' }).toPromise()
  //       .then((response: any) => {
  //         var results: any = {};
  //         results.data = response.body;
  //         results.headers = response.headers;
  //         results.status = response.status;
  //         resolve(results);
  //       })
  //       .catch((error) => {
  //         console.log("get_news_data," + error);
  //         reject(error);
  //       })
  //   });

  // }

  get_om_status() {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.omstatus_url + '?' + new Date().getTime(), {
          observe: 'response',
        })
        .toPromise()
        .then((response: any) => {
          var results: any = {};
          results.data = response.body;
          results.headers = response.headers;
          results.status = response.status;
          resolve(results);
        })
        .catch((error) => {
          console.log('get_news_data,' + error);
          reject(error);
        });
    });
  }

  // get_google_doc_url() {

  //   return new Promise((resolve, reject) => {

  //     this.http.get(this.google_doc_url + '?' + new Date().getTime(), { observe: 'response' }).toPromise()
  //       .then((response: any) => {
  //         var results: any = {};
  //         results.data = response.body;
  //         results.headers = response.headers;
  //         results.status = response.status;
  //         resolve(results);
  //       })
  //       .catch((error) => {
  //         console.log("get_news_data," + error);
  //         reject(error);
  //       })
  //   });

  // }
}
