import { StateUtilsService } from './../state-utils.service';
/* eslint-disable no-var */
/* eslint-disable prefer-const */
import { Injectable } from '@angular/core';
import {
  Channel,
  ElineUser,
  MessageType,
  Post,
} from '@mommy/models/Eline.model';
import { AppSettings } from 'app/app.settings';
import * as firebase from 'firebase/compat/app';
import * as lodash from 'lodash';
import { ChatService } from './chat-service';
import { ElineUserService } from './eline-user-service';

export interface CheckChannelParams {
  createChannelIfNotExist?: boolean;
  channel?: Channel;
  selectedHospital?: any;
  selectedPatient?: any;
  user?: ElineUser;
  sendWelcomeMessage?: boolean; // 是否傳送 welcome message, 目前 for 媽咪學苑建立預設channel判斷使用
}

@Injectable({
  providedIn: 'root',
})
/* 主要是將 chat page 裡面的function 移至這個 service 內 */
export class MyChatService {
  constructor(
    private chatsvc: ChatService,
    private stateUtils: StateUtilsService,
    private userService: ElineUserService
  ) {
    console.log('Hello MyChatService');
  }

  // check firebase 是否已經建立好 channel 了
  // params
  // 1. createChannelIfNotExist
  // 2. selectedHospital
  // 3. selectedPatient
  // 4. user
  checkChannelInstance(params: CheckChannelParams) {
    console.log('checkChannelInstance params', params);

    return new Promise(async (resolve, reject) => {
      const fbapp = firebase.default.app();

      let channel_id;

      if (params.channel) {
        // 已經有channel了
        resolve(params.channel);
        return;
      }

      if (params.user.roles === 'user') {
        if (!params.selectedHospital) {
          reject('hospital is null');
          return;
        }

        channel_id =
          params.selectedHospital.team_id + '-' + params.user.user_id;
      } else {
        if (!params.selectedPatient) {
          reject('patient is null');
          return;
        }

        channel_id =
          params.selectedPatient.team_id + '-' + params.selectedPatient.user_id;
      }
      console.warn('channel_id', channel_id);

      if (!channel_id) {
        reject('no channel id');
        return;
      }

      let channel;

      // 檢查 store 是否已經有 channel 了
      // store 有, 表示 firebase一定有(才能建立 store's state)
      channel = this.stateUtils.getChannelById(channel_id);

      if (channel) {
        console.log('store 內有 channel', channel);
        resolve(channel);
        return;
      }

      try {
        channel = await this.chatsvc
          .getChannel(fbapp, channel_id)
          // .pipe(takeUntil(of(true).pipe(delay(5000))))
          .toPromise();
        if (channel) {
          console.log('channel exist', channel);
          resolve(channel);
          return;
        } else {
          console.log('channel not exist', channel);
        }
      } catch (error) {
        console.log('getChannel error ', error);
      }

      if (params.createChannelIfNotExist) {
        try {
          // 先建立security rule
          const free_channel_result =
            await this.userService.update_channel_id_to_free(channel_id);
          const new_channel = await this.create_channel(
            fbapp,
            params,
            channel_id
          );
          resolve(new_channel);
        } catch (error) {
          console.error('create channel error', error);
          reject(error);
        }
      } else {
        reject('channel not exist');
      }
    });
  }

  // 建立 channel
  private create_channel(
    fbapp: firebase.default.app.App,
    params: CheckChannelParams,
    channel_id: string
  ) {
    console.log('create_channel, channel_id: ', channel_id);

    return new Promise((resolve, reject) => {
      let ts = firebase.default.database.ServerValue.TIMESTAMP;
      let channel: Channel = <Channel>{};

      channel.cat = ts;
      channel.cid = channel_id;
      channel.lp = '';
      channel.lpat = ts;

      if (params.user.roles === 'user') {
        channel.tdn =
          params.selectedHospital.team_name +
          '-' +
          params.selectedHospital.team_subname;
        channel.ta = params.selectedHospital.avatar;
        channel.udn = params.user.display_name;
        channel.ua = params.user.avatar;
        channel.tid = params.selectedHospital.team_id;
        channel.uid = params.user.user_id;
      } else {
        // for多團隊調整
        var teams = params.user.teams;
        var team = lodash.find(teams, {
          team_id: params.selectedPatient.team_id,
        });

        if (team) {
          console.log('team', team);
          channel.tdn = team.team_name + '-' + team.team_subname;
          channel.ta = team.team_avatar;
        } else {
          reject('建立Channel失敗(no team_id)');
          return;
        }

        channel.udn = params.selectedPatient.display_name;
        channel.ua = params.selectedPatient.avatar;
        channel.uid = params.selectedPatient.user_id;
        // for多團隊調整
        channel.tid = params.selectedPatient.team_id;
      }
      console.warn('createChannel: ', channel);
      this.chatsvc
        .createChannel(fbapp, channel)
        .then(() => {
          console.log('create channel success..');
          channel.first_chat_flag = true;

          if (params.sendWelcomeMessage) {
            this.send_welcome_message(fbapp, channel, params.selectedHospital);
          }

          resolve(channel);
        })
        .catch((err) => {
          console.error('create channel error', err);
          reject(err);
        });

      // 先開放 channel rule ==> 似乎不需要, 因為新的channel會呼叫link_team api, 後端會重新計算該user's channel & 更新到 firebase's access_user
      // this.userService
      //   .update_channel_id_to_free(channel_id)
      //   .then((resp) => {
      //     console.log('set channel init rule done');
      //   })
      //   .catch((err) => {
      //     console.log('set channel init rule fail', err);
      //   });
    });
  }

  private send_welcome_message(
    fbapp: firebase.default.app.App,
    channel,
    hospital
  ) {
    console.log('send_welcome_message');

    let ts = firebase.default.database.ServerValue.TIMESTAMP;
    let new_msg: Post = <Post>{};
    new_msg.cat = ts;
    new_msg.uat = ts;
    new_msg.uid = 't' + channel.tid; // teamid
    new_msg.un = AppSettings.AUTO_REPLY_DISPLAY_NAME; // team's user才有值'自動回覆'
    new_msg.m = hospital.welcome_message; // 醫護端如果要發送 welcome message, 需要調整api以取得 team's welcome_message設定值 ==> 後端 jerry 處理了
    new_msg.t = MessageType.TEXT;
    new_msg.rs = '0';

    console.log('welcome new_msg', new_msg);
    // this.messages.push(new_msg);

    this.chatsvc
      .sendMessage(fbapp, channel.cid, new_msg)
      .then((data) => {
        console.log(data);
        console.log('send message to firebase done');
      })
      .catch((err) => {
        console.error('send welcome message error', err);
      });
  }
}
