import { Injectable } from '@angular/core';
import { MemberShippingInfo } from '@mommy/models/MemberShippingInfo.model';
import { MemberShippingInfoService } from '@mommy/services/member/member-shipping-info.service';
import { StorageService } from '@mommy/services/storage.service';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  AddMemberShippingInfo,
  GetMemberShippingInfos,
  ModifyMemberShippingInfo,
  RemoveMemberShippingInfo,
} from './member-shipping-info.actions';

export interface MemberShippingInfoStateModel {
  loading: boolean;
  member_shipping_infos: MemberShippingInfo[];
  hasCache: boolean;
}

const defaultMemberShippingInfoState = (): MemberShippingInfoStateModel => {
  return {
    loading: false,
    member_shipping_infos: [],
    hasCache: false,
  };
};

@State<MemberShippingInfoStateModel>({
  name: 'MemberShippingInfoState',
  defaults: defaultMemberShippingInfoState(),
})
@Injectable()
export class MemberShippingInfoState {
  constructor(
    private storage: StorageService,
    private memberShippingInfoService: MemberShippingInfoService,
    private store: Store
  ) {}

  @Selector([MemberShippingInfoState])
  static member_shipping_infos(state: MemberShippingInfoStateModel) {
    return state.member_shipping_infos;
  }

  @Action(GetMemberShippingInfos)
  async GetMemberShippingInfos(
    ctx: StateContext<MemberShippingInfoStateModel>
  ) {
    console.log('[Action] GetMemberShippingInfos');

    try {
      const data: any = await this.memberShippingInfoService.get_list();
      console.log('GetMemberShippingInfos success', data);

      ctx.patchState({
        member_shipping_infos: data,
      });
    } catch (error) {
      console.warn('GetMemberShippingInfos error', error);
      throw error;
    }
  }

  @Action(AddMemberShippingInfo)
  async AddMemberShippingInfo(
    ctx: StateContext<MemberShippingInfoStateModel>,
    { payload }
  ) {
    console.log('[Action] AddMemberShippingInfo');

    try {
      const result = await this.memberShippingInfoService.add(payload);
      console.log('AddMemberShippingInfo success', result);
      this.store.dispatch(new GetMemberShippingInfos());
    } catch (error) {
      console.error('AddMemberShippingInfo error', error);
      throw error;
    }
  }

  @Action(RemoveMemberShippingInfo)
  async RemoveMemberShippingInfo(
    ctx: StateContext<MemberShippingInfoStateModel>,
    { member_shipping_info_id }
  ) {
    console.log('[Action] RemoveMemberShippingInfo');

    try {
      const result = await this.memberShippingInfoService.delete(
        member_shipping_info_id
      );
      console.log('RemoveMemberShippingInfo success', result);
      this.store.dispatch(new GetMemberShippingInfos());
    } catch (error) {
      console.error('RemoveMemberShippingInfo error', error);
      throw error;
    }
  }

  @Action(ModifyMemberShippingInfo)
  async ModifyMemberShippingInfo(
    ctx: StateContext<MemberShippingInfoStateModel>,
    action: ModifyMemberShippingInfo
  ) {
    console.log('[Action] ModifyMemberShippingInfo');

    try {
      const result = await this.memberShippingInfoService.update(
        action.payload
      );
      console.log('ModifyMemberShippingInfo success', result);
      this.store.dispatch(new GetMemberShippingInfos());
    } catch (error) {
      console.error('ModifyMemberShippingInfo error', error);
      throw error;
    }
  }
}
