import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { UtilService } from '@mommy/services/util.service';
import { AuthState } from '@mommy/state/auth/auth.state';
import { Store } from '@ngxs/store';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store,
    private util: UtilService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('[canActivate] route.data', route.data);

    // 判斷要檢查哪幾個 token, MOMMY/ELINE/EC
    if (route.data) {
      // check route.data.types 的token 是否 authState 都具備
      let isAuth = true;
      const types: string[] = route.data.types;
      types.forEach((element) => {
        if (element === 'MOMMY') {
          const mommy_isAuthenticated = this.store.selectSnapshot(
            AuthState.isMommyAuthenticated
          );
          if (!mommy_isAuthenticated) {
            isAuth = false;
          }
        }

        if (element === 'ELINE') {
          const eline_isAuthenticated = this.store.selectSnapshot(
            AuthState.isElineAuthenticated
          );
          if (!eline_isAuthenticated) {
            isAuth = false;
          }
        }

        if (element === 'EC') {
          const ec_isAuthenticated = this.store.selectSnapshot(
            AuthState.isECAuthenticated
          );
          if (!ec_isAuthenticated) {
            isAuth = false;
          }
        }
      });

      if (!isAuth) {
        this.util
          .showLoginPopupModal()
          .then((res) => {
            if (res) {
              this.router.navigate(['/login']);
            }
          })
          .catch((err) => {
            console.log('[showLoginPopupModal] user cancel', err);
          });
      }

      return isAuth;
    } else {
      // 沒有定義 route.data 表示沒有限制
      return true;
    }

    // var user: AuthUser = this.userContextService.user$.getValue();
    // console.log('[canActivate] currentUser', user);
    // console.log('[canActivate] route.url', route.url);
    // console.log('[canActivate] route.data', route.data);

    // if (user != null) {
    //   // check route.data.roles 是否與 user.role相符

    //   if (route.data) {
    //     const exists = _.indexOf(route.data.roles, user.role_name);
    //     console.log('exists', exists);

    //     if (exists == -1) {
    //       // redirect to accessdeny page
    //       this.router.navigate(['/error403']);
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   } else {
    //     // 沒有定義 route.data 表示沒有限制
    //     return true;
    //   }
    // }

    // // not logged in so redirect to login page with the return url and return false
    // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    // return false;
  }
}
