import { Injectable } from '@angular/core';
import { CodeTaxonomyInfo } from '@mommy/models/CodeTaxonomyInfo.model';
import { StorageService } from '@mommy/services/storage.service';
import { TaxonomyService } from '@mommy/services/taxonomy/taxonomy.service';
import {
  Action,
  createSelector,
  NgxsAfterBootstrap,
  State,
  StateContext,
} from '@ngxs/store';
import { LoadCacheTaxonomy } from './taxonomy.actions';

export interface TaxonomyStateModel {
  loading: boolean;
  taxonomies: CodeTaxonomyInfo[];
  hasCache: boolean;
}

const defaultTaxonomyState = (): TaxonomyStateModel => {
  return {
    loading: false,
    taxonomies: [],
    hasCache: false,
  };
};

@State<TaxonomyStateModel>({
  name: 'TaxonomyState',
  defaults: defaultTaxonomyState(),
})
@Injectable()
export class TaxonomyState implements NgxsAfterBootstrap {
  constructor(
    private storage: StorageService,
    private taxonomySvc: TaxonomyService
  ) {}

  async ngxsAfterBootstrap(ctx: StateContext<TaxonomyStateModel | null>) {
    console.log('[TaxonomyState] ngxsAfterBootstrap');

    const getTaxonomyFromServer = async () => {
      // try read data from server
      console.log('getTaxonomyFromServer');
      try {
        const taxonomies = await this.taxonomySvc.getAllTaxonomy();
        console.log('taxonomies', taxonomies);
        await this.storage.set('taxonomies', taxonomies);
        await ctx.dispatch(new LoadCacheTaxonomy()).toPromise();
      } catch (error2) {
        console.warn('getAllTaxonomy error', error2);
      }
    };

    try {
      await ctx.dispatch(new LoadCacheTaxonomy()).toPromise();
      console.log('load local cache taxonomy success');
      // 再呼叫 getAllTaxonomy 來更新 local cache
      getTaxonomyFromServer();
    } catch (error) {
      console.warn('LoadCacheTaxonomy error', error);
      // 如果沒有 cache, 就去 server 取
      getTaxonomyFromServer();
    }
  }

  // 建立 dynamic selector, 傳入 type name(ex: 科別專長, 能不能吃分類..) 回傳對應類別的 taxonomy
  // 這個方法支援 memoized selector
  // Note that each of these selectors have their own separate memoization.
  // Even if two dynamic selectors created in this way are provided the same argument, they will have separate memoization.
  static taxonomyType(type: string) {
    return createSelector([TaxonomyState], (state: TaxonomyStateModel) => {
      return state.taxonomies.filter(
        (item) => item.taxonomy_type.indexOf(type) > -1
      );
    });
  }

  static taxonomyById(taxonomy_id: number) {
    return createSelector([TaxonomyState], (state: TaxonomyStateModel) => {
      return state.taxonomies.filter(
        (item) => item.taxonomy_id === taxonomy_id
      );
    });
  }

  @Action(LoadCacheTaxonomy)
  async loadCacheTaxonomy(ctx: StateContext<TaxonomyStateModel>) {
    const state = ctx.getState();
    const _taxonomy: any = await this.storage.get('taxonomies');
    console.log('loadCacheTaxonomy', _taxonomy);

    if (_taxonomy) {
      ctx.patchState({
        loading: false,
        taxonomies: _taxonomy,
        hasCache: true,
      });
    } else {
      throw new Error('no cache');
    }
  }
}
