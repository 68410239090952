import { gql } from 'apollo-angular';

export const postFragment = gql`
  fragment postBase on PostInfo {
    post_id
    post_title
    post_type
    post_excerpt
    post_feature_image_url
    post_status
    video_source_url
    video_source_type
    video_duration
    view_count
    updated_at
    encyclopedia_hierarchy_taxonomy {
      taxonomy_id
    }
    can_or_not_eat_taxonomy {
      taxonomy_id
    }
    can_or_not_eat_tag_taxonomy {
      taxonomy_id
      taxonomy_name
      parent_id
    }
    pgc_tag_taxonomy {
      taxonomy_id
    }
    encyclopedia_tag_taxonomy {
      taxonomy_id
    }
    pregnancy_tag_taxonomy {
      taxonomy_id
    }
    post_author {
      expert_id
      expert_avatar_url
      expert_name
      subject_classes {
        taxonomy_id
        taxonomy_name
      }
    }
    related_posts {
      post_id
      post_title
      post_author {
        expert_id
        expert_avatar_url
        expert_name
      }
    }
  }
`;

export const QUERY_POST_DETAIL = gql`
  query getPost($post_id: Float!) {
    getPost(post_id: $post_id) {
      ...postBase
      post_content_html
      post_from
      post_permanent_link
      comment_count
      keep_count
      like_count
      share_count
      can_or_not_eat_tag_taxonomy {
        taxonomy_id
        taxonomy_name
      }
      post_author {
        expert_id
        expert_avatar_url
        expert_name
        subject_classes {
          taxonomy_id
          taxonomy_name
        }
        watch_count
        like_count
        keep_count
      }
      related_posts {
        post_id
        post_title
        post_feature_image_url
        post_excerpt
        post_type
        post_author {
          expert_id
          expert_avatar_url
          expert_name
        }
      }
      related_products
      expert_related_products
      comments {
        post_comment_id
        comment
        created_at
        comment_modified_at
        comment_user {
          user_id
          display_name
          avatar
        }
      }
    }
  }
  ${postFragment}
`;

export const QUERY_POST_LIST = gql`
  query ($updated_at: Float!) {
    getPostList(updated_at: $updated_at) {
      ...postBase
    }
  }
  ${postFragment}
`;

export const QUERY_POST_LIST_FILTER = gql`
  query ($filter_value: String!) {
    getPostListByFilter(filter_value: $filter_value) {
      ...postBase
    }
  }
  ${postFragment}
`;
