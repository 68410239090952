import { Injectable } from '@angular/core';
import { VoteCommentInfo } from '@mommy/models/VoteCommentInfo.model';
import { MemberVoteCommentService } from '@mommy/services/member/member-vote-comment.service';
import { StorageService } from '@mommy/services/storage.service';
import { UserService } from '@mommy/services/user/user.service';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  AddMemberVoteComment,
  InitLocalCacheMemberVoteComments,
  LoadCacheMemberVoteComments,
  ModifyMemberVoteComment,
  RefreshMemberVoteComments,
} from './member-vote-comment.actions';

export interface MemberVoteCommentStateModel {
  loading: boolean;
  member_vote_comments: VoteCommentInfo[];
  hasCache: boolean;
}

const defaultMemberVoteCommentState = (): MemberVoteCommentStateModel => {
  return {
    loading: false,
    member_vote_comments: [],
    hasCache: false,
  };
};

@State<MemberVoteCommentStateModel>({
  name: 'MemberVoteCommentState',
  defaults: defaultMemberVoteCommentState(),
})
@Injectable()
export class MemberVoteCommentState {
  constructor(
    private storage: StorageService,
    private memberVoteCommentSvc: MemberVoteCommentService,
    private userService: UserService,
    private store: Store
  ) {}

  @Selector()
  static member_vote_comments(state: MemberVoteCommentStateModel) {
    return state.member_vote_comments;
  }

  @Action(InitLocalCacheMemberVoteComments)
  async InitLocalCacheMemberVoteComments(
    ctx: StateContext<MemberVoteCommentStateModel>
  ) {
    try {
      await ctx.dispatch(new LoadCacheMemberVoteComments()).toPromise();
      console.log('load local cache memberVoteComments success');
      // 再呼叫 getMemberVoteCommentsFromServer 來更新 local cache
      //this.getMemberVoteCommentsFromServer(ctx);
      await ctx.dispatch(new RefreshMemberVoteComments()).toPromise();
    } catch (error) {
      console.warn('LoadCacheMemberVoteComments error', error);
      // 如果沒有 cache, 就去 server 取
      await ctx.dispatch(new RefreshMemberVoteComments()).toPromise();
    }
  }

  @Action(LoadCacheMemberVoteComments)
  async LoadCacheMemberVoteComments(
    ctx: StateContext<MemberVoteCommentStateModel>
  ) {
    console.log('[Action] LoadCacheMemberVoteComments');

    const _member_vote_comments: any = await this.storage.get(
      'member_vote_comments'
    );

    if (_member_vote_comments) {
      ctx.setState({
        loading: false,
        member_vote_comments: _member_vote_comments,
        hasCache: true,
      });
    } else {
      throw new Error('no cache');
    }
  }

  @Action(RefreshMemberVoteComments)
  async RefreshMemberVoteComments(
    ctx: StateContext<MemberVoteCommentStateModel>
  ) {
    console.log('[Action] RefreshMemberVoteComments');
    this.getMemberVoteCommentsFromServer(ctx);
  }

  @Action(AddMemberVoteComment)
  async AddMemberVoteComment(
    ctx: StateContext<MemberVoteCommentStateModel>,
    { payload }
  ) {
    console.log('[Action] AddMemberVoteComment');

    try {
      const result = await this.memberVoteCommentSvc.add(payload);
      console.log('AddMemberVoteComment success', result);
      this.store.dispatch(new RefreshMemberVoteComments());
    } catch (error) {
      console.error('AddMemberVoteComment error', error);
      throw error;
    }
  }

  @Action(ModifyMemberVoteComment)
  async ModifyMemberVoteComment(
    ctx: StateContext<MemberVoteCommentStateModel>,
    { payload }
  ) {
    console.log('[Action] ModifyMemberVoteComment');

    try {
      const result = await this.memberVoteCommentSvc.update(payload);
      console.log('ModifyMemberVoteComment success', result);
      this.store.dispatch(new RefreshMemberVoteComments());
    } catch (error) {
      console.error('ModifyMemberVoteComment error', error);
      throw error;
    }
  }

  private async getMemberVoteCommentsFromServer(
    ctx: StateContext<MemberVoteCommentStateModel>
  ) {
    // try read data from server
    console.log('getMemberVoteCommentsFromServer');
    try {
      const member_post_comments =
        await this.userService.getMemberVoteComments();
      console.log('member_post_comments', member_post_comments);
      await ctx.dispatch(new LoadCacheMemberVoteComments()).toPromise();
      console.log('load local cache vote_comments success');
    } catch (error2) {
      console.warn('getMemberVoteComments error', error2);
    }
  }
}
