import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-register-reward-toast',
  templateUrl: './register-reward-toast.component.html',
  styleUrls: ['./register-reward-toast.component.scss'],
})
export class RegisterRewardToastComponent implements OnInit {
  @Output() loginOnClick = new EventEmitter<any>();

  showFlag = true;

  constructor() {}

  ngOnInit() {}

  dismiss() {
    this.showFlag = false;
  }

  login() {
    this.loginOnClick.emit();
    this.showFlag = false;
  }
}
