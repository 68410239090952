import { MemberShippingInfo } from '@mommy/models/MemberShippingInfo.model';

export class GetMemberShippingInfos {
  static readonly type = '[MemberShippingInfo] GetMemberShippingInfos';
}

export class AddMemberShippingInfo {
  static readonly type = '[MemberShippingInfo] AddMemberShippingInfo';
  constructor(public payload: MemberShippingInfo) {}
}

export class ModifyMemberShippingInfo {
  static readonly type = '[MemberShippingInfo] ModifyMemberShippingInfo';
  constructor(public payload: MemberShippingInfo) {}
}

export class RemoveMemberShippingInfo {
  static readonly type = '[MemberShippingInfo] RemoveMemberShippingInfo';
  constructor(public member_shipping_info_id: number) {}
}
