import { Injectable } from '@angular/core';
import { APIResponse } from '@mommy/models/Comm.model';
import { CartItemInfo } from '@mommy/models/ec/CartItemInfo.model';
import {
  CheckOutInfo_Input,
  CheckOutInfo_Input2,
} from '@mommy/models/ec/CheckOutInfo.model';
import {
  QueryBillParams,
  QueryCoinHistParams,
  QueryReturnListParams,
} from '@mommy/models/ec/ECCommon.model';
import { ApplyOrderReturn_Input } from '@mommy/models/ec/OrderReturnInfo.model';
import { ECApi } from './ec-api';

@Injectable({
  providedIn: 'root',
})
export class ECOrderService {
  constructor(private api: ECApi) {
    console.log('Hello ECOrderService');
  }

  // 取得會員咪幣資訊
  getCustomerCoinInfo() {
    const endpoint = `Portal/Payment/GetCustomerCoinInfo`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.get(endpoint).toPromise()
        ));
        console.log('result', result);
        resolve(result);
      } catch (error) {
        console.error('getCustomerCoinInfo error', error);
        reject(error);
      }
    });
  }

  // 取得會員咪幣歷史紀錄
  getCoinTransByCustomerPaging(params: QueryCoinHistParams) {
    const endpoint = `Portal/Payment/GetCoinTransByCustomerPaging`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.put(endpoint, params).toPromise()
        ));
        console.log('result', result);
        resolve(result);
      } catch (error) {
        console.error('getCoinTransByCustomerPaging error', error);
        reject(error);
      }
    });
  }

  // 更新購物車(set)
  setShippingCarts(memberCarts: CartItemInfo[]) {
    const endpoint = `Portal/Shipping/SetShippingCarts`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.put(endpoint, memberCarts).toPromise()
        ));
        console.log('result', result);
        resolve(result);
      } catch (error) {
        console.error('setShippingCarts error', error);
        reject(error);
      }
    });
  }

  // 取得購物車詳細清單
  getShippingCartViews(): Promise<any> {
    const endpoint = `Portal/Shipping/GetShippingCartViews`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.get(endpoint).toPromise()
        ));
        console.log('result', result);
        resolve(result);
      } catch (error) {
        console.error('getShippingCartViews error', error);
        reject(error);
      }
    });
  }

  // 取得結帳商品資訊
  getCheckOutInfo(checkOutInfo_Input: CheckOutInfo_Input[]): Promise<any> {
    const endpoint = `Portal/Order/GetCheckOutInfo`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.post(endpoint, checkOutInfo_Input).toPromise()
        ));
        console.log('result', result);
        resolve(result);
      } catch (error) {
        console.error('getCheckOutInfo error', error);
        reject(error);
      }
    });
  }

  // 商品結帳產生訂單資訊
  setCheckOutInfo(checkOutInfo_Input2: CheckOutInfo_Input2): Promise<any> {
    const endpoint = `Portal/Order/SetCheckOutInfo`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.post(endpoint, checkOutInfo_Input2).toPromise()
        ));
        console.log('result', result);
        resolve(result);
      } catch (error) {
        console.error('setCheckOutInfo error', error);
        reject(error);
      }
    });
  }

  // 取得訂單資訊list
  getCustomerBillViewByPaging(params: QueryBillParams) {
    const endpoint = `Portal/Order/GetCustomerBillViewByPaging`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.put(endpoint, params).toPromise()
        ));
        console.log('result', result);
        resolve(result);
      } catch (error) {
        console.error('getCustomerBillViewByPaging error', error);
        reject(error);
      }
    });
  }

  // 取得訂單資訊(單筆)
  getCustomerBillViewByOrderBillId(params: QueryBillParams) {
    const endpoint = `Portal/Order/GetCustomerBillViewByPaging`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.put(endpoint, params).toPromise()
        ));
        console.log('result', result);
        resolve(result);
      } catch (error) {
        console.error('getCustomerBillViewByPaging error', error);
        reject(error);
      }
    });
  }

  // 取消訂單
  cancelOrder(orderBillId: number) {
    const endpoint = `Portal/Order/CancelOrder`;

    return new Promise(async (resolve, reject) => {
      try {
        const params: any = {};
        params.orderBillId = orderBillId;

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        resolve(result);
      } catch (error) {
        console.error('cancelOrder error', error);
        reject(error);
      }
    });
  }

  // 訂單商品退貨申請
  applyOrderReturn(params: ApplyOrderReturn_Input[]) {
    const endpoint = `Portal/Order/ApplyOrderReturn`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        resolve(result);
      } catch (error) {
        console.error('applyOrderReturn error', error);
        reject(error);
      }
    });
  }

  // 取得退貨單資訊list
  getOrderReturnInfoByPaging(params: QueryReturnListParams) {
    const endpoint = `Portal/Order/GetOrderReturnInfoByPaging`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.put(endpoint, params).toPromise()
        ));
        console.log('result', result);
        resolve(result);
      } catch (error) {
        console.error('getOrderReturnInfoByPaging error', error);
        reject(error);
      }
    });
  }

  // 取得退貨單資訊(單筆) (目前暫時沒用到)
  getOrderReturnInfoByReturnId(params: QueryReturnListParams) {
    const endpoint = `Portal/Order/GetOrderReturnInfoByPaging`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.put(endpoint, params).toPromise()
        ));
        console.log('result', result);
        resolve(result);
      } catch (error) {
        console.error('getOrderReturnInfoByReturnId error', error);
        reject(error);
      }
    });
  }
}
