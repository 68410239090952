import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { environment } from '@mommy/environments/environment';
import { ExpertInfo } from '@mommy/models/ExpertInfo.model';
import * as _ from 'lodash';
import { SwiperComponent } from 'swiper/angular';
import { SwiperOptions } from 'swiper/types/swiper-options';

@Component({
  selector: 'app-expert-related-product',
  templateUrl: './expert-related-product.component.html',
  styleUrls: ['./expert-related-product.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExpertRelatedProductComponent
  implements OnInit, AfterContentChecked
{
  @ViewChild('swiper_expert_related_products') swiperProduct: SwiperComponent;
  @Output() productClick: EventEmitter<any> = new EventEmitter<any>();

  config: SwiperOptions = {
    spaceBetween: 10,
    // autoplay: true,
    pagination: false,
    slidesPerView: 'auto',
  };

  _expert_related_products: any;
  _expert: ExpertInfo;
  _expert_tag: any;

  constructor() {
    console.log('ExpertRelatedProductComponent');
  }

  ngOnInit() {}

  get expert_related_products(): any {
    return this._expert_related_products;
  }

  @Input() set expert_related_products(value: any) {
    console.log('expert_related_products', value);
    if (value) {
      const new_value = _.cloneDeep(value);
      _.forEach(new_value, (commodity) => {
        commodity.backgroundImagePath =
          `url(${environment.EC_IMAGE_CDN_URL + commodity.imagePath})` +
          ', url(assets/img/default/ec_commodity_default.png)';
      });
      this._expert_related_products = new_value;
      console.log(
        'this._expert_related_products',
        this._expert_related_products
      );
    } else {
      this._expert_related_products = [];
    }
  }

  get expert(): ExpertInfo {
    return this._expert;
  }

  @Input() set expert(value: ExpertInfo) {
    console.log('expert_related_products expert', value);
    if (value) {
      this._expert = value;

      let _section = '';
      if (value.subject_classes?.length > 0) {
        _section = value.subject_classes[0].taxonomy_name;
      }

      // if (value.outpatients?.length > 0) {
      //   this._expert_tag = `${_section}-${value.outpatients[0]?.outpatient_hospital?.hospital_name}`;
      // } else {
      //   this._expert_tag = `${_section}`;
      // }

      this._expert_tag = `${_section}`;
    } else {
      this._expert = {};
    }
  }

  ngAfterContentChecked(): void {
    if (this.swiperProduct) {
      console.log('swiperProduct', this.swiperProduct);
      this.swiperProduct.updateSwiper({});
    }
  }

  onProductClick(product) {
    console.log('onProductClick', product);
    this.productClick.emit(product);
  }
}
