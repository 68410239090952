import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CommodityInfo } from '@mommy/models/ec/CommodityInfo.model';
import { UtilService } from '@mommy/services/util.service';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-add-cart-modal',
  templateUrl: './add-cart-modal.page.html',
  styleUrls: ['./add-cart-modal.page.scss'],
})
export class AddCartModalPage implements OnInit {
  type: 'cart' | 'buy' = 'cart';
  quantity = 1;
  commodity: CommodityInfo;
  selectedSpec: any; // 使用者選到的規格
  customActionSheetOptions: any;
  _price;
  _pricing;
  _specQuantity: number;

  _maxRedeemedPoints;

  constructor(
    public modalController: ModalController,
    public navParams: NavParams,
    private store: Store,
    private util: UtilService
  ) {
    this.commodity = this.navParams.get('commodity');
    console.log('commodity', this.commodity);

    this.type = this.navParams.get('type');
    console.log('type', this.type);

    this._price = this.commodity.price;
    this._pricing = this.commodity.pricing;

    this._maxRedeemedPoints = this.commodity.maxRedeemedPoints;

    this.customActionSheetOptions = {
      header: '請選擇規格',
    };

    // 若只有一個規格，預設選擇
    if (this.commodity?.spec?.length === 1) {
      this.selectedSpec = this.commodity.spec[0];
      this._price = this.selectedSpec.price;
      this._pricing = this.selectedSpec.pricing;
      this._specQuantity = this.selectedSpec.quantity;
      this._maxRedeemedPoints = this.selectedSpec.maxRedeemedPoints;
    }
  }

  ngOnInit() {}

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  quantityChange(event) {
    console.log('quantityChange', event);
    this.quantity = event;
  }

  // 規格變更後需要連動顯示價格
  specChange(event) {
    console.log('specChange', event);
    this._price = event.detail.value.price;
    this._pricing = event.detail.value.pricing;
    this._specQuantity = event.detail.value.quantity;
    this._maxRedeemedPoints = event.detail.value.maxRedeemedPoints;
  }

  addCart() {
    console.log('addCart item', this.selectedSpec);
    console.log('addCart quantity', this.quantity);

    if (!this.selectedSpec) {
      this.util.showToast('請選擇規格');
      return;
    }

    if (this.quantity <= 0) {
      this.util.showToast('請選擇數量');
      return;
    }

    // 檢查庫存
    if (this._specQuantity < this.quantity) {
      this.util.showToast('庫存不足');
      return;
    }

    const cart = {
      commoditySpecId: this.selectedSpec.id,
      quantity: this.quantity,
    };

    this.modalController.dismiss({
      cart,
    });
  }
}
