import { Injectable } from '@angular/core';
import { CodeZipCodeInfo } from '@mommy/models/CodeZipCodeInfo.model';
import { StorageService } from '@mommy/services/storage.service';
import { ZipcodeService } from '@mommy/services/zipcode/zipcode.service';
import {
  Action,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { InitLocalCacheZipcode, LoadCacheZipcode } from './zipcode.actions';

export interface ZipcodeStateModel {
  loading: boolean;
  zipcodes: CodeZipCodeInfo[];
  hasCache: boolean;
}

const defaultZipcodeState = (): ZipcodeStateModel => {
  return {
    loading: false,
    zipcodes: [],
    hasCache: false,
  };
};

@State<ZipcodeStateModel>({
  name: 'ZipcodeState',
  defaults: defaultZipcodeState(),
})
@Injectable()
export class ZipcodeState implements NgxsAfterBootstrap {
  constructor(
    private storage: StorageService,
    private zipcodeSvc: ZipcodeService
  ) {}

  async ngxsAfterBootstrap(ctx: StateContext<ZipcodeStateModel | null>) {
    console.log('[ZipcodeState] ngxsAfterBootstrap');

    // const getZipcodeFromServer = async () => {
    //   // try read data from server
    //   console.log('getZipcodeFromServer');
    //   try {
    //     const zipcodes = await this.zipcodeSvc.getAllZipcode();
    //     console.log('zipcodes', zipcodes);
    //     await this.storage.set('zipcodes', zipcodes);
    //     await ctx.dispatch(new LoadCacheZipcode()).toPromise();
    //   } catch (error2) {
    //     console.warn('getAllZipcode error', error2);
    //   }
    // };

    // try {
    //   await ctx.dispatch(new LoadCacheZipcode()).toPromise();
    //   console.log('load local cache zipcode success');
    //   // 再呼叫 getAllZipcode 來更新 local cache
    //   getZipcodeFromServer();
    // } catch (error) {
    //   console.warn('LoadCacheZipcode error', error);
    //   // 如果沒有 cache, 就去 server 取
    //   getZipcodeFromServer();
    // }
  }

  @Selector()
  static zipcodes(state: ZipcodeStateModel) {
    return state.zipcodes;
  }

  @Action(InitLocalCacheZipcode)
  async initLocalCacheZipcode(ctx: StateContext<ZipcodeStateModel>) {
    const _zipcodes: any = await this.storage.get('zipcodes');

    if (_zipcodes) {
      // do nothing
    } else {
      this.getZipcodeFromServer(ctx);
    }
  }

  @Action(LoadCacheZipcode)
  async loadCacheZipcode(ctx: StateContext<ZipcodeStateModel>) {
    const state = ctx.getState();
    const _zipcodes: any = await this.storage.get('zipcodes');

    if (_zipcodes) {
      ctx.patchState({
        loading: false,
        zipcodes: _zipcodes,
        hasCache: true,
      });
    } else {
      throw new Error('no cache');
    }
  }

  private async getZipcodeFromServer(ctx: StateContext<ZipcodeStateModel>) {
    // try read data from server
    console.log('getZipcodeFromServer');
    try {
      const zipcodes = await this.zipcodeSvc.getAllZipcode();
      console.log('zipcodes', zipcodes);
      await this.storage.set('zipcodes', zipcodes);
      await ctx.dispatch(new LoadCacheZipcode()).toPromise();
      console.log('load local cache zipcode success');
    } catch (error2) {
      console.warn('getAllZipcode error', error2);
    }
  }
}
