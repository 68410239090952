import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExpertInfo } from '@mommy/models/ExpertInfo.model';
import * as _ from 'lodash';
import { RelatedPostInfo } from './../../../models/RelatedPostInfo.model';

@Component({
  selector: 'app-related-pgc-post',
  templateUrl: './related-pgc-post.component.html',
  styleUrls: ['./related-pgc-post.component.scss'],
})
export class RelatedPgcPostComponent implements OnInit {
  @Input() showLabel: boolean;
  @Output() PostOnClick = new EventEmitter<RelatedPostInfo>();
  @Output() expertOnClick = new EventEmitter<ExpertInfo>();

  _posts: RelatedPostInfo[] = [];
  _tag: string; // 副標題, expert_name - 專長

  constructor() {}

  ngOnInit() {}

  @Input() set relatedPgcPosts(value: RelatedPostInfo[]) {
    console.log('relatedPgcPosts', value);

    // 過濾掉 author 為空的 post
    const new_value = _.chain(value)
      .filter((post: any) => {
        return post.post_author;
      })
      .map((post) => {
        const section = post.post_author?.subject_classes?.[0]?.taxonomy_name;
        let _tag = '';
        if (section) {
          _tag = post.post_author?.expert_name + ' - ' + section;
        } else {
          _tag = post.post_author?.expert_name || '';
        }
        const new_post = _.cloneDeep(post);
        new_post.post_author.section = _tag;

        return new_post;
      })
      .value();

    this._posts = new_value;
  }

  clickPost(post) {
    this.PostOnClick.emit(post);
  }

  expertClick(post) {
    this.expertOnClick.emit(post.post_author);
  }
}
