import { Component, Input, OnInit } from '@angular/core';
import { PostInfo } from '@mommy/models/PostInfo.model';
import { TaxonomyState } from '@mommy/state/taxonomy/taxonomy.state';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';

@Component({
  selector: 'app-canieat-post-list',
  templateUrl: './canieat-post-list.component.html',
  styleUrls: ['./canieat-post-list.component.scss'],
})
export class CanieatPostListComponent implements OnInit {
  _item: PostInfo;
  _feature_image: string;

  constructor(private store: Store) {}

  ngOnInit() {}

  get item(): any {
    return this._item;
  }

  @Input() set item(value: PostInfo) {
    console.log('item', value);
    this._feature_image = `${value.post_feature_image_url}`;

    // 處理 標籤的parent taxonomy
    const new_post = _.cloneDeep(value);
    new_post.can_or_not_eat_tag_taxonomy = _.map(
      new_post.can_or_not_eat_tag_taxonomy,
      (tag) => {
        const parent_taxonomy = this.store.selectSnapshot(
          TaxonomyState.taxonomyById(tag.parent_id)
        );
        console.log('parent_taxonomy', parent_taxonomy);
        tag.parent_taxonomy = parent_taxonomy?.[0];
        tag.parent_taxonomy_name = parent_taxonomy?.[0]?.taxonomy_name;
        return tag;
      }
    );

    // 依照 懷孕,月子,哺乳 順序來排序
    new_post.can_or_not_eat_tag_taxonomy = _.sortBy(
      new_post.can_or_not_eat_tag_taxonomy,
      (elem) => {
        const rank = {
          懷孕: 1,
          月子: 2,
          哺乳: 3,
        };
        return rank[elem.taxonomy_name];
      }
    );

    console.log('new_post', new_post);
    this._item = new_post;
  }
}
