import { gql } from 'apollo-angular';

export const QUERY_MY = gql`
  query {
    getMy {
      basic_info {
        user_id
        display_name
        avatar
        email
        account_type
        city
        district
        baby_due_date
        lmp_date
        last_online_datetime
        device_info
        member_status
        member_level
        birthday
        identity_info
        pregnancy_info
      }
      member_babies {
        member_baby_id
        baby_name
        birthday
        gender
      }
      expert_watchs {
        expert_id
        expert_name
        expert_avatar_url
        subject_classes {
          taxonomy_id
          taxonomy_name
        }
        outpatients {
          job_title
          outpatient_hospital {
            hospital_name
          }
        }
      }
      post_keeps {
        post_id
        created_at
      }
      post_likes {
        post_id
      }
      member_tasks {
        member_task_id
        task_code
        finished_at
        reward_at
        memo
        progress
      }
      member_current_signin_tasks {
        signin_date
        week
        week_cul_count
        coin_point
        reward_at
        updated_at
      }
      member_votes {
        member_vote_id
        vote_id
        vote_option
      }
      post_comments {
        post_comment_id
        post_id
      }
      vote_comments {
        vote_comment_id
        vote_id
      }
      member_maternitykits {
        member_maternitykit_id
        item_name
        item_desc
        maternitykit_period_type
        done_flag
        maternitykit_id
        related_product
      }
    }
  }
`;

// 取得會員的寶寶資料
export const QUERY_MY_BABIES = gql`
  query {
    getMy {
      member_babies {
        member_baby_id
        baby_name
        birthday
        gender
      }
    }
  }
`;

// 取得會員的關注專家資料
export const QUERY_MY_EXPERT_WATCHS = gql`
  query {
    getMy {
      expert_watchs {
        expert_id
        expert_name
        expert_avatar_url
        subject_classes {
          taxonomy_id
          taxonomy_name
        }
        outpatients {
          job_title
          outpatient_hospital {
            hospital_name
          }
        }
      }
    }
  }
`;

// 取得會員收藏文章的紀錄
export const QUERY_MY_POST_KEEP = gql`
  query {
    getMy {
      post_keeps {
        post_id
        post_info {
          post_title
          post_author {
            expert_id
            expert_name
            subject_classes {
              taxonomy_id
              taxonomy_name
            }
          }
          post_type
        }
        created_at
      }
    }
  }
`;

// 取得會員送出愛心文章的紀錄
export const QUERY_MY_POST_LIKE = gql`
  query {
    getMy {
      post_likes {
        post_id
        post_info {
          post_title
          post_author {
            expert_id
            expert_name
            subject_classes {
              taxonomy_id
              taxonomy_name
            }
          }
          post_type
        }
        created_at
      }
    }
  }
`;

// 取得會員評論文章,Vote的紀錄
export const QUERY_MY_COMMENT = gql`
  query {
    getMy {
      vote_comments {
        vote_comment_id
        vote_id
        comment
        created_at
        comment_modified_at
      }
      post_comments {
        post_comment_id
        post_id
        comment
        created_at
        comment_modified_at
      }
    }
  }
`;

// 取得會員評論文章
export const QUERY_MY_POST_COMMENT = gql`
  query {
    getMy {
      post_comments {
        post_comment_id
        post_id
        comment
        created_at
        comment_modified_at
      }
    }
  }
`;

// 取得會員Vote的評論紀錄
export const QUERY_MY_VOTE_COMMENT = gql`
  query {
    getMy {
      vote_comments {
        vote_comment_id
        vote_id
        comment
        created_at
        comment_modified_at
      }
    }
  }
`;

// 取得會員準備清單的紀錄
export const QUERY_MY_MATERNITYKITS = gql`
  query {
    getMy {
      member_maternitykits {
        member_maternitykit_id
        item_name
        item_desc
        maternitykit_period_type
        done_flag
        maternitykit_id
        related_product
        created_at
        updated_at
      }
    }
  }
`;

// 取得會員寶寶餵養的紀錄
export const QUERY_MY_BABY_FEED = gql`
  query {
    getMy {
      member_baby_feeds {
        feed_id
        feed_type
        record_date
        record_time
        dairy_type
        dairy_volumn
        diaper_type
        memo
      }
    }
  }
`;

// 取得會員寶寶生長的紀錄
export const QUERY_MY_BABY_GROW = gql`
  query {
    getMy {
      member_baby_grows {
        grow_id
        record_date
        height
        weight
        head
      }
    }
  }
`;

// 取得會員投票的紀錄
export const QUERY_MY_VOTE = gql`
  query {
    getMy {
      member_votes {
        member_vote_id
        vote_id
        vote_option
      }
    }
  }
`;
