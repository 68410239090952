import { CodeTaxonomyInfo } from '@mommy/models/CodeTaxonomyInfo.model';
// [專家查詢頁面] 設定查詢關鍵字
export class SetUIPageExpertSearchKeyword {
  static readonly type = '[AppUI] SetUIPageExpertSearchKeyword';
  constructor(public keyword: string) {}
}

// [專家查詢頁面] 調整顯示筆數
export class SetUIPageExpertSearchListCount {
  static readonly type = '[AppUI] SetUIPageExpertSearchListCount';
  constructor(public count: number) {}
}

// [醫院查詢頁面] 設定查詢關鍵字
export class SetUIPageHospitalSearchKeyword {
  static readonly type = '[AppUI] SetUIPageHospitalSearchKeyword';
  constructor(public keyword: string) {}
}

// [醫院查詢頁面] 調整顯示筆數
export class SetUIPageHospitalSearchListCount {
  static readonly type = '[AppUI] SetUIPageHospitalSearchListCount';
  constructor(public count: number) {}
}

// [專家清單頁面] 調整顯示筆數
export class SetUIPageExpertListListCount {
  static readonly type = '[AppUI] SetUIPageExpertListListCount';
  constructor(public count: number) {}
}

// [醫院清單頁面] 調整顯示筆數
export class SetUIPageHospitalListListCount {
  static readonly type = '[AppUI] SetUIPageHospitalListListCount';
  constructor(public count: number) {}
}

// [醫院zipcode結果頁面] 設定zip code
export class SetUIPageHospitalListZipCode {
  static readonly type = '[AppUI] SetUIPageHospitalListZipCode';
  constructor(public zipcode: string) {}
}

//#region ======================== [醫院查詢 分類條件設定] ========================
// [醫院查詢頁 分類條件設定] 設定熱門分類
export class SetUIPageHospitalSearchTopCategorys {
  static readonly type = '[AppUI] SetUIPageHospitalSearchTopCategorys';
  constructor(public top_categorys: CodeTaxonomyInfo[]) {}
}

// [醫院查詢頁 分類條件設定] 設定科別專長分類 subject_classes
export class SetUIPageHospitalSearchSubjectClasses {
  static readonly type = '[AppUI] SetUIPageHospitalSearchSubjectClasses';
  constructor(public subject_classes: CodeTaxonomyInfo[]) {}
}

// [醫院查詢頁 分類條件設定] 設定醫療護理分類 medical_cares
export class SetUIPageHospitalSearchMedicalCares {
  static readonly type = '[AppUI] SetUIPageHospitalSearchMedicalCares';
  constructor(public medical_cares: CodeTaxonomyInfo[]) {}
}

// [醫院查詢頁 分類條件設定] 設定硬體設備分類 hardware_equipments
export class SetUIPageHospitalSearchHardwareEquipments {
  static readonly type = '[AppUI] SetUIPageHospitalSearchHardwareEquipments';
  constructor(public hardware_equipments: CodeTaxonomyInfo[]) {}
}

// [醫院查詢頁 分類條件設定] 設定生活機能分類 life_functions
export class SetUIPageHospitalSearchLifeFunctions {
  static readonly type = '[AppUI] SetUIPageHospitalSearchLifeFunctions';
  constructor(public life_functions: CodeTaxonomyInfo[]) {}
}

// [醫院查詢頁 分類條件設定] 設定區域分類 section2
export class SetUIPageHospitalSearchSection2 {
  static readonly type = '[AppUI] SetUIPageHospitalSearchSection2';
  constructor(public section2: CodeTaxonomyInfo[]) {}
}

// [醫院查詢頁 分類條件設定] 設定區域 city
export class SetUIPageHospitalSearchCity {
  static readonly type = '[AppUI] SetUIPageHospitalSearchCity';
  constructor(public city: string) {}
}

// [醫院查詢頁 分類條件設定] 設定區域 district
export class SetUIPageHospitalSearchDistrict {
  static readonly type = '[AppUI] SetUIPageHospitalSearchDistrict';
  constructor(public district: string[]) {}
}

// [醫院查詢頁 分類條件設定] Reset 所有設定條件
export class SetUIPageHospitalSearchResetAll {
  static readonly type = '[AppUI] SetUIPageHospitalSearchResetAll';
}
//#endregion ======================== [醫院查詢 分類條件設定] ========================

//#region ======================== [專家查詢 分類條件設定] ========================
// [專家查詢頁 分類條件設定] 設定熱門分類
export class SetUIPageExpertSearchTopCategorys {
  static readonly type = '[AppUI] SetUIPageExpertSearchTopCategorys';
  constructor(public top_categorys: CodeTaxonomyInfo[]) {}
}

// [專家查詢頁 分類條件設定] 設定科別專長分類 subject_classes
export class SetUIPageExpertSearchSubjectClasses {
  static readonly type = '[AppUI] SetUIPageExpertSearchSubjectClasses';
  constructor(public subject_classes: CodeTaxonomyInfo[]) {}
}

// [專家查詢頁 分類條件設定] 設定專業人士分類 expert2
export class SetUIPageExpertSearchExpert2 {
  static readonly type = '[AppUI] SetUIPageExpertSearchExpert2';
  constructor(public expert2: CodeTaxonomyInfo[]) {}
}

// [專家查詢頁 分類條件設定] 設定區域分類 section2
export class SetUIPageExpertSearchSection2 {
  static readonly type = '[AppUI] SetUIPageExpertSearchSection2';
  constructor(public section2: CodeTaxonomyInfo[]) {}
}

// [專家查詢頁 分類條件設定] Reset 所有設定條件
export class SetUIPageExpertSearchResetAll {
  static readonly type = '[AppUI] SetUIPageExpertSearchResetAll';
}
//#endregion ======================== [專家查詢 分類條件設定] ========================

// [APP] 設定User的經緯度
export class SetUIPageLatLon {
  static readonly type = '[AppUI] SetUIPageLatLon';
  constructor(public lat: number, public lon: number) {}
}

export class SetUIParams {
  static readonly type = '[AppUI] SetParams';
  constructor(public params: any) {}
}

// [百科分類結果頁] 設定分類taxonomy
export class SetUIPediaCategoryTaxonomy {
  static readonly type = '[AppUI] SetUIPediaCategoryTaxonomy';
  constructor(public taxonomy: CodeTaxonomyInfo) {}
}

// [首頁] 推薦的PGC posts (baby mode)
export class SetUIPageHomeRecommendBabyPosts {
  static readonly type = '[AppUI] SetUIPageHomeRecommendBabyPosts';
  constructor(public post_ids: number[]) {}
}

// [首頁] 推薦的PGC posts (pregnancy mode)
export class SetUIPageHomeRecommendPregnancyPosts {
  static readonly type = '[AppUI] SetUIPageHomeRecommendPregnancyPosts';
  constructor(public post_ids: number[]) {}
}

// [首頁] 推薦的PGC post count
export class SetUIPageHomeRecommendPostCount {
  static readonly type = '[AppUI] SetUIPageHomeRecommendPostCount';
  constructor(public post_count: number) {}
}

// [首頁] 設定APP UI 模式
export class SetAPPUIMode {
  static readonly type = '[AppUI] SetAPPUIMode';
  constructor(public mode: 'pregnancy' | 'baby') {}
}
