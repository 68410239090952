export class InitLocalCachePosts {
  static readonly type = '[Post] InitLocalCachePosts';
}

export class LoadCachePosts {
  static readonly type = '[Post] LoadCachePosts';
}

export class RefreshPosts {
  static readonly type = '[Post] RefreshPosts';
}

export class GetPostDetail {
  static readonly type = '[Post] GetPostDetail';
  constructor(public post_id: number) {}
}

// 變更首頁顯示推薦PGC的筆數
export class SetHomePgcCount {
  static readonly type = '[Post] SetHomePgcCount';
  constructor(public count: number) {}
}

// 全文檢索查詢 線上
export class GetFilterPosts {
  static readonly type = '[Post] GetFilterPosts';
  constructor(public filter_value: string) {}
}

// 計算 pgc post's score
export class CalculatePgcPostScore {
  static readonly type = '[Post] CalculatePgcPostScore';
}

// 更新 PostState.posts
export class SetPostsToPostState {
  static readonly type = '[Post] SetPostsToPostState';
  constructor(public payload: any) {}
}

// PostClick log
export class PostClickLog {
  static readonly type = '[Post] PostClickLog';
  constructor(public post_id: number) {}
}

// PostShare log
export class PostShareLog {
  static readonly type = '[Post] PostShareLog';
  constructor(public post_id: number) {}
}

// 增加 pgc文章檢舉id 的紀錄
export class AddPgcPostReportId {
  static readonly type = '[Post] AddPgcPostReportId';
  constructor(public post_id: number) {}
}

// 增加 pgc文章檢舉評論id 的紀錄
export class AddPgcPostReportCommentId {
  static readonly type = '[Post] AddPgcPostReportCommentId';
  constructor(public comment_id: number) {}
}
