import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { CapUtilService } from '@mommy/services/caputil.service';
import { UtilService } from '@mommy/services/util.service';

@Directive({
  selector: '[appLinkcheck]',
})
export class LinkCheckDirective implements OnInit {
  @Input() public myHtmlContent: any;
  @Input() public input: any;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private util: UtilService,
    private caputil: CapUtilService,
    private _renderer: Renderer2
  ) {
    // console.log('LinkCheckDirective init2');
  }

  ngOnInit() {
    // console.log('LinkCheckDirective init');

    this.renderer.listen(this.el.nativeElement, 'click', (event) => {
      const rc = true;
      console.log(event.target.nodeName);
      if (event.target.nodeName === 'A') {
        // rc = this.processClickOnATag(event.target.href);
        console.log(event.target.href);
        console.log('use in app browser open');
        this.caputil.iab_browser(event.target.href);
        return false;
      } else if (event.target.nodeName === 'IMG') {
        console.log(event.target.src);
        // this.util.iab_browser(event.target.src);
        // this.caputil.cordova_iab_show_img(event.target.src);
        this.util.imageModal(event.target.src);
        // this.util.themeable_browser(event.target.src,'圖片');
        return false;
      }
      return rc;
    });
  }

  // 透過dynamic content compile實作了imageviewer,故不需要以下的程式了

  // ngOnChanges(changes: SimpleChanges){
  //   if(changes.myHtmlContent){
  //     console.log('myHtmlContent changed');
  //     console.log('myHtmlContent',this.myHtmlContent);
  //     console.log('img:',this.el.nativeElement.querySelectorAll('img'));

  //     //要放在timeout裡面才可以取道正確的element,原因不明
  //     setTimeout(() => {
  //       var parent = this.el.nativeElement;
  //       console.log('parent',parent);
  //       var nodes = this.el.nativeElement.querySelectorAll('img');

  //       console.log('nodes',nodes);
  //       _.forEach(nodes,(elem)=>{
  //         this.add_img_text_block(parent,elem);
  //         // this.add_img_text_block_directive(elem);
  //       })
  //     }, 0);
  //   }
  // }

  // 為每個img element 1.放在div裡面 2.新增text_block element
  // add_img_text_block(parent_element,img_element) {

  //   console.log('add_img_text_block');
  //   console.log('parent_element,',parent_element);
  //   console.log('pareimg_elementnt,',img_element);

  //   // Create a root div
  //   var rootdivElement = this._renderer.createElement("div");
  //   rootdivElement.appendChild(img_element); //把img element放在div內
  //   this._renderer.appendChild(parent_element,rootdivElement); //把這個div放到post_content element內

  //   // Create a div
  //   var divElement = this._renderer.createElement("div");
  //   var spanElement = this._renderer.createElement("span");
  //   spanElement.innerText = '放大瀏覽';

  //   // Add class "img-text-block"
  //   this._renderer.addClass(spanElement, "img-text-block");

  //   // Add click listen, 當click, trigger click event to view image
  //   this._renderer.listen(spanElement,'click',()=>{
  //     console.log('span click...');
  //     img_element.click();
  //   });

  //   // add div after image element
  //   this._renderer.appendChild(rootdivElement,divElement);
  //   this._renderer.appendChild(rootdivElement,spanElement);

  // }

  // 待實驗
  // add_img_text_block_directive(img_element) {
  //   this._renderer.setAttribute(img_element,'imageViewer','');

  // }

  // @HostListener('change') ngOnChanges() {
  //     console.log('test');
  //     console.log('myHtmlContent',this.myHtmlContent);
  //     console.log('this.el.nativeElement',this.el.nativeElement);
  //     console.log('img:',this.el.nativeElement.querySelector('img'));
  //     console.log('p:',this.el.nativeElement.querySelector('p'));
  //     console.log('a:',this.el.nativeElement.querySelector('a'));
  //     console.log('span:',this.el.nativeElement.querySelector('span'));
  //     setTimeout(()=>{
  //       console.log('----------');
  //       console.log('this.el.nativeElement',this.el.nativeElement);
  //       console.log('img:',this.el.nativeElement.querySelector('img'));
  //       console.log('p:',this.el.nativeElement.querySelector('p'));
  //       console.log('a:',this.el.nativeElement.querySelector('a'));
  //       console.log('span:',this.el.nativeElement.querySelector('span'));
  //     },1000);

  // }
}
