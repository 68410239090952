import { Injectable } from '@angular/core';
import { CacheService } from '@mommy/services/cache.service';
import { UtilService } from '@mommy/services/util.service';
import { Apollo } from 'apollo-angular';
import { take } from 'rxjs/operators';
import {
  QUERY_MY,
  QUERY_MY_BABIES,
  QUERY_MY_EXPERT_WATCHS,
  QUERY_MY_MATERNITYKITS,
  QUERY_MY_POST_COMMENT,
  QUERY_MY_POST_KEEP,
  QUERY_MY_POST_LIKE,
  QUERY_MY_VOTE_COMMENT,
} from './user.graphql';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private apollo: Apollo,
    private cacheService: CacheService,
    private util: UtilService
  ) {
    console.log('Hello UserService');
  }

  // 取得會員基本資訊
  // 1. basic_info
  // 2. member_babies
  // 3. expert_watchs
  // 4. post_keeps [post_id]
  // 5. post_likes [post_id]
  // 6. member_tasks
  getMy() {
    console.log('getMy');

    return new Promise(async (resolve, reject) => {
      this.apollo
        .watchQuery<{ getMy: any }>({
          query: QUERY_MY,
          fetchPolicy: 'network-only',
        })
        .valueChanges.pipe(take(1))
        .subscribe(
          async ({ data, loading }) => {
            console.log('loading', loading);
            console.log('data', data);

            if (data) {
              // loading = false; //如果有data,表示有cache
              if (data?.getMy) {
                try {
                  const new_data = await this.cacheService.setData2(
                    data?.getMy,
                    'mommy_user'
                  );
                  resolve(new_data);
                } catch (error) {
                  console.error('cacheService merge data error', error);
                  reject(-1);
                }
              } else {
                console.log('no new data need to merge..');
                reject('no new data need to merge');
              }
            } else {
              reject('data is null');
            }
          },
          ({ graphQLErrors, networkError }) => {
            const msg = this.util.getErrorDesc(graphQLErrors, networkError);
            this.util.showToast(msg, '', 0, true);
            reject(-1);
          }
        );
    });
  }

  // 取得會員待產工具資料
  getMemberMaternityKits() {
    console.log('getMemberMaternityKits');

    return new Promise(async (resolve, reject) => {
      this.apollo
        .watchQuery<{ getMy: any }>({
          query: QUERY_MY_MATERNITYKITS,
          fetchPolicy: 'network-only',
        })
        .valueChanges.pipe(take(1))
        .subscribe(
          async ({ data, loading }) => {
            console.log('loading', loading);
            console.log('data', data);

            if (data) {
              // loading = false; //如果有data,表示有cache
              if (data?.getMy) {
                try {
                  const new_data = await this.cacheService.setData2(
                    data?.getMy,
                    'member_maternitykits'
                  );
                  resolve(new_data);
                } catch (error) {
                  console.error('cacheService merge data error', error);
                  reject(-1);
                }
              } else {
                console.log('no new data need to merge..');
                reject('no new data need to merge');
              }
            } else {
              reject('data is null');
            }
          },
          ({ graphQLErrors, networkError }) => {
            const msg = this.util.getErrorDesc(graphQLErrors, networkError);
            this.util.showToast(msg, '', 0, true);
            reject(-1);
          }
        );
    });
  }

  // 取得會員的寶寶資料
  getMemberBabies() {
    console.log('getMemberBabies');

    return new Promise(async (resolve, reject) => {
      this.apollo
        .watchQuery<{ getMy: any }>({
          query: QUERY_MY_BABIES,
          fetchPolicy: 'network-only',
        })
        .valueChanges.pipe(take(1))
        .subscribe(
          async ({ data, loading }) => {
            console.log('loading', loading);
            console.log('data', data);

            if (data) {
              // loading = false; //如果有data,表示有cache
              if (data?.getMy) {
                try {
                  const new_data = await this.cacheService.patchData2(
                    data?.getMy,
                    'mommy_user',
                    'member_babies'
                  );
                  resolve(new_data);
                } catch (error) {
                  console.error('cacheService merge data error', error);
                  reject(-1);
                }
              } else {
                console.log('no new data need to merge..');
                reject('no new data need to merge');
              }
            } else {
              reject('data is null');
            }
          },
          ({ graphQLErrors, networkError }) => {
            const msg = this.util.getErrorDesc(graphQLErrors, networkError);
            this.util.showToast(msg, '', 0, true);
            reject(-1);
          }
        );
    });
  }

  // 取得會員的關注專家資料
  getMemberExpertWatchs() {
    console.log('getMemberExpertWatchs');

    return new Promise(async (resolve, reject) => {
      this.apollo
        .watchQuery<{ getMy: any }>({
          query: QUERY_MY_EXPERT_WATCHS,
          fetchPolicy: 'network-only',
        })
        .valueChanges.pipe(take(1))
        .subscribe(
          async ({ data, loading }) => {
            console.log('loading', loading);
            console.log('data', data);

            if (data) {
              // loading = false; //如果有data,表示有cache
              if (data?.getMy) {
                try {
                  const new_data = await this.cacheService.patchData2(
                    data?.getMy,
                    'mommy_user',
                    'expert_watchs'
                  );
                  resolve(new_data);
                } catch (error) {
                  console.error('cacheService merge data error', error);
                  reject(-1);
                }
              } else {
                console.log('no new data need to merge..');
                reject('no new data need to merge');
              }
            } else {
              reject('data is null');
            }
          },
          ({ graphQLErrors, networkError }) => {
            const msg = this.util.getErrorDesc(graphQLErrors, networkError);
            this.util.showToast(msg, '', 0, true);
            reject(-1);
          }
        );
    });
  }

  // 取得會員的收藏文章的資料
  getMemberPostKeeps() {
    console.log('getMemberPostKeeps');

    return new Promise(async (resolve, reject) => {
      this.apollo
        .watchQuery<{ getMy: any }>({
          query: QUERY_MY_POST_KEEP,
          fetchPolicy: 'network-only',
        })
        .valueChanges.pipe(take(1))
        .subscribe(
          async ({ data, loading }) => {
            console.log('loading', loading);
            console.log('data', data);

            if (data) {
              // loading = false; //如果有data,表示有cache
              if (data?.getMy) {
                try {
                  const new_data = await this.cacheService.patchData2(
                    data?.getMy,
                    'mommy_user',
                    'post_keeps'
                  );
                  resolve(new_data);
                } catch (error) {
                  console.error('cacheService merge data error', error);
                  reject(-1);
                }
              } else {
                console.log('no new data need to merge..');
                reject('no new data need to merge');
              }
            } else {
              reject('data is null');
            }
          },
          ({ graphQLErrors, networkError }) => {
            const msg = this.util.getErrorDesc(graphQLErrors, networkError);
            this.util.showToast(msg, '', 0, true);
            reject(-1);
          }
        );
    });
  }

  // 取得會員的愛心文章資料
  getMemberPostLikes() {
    console.log('getMemberPostLikes');

    return new Promise(async (resolve, reject) => {
      this.apollo
        .watchQuery<{ getMy: any }>({
          query: QUERY_MY_POST_LIKE,
          fetchPolicy: 'network-only',
        })
        .valueChanges.pipe(take(1))
        .subscribe(
          async ({ data, loading }) => {
            console.log('loading', loading);
            console.log('data', data);

            if (data) {
              // loading = false; //如果有data,表示有cache
              if (data?.getMy) {
                try {
                  const new_data = await this.cacheService.patchData2(
                    data?.getMy,
                    'mommy_user',
                    'post_likes'
                  );
                  resolve(new_data);
                } catch (error) {
                  console.error('cacheService merge data error', error);
                  reject(-1);
                }
              } else {
                console.log('no new data need to merge..');
                reject('no new data need to merge');
              }
            } else {
              reject('data is null');
            }
          },
          ({ graphQLErrors, networkError }) => {
            const msg = this.util.getErrorDesc(graphQLErrors, networkError);
            this.util.showToast(msg, '', 0, true);
            reject(-1);
          }
        );
    });
  }

  // 取得會員的pgc文章評論資料
  getMemberPostComments() {
    console.log('getMemberPostComments');

    return new Promise(async (resolve, reject) => {
      this.apollo
        .watchQuery<{ getMy: any }>({
          query: QUERY_MY_POST_COMMENT,
          fetchPolicy: 'network-only',
        })
        .valueChanges.pipe(take(1))
        .subscribe(
          async ({ data, loading }) => {
            console.log('loading', loading);
            console.log('data', data);

            if (data) {
              // loading = false; //如果有data,表示有cache
              if (data?.getMy) {
                try {
                  const new_data = await this.cacheService.setData2(
                    data?.getMy.post_comments,
                    'member_post_comments'
                  );
                  resolve(new_data);
                } catch (error) {
                  console.error('cacheService merge data error', error);
                  reject(-1);
                }
              } else {
                console.log('no new data need to merge..');
                reject('no new data need to merge');
              }
            } else {
              reject('data is null');
            }
          },
          ({ graphQLErrors, networkError }) => {
            const msg = this.util.getErrorDesc(graphQLErrors, networkError);
            this.util.showToast(msg, '', 0, true);
            reject(-1);
          }
        );
    });
  }

  // 取得會員的 vote 評論資料
  getMemberVoteComments() {
    console.log('getMemberVoteComments');

    return new Promise(async (resolve, reject) => {
      this.apollo
        .watchQuery<{ getMy: any }>({
          query: QUERY_MY_VOTE_COMMENT,
          fetchPolicy: 'network-only',
        })
        .valueChanges.pipe(take(1))
        .subscribe(
          async ({ data, loading }) => {
            console.log('loading', loading);
            console.log('data', data);

            if (data) {
              // loading = false; //如果有data,表示有cache
              if (data?.getMy) {
                try {
                  const new_data = await this.cacheService.setData2(
                    data?.getMy.vote_comments,
                    'member_vote_comments'
                  );
                  resolve(new_data);
                } catch (error) {
                  console.error('cacheService merge data error', error);
                  reject(-1);
                }
              } else {
                console.log('no new data need to merge..');
                reject('no new data need to merge');
              }
            } else {
              reject('data is null');
            }
          },
          ({ graphQLErrors, networkError }) => {
            const msg = this.util.getErrorDesc(graphQLErrors, networkError);
            this.util.showToast(msg, '', 0, true);
            reject(-1);
          }
        );
    });
  }
}
