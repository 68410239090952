import { Injectable } from '@angular/core';
import {
  AlertController,
  ModalController,
  NavController,
  Platform,
  ToastController,
} from '@ionic/angular';
import dataHead_F from '@mommy/assets/babyHead_F.json'; // 女寶寶頭圍百分位資料
import dataHead_M from '@mommy/assets/babyHead_M.json'; // 男寶寶頭圍百分位資料

import dataHeight_F from '@mommy/assets/babyHeight_F.json'; // 女寶寶身高百分位資料
import dataHeight_M from '@mommy/assets/babyHeight_M.json'; // 男寶寶身高百分位資料

import dataWeight_F from '@mommy/assets/babyWeight_F.json'; // 女寶寶體重百分位資料
import dataWeight_M from '@mommy/assets/babyWeight_M.json'; // 男寶寶體重百分位資料
import { ReferralClickLog } from '@mommy/models/Comm.model';

import { Channel, Post } from '@mommy/models/Eline.model';
import { ExpertInfo } from '@mommy/models/ExpertInfo.model';
import { MemberBabyGrowRecordInfo } from '@mommy/models/MemberBabyGrowRecordInfo.model';
import { MemberBabyInfo } from '@mommy/models/MemberBabyInfo.model';
import { PostInfo } from '@mommy/models/PostInfo.model';
import { StateUtilsService } from '@mommy/services/state-utils.service';
import { StorageService } from '@mommy/services/storage.service';
import { ImageModalPage } from '@mommy/shared/modal/image-modal/image-modal.page';
import { CalculateHospitalDistance } from '@mommy/state/hospital/hospital.actions';
import {
  AddMemberExpertWatch,
  RemoveMemberExpertWatch,
} from '@mommy/state/member-expert-watch/member-expert-watch.actions';
import {
  AddMemberPostKeep,
  RemoveMemberPostKeep,
} from '@mommy/state/member-post-keep/member-post-keep.actions';
import {
  AddMemberPostLike,
  RemoveMemberPostLike,
} from '@mommy/state/member-post-like/member-post-like.actions';
import { Store } from '@ngxs/store';
import {
  addDays,
  differenceInDays,
  differenceInMinutes,
  eachWeekOfInterval,
  format,
  formatISO,
  getDay,
  intervalToDuration,
  parse,
  parseISO,
} from 'date-fns';
import * as _ from 'lodash';
import { ApiOmProvider } from './api-om';
import { CapUtilService } from './caputil.service';

declare const cordova: any;

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  referralClickLogs: ReferralClickLog[];

  constructor(
    public navCtrl: NavController,
    public modalController: ModalController,
    private toastCtrl: ToastController,
    public alertCtrl: AlertController,
    private stateUtil: StateUtilsService,
    private store: Store,
    private storage: StorageService,
    private platform: Platform,
    private caputil: CapUtilService,
    private omapi: ApiOmProvider
  ) {}

  async showError(errorMsg) {
    const prompt = await this.alertCtrl.create({
      header: '',
      message: errorMsg,
      cssClass: 'my-custom-alert-class',
      buttons: ['確定'],
    });
    await prompt.present();
  }

  async showInfo(msg) {
    const prompt = await this.alertCtrl.create({
      header: '',
      message: msg,
      cssClass: 'my-custom-alert-class',
      buttons: ['確定'],
    });
    await prompt.present();
  }

  async showColumnInfo(header, msg) {
    const alert = await this.alertCtrl.create({
      header,
      message: msg,
      cssClass: 'my-custom-alert-class',
      buttons: ['確定'],
    });

    await alert.present();
  }

  showYNconfirm(title, msg, cssClass?) {
    if (!cssClass) {
      cssClass = 'my-custom-alert-class';
    }

    return new Promise(async (resolve, reject) => {
      const alert = await this.alertCtrl.create({
        header: title,
        message: msg,
        cssClass,
        backdropDismiss: false,
        buttons: [
          {
            text: '取消',
            cssClass: 'alert-button-cancel',
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
              reject('cancel');
            },
          },
          {
            text: '確定',
            cssClass: 'alert-button-confirm',
            handler: () => {
              console.log('confirm clicked');
              resolve('OK');
            },
          },
        ],
      });
      await alert.present();
    });
  }

  showYconfirm(title, msg, cssClass?) {
    if (!cssClass) {
      cssClass = 'my-custom-alert-class';
    }
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertCtrl.create({
        header: title,
        message: msg,
        cssClass,
        backdropDismiss: false,
        buttons: [
          {
            text: '確定',
            cssClass: 'alert-button-confirm',
            handler: () => {
              console.log('confirm clicked');
              resolve('OK');
            },
          },
        ],
      });
      await alert.present();
    });
  }

  async showToast(
    msg: string,
    header?: string,
    myduration?: number,
    showCloseButton?: boolean
  ) {
    if (myduration === null || myduration === undefined) {
      myduration = 2000;
    }

    let myButtons = [];
    if (showCloseButton) {
      myButtons.push({ text: '關閉', role: 'cancel' });
    } else {
      myButtons = [];
    }

    const toast = await this.toastCtrl.create({
      message: msg,
      header,
      duration: myduration,
      position: 'bottom',
      color: 'dark',
      keyboardClose: true,
      buttons: myButtons,
    });

    await toast.present();
  }

  async showErrorWithToash(errorMsg: string) {
    const toast = await this.toastCtrl.create({
      message: errorMsg,
      duration: 3000,
      position: 'top',
      color: 'dark',
      keyboardClose: true,
    });

    await toast.present();
  }

  async showInfoWithToash(infoMsg) {
    const toast = await this.toastCtrl.create({
      message: infoMsg,
      duration: 3000,
      color: 'dark',
      position: 'top',
      keyboardClose: true,
    });

    await toast.present();
  }

  // 檢舉文章評論的 彈跳視窗
  showReportAlert() {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertCtrl.create({
        header: '檢舉留言或文章',
        cssClass: 'my-report-alert-class',
        backdropDismiss: false,
        mode: 'md',
        inputs: [
          {
            label: '不請自來的廣告或垃圾留言',
            type: 'radio',
            value: '不請自來的廣告或垃圾留言',
            cssClass: 'my-report-radio-class',
          },
          {
            label: '色情或煽情露骨留言',
            type: 'radio',
            value: '色情或煽情露骨留言',
            cssClass: 'my-report-radio-class',
          },
          {
            label: '虐待兒童',
            type: 'radio',
            value: '虐待兒童',
            cssClass: 'my-report-radio-class',
          },
          {
            label: '仇恨言論或血腥暴力的內容',
            type: 'radio',
            value: '仇恨言論或血腥暴力的內容',
            cssClass: 'my-report-radio-class',
          },
          {
            label: '宣傳恐怖主義',
            type: 'radio',
            value: '宣傳恐怖主義',
            cssClass: 'my-report-radio-class',
          },
          {
            label: '騷擾或霸凌內容',
            type: 'radio',
            value: '騷擾或霸凌內容',
            cssClass: 'my-report-radio-class',
          },
          {
            label: '自殺或自殘',
            type: 'radio',
            value: '自殺或自殘',
            cssClass: 'my-report-radio-class',
          },
          {
            label: '不實資訊',
            type: 'radio',
            value: '不實資訊',
            cssClass: 'my-report-radio-class',
          },
        ],
        buttons: [
          {
            text: '檢舉',
            cssClass: 'alert-button-report',
            handler: (data) => {
              console.log('report clicked');
              resolve(data);
            },
          },
          {
            text: '取消',
            role: 'cancel',
            cssClass: 'alert-button-cancel',
            handler: () => {
              console.log('cancel clicked');
              reject('CANCEL');
            },
          },
        ],
      });
      await alert.present();
    });
  }

  async createModal(initHeight: number, modalComponent: any, data: any) {
    console.log('window.innerHeight', window.innerHeight);

    // 取得 css env(safe-area-inset-bottom)
    const sab = this.getSafeAreaInsetBottom();

    const breakPoint = (initHeight + sab + 10) / window.innerHeight;
    console.log('breakPoint', breakPoint);
    const secondBreakPoint = breakPoint + 0.2;

    const modal = await this.modalController.create({
      component: modalComponent,
      cssClass: 'my-custom-modal-class',
      breakpoints: [0, breakPoint, secondBreakPoint, 1],
      initialBreakpoint: breakPoint,
      componentProps: data,
    });

    return modal;
  }

  async createFixedModal(
    modalComponent: any,
    data: any,
    cssClass: string,
    animated?: boolean
  ) {
    if (animated === null || animated === undefined) {
      animated = false;
    }

    const modal = await this.modalController.create({
      component: modalComponent,
      cssClass: 'my-custom-modal-class ' + cssClass,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: data,
      animated: animated,
    });

    return modal;
  }

  // 取得環境的safe area inset bottom
  getSafeAreaInsetBottom() {
    const sab = getComputedStyle(document.documentElement).getPropertyValue(
      '--safe-area-inset-bottom'
    );
    let intSabPx = 0;
    if (sab) {
      intSabPx = parseInt(sab.replace('px', ''), 10);
    }
    return intSabPx;
  }

  // 取得環境的safe area inset top
  getSafeAreaInsetTop() {
    const sat = getComputedStyle(document.documentElement).getPropertyValue(
      '--safe-area-inset-top'
    );
    let intSatPx = 0;
    if (sat) {
      intSatPx = parseInt(sat.replace('px', ''), 10);
    }
    return intSatPx;
  }

  getErrorDesc(graphQLErrors, networkError) {
    let msg = '';
    if (networkError) {
      console.error('networkError', networkError);
      msg = `網路錯誤(${networkError.status})！請確定您的網路連線是否正常。`;
    } else if (graphQLErrors) {
      console.error('graphQLErrors', graphQLErrors);

      if (graphQLErrors[0].message.statusCode === 403) {
        msg = graphQLErrors[0].message.message;
      } else if (graphQLErrors[0].message.statusCode === 401) {
        msg = graphQLErrors[0].message.error;
      } else if (graphQLErrors[0].message === 'USER_IS_PENDING') {
        msg = '您的帳號異常，系統已自動登出';
      } else {
        msg = graphQLErrors[0].message.message;
        if (graphQLErrors[0].message.message) {
          msg = graphQLErrors[0].message.message;
        } else {
          if ((msg = graphQLErrors[0].message)) {
            msg = msg = graphQLErrors[0].message;
          } else {
            msg = 'ERROR';
          }
        }
      }
    } else {
      msg = 'ERROR';
    }

    return msg;
  }

  async showLoginPopupModal() {
    // return new Promise(async (resolve, reject) => {
    //   const modal = await this.modalController.create({
    //     component: LoginPopupModalPage,
    //     cssClass: 'login-popup-modal',
    //     componentProps: {},
    //   });
    //   modal.onDidDismiss().then((data) => {
    //     console.log('data', data);
    //     if (data.data) {
    //       resolve(data.data);
    //     } else {
    //       reject(false);
    //     }
    //   });
    //   await modal.present();
    // });
    return false;
  }

  // 關注專家
  async addExpertWatch(expert: ExpertInfo) {
    console.log('addExpertWatch', expert);
    if (this.stateUtil.isMommyAuthenticated()) {
      try {
        await this.store
          .dispatch(new AddMemberExpertWatch(expert.expert_id))
          .toPromise();
        console.log('addExpertWatch success');
      } catch (error) {
        console.error('addExpertWatch error', error);
        this.showToast('關注操作失敗，請稍後再試！');
      }
    } else {
      try {
        await this.showLoginPopupModal();
        this.navCtrl.navigateForward(['/login']);
      } catch (error) {
        console.log('showLoginPopupModal user cancel', error);
      }
    }
  }

  // 取消關注專家
  async removeExpertWatch(expert: ExpertInfo) {
    console.log('removeExpertWatch', expert);
    if (this.stateUtil.isMommyAuthenticated()) {
      try {
        await this.store
          .dispatch(new RemoveMemberExpertWatch(expert.expert_id))
          .toPromise();
        console.log('removeExpertWatch success');
      } catch (error) {
        console.error('removeExpertWatch error', error);
        this.showToast('取消關注操作失敗，請稍後再試！');
      }
    } else {
      try {
        await this.showLoginPopupModal();
        this.navCtrl.navigateForward(['/login']);
      } catch (error) {
        console.log('showLoginPopupModal user cancel', error);
      }
    }
  }

  // 收藏文章
  async addPostKeep(post: PostInfo) {
    console.log('addPostKeep', post);
    if (this.stateUtil.isMommyAuthenticated()) {
      try {
        await this.store
          .dispatch(new AddMemberPostKeep(post.post_id))
          .toPromise();
        console.log('addPostKeep success');
      } catch (error) {
        console.error('addPostKeep error', error);
        this.showToast('收藏文章操作失敗，請稍後再試！');
      }
    } else {
      try {
        await this.showLoginPopupModal();
        this.navCtrl.navigateForward(['/login']);
      } catch (error) {
        console.log('showLoginPopupModal user cancel', error);
      }
    }
  }

  // 取消收藏文章
  async removePostKeep(post: PostInfo) {
    console.log('removePostKeep', post);
    if (this.stateUtil.isMommyAuthenticated()) {
      try {
        await this.store
          .dispatch(new RemoveMemberPostKeep(post.post_id))
          .toPromise();
        console.log('removePostKeep success');
      } catch (error) {
        console.error('removePostKeep error', error);
        this.showToast('取消收藏文章操作失敗，請稍後再試！');
      }
    } else {
      try {
        await this.showLoginPopupModal();
        this.navCtrl.navigateForward(['/login']);
      } catch (error) {
        console.log('showLoginPopupModal user cancel', error);
      }
    }
  }

  // 送出愛心文章
  async addPostLike(post: PostInfo) {
    console.log('addPostLike', post);
    if (this.stateUtil.isMommyAuthenticated()) {
      try {
        await this.store
          .dispatch(new AddMemberPostLike(post.post_id))
          .toPromise();
        console.log('addPostLike success');
      } catch (error) {
        console.error('addPostLike error', error);
        this.showToast('送出愛心文章操作失敗，請稍後再試！');
      }
    } else {
      try {
        await this.showLoginPopupModal();
        this.navCtrl.navigateForward(['/login']);
      } catch (error) {
        console.log('showLoginPopupModal user cancel', error);
      }
    }
  }

  // 取消送出愛心文章
  async removePostLike(post: PostInfo) {
    console.log('removePostLike', post);
    if (this.stateUtil.isMommyAuthenticated()) {
      try {
        await this.store
          .dispatch(new RemoveMemberPostLike(post.post_id))
          .toPromise();
        console.log('removePostLike success');
      } catch (error) {
        console.error('removePostLike error', error);
        this.showToast('取消送出愛心文章操作失敗，請稍後再試！');
      }
    } else {
      try {
        await this.showLoginPopupModal();
        this.navCtrl.navigateForward(['/login']);
      } catch (error) {
        console.log('showLoginPopupModal user cancel', error);
      }
    }
  }

  // 兩個經緯度的距離計算(km)
  // const dis = this.util.getDistanceFromLatLonInKm(
  //   25.021034325870357,
  //   121.47232298534388, Peter家
  //   25.01427279849733,
  //   121.4638315281212  板橋車站
  // );
  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    console.log('getDistanceFromLatLonInKm', { lat1, lon1, lat2, lon2 });

    const deg2rad = (deg) => {
      return deg * (Math.PI / 180);
    };

    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    console.log('distance', d);
    return d;
  }

  // 用 inappbrowser _system 來開啟地圖
  async openMap(address: string) {
    console.log('openMap', address);
    let url = '';
    if (this.platform.is('ios')) {
      url = `maps://?q=${address}`;
    } else {
      url = `geo:0,0?q=${address}`;
    }
    console.log('openMap url', url);
    this.caputil.cordova_iab_browser_with_system(url);
  }

  // 取得裝置的gps位置,並且計算醫院的距離
  async process_gps_coords() {
    console.log('process_gps_coords');
    const user_lat = 0;
    const user_lon = 0;

    // 處理順序:
    // 1. 讀取上次使用的位置
    const gps_coords = await this.storage.get('gps_coords');
    if (gps_coords) {
      console.log('gps_coords', gps_coords);
      this.store.dispatch(
        new CalculateHospitalDistance(
          gps_coords?.latitude || 0,
          gps_coords?.longitude || 0
        )
      );
    }

    // 2. 利用gps取得目前位置
    try {
      const gps_position = await this.caputil.getCurrentPosition();
      const gps_coords = {
        latitude: gps_position.coords.latitude,
        longitude: gps_position.coords.longitude,
      };
      console.log('gps_coords', gps_coords);

      this.store.dispatch(
        new CalculateHospitalDistance(
          gps_coords?.latitude || 0,
          gps_coords?.longitude || 0
        )
      );

      // 3. 儲存目前位置
      await this.storage.set('gps_coords', gps_coords);
    } catch (error) {
      console.error('getCurrentPosition error', error);
    }
  }

  // 依照時間給予不同的date format
  // 當天 HH:mm
  // 當年 M/D
  // else: YYYY/M/D
  mydateformat(ts) {
    // console.log(ts);
    if (ts === undefined) {
      return 'yyyy/M/d HH:mm';
    }

    // Create date from input value
    const inputDate = new Date(ts);

    // Get today's date
    const todaysDate = new Date();

    // call setHours to take the time out of the comparison
    if (inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
      // Date equals today's date
      return 'HH:mm';
    }
    // console.log(inputDate.getFullYear());
    // console.log(todaysDate.getFullYear());

    if (inputDate.getFullYear() === todaysDate.getFullYear()) {
      // console.log('same year');
      return 'M/d';
    } else {
      // console.log('not same year');
      return 'yyyy/M/d';
    }
  }

  save_photo(msg_obj) {
    return new Promise((resolve, reject) => {
      console.log('cordova.plugins', JSON.stringify(cordova.plugins));
      cordova.plugins.photoLibrary.requestAuthorization(
        () => {
          console.log('got permission');

          // cordova.plugins.photoLibrary.getAlbums(
          //   function (albums) {
          //     albums.forEach(function(album) {
          //       console.log('*****');
          //       console.log(album.id);
          //       console.log(album.title);
          //       console.log('*****');
          //     });
          //   },
          //   function (err) { }
          // );
          // file or remote URL. url can also be dataURL, but giving it a file path is much faster
          const url = msg_obj.pic;
          const album = 'ELINE';
          // var album = '';
          console.log('image url', url);

          // https://github.com/terikon/cordova-plugin-photo-library/issues/116
          cordova.plugins.photoLibrary.saveImage(
            url + '&ext=.jpg',
            album,
            (libraryItem) => {
              console.log('save photo success libraryItem', libraryItem);
              resolve('OK');
            },
            (err) => {
              console.log('save photo error', err);
              reject(err);
            }
          );
        },
        () => {
          console.log('user deny permission');
          reject('無權限!');
        },
        {
          read: true,
          write: true,
        }
      );
    });
  }

  // 啟動相機/相簿取得照片 from:camera , photo
  // TODO: 換成 capacitor
  // take_photo(from: 'camera' | 'photo' | 'file') {
  //   // return new Promise((resolve, reject) => {
  //   //   const navigatorObject: any = window.navigator;
  //   //   if (from === 'file') {
  //   //   } else {
  //   //     if (!navigatorObject.camera) {
  //   //       reject('您的裝置不支援這個的功能!');
  //   //       return;
  //   //     }
  //   //     // default config
  //   //     let camera_param: CameraOptions = {};
  //   //     camera_param.quality = 50; // 原 eline 是設定 50
  //   //     // camera_param.targetWidth = 640;
  //   //     // camera_param.targetHeight = 640;
  //   //     console.log('camera_param', camera_param);
  //   //     // alert(JSON.stringify(camera_param));
  //   //     camera_param.encodingType = this.camera.EncodingType.JPEG;
  //   //     camera_param.destinationType = this.camera.DestinationType.DATA_URL;
  //   //     camera_param.correctOrientation = true;
  //   //     if (cameraOption) {
  //   //       camera_param = { ...camera_param, ...cameraOption }; // 傳入的參數,覆蓋default參數
  //   //     }
  //   //     if (from === 'camera') {
  //   //       camera_param.sourceType = this.camera.PictureSourceType.CAMERA;
  //   //     } else if (from === 'photo') {
  //   //       camera_param.sourceType = this.camera.PictureSourceType.PHOTOLIBRARY;
  //   //     }
  //   //     this.camera.getPicture(camera_param).then(
  //   //       (imageData) => {
  //   //         // imageData is either a base64 encoded string or a file URI
  //   //         // If it's base64:
  //   //         // this.myticket.close_image1_data = 'data:image/jpg;base64,' + imageData;
  //   //         //強制在data binding, ionic2 應該不需要
  //   //         const base64Image = 'data:image/jpeg;base64,' + imageData;
  //   //         resolve(base64Image);
  //   //       },
  //   //       (err) => {
  //   //         // Handle error
  //   //         const n = err.indexOf('cancelled');
  //   //         const m = err.indexOf('No Image Selected');
  //   //         const o = err.indexOf('No Image Selected');
  //   //         const p = err.indexOf('has no access to assets');
  //   //         if (!(n >= 0 || m >= 0 || o >= 0 || p >= 0)) {
  //   //           reject(err);
  //   //         } else {
  //   //           console.log(err);
  //   //           reject('cancel');
  //   //         }
  //   //       }
  //   //     );
  //   //   }
  //   // });
  // }

  // TODO: 換成 capacitor
  // async iab_browser(url: string) {
  //   // const browser = this.iab.create(url,'_self','{}');
  //   // const browser = new InAppBrowser(url);
  //   console.log('iab_browser _blank');

  //   // // default append auth_token
  //   // const auth_token = await this.storage.get('auth_token');
  //   // var _url = new URL(url);
  //   // if (auth_token) {
  //   //   _url.searchParams.append('auth_token', auth_token);
  //   // }

  //   // // 判斷是否為 mommycareyou.com and 有 external 關鍵字, if yes, 用system browser 開啟
  //   // // 判斷是否為自己的domain, ex: mommycareyou.com , redirect to 站外網址, 一律先到站內(含auth_token)再轉址
  //   // const match_url = _.filter(environment.SELF_DOMAIN_URL, (elem) => {
  //   //   return url.includes(elem);
  //   // });

  //   // var eline_flag = match_url.length > 0;
  //   // var external_flag = url.includes('external');

  //   // //判斷是否為 malldj url
  //   // const malldj_url = _.filter(environment.MALLDJ_URL, (elem) => {
  //   //   return url.includes(elem);
  //   // });
  //   // var malldj_flag = malldj_url.length > 0;

  //   // console.log('_url', _url.toString());
  //   // if (eline_flag && external_flag) {
  //   //   this.iab_browser_with_system(_url.toString());
  //   // } else if (malldj_flag) {
  //   //   this.iab_browser_with_system(url);
  //   // } else {
  //   //   const browser = this.iab.create(_url.toString(), '_blank');
  //   // }
  // }

  // async iab_browser_with_system(url: string) {
  //   // const browser = this.iab.create(url, '_system');
  // }

  // async iab_show_img(url: string) {
  //   console.log('iab_browser _blank');
  //   // const browser = this.iab.create(
  //   //   url,
  //   //   '_blank',
  //   //   'hidenavigationbuttons=no,location=no,hideurlbar=yes,toolbar=yes,enableViewportScale=yes'
  //   // );
  // }

  merge_message(channel: Channel) {
    const get_lastunreadmessagekey = (msgs: any): string => {
      const msg: Post = (<any>_.chain(msgs)
        .takeRightWhile((elem: any) => {
          return elem.rs === '0';
        })
        .head()).value();

      if (msg) {
        return msg.key;
      } else {
        return '';
      }
    };

    return new Promise((resolve, reject) => {
      console.log('merge_message..');

      //get local message
      //if localmessage's createat > temp message's createat 則不merge
      // get temp message
      this.storage.get(channel.cid + '-temp').then((temp_msgs) => {
        // get last local message
        this.storage.get(channel.cid).then((local_msgs) => {
          if (temp_msgs === undefined || temp_msgs == null) {
            console.log('沒有temp message,直接進入chat page');
            //沒有temp message,直接進入chat page
            channel.lastunreadmessagekey = get_lastunreadmessagekey(local_msgs);
            channel.local_msgs = local_msgs;
            resolve(channel);
          } else {
            if (local_msgs === undefined || local_msgs == null) {
              console.log(
                '沒有last local message,應該是新的chat,直接以temp取代'
              );
              //沒有last local message,應該是新的chat,直接以temp取代
              channel.lastunreadmessagekey =
                get_lastunreadmessagekey(temp_msgs);
              this.storage
                .set(channel.cid, temp_msgs)
                .then(() => {
                  channel.local_msgs = temp_msgs;
                  resolve(channel);
                })
                .catch((err) => {
                  console.log(err);
                  resolve(channel);
                });
            } else {
              //merge
              const diff_temp = _.differenceBy(local_msgs, temp_msgs, 'key');
              const message_temp = _.unionBy(diff_temp, temp_msgs, 'key');
              const msgs = _.filter(_.sortBy(message_temp, 'cat'), 'cat'); //過濾掉createat = undefined的資料

              // console.log(this.last_local_msg);
              // console.log(this.messages);
              // console.log(diff_temp);
              // console.log(message_temp);
              // console.log(msgs);

              console.log(msgs);

              // let msgs = lodash.unionBy(this.last_local_msg,this.firebase_msg,'$key');

              //新的資料若比舊的資料少(不正常),則不更新
              if (msgs.length >= local_msgs.length) {
                channel.lastunreadmessagekey = get_lastunreadmessagekey(msgs);
                this.storage
                  .set(channel.cid, msgs)
                  .then(() => {
                    console.log('save channel messages to storage done.');
                    channel.local_msgs = msgs;
                    resolve(channel);
                  })
                  .catch((error) => {
                    console.log(error);
                    resolve(channel);
                  });

                // 將最後一則訊息的createat儲存,方便使用 channel_id-lastlocalmsgcreateat
                this.storage
                  .set(
                    channel.cid + '-lastlocalmsgcreateat',
                    (<any>_.last(msgs)).createat
                  )
                  .then(() =>
                    console.log('save last messages createat to storage done.')
                  )
                  .catch((error) => {
                    console.log(error);
                    reject(error);
                  });
              } else {
                console.warn(
                  'msgs.length = ' +
                    msgs.length +
                    ' < last_local_msg.length = ' +
                    local_msgs.length
                );
                channel.lastunreadmessagekey =
                  get_lastunreadmessagekey(local_msgs);
                channel.local_msgs = local_msgs;
                resolve(channel);
              }
            } //if local_msgs == undefined
          } // if temp_msgs == undefined
        });
      });
    });
  }

  // show api error
  show_api_error(error) {
    if (error.api_error) {
      this.showError('作業發生錯誤,請稍後再試!<br>錯誤訊息:' + error.message);
    } else {
      if (error.message) {
        this.showError(
          '作業失敗!<br>請確認網路是否正常?或稍後再試!<br>錯誤訊息:' +
            error.message
        );
      } else {
        this.showError(
          '作業失敗!<br>請確認網路是否正常?或稍後再試!<br>錯誤訊息:' + error
        );
      }
    }
  }

  // 分享news / health_education content
  // TODO: 調整為 capacitor
  // do_share_content(public_code: string) {
  //   console.log('do_share_content', public_code);
  //   // let _url = environment.PUBLIC_POST_HOST + public_code;

  //   // this.socialSharing.shareWithOptions({ url: _url });
  // }

  async imageModal(image: string) {
    const modal = await this.modalController.create({
      component: ImageModalPage,
      componentProps: {
        img: image,
      },
      cssClass: 'transparent-modal',
    });
    return await modal.present();
  }

  // 檢查使用者是否已經被停用
  checkIfUserIsPending(payload: any) {
    if (payload.error && payload.error?.message === 'USER_IS_PENDING') {
      return true;
    } else {
      return false;
    }
  }

  // <=================================
  // 首頁, 計算孕期天數
  // 返回 week (寶寶n週), babyActiveDays (距出生x天), imagePath
  getPregnancyInfo(baby_due_date: string, lmp_date: string) {
    console.log('getPregnancyInfo baby_due_date', baby_due_date);
    console.log('getPregnancyInfo lmp_date', lmp_date);

    let activeBabyDueDate;
    let date_activeBabyDueDate;
    if (baby_due_date) {
      date_activeBabyDueDate = parseISO(baby_due_date);
      activeBabyDueDate = format(parseISO(baby_due_date), 'yyyy-MM-dd');
    } else if (lmp_date) {
      const _lmp_date = parseISO(lmp_date);
      const _baby_due_date = addDays(_lmp_date, 280);
      date_activeBabyDueDate = _baby_due_date;
      activeBabyDueDate = format(_baby_due_date, 'yyyy-MM-dd');
    } else {
      console.warn('no baby_due_date or lmp_date');
      return null;
    }

    // 取得週數
    const week = this.getWeeks(activeBabyDueDate);

    // 取得距離出生天數
    const babyActiveDays = this.getBabyDays(new Date(), date_activeBabyDueDate);

    // 取得圖片路徑
    const imagePath = this.getPregnancyImagePath(week);

    return { week, babyActiveDays, imagePath };
  }

  // 依照預產期, 計算出40週的起始日期, 並計算today是落在哪一週
  private getWeeks(str_baby_due_date: string) {
    let baby_due_date;
    let first_date;
    console.log('str_baby_due_date', str_baby_due_date);

    if (str_baby_due_date) {
      baby_due_date = parse(str_baby_due_date, 'yyyy-MM-dd', new Date());
      first_date = addDays(baby_due_date, -280);
      console.log('first_date', first_date);
    } else {
      first_date = new Date();
      baby_due_date = addDays(first_date, 280);
    }

    // // 從第1天起算, 計算每週的區間 及 把當週的第一天加入到陣列中
    // this.weeks = eachWeekOfInterval(
    //   {
    //     start: first_date,
    //     end: baby_due_date,
    //   },
    //   { weekStartsOn: 1 }
    // );

    // 從第1天起算, 計算每週的區間 及 把當週的第一天加入到陣列中
    // 以下設定可以產生 41個陣列, 去除第0個元素, 1~40可以剛好為孕期的40週
    let _weekStartsOn: any = getDay(baby_due_date);
    if (_weekStartsOn === 6) {
      _weekStartsOn = 0;
    }
    console.log('_weekStartsOn', _weekStartsOn);
    const weeks = eachWeekOfInterval(
      {
        start: first_date,
        end: baby_due_date,
      },
      { weekStartsOn: _weekStartsOn + 1 }
    );

    console.log('weeks', weeks);

    const today = new Date();

    let activeWeekIndex = -1;
    for (let i = 0; i < weeks.length; i++) {
      const minutes = differenceInMinutes(today, weeks[i]);
      if (minutes >= 0 && minutes < 7 * 24 * 60) {
        activeWeekIndex = i;
      }
    }

    console.log('activeWeekIndex', activeWeekIndex);
    console.log('weeks[activeWeekIndex]', weeks[activeWeekIndex]);

    // if (activeWeekIndex === -1) {
    //   return -1;
    // } else {
    //   return activeWeekIndex + 1;
    // }
    return activeWeekIndex;
  }

  // 計算 x日期 離寶寶出生天數
  private getBabyDays(x: Date, baby_due_date: Date) {
    const activeBabyDays = differenceInDays(baby_due_date, x) + 1;
    console.log('activeBabyDays', activeBabyDays);
    return activeBabyDays;
  }

  // 依照週別, 取得對應的圖片路徑
  private getPregnancyImagePath(week) {
    let imagePath;
    if (week <= 4) {
      imagePath = 'pregnancy1.png';
    } else if (week <= 8) {
      imagePath = 'pregnancy2.png';
    } else if (week <= 12) {
      imagePath = 'pregnancy3.png';
    } else if (week <= 16) {
      imagePath = 'pregnancy4.png';
    } else if (week <= 20) {
      imagePath = 'pregnancy5.png';
    } else if (week <= 24) {
      imagePath = 'pregnancy6.png';
    } else if (week <= 28) {
      imagePath = 'pregnancy7.png';
    } else if (week <= 32) {
      imagePath = 'pregnancy8.png';
    } else if (week > 32) {
      imagePath = 'pregnancy9.png';
    } else {
      imagePath = 'pregnancy1.png';
    }
    return imagePath;
  }
  // =================================>

  // 首頁, 計算寶寶出生天數
  // 返回 n 個月 m 天, 無則 return ''
  getBabyInfo(babies) {
    console.log('getBabyInfo', babies);

    let activeBabyDays = '';
    if (babies && babies.length > 0) {
      const small_baby = _.chain(babies)
        .orderBy(['birthday'], ['desc'])
        .first()
        .value();

      const baby_birthday_date = parseISO(small_baby.birthday);
      const today = new Date();
      const babyDuration = intervalToDuration({
        start: baby_birthday_date,
        end: today,
      });

      const months = babyDuration.years * 12 + babyDuration.months;
      activeBabyDays = `${months} 個月 ${babyDuration.days} 天`;

      // 如果超過6個月就不顯示了
      if (months > 6) {
        activeBabyDays = '';
      }
    } else {
      activeBabyDays = '';
    }

    console.log('activeBabyDays', activeBabyDays);
    return activeBabyDays;
  }

  // 取得寶寶頭圍百分位
  getBabyHeadPercent(baby: MemberBabyInfo, record: MemberBabyGrowRecordInfo) {
    console.log('getBabyHeadPercent', baby, record);

    try {
      // 計算紀錄日期距離寶寶出生月數
      const baby_birthday_date = parse(baby.birthday, 'yyyy-MM-dd', new Date());
      const record_date = parse(record.record_date, 'yyyy-MM-dd', new Date());
      const babyDuration = intervalToDuration({
        start: baby_birthday_date,
        end: record_date,
      });
      const months = babyDuration.years * 12 + babyDuration.months;

      // 依據寶寶性別, 取得寶寶頭圍當月份的百分位資料 from dataHead_M
      let _dataHead;
      if (baby.gender === 'M') {
        _dataHead = _.find(dataHead_M, { Month: months });
      } else {
        _dataHead = _.find(dataHead_F, { Month: months });
      }
      console.log('_dataHead_M', _dataHead);

      // 依據寶寶的頭圍資料, 依序比對頭圍百分位資料, 取得最接近的百分位
      let _percent = 0;
      _.forOwn(_dataHead, (value, key) => {
        if (key.startsWith('P')) {
          if (record.head >= value) {
            _percent = parseInt(key.replace('P', ''));
          }
        }
      });
      console.log('_percent', _percent);
      return _percent;
    } catch (error) {
      console.error('getBabyHeadPercent error', error);
      return '--';
    }
  }

  // 取得寶寶身高百分位
  getBabyHeightPercent(baby: MemberBabyInfo, record: MemberBabyGrowRecordInfo) {
    console.log('getBabyHeightPercent', baby, record);

    try {
      // 計算紀錄日期距離寶寶出生月數
      const baby_birthday_date = parse(baby.birthday, 'yyyy-MM-dd', new Date());
      const record_date = parse(record.record_date, 'yyyy-MM-dd', new Date());
      const babyDuration = intervalToDuration({
        start: baby_birthday_date,
        end: record_date,
      });
      const months = babyDuration.years * 12 + babyDuration.months;

      // 依據寶寶性別, 取得寶寶身高當月份的百分位資料 from dataHead_M
      let _dataHeight;
      if (baby.gender === 'M') {
        _dataHeight = _.find(dataHeight_M, { Month: months });
      } else {
        _dataHeight = _.find(dataHeight_F, { Month: months });
      }
      console.log('_dataHeight_M', _dataHeight);

      // 依據寶寶的身高資料, 依序比對身高百分位資料, 取得最接近的百分位
      let _percent = 0;
      _.forOwn(_dataHeight, (value, key) => {
        if (key.startsWith('P')) {
          if (record.height >= value) {
            _percent = parseInt(key.replace('P', ''));
          }
        }
      });
      console.log('_percent', _percent);
      return _percent;
    } catch (error) {
      console.error('getBabyHeightPercent error', error);
      return '--';
    }
  }

  // 取得寶寶體重百分位
  getBabyWeightPercent(baby: MemberBabyInfo, record: MemberBabyGrowRecordInfo) {
    console.log('getBabyWeightPercent', baby, record);

    try {
      // 計算紀錄日期距離寶寶出生月數
      const baby_birthday_date = parse(baby.birthday, 'yyyy-MM-dd', new Date());
      const record_date = parse(record.record_date, 'yyyy-MM-dd', new Date());
      const babyDuration = intervalToDuration({
        start: baby_birthday_date,
        end: record_date,
      });
      const months = babyDuration.years * 12 + babyDuration.months;

      // 依據寶寶性別, 取得寶寶體重當月份的百分位資料 from dataHead_M
      let _dataWeight;
      if (baby.gender === 'M') {
        _dataWeight = _.find(dataWeight_M, { Month: months });
      } else {
        _dataWeight = _.find(dataWeight_F, { Month: months });
      }
      console.log('_dataWeight_M', _dataWeight);

      // 依據寶寶的體重資料, 依序比對體重百分位資料, 取得最接近的百分位
      let _percent = 0;
      _.forOwn(_dataWeight, (value, key) => {
        if (key.startsWith('P')) {
          if (record.weight >= value) {
            _percent = parseInt(key.replace('P', ''));
          }
        }
      });
      console.log('_percent', _percent);
      return _percent;
    } catch (error) {
      console.error('getBabyWeightPercent error', error);
      return '--';
    }
  }

  // 增加 推薦商品點擊紀錄
  async addReferralClickLog(
    post_id: number,
    commodityId: number,
    type: 'normal' | 'special'
  ) {
    console.log('addReferralClickLog', { post_id, commodityId });

    try {
      let log: ReferralClickLog = {};
      log.referral_code = `POST_${post_id}`;
      log.commodityId = commodityId;
      log.created_at = formatISO(new Date());
      log.type = type;
      console.log('addReferralClickLog log', log);

      // 從 localstorage 取出 referral_click_logs
      // 將 log 加入 referral_click_logs 陣列內
      // 將 referral_click_logs 存回 localstorage
      let referral_click_logs = await this.storage.get('referral_click_logs');
      if (!referral_click_logs) {
        referral_click_logs = [];
      }
      referral_click_logs.push(log);
      await this.storage.set('referral_click_logs', referral_click_logs);
      this.referralClickLogs = referral_click_logs;
    } catch (error) {
      console.error('addReferralClickLog error', error);
    }
  }

  // 取得 推薦商品的點擊紀錄
  async getReferralCodeByCommodityId(commodityId: number) {
    console.log('getReferralCodeByCommodityId', commodityId);

    try {
      if (!this.referralClickLogs) {
        this.referralClickLogs = await this.storage.get('referral_click_logs');
        if (!this.referralClickLogs) {
          this.referralClickLogs = [];
        }
      }

      // 從紀錄內找出 commodityId 相同的紀錄
      // 需限定是 7 天內的紀錄
      // 若有多筆, 則取最後一筆
      const _referralClickLogs = _.filter(this.referralClickLogs, (log) => {
        const created_at = parseISO(log.created_at);
        const now = new Date();
        const duration = intervalToDuration({
          start: created_at,
          end: now,
        });
        const days =
          duration.years * 365 + duration.months * 30 + duration.days;
        console.log('referral click log days', log, days);
        return log.commodityId === commodityId && days <= 7;
      });
      console.log('_referralClickLogs', _referralClickLogs);
      if (_referralClickLogs.length > 0) {
        const log = _.last(_referralClickLogs);
        console.log('final log', log);
        return log.referral_code;
      }
      return null;
    } catch (error) {
      console.error('getReferralCodeByCommodityId error', error);
      return null;
    }
  }

  // 取得comm server上的om_status
  // 為避免太多的query,故實做cache機制
  // 1.是否有data cache
  // 2. N--> call api
  //    Y--> 是否上次fetch time to now 已經 > cacheExpirationSeconds
  // 3. Y--> call api
  //    N--> load cache data
  get_om_status2() {
    return new Promise((resolve, reject) => {
      var cacheExpirationSeconds = 180;

      var api_call = () => {
        this.omapi.get_om_status().then(
          (response: any) => {
            console.log('response', response.data);

            var result: any = {};
            result.om_mode = response.data.om_mode;
            result.om_desc = response.data.om_desc;

            //儲存cache data
            localStorage.setItem('om_status', JSON.stringify(result));
            localStorage.setItem(
              'om_fetch_time',
              new Date().getTime().toString()
            );

            resolve(result);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      };

      const om_status = localStorage.getItem('om_status');
      if (om_status) {
        //取出上一次fetch time
        var last_fetch_time: any = localStorage.getItem('om_fetch_time');
        var expire_flag =
          new Date().getTime() - last_fetch_time >
          cacheExpirationSeconds * 1000;

        console.log('last_fetch_time', last_fetch_time);
        console.log('expire_flag', expire_flag);

        if (expire_flag) {
          // 已經超過cache expire time -> call api
          api_call();
        } else {
          // 尚未超過cache expire time -> load data from cache
          var result = JSON.parse(om_status);
          resolve(result);
        }
      } else {
        // call api
        api_call();
      }
    });
  }

  // // 取得comm server上的news_data,並判斷是否有需要顯示在header上? 條件是有expire_date >= today則顯示
  // // 為避免太多的query,故實做cache機制
  // // 1.是否有data cache
  // // 2. N--> call api
  // //    Y--> 是否上次fetch time to now 已經 > cacheExpirationSeconds
  // // 3. Y--> call api
  // //    N--> load cache data
  // get_news_data() {
  //   return new Promise((resolve, reject) => {
  //     const cacheExpirationSeconds = 180;

  //     const api_call = () => {
  //       this.omapi
  //         .get_news_data()
  //         .then((response: any) => {
  //           console.log('response', response.data);
  //           this.localStorageService.set('news_data', response.data);

  //           //check是否需要顯示
  //           var news = _.filter(response.data, function (m) {
  //             var d1 = moment(m.expire_date, 'YYYY-MM-DD HH:mm:ss');
  //             var d2 = moment();
  //             var diff = moment(m.expire_date, 'YYYY-MM-DD HH:mm:ss').diff(moment(), 'minutes');
  //             console.log('d1', d1.format('YYYY-MM-DD HH:mm:ss'));
  //             console.log('d2', d2.format('YYYY-MM-DD HH:mm:ss'));
  //             console.log('diff', diff);

  //             return moment(m.expire_date, 'YYYY-MM-DD HH:mm:ss').diff(moment(), 'minutes') >= 0;
  //           });
  //           console.log('news', news);

  //           var result: any = {};

  //           if (news.length > 0) {
  //             result.show = 'Y';
  //             result.data = news;
  //           } else {
  //             result.show = 'N';
  //             result.data = news;
  //           }

  //           //儲存cache data
  //           this.localStorageService.set('om_news', result);
  //           this.localStorageService.set('om_news_fetch_time', new Date().getTime());
  //           resolve(result);
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //           reject(error);
  //         });
  //     };

  //     if (this.localStorageService.get('om_news')) {
  //       //取出上一次fetch time
  //       var last_fetch_time: any = this.localStorageService.get('om_news_fetch_time');
  //       var expire_flag = new Date().getTime() - last_fetch_time > cacheExpirationSeconds * 1000;

  //       console.log('last_fetch_time', last_fetch_time);
  //       console.log('expire_flag', expire_flag);

  //       if (expire_flag) {
  //         // 已經超過cache expire time -> call api
  //         api_call();
  //       } else {
  //         // 尚未超過cache expire time -> load data from cache
  //         var result = this.localStorageService.get('om_news');
  //         resolve(result);
  //       }
  //     } else {
  //       // call api
  //       api_call();
  //     }
  //   });
  // }

  // 取得server上的app_version_data,並判斷是否有新版本可以更新?
  // 條件是 latest_app_version > local app_version則顯示
  // dialog:  已有新版本的APP可以更新了，現在去更新？[先不用] [去更新]
  // 為避免太多的query,故實做cache機制
  // 1.是否有data cache
  // 2. N--> call api
  //    Y--> 是否上次fetch time to now 已經 > cacheExpirationSeconds
  // 3. Y--> call api
  //    N--> load cache data
  /*
  {
    "latest_version_code_android":"130",  //1.3.0 -> 130  (第二位,第三位 不可以為雙位數)
    "latest_version_code_ios":"130",      //1.3.0 -> 130
    "app_url":"https://www.eline.com.tw/app.html"
  }
  */
  // get_app_version_data() {
  //   return new Promise((resolve, reject) => {
  //     // var cacheExpirationSeconds = 30;
  //     var cacheExpirationSeconds = 60 * 60 * 24; //一天

  //     var api_call = () => {
  //       this.omapi
  //         .get_latest_app_version()
  //         .then((response: any) => {
  //           console.log('response', response.data);
  //           this.localStorageService.set('app_version', response.data);

  //           // check 是否有新版本
  //           var local_app_version = this.globals.APP_VERSION_CODE;
  //           var latest_app_version = '';

  //           if (this.platform.is('ios')) {
  //             latest_app_version = response.data.latest_version_code_ios;
  //           } else if (this.platform.is('android')) {
  //             latest_app_version = response.data.latest_version_code_android;
  //           } else {
  //             console.log('not ios/android..ignore app version check..');
  //             latest_app_version = response.data.latest_version_code_ios;
  //           }

  //           var result: any = {};
  //           result.app_url = response.data.app_url;

  //           if (latest_app_version) {
  //             if (parseInt(latest_app_version) > parseInt(local_app_version)) {
  //               console.log('有新版');
  //               result.show = 'Y';
  //             } else {
  //               console.log('無新版');
  //               result.show = 'N';
  //             }
  //           } else {
  //             console.log('版本資料取得錯誤');
  //             result.show = 'N';
  //           }

  //           //儲存cache data
  //           this.localStorageService.set('app_version_check_fetch_time', new Date().getTime());
  //           resolve(result);
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //           reject(error);
  //         });
  //     };

  //     if (this.localStorageService.get('app_version_check_fetch_time')) {
  //       //取出上一次fetch time
  //       var last_fetch_time: any = this.localStorageService.get('app_version_check_fetch_time');
  //       var expire_flag = new Date().getTime() - last_fetch_time > cacheExpirationSeconds * 1000;

  //       console.log('last_app_version_check_fetch_time', last_fetch_time);
  //       console.log('expire_flag', expire_flag);

  //       if (expire_flag) {
  //         // 已經超過cache expire time -> call api
  //         api_call();
  //       } else {
  //         // 尚未超過cache expire time -> 就不顯示提示
  //         reject('too_near');
  //       }
  //     } else {
  //       // call api
  //       api_call();
  //     }
  //   });
  // }

  // showAppUpdateAlert(app_url: string) {
  //   console.log('showAppUpdateAlert..', app_url);

  //   return new Promise(async (resolve, reject) => {
  //     let alert = await this.alertCtrl.create({
  //       header: '提示',
  //       message: '有新版本的APP可以更新，是否現在就去更新？',
  //       buttons: [
  //         {
  //           text: '先不用',
  //           role: 'cancel',
  //           handler: () => {
  //             console.log('Cancel clicked');

  //             reject('cancel');
  //           },
  //         },
  //         {
  //           text: '好，去更新',
  //           handler: () => {
  //             console.log('confirm clicked');
  //             this.iab_browser_with_system(app_url);
  //             resolve('OK');
  //           },
  //         },
  //       ],
  //     });
  //     await alert.present();
  //   });
  // }

  getRandom(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  formatDate(date) {
    return format(parseISO(date), 'yyyy-MM-dd');
  }

  isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  padLeadingZeros(num, size) {
    let s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  }

  sleep(ms) {
    return new Promise((resolve) => {
      return setTimeout(resolve, ms);
    });
  }
}
