import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PostInfo } from '@mommy/models/PostInfo.model';

@Component({
  selector: 'app-pedia-post-list',
  templateUrl: './pedia-post-list.component.html',
  styleUrls: ['./pedia-post-list.component.scss'],
})
export class PediaPostListComponent implements OnInit {
  @Output() postOnClick = new EventEmitter<PostInfo>();

  _post: PostInfo;

  constructor() {}

  ngOnInit() {}

  get post(): any {
    return this._post;
  }

  @Input() set post(value: any) {
    if (value) {
      this._post = value;
      // console.log('_post', value);
    } else {
      this._post = {};
    }
  }

  postClick() {
    this.postOnClick.emit(this._post);
  }
}
