export class InitLocalCacheHospitals {
  static readonly type = '[Hospital] InitLocalCacheHospitals';
}

export class LoadCacheHospitals {
  static readonly type = '[Hospital] LoadCacheHospitals';
}

export class RefreshHospitals {
  static readonly type = '[Hospital] RefreshHospitals';
}

export class GetHospitalDetail {
  static readonly type = '[Hospital] GetHospitalDetail';
  constructor(public hospital_id: number) {}
}

// 計算醫院距離
export class CalculateHospitalDistance {
  static readonly type = '[Hospital] CalculateHospitalDistance';
  constructor(public user_lat: number, public user_lon: number) {}
}

// 更新 HospitalState.hospitals
export class SetHospitalsToHospitalState {
  static readonly type = '[Hospital] SetHospitalsToHospitalState';
  constructor(public payload: any) {}
}

// HospitalClick log
export class HospitalClickLog {
  static readonly type = '[Hospital] HospitalClickLog';
  constructor(public hospital_id: number) {}
}

// HospitalElineClick log
export class HospitalElineClickLog {
  static readonly type = '[Hospital] HospitalElineClickLog';
  constructor(public hospital_id: number) {}
}
