export class InitLocalCacheECCommodityCategories {
  static readonly type = '[EC] InitLocalCacheECCommodityCategories';
}

export class LoadCacheECCommodityCategories {
  static readonly type = '[EC] LoadCacheECCommodityCategories';
}

export class RefreshECCommodityCategories {
  static readonly type = '[EC] RefreshECCommodityCategories';
}

// 取得EC首頁商品list (不含咪幣兌換商品)(線上)
// 在state處理分頁的邏輯
export class GetECHomeCommodityList {
  static readonly type = '[EC] GetECHomeCommodityList';
}

// 搜尋商品list (不含咪幣兌換商品) (線上)
// 在state處理分頁的邏輯
export class SearchECCommodityList {
  static readonly type = '[EC] SearchECCommodityList';
  constructor(public keyword: string) {}
}

// 重置EC搜尋商品list的分頁資訊
export class ResetSearchECCommodityListPaganation {
  static readonly type = '[EC] ResetSearchECCommodityListPaganation';
}

// 取得EC Category detail, 包含放在此目錄內的商品list (線上)
// 在此處理分頁的邏輯
export class GetECCommodityCategoryDetail {
  static readonly type = '[EC] GetCommodityCategoryDetail';
  constructor(public category_id: number) {}
}

// 取得EC首頁商品detail (線上)
export class GetECCommodityDetail {
  static readonly type = '[EC] GetECCommodityDetail';
  constructor(public commodity_id: number) {}
}

// 取得EC隱形賣場商品detail (線上)
export class GetECSpecialCommodityDetail {
  static readonly type = '[EC] GetECSpecialCommodityDetail';
  constructor(public commodity_id: number) {}
}

// 取得EC首頁熱門活動商品list(我的最愛目錄) (線上)
export class GetECFavoriteCommodityList {
  static readonly type = '[EC] GetECFavoriteCommodityList';
}

// 取得咪幣兌換目錄內的商品list(咪幣目錄) (線上)
export class GetECCoinExchangeCommodityList {
  static readonly type = '[EC] GetECCoinExchangeCommodityList';
}

// 搜尋商品list by tag (不含咪幣兌換商品) (線上)
export class SearchECCommodityListByTag {
  static readonly type = '[EC] SearchECCommodityListByTag';
  constructor(public tag: string) {}
}
