import { MemberVoteInfo } from '@mommy/models/MemberVoteInfo.model';

export class InitLocalCacheVote {
  static readonly type = '[Vote] InitLocalCache';
}

export class LoadCacheVote {
  static readonly type = '[Vote] LoadCache';
}

export class RefreshVote {
  static readonly type = '[Vote] Refresh';
}

export class GetVoteDetail {
  static readonly type = '[Vote] GetVoteDetail';
  constructor(public vote_id: number) {}
}

export class AddMemberVote {
  static readonly type = '[Vote] AddMemberVote';
  constructor(public payload: MemberVoteInfo) {}
}

export class UpdateMemberVote {
  static readonly type = '[Vote] UpdateMemberVote';
  constructor(public payload: MemberVoteInfo) {}
}

// 更新 VoteState.votes
export class SetVotesToVoteState {
  static readonly type = '[Vote] SetVotesToVoteState';
  constructor(public payload: any) {}
}

// 增加 vote檢舉評論id 的紀錄
export class AddVoteReportCommentId {
  static readonly type = '[Vote] AddVoteReportCommentId';
  constructor(public comment_id: number) {}
}
