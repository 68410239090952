import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-login-popup-modal',
  templateUrl: './login-popup-modal.page.html',
  styleUrls: ['./login-popup-modal.page.scss'],
})
export class LoginPopupModalPage implements OnInit {
  constructor(public modalController: ModalController) {}

  ngOnInit() {}

  login() {
    this.modalController.dismiss(true);
  }
}
