import { Injectable } from '@angular/core';
import {
  ApolloLink,
  defaultDataIdFromObject,
  InMemoryCache,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { environment } from '@mommy/environments/environment';
import { HttpLink } from 'apollo-angular/http';

@Injectable({
  providedIn: 'root',
})
export class ApolloClientService {
  cache: InMemoryCache;

  constructor(private httpLink: HttpLink) {
    this.cache = new InMemoryCache({
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      dataIdFromObject(responseObject) {
        switch (responseObject.__typename) {
          case 'posts':
            return '' + responseObject.id;
          default:
            return defaultDataIdFromObject(responseObject);
        }
      },
    });
  }

  getOptionsPromise() {
    return new Promise(async (resolve, reject) => {
      // await before instantiating ApolloClient, else queries might run before the cache is persisted

      // await persistCache({
      //   cache: this.cache,
      //   storage: window.localStorage,
      // });

      // await persistCache({
      //   cache: this.cache,
      //   storage: {
      //     getItem: key => this.storage.get(key),
      //     setItem: (key, data) => {
      //       console.log('key', key);
      //       console.log('data', data);
      //       const dd = JSON.parse(<string>data);
      //       console.log('data', dd);
      //       this.storage.set(key, dd);
      //     },
      //     removeItem: key => this.storage.remove(key),
      //   },
      // });

      console.log('resolve getOptionsPromise..');
      resolve(true);
    });
  }

  getOptions() {
    const uri = environment.GRAPHQL_END_POINT;
    const basic = setContext((operation, context) => ({
      headers: {
        Accept: 'charset=utf-8',
      },
    }));

    // *** authorization Bearer header 移到 token.interceptor.ts 處理了 *** //
    // // Get the authentication token from local storage if it exists
    // const auth = setContext((operation, context) => {
    //   const token = localStorage.getItem('token');
    //   return {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   };
    // });

    // const link = ApolloLink.from([basic, auth, this.httpLink.create({ uri })]);

    const link = ApolloLink.from([basic, this.httpLink.create({ uri })]);
    // const cache = new InMemoryCache();
    const cache = this.cache;

    console.log('link', link);
    console.log('cache', cache);

    return {
      link,
      cache,
    };
  }
}
