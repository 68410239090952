import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ExpertInfo } from '@mommy/models/ExpertInfo.model';
import { PostInfo } from '@mommy/models/PostInfo.model';
import { UserState } from '@mommy/state/user/user.state';
import { Select } from '@ngxs/store';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-expert-post-list',
  templateUrl: './expert-post-list.component.html',
  styleUrls: ['./expert-post-list.component.scss'],
})
export class ExpertPostListComponent implements OnInit, OnDestroy {
  @Select(UserState.mommy_user) mommy_user$;
  private ngUnsubscribe = new Subject();

  @Output() postOnClick = new EventEmitter<PostInfo>();
  @Output() expertOnClick = new EventEmitter<ExpertInfo>();
  @Output() expertAddWatchOnClick = new EventEmitter<ExpertInfo>();
  @Output() expertRemoveWatchOnClick = new EventEmitter<ExpertInfo>();

  _feature_image: string;
  _post: PostInfo;
  _section: string;

  _has_watch = false;

  constructor() {}

  ngOnInit() {
    // user$, 訂閱 user 資料, 以判斷是否有加入關注
    this.mommy_user$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((mommy_user) => {
        console.log('mommy_user$', mommy_user);

        if (mommy_user) {
          const expert = _.find(mommy_user.expert_watchs, {
            expert_id: this._post?.post_author?.expert_id,
          });
          if (expert) {
            this._has_watch = true;
          } else {
            this._has_watch = false;
          }
        } else {
          this._has_watch = false;
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get post(): any {
    return this._post;
  }

  @Input() set post(value: any) {
    if (value) {
      this._post = value;
      console.log('_post', value);
      this._feature_image =
        `url(${value.post_feature_image_url})` +
        ', url(assets/img/default/pgc_post_default.png)';
      console.log('_feature_image', this._feature_image);
      this._section = value.post_author?.subject_classes?.[0]?.taxonomy_name;
    } else {
      this._post = {};
    }
  }

  postClick() {
    this.postOnClick.emit(this._post);
  }

  expertClick() {
    this.expertOnClick.emit(this._post.post_author);
  }

  addWatchClick(event) {
    event.stopPropagation();
    this.expertAddWatchOnClick.emit(this._post.post_author);
  }

  removeWatchClick(event) {
    event.stopPropagation();
    this.expertRemoveWatchOnClick.emit(this._post.post_author);
  }
}
